export const kFloorCoverings = {
  "Bare concrete, no covering": 0,
  "Asphalt tile": 0.05,
  "Rubber tile": 0.05,
  "Light carpet": 0.6,
  "Light carpet with rubber pad": 1,
  "Light carpet with light pad": 1.4,
  "Light carpet with heavy pad": 1.7,
  "Heavy carpet": 0.8,
  "Heavy carpet with rubber pad": 1.2,
  "Heavy carpet with light pad": 1.6,
  "Heavy carpet with heavy pad": 1.9,
  "3/8 in. hardwood": 0.54,
  "5/8 in. wood floor (oak)": 0.57,
  "1/2 in. oak parquet and pad": 0.68,
  "Linoleum": 0.12,
  "Marble floor and mudset": 0.18,
  "Rubber pad": 0.62,
  "Prime urethane underlayment, 3/8 in.": 1.61,
  "48 oz. waffled sponge rubber": 0.78,
  "Bonded urethane, 1/2 in.": 2.09,
  "Carpet and rebounded urethane pad": 2.38,
  "Carpet and rubber pad (one-piece)": 0.68,
  "Pile carpet with rubber pad": 1.59,
  "Linoleum/cork tile": 0.51,
  "PVC/rubber floor covering rubber tile": 0.34,
  "PVC/rubber floor covering terazzo": 0.08
};
 
