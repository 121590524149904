import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn } from '../SharedUtils.js'
import { lookupData } from '../Base.js'

import Papa from 'papaparse';

// Cols are numbered A-Z, then AA-AZ, and so on
export function colStrToIndex(colStr) {
  if (!colStr) {
    throw new Error(`Invalid colStr: ${colStr}`);
  }
  let colIndex = 0;
  for (let i = 0; i < colStr.length; ++i) {
    let offset = colStr.charCodeAt(colStr.length - 1 - i) - 'A'.charCodeAt(0) + 1;
    colIndex += offset * Math.pow(26, i);
  }
  return colIndex - 1;
}

function dumpCsvRows(rows) {
  let str = "";
  for (let i = 0; i < rows.length; ++i) {
    str += `${i}. ${rows[i].join(', ')}\n`
  }
  console.log("CSV:\n" + str);
}

export class CsvHelper {
  constructor(csvData) {
    // TODO - handle parse errors (unit test that this throws, or wrap it)
    this.csv = Papa.parse(csvData);
    // console.log("Data:\n" + prettyJson(this.csv.data));
  }

  numRows() {
    return this.csv.data.length;
  }

  numCols() {
    return this.csv.data[0].length;
  }

  colStrToIndex(colStr) {
    return colStrToIndex(colStr);
  }

  lookupValue(rowObj, colObj, resultType) {
    let rowIndex = null;
    if (typeof rowObj == 'number') {
      rowIndex = rowObj;
    } else if (typeof rowObj == 'string') {
      rowIndex = this._getRowIndexFromId(rowObj);
    } else {
      throw new Error(`Unexpected type for rowObj: ` + (typeof rowObj))
    }
    let colIndex = null;
    if (typeof colObj == 'number') {
      colIndex = colObj;
    } else if (typeof colObj == 'string') {
      colIndex = this.colStrToIndex(colObj);
    } else {
      throw new Error(`Unexpected type for colObj: ` + (typeof colObj))
    }

    if (!(0 <= rowIndex && rowIndex < this.csv.data.length)) {
      throw new Error("Row is out of range: " + rowIndex);
    }
    let rowData = this.csv.data[rowIndex];
    if (!(0 <= colIndex && colIndex < rowData.length)) {
      console.log(`Col is out of range: ${colIndex}, rowData:`, rowData);
      throw new Error("Col is out of range: " + colIndex);
    }
    resultType = (resultType === undefined) ? Number : resultType;
    return resultType(rowData[colIndex]);
  }

  _getRowIndexFromId(rowIdStr) {
    // The first column contains some "row ids". We find
    // the row with the one we want.
    for (let i = 0; i < this.csv.data.length; ++i) {
      let row = this.csv.data[i];
      if (row[0] == rowIdStr) {
        return i;
      }
    }
    throw new Error("Row not found for the given rowId: " + rowIdStr);
  }
};

