<script setup>
import { ref } from 'vue'
import { gApp, InteriorShadingType, InteriorShadeType, ShadesOrientation, } from './State.js'
import { formatNum, removeElem, generateItemName, isDupName, } from './SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'

let proj = gApp.proj();
let shadings = proj.interiorShadingTypes;
let shading = ref(null);
let deleteModal = ref(null);

function selectShading(newShading) {
  shading.value = newShading;
}

function deleteShading(someShading) {
  deleteModal.value.showModal("Delete", `Delete shading type ${someShading.name.value}?`, () => {
    removeElem(shadings, someShading);
  });
}

function addShading() {
  shadings.push(InteriorShadingType.create(generateItemName('InShading', shadings), true))
  selectShading(shadings[shadings.length - 1]);
}

</script>

<template>
  <div>
    <HelpSection id="InteriorShadingsView">
      <h4 class="mb-s">Interior Shading Types</h4>
      <p class="mb-s">
        Use this page to build different interior shading types for use in the Build House tab.
      </p>
      <p>For the <b>orientation</b>, if a shading device covers the window by moving from one side to the other, it is considered vertical. If it covers the window by moving from the top downward to the bottom, it is considered horizontal.</p>
      <p>Descriptions of each type of blinds/curtain:</p>
      <table class="SimpleTable">
          <tr>
              <td><b>Fabric Curtain:</b></td>
              <td>Drapery made of fabric or cloth-type material that is all one piece. Blinds that are made of many individual pieces that have an adjustable angle do not fall into this category.</td>
          </tr>
          <tr>
              <td><b>Louvered Shades:</b></td>
              <td>Shades that are made of many different slat-type pieces, whether made of wood, plastic, fabric, metal, or other material. Venetian blinds are a type of louvered shade. Louvered shades may be on the outside of a window as a fixed piece at a particular angle, and may even be in between panes of glass.</td>
          </tr>
          <tr>
              <td><b>Roller Shades:</b></td>
              <td>These are almost always of the horizontal type that either get pulled down from a roller at the top of the window, or are electronically lowered. The most common colour is black, however white is also common; other colours are unusual but do exist. This type of shading looks similar to a projector screen that gets pulled in a classroom, however they are usually slightly more translucent.</td>
          </tr>
      </table>

      <p>For each shading type, use the legends below to differentiate between the dropdown menu options:</p>

      <p><b>Fabric Curtains:</b></p>
      <table class="SimpleTable">
          <tr>
              <td>Open</td>
              <td>Details of objects can easily be seen.</td>
          </tr>
          <tr>
              <td>Semi-Open</td>
              <td>Large objects and shadows can easily be seen, but details are not visible.</td>
          </tr>
          <tr>
              <td>Closed</td>
              <td>No objects are visible through the curtain.</td>
          </tr>
      </table>

      <p><b>Louvered Shades:</b></p>
      <table class="SimpleTable">
          <tr>
              <td>Reflectiveness=0.15</td>
              <td>Dark-coloured slats. This will provide a more conservative estimate of cooling loads.</td>
          </tr>
          <tr>
              <td>Reflectiveness=0.5</td>
              <td>Typical slats, this will likely provide a more accurate cooling load calculation in most cases, unless it is known that the reflectiveness is significantly higher or lower.</td>
          </tr>
          <tr>
              <td>Reflectiveness=0.8</td>
              <td>Highly reflective slats.</td>
          </tr>
        </table>
        <table class="SimpleTable">
          <tr>
              <td>Unknown</td>
              <td>If unknown is selected, the software will take the worst-case scenario for the shade type. This is not recommended as it is unlikely that the shades would be positioned this way during design conditions.</td>
          </tr>
          <tr>
            <td>0&deg;</td>
              <td>Fully Open. This is not common during design conditions, because it would produce a similar result to the worst-case scenario. Therefore this is also not recommended. However, if a conservative estimate of cooling loads is needed, then this is a good selection.</td>
          </tr>
          <tr>
              <td>Track Solar Angle</td>
              <td>This will produce the best-case scenario for keeping out solar heat. However, this is highly unlikely unless the louvered shades are motorized and programmed to track the solar angle. If the shades are manually operated, this is not recommended.</td>
          </tr>
          <tr>
              <td>45&deg;</td>
              <td>This is a more realistic estimate for the position of louvered shades during design conditions, however some people prefer to have them fully closed.</td>
          </tr>
          <tr>
              <td>Fully Closed</td>
              <td>Fully closing the louvered shades is common during peak sunlight hours. This is another good choice for estimating peak loads accuracy. However, if a conservative estimate is needed, then this is not a good option.</td>
          </tr>
      </table>

      <p><b>Roller Shades:</b></p>
      <p>The options for roller shades are self-explanatory.</p>
    </HelpSection>
    <div v-if="shading">
      <div class="mb-m">
        <button @click="shading=null" class="LinkBtn mb-m"><i class="bi-arrow-left-circle mr-xxs"></i>Done Editing</button>
        <h1>{{ shading.name.value || 'Untitled' }}</h1>
      </div>
      <FieldInput v-model="shading.name" />
      <WarnText v-if="isDupName(shading.name.value, shadings)">There is already a shading with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <FieldInput v-model="shading.type" />
        <FieldGroup v-if="shading.type.value == InteriorShadeType.FabricCurtain" v-model="shading.fabricCurtainGroup" />
        <FieldGroup v-else-if="shading.type.value == InteriorShadeType.LouveredShades" v-model="shading.louveredShadesGroup" />
        <FieldGroup v-else-if="shading.type.value == InteriorShadeType.RollerShades" v-model="shading.rollerShadesGroup" />
        <FieldInput v-model="shading.orientation" />
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Interior Shadings</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text">
          Add an <b>Interior Shading Type</b> for each different type of interior shading used in your project. Then, you will be able to apply these shadings
          to your house's windows from the <b>Build House</b> page.
        </p>
      </div>
      <button @click="addShading" class="MarginBotXS CreateBtn">Add shading type</button>
      <table class="SimpleTable" v-if="shadings.length">
        <tr>
          <th>Name</th>
          <th>Style</th>
          <th>Details</th>
          <th>Orientation</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(shading, index) in shadings" class="WindowSection MarginBotL">
          <td>{{ shading.name.value }}</td>
          <td>{{ InteriorShadeType._labels[shading.type.value] }}</td>
          <td>{{ shading.getDetailsStr() }}</td>
          <td>{{ ShadesOrientation._labels[shading.orientation.value] }}</td>
          <td><button @click="selectShading(shading)">Edit</button></td>
          <td><button @click="deleteShading(shading)">Delete</button></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
</style>
