import * as ser from './SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { WindowsData, } from './MaterialData/Windows/WindowsData.js' 
import { CalcContext } from './CalcContext.js'
import { IACDebugger } from './IACDebugger.js'

import { Project, ProjectTypes } from './Project.js'

export { Units } from './Units.js'

import {
  kEpsilon,
  FieldType,
  kFieldTypesData,
  FieldInputType,
  Field,
  FieldGroup,
  ProjectUnits,
} from './Field.js'
export * from './Field.js'

import {
  WallType,
  RoofType,
  WindowType,
  InteriorShadingType,
  ExteriorShadingType,
  DoorType,
  SkylightType,
  BufferSpaceType,
  Wall,
  Roof,
  Partition,
  VentilationInfiltrationData,
  InternalsData,
  HouseFloor,
  HouseMiscDetails,
  ToplevelData,
} from './Components.js'
export * from './Components.js'

import { BuildingAndEnv } from './BuildingAndEnv.js'
import { System } from './System.js'
import { Space } from './Space.js'
import { Schedule } from './Schedule.js'

export class BuildingLoadResults {
  init(proj) {
    this.proj = proj

    // TODO
  }
}
setupClass(BuildingLoadResults);

class CommercialDebugData {
  init(proj) {
    this.proj = proj

    this.iacDebugger = IACDebugger.create(proj)
  }
}
setupClass(CommercialDebugData);

export class CommercialProject extends Project {    
  init(id) {
    super.init(id);

    this.type = ProjectTypes.Commercial;

    this.buildingAndEnv = BuildingAndEnv.create();
    this.systems = [];
    this.spaces = [];
    this.schedules = [];

    this.wallTypes = [];
    this.roofTypes = [];
    this.windowTypes = [];
    this.interiorShadingTypes = [];
    this.exteriorShadingTypes = [];
    this.doorTypes = [];
    this.skylightTypes = [];
    this.bufferSpaceTypes = [];

    this.windowsData = new WindowsData();

    this.loadResults = null;

    this.debugData = CommercialDebugData.create(this);

    // Hack for system results page
    this.resultsPageSelectedSystem = null;
  }

  get serFields() {
    return [
      ...super.serFields,

      'buildingAndEnv',
      ser.arrayField('systems', () => { return System.create(); }),
      ser.arrayField('spaces', () => { return Space.create(); }),
      ser.arrayField('schedules', () => { return Schedule.create(); }),

      ser.arrayField('wallTypes', () => { return WallType.create(); }),
      ser.arrayField('roofTypes', () => { return RoofType.create(); }),
      ser.arrayField('windowTypes', () => { return WindowType.create(); }),
      ser.arrayField('interiorShadingTypes', () => { return InteriorShadingType.create(); }),
      ser.arrayField('exteriorShadingTypes', () => { return ExteriorShadingType.create(); }),
      ser.arrayField('doorTypes', () => { return DoorType.create(); }),
      ser.arrayField('skylightTypes', () => { return SkylightType.create(); }),
      ser.arrayField('bufferSpaceTypes', () => { return BufferSpaceType.create(); }),
    ]
  }

  async onSetupNewProject() {
    console.log("Setting up new Commercial project...");

    // Set the default value to Winnipeg MB, Canada
    // Do this here b/c we only want to do on project create
    await this.buildingAndEnv.locationData.setLocation({
      fullName: 'Winnipeg, MB. Canada',
      name: 'Winnipeg',
      path: ['Canada', 'MB', 'Winnipeg'],
    });
  }

  getLocationData() {
    return this.buildingAndEnv.locationData;
  }

  getProjectUnits() {
    return ProjectUnits.Imperial;
  }

  getBaseRoute() {
    return `/building/${this.id}`;
  }

  getSystemWithId(id) {
    return this.systems.find((system) => system.id === id);
  }

  getResultsPageSelectedSystem() {
    if (this.resultsPageSelectedSystem === null) {
      if (this.systems.length > 0) {
        return this.systems[0];
      } else {
        return null;
      }
    }
    return this.resultsPageSelectedSystem;
  }

  setResultsPageSelectedSystem(system) {
    this.resultsPageSelectedSystem = system;
  }
};
setupClass(CommercialProject);