import { Field } from './Field.js';

export class FieldWithVariableUnits extends Field {
  constructor(data) {
    super(data);

    this.unitOptions = data.unitOptions;
    this.serFields = [
      ...this.serFields,
      'units',
    ];
  }
}

export class MultiTieredSelect extends Field {
  constructor(data) {
    super(data);
    this.type = 'MultiTieredSelect';
    this.value = [];

    this.optionsMap = data.optionsMap;
  }

  getShortValue() {
    if (!this.value.length) {
      return 'None';
    }
    let labels = this.getLabels();
    return labels[labels.length - 1];
  }

  getLabels() {
    let labels = []
    let curDict = this.optionsMap;
    for (let i = 0; i < this.value.length; i++) {
      let part = this.value[i]
      let enumType = curDict.type || curDict;
      labels.push(enumType._labels[part]);
      if (i + 1 < this.value.length) {
        curDict = curDict.children[part];
      }
    }
    return labels;
  }

  getLastLabel() {
    return this.getLabels()[this.getLabels().length - 1];
  }

  getOptionsForPath(path) {
    console.log('Looking up path:', path)
    let curDict = this.optionsMap;
    for (let i = 0; i < path.length; i++) {
      let part = path[i]
      if (!('children' in curDict)) {
        // We hit a leaf node
        return null
      }
      if (!(part in curDict.children)) {
        throw new Error(`Could not find path ${path.join('/')} in options for ${this.name}`);
      }
      curDict = curDict.children[part];
    }
    if (!('type' in curDict)) {
      return {
        type: curDict,
        children: []
      }
    }
    return curDict;
  }

  isLeafPath(path) {
    return this.getOptionsForPath(path) === null;
  }
}