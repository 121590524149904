export let WindowUvals = `
,Single Glazing,,,,,,,,,,,,,,,,,,,,,,,,,,,,
1,1/8 in. glass,,1.04,1.04,1.23,1.07,0.93,0.91,0.85,1.12,1.07,0.98,0.98,1.04,2.5,2.1,1.21,1.1,1.1,1.19,1.19,1.77,1.7,1.61,1.42,1.35,1.34,1.25,1
2,1/4 in. acrylic/polycarbonate,,0.88,0.88,1.1,0.94,0.81,0.8,0.74,0.98,0.92,0.84,0.84,0.88,2.24,1.84,1.06,0.96,0.96,1.03,1.03,1.6,1.54,1.45,1.31,1.2,1.2,1.1,2
3,1/8 in. acrylic/polycarbonate,,0.96,0.96,1.17,1.01,0.87,0.86,0.79,1.05,0.99,0.91,0.91,0.96,2.37,1.97,1.13,1.03,1.03,1.11,1.11,1.68,1.62,1.53,1.39,1.27,1.27,1.18,3
,Double Glazing,,,,,,,,,,,,,,,,,,,,,,,,,,,,
4,1/4 in. air space,,0.55,0.64,0.81,0.64,0.57,0.55,0.5,0.68,0.62,0.56,0.56,0.55,1.72,1.32,0.77,0.67,0.63,0.58,0.66,1.1,0.96,0.92,0.84,0.8,0.83,0.66,4
5,1/2 in. air space,,0.48,0.59,0.76,0.58,0.52,0.5,0.45,0.62,0.56,0.5,0.5,0.48,1.62,1.22,0.71,0.61,0.57,0.57,0.65,1.09,0.95,0.91,0.84,0.79,0.82,0.65,5
6,1/4 in. argon space,,0.51,0.61,0.78,0.61,0.54,0.52,0.47,0.65,0.59,0.53,0.52,0.51,1.66,1.26,0.74,0.63,0.59,0.53,0.63,1.05,0.91,0.87,0.8,0.76,0.8,0.62,6
7,1/2 in. argon space,,0.45,0.57,0.73,0.56,0.5,0.48,0.43,0.6,0.53,0.48,0.47,0.45,1.57,1.17,0.68,0.58,0.54,0.53,0.63,1.05,0.91,0.87,0.8,0.76,0.8,0.62,7
,"Double Glazing, e = 0.60 on surface 2 or 3",,,,,,,,,,,,,,,,,,,,,,,,,,,,
8,1/4 in. air space,,0.52,0.62,0.79,0.61,0.55,0.53,0.48,0.66,0.59,0.54,0.53,0.52,1.68,1.28,0.74,0.64,0.6,0.54,0.63,1.06,0.92,0.88,0.81,0.77,0.8,0.63,8
9,1/2 in. air space,,0.44,0.56,0.72,0.55,0.49,0.48,0.43,0.59,0.53,0.47,0.47,0.44,1.56,1.16,0.68,0.57,0.53,0.53,0.63,1.05,0.91,0.87,0.8,0.76,0.8,0.62,9
10,1/4 in. argon space,,0.47,0.58,0.75,0.57,0.51,0.5,0.45,0.61,0.55,0.49,0.49,0.47,1.6,1.2,0.7,0.6,0.56,0.49,0.6,1.01,0.87,0.83,0.76,0.72,0.77,0.58,10
11,1/2 in. argon space,,0.41,0.54,0.7,0.53,0.47,0.45,0.41,0.56,0.5,0.44,0.44,0.41,1.51,1.11,0.65,0.55,0.51,0.49,0.6,1.01,0.87,0.83,0.76,0.72,0.77,0.58,11
,"Double Glazing, e = 0.40 on surface 2 or 3",,,,,,,,,,,,,,,,,,,,,,,,,,,,
12,1/4 in. air space,,0.49,0.6,0.76,0.59,0.53,0.51,0.46,0.63,0.57,0.51,0.51,0.49,1.63,1.23,0.72,0.62,0.58,0.51,0.61,1.03,0.89,0.85,0.78,0.74,0.78,0.6,12
13,1/2 in. air space,,0.4,0.54,0.69,0.52,0.47,0.45,0.4,0.55,0.49,0.44,0.43,0.4,1.5,1.1,0.64,0.54,0.5,0.5,0.61,1.02,0.88,0.84,0.77,0.73,0.78,0.59,13
14,1/4 in. argon space,,0.43,0.56,0.72,0.54,0.49,0.47,0.42,0.58,0.52,0.46,0.46,0.43,1.54,1.14,0.67,0.56,0.52,0.44,0.56,0.96,0.83,0.78,0.72,0.68,0.74,0.54,14
15,1/2 in. argon space,,0.36,0.51,0.66,0.49,0.44,0.42,0.37,0.52,0.46,0.4,0.4,0.36,1.44,1.04,0.61,0.5,0.46,0.46,0.58,0.98,0.85,0.8,0.74,0.7,0.75,0.56,15
,"Double Glazing, e = 0.20 on surface 2 or 3",,,,,,,,,,,,,,,,,,,,,,,,,,,,
16,1/4 in. air space,,0.45,0.57,0.73,0.56,0.5,0.48,0.43,0.6,0.53,0.48,0.47,0.45,1.57,1.17,0.68,0.58,0.54,0.46,0.58,0.98,0.85,0.8,0.74,0.7,0.75,0.56,16
17,1/2 in. air space,,0.35,0.5,0.65,0.48,0.43,0.41,0.37,0.51,0.45,0.39,0.39,0.35,1.43,1.03,0.6,0.5,0.45,0.46,0.58,0.98,0.85,0.8,0.74,0.7,0.75,0.56,17
18,1/4 in. argon space,,0.38,0.52,0.68,0.51,0.45,0.43,0.39,0.54,0.47,0.42,0.42,0.38,1.47,1.07,0.62,0.52,0.48,0.39,0.53,0.91,0.78,0.74,0.68,0.64,0.7,0.5,18
19,1/2 in. argon space,,0.3,0.46,0.61,0.45,0.39,0.38,0.33,0.47,0.41,0.35,0.35,0.3,1.35,0.95,0.55,0.45,0.41,0.4,0.54,0.92,0.79,0.75,0.68,0.64,0.71,0.51,19
,"Double Glazing, e = 0.10 on surface 2 or 3",,,,,,,,,,,,,,,,,,,,,,,,,,,,
20,1/4 in. air space,,0.42,0.55,0.71,0.54,0.48,0.46,0.41,0.57,0.51,0.45,0.45,0.42,1.53,1.13,0.66,0.56,0.51,0.44,0.56,0.96,0.83,0.78,0.72,0.68,0.74,0.54,20
21,1/2 in. air space,,0.32,0.48,0.63,0.46,0.41,0.39,0.34,0.49,0.42,0.37,0.37,0.32,1.38,0.98,0.57,0.47,0.43,0.44,0.56,0.96,0.83,0.78,0.72,0.68,0.74,0.54,21
22,1/4 in. argon space,,0.35,0.5,0.65,0.48,0.43,0.41,0.37,0.51,0.45,0.39,0.39,0.35,1.43,1.03,0.6,0.5,0.45,0.36,0.51,0.88,0.75,0.71,0.65,0.61,0.68,0.47,22
23,1/2 in. argon space,,0.27,0.44,0.59,0.42,0.37,0.36,0.31,0.44,0.38,0.33,0.32,0.27,1.3,0.9,0.53,0.43,0.38,0.38,0.52,0.9,0.77,0.73,0.67,0.63,0.69,0.49,23
,"Double Glazing, e = 0.05 on surface 2 or 3",,,,,,,,,,,,,,,,,,,,,,,,,,,,
24,1/4 in. air space,,0.41,0.54,0.7,0.53,0.47,0.45,0.41,0.56,0.5,0.44,0.44,0.41,1.51,1.11,0.65,0.55,0.51,0.42,0.55,0.94,0.81,0.76,0.7,0.66,0.72,0.52,24
25,1/2 in. air space,,0.3,0.46,0.61,0.45,0.39,0.38,0.33,0.47,0.41,0.35,0.35,0.3,1.35,0.95,0.55,0.45,0.41,0.43,0.56,0.95,0.82,0.77,0.71,0.67,0.73,0.53,25
26,1/4 in. argon space,,0.33,0.48,0.64,0.47,0.42,0.4,0.35,0.49,0.43,0.38,0.37,0.33,1.4,1,0.58,0.48,0.44,0.34,0.49,0.86,0.73,0.69,0.63,0.59,0.66,0.45,26
27,1/2 in. argon space,,0.25,0.42,0.57,0.41,0.36,0.34,0.3,0.43,0.36,0.31,0.31,0.25,1.27,0.87,0.51,0.41,0.37,0.36,0.51,0.88,0.75,0.71,0.65,0.61,0.68,0.47,27
,Triple Glazing,,,,,,,,,,,,,,,,,,,,,,,,,,,,
28,1/4 in. air spaces,,0.38,0.52,0.67,0.49,0.43,0.43,0.38,0.53,0.47,0.42,0.42,0.38,see note 7,see note 7,0.61,0.51,0.46,0.39,0.53,0.9,0.75,0.71,0.64,0.62,0.69,0.48,28
29,1/2 in. air spaces,,0.31,0.47,0.61,0.44,0.38,0.38,0.34,0.47,0.41,0.36,0.36,0.31,,,0.55,0.45,0.4,0.36,0.51,0.87,0.72,0.68,0.61,0.6,0.67,0.45,29
30,1/4 in. argon spaces,,0.34,0.49,0.63,0.46,0.41,0.4,0.36,0.5,0.44,0.38,0.38,0.34,,,0.58,0.48,0.43,0.35,0.5,0.86,0.71,0.67,0.6,0.59,0.66,0.44,30
31,1/2 in. argon spaces,,0.29,0.45,0.59,0.42,0.37,0.36,0.32,0.45,0.4,0.34,0.34,0.29,,,0.53,0.43,0.38,0.33,0.48,0.84,0.69,0.65,0.59,0.57,0.65,0.42,31
,"Triple Glazing, e = 0.20 on surface 2, 3, 4, or 5",,,,,,,,,,,,,,,,,,,,,,,,,,,,
32,1/4 in. air spaces,,0.33,0.48,0.62,0.45,0.4,0.39,0.35,0.49,0.43,0.37,0.37,0.33,see note 7,see note 7,0.57,0.47,0.42,0.34,0.49,0.85,0.7,0.66,0.59,0.58,0.65,0.43,32
33,1/2 in. air spaces,,0.25,0.42,0.56,0.39,0.34,0.33,0.29,0.42,0.36,0.31,0.31,0.25,,,0.5,0.4,0.35,0.31,0.47,0.82,0.67,0.63,0.57,0.56,0.63,0.41,33
34,1/4 in. argon spaces,,0.28,0.45,0.58,0.41,0.36,0.36,0.31,0.45,0.39,0.33,0.33,0.28,,,0.53,0.43,0.37,0.28,0.45,0.8,0.64,0.6,0.54,0.53,0.61,0.38,34
35,1/2 in. argon spaces,,0.22,0.4,0.54,0.37,0.32,0.31,0.27,0.39,0.33,0.28,0.28,0.22,,,0.47,0.37,0.32,0.27,0.44,0.79,0.63,0.59,0.53,0.52,0.6,0.37,35
,"Triple Glazing, e = 0.20 on surfaces 2 or 3 and 4 or 5",,,,,,,,,,,,,,,,,,,,,,,,,,,,
36,1/4 in. air spaces,,0.29,0.45,0.59,0.42,0.37,0.36,0.32,0.45,0.4,0.34,0.34,0.29,see note 7,see note 7,0.53,0.43,0.38,0.29,0.45,0.81,0.65,0.61,0.55,0.54,0.62,0.39,36
37,1/2 in. air spaces,,0.2,0.39,0.52,0.35,0.31,0.3,0.26,0.38,0.32,0.26,0.26,0.2,,,0.46,0.36,0.3,0.27,0.44,0.79,0.63,0.59,0.53,0.52,0.6,0.37,37
38,1/4 in. argon spaces,,0.23,0.41,0.54,0.37,0.33,0.32,0.28,0.4,0.34,0.29,0.29,0.23,,,0.48,0.38,0.33,0.24,0.42,0.76,0.6,0.57,0.5,0.49,0.58,0.35,38
39,1/2 in. argon spaces,,0.17,0.36,0.49,0.33,0.28,0.28,0.24,0.35,0.29,0.24,0.24,0.17,,,0.43,0.33,0.28,0.22,0.4,0.74,0.58,0.55,0.49,0.48,0.57,0.33,39
,"Triple Glazing, e = 0.10 on surfaces 2 or 3 and 4 or 5",,,,,,,,,,,,,,,,,,,,,,,,,,,,
40,1/4 in. air spaces,,0.27,0.44,0.58,0.4,0.36,0.35,0.31,0.44,0.38,0.32,0.32,0.27,see note 7,see note 7,0.52,0.42,0.37,0.27,0.44,0.79,0.63,0.59,0.53,0.52,0.6,0.37,40
41,1/2 in. air spaces,,0.18,0.37,0.5,0.34,0.29,0.28,0.25,0.36,0.3,0.25,0.25,0.18,,,0.44,0.34,0.29,0.25,0.42,0.77,0.61,0.57,0.51,0.5,0.59,0.36,41
42,1/4 in. argon spaces,,0.21,0.39,0.53,0.36,0.31,0.31,0.27,0.38,0.33,0.27,0.27,0.21,,,0.46,0.36,0.31,0.21,0.39,0.73,0.57,0.54,0.48,0.47,0.56,0.32,42
43,1/2 in. argon spaces,,0.14,0.34,0.47,0.3,0.26,0.26,0.22,0.32,0.27,0.21,0.21,0.14,,,0.4,0.3,0.25,0.2,0.39,0.72,0.56,0.53,0.47,0.46,0.55,0.31,43
,"Quadruple Glazing, e = 0.10 on surfaces 2 or 3 and 4 or 5",,,,,,,,,,,,,,,,,,,,,,,,,,,,
44,1/4 in. air spaces,,0.22,0.4,0.54,0.37,0.32,0.31,0.27,0.39,0.33,0.28,0.28,0.22,see note 7,see note 7,0.47,0.37,0.32,0.22,0.4,0.74,0.58,0.55,0.49,0.48,0.57,0.33,44
45,1/2 in. air spaces,,0.15,0.35,0.48,0.31,0.27,0.26,0.23,0.33,0.27,0.22,0.22,0.15,,,0.41,0.31,0.26,0.19,0.38,0.71,0.55,0.52,0.46,0.45,0.54,0.3,45
46,1/4 in. argon spaces,,0.17,0.36,0.49,0.33,0.28,0.28,0.24,0.35,0.29,0.24,0.24,0.17,,,0.43,0.33,0.28,0.18,0.37,0.7,0.54,0.51,0.45,0.44,0.54,0.29,46
47,1/2 in. argon spaces,,0.12,0.32,0.45,0.29,0.25,0.24,0.2,0.31,0.25,0.2,0.2,0.12,,,0.39,0.29,0.23,0.16,0.35,0.68,0.52,0.49,0.43,0.42,0.52,0.28,47
48,1/4 in. krypton spaces,,0.12,0.32,0.45,0.29,0.25,0.24,0.2,0.31,0.25,0.2,0.2,0.12,,,0.39,0.29,0.23,0.13,0.33,0.65,0.49,0.46,0.4,0.4,0.5,0.25,48
`
