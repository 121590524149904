import { setupClass } from "./Base.js";
import { prettyJson } from "./SharedUtils.js";
import { Field, FieldType, } from "./Field.js";
import { IACCalculator } from "./IACCalculator.js";

export class IACDebugger {
  init(proj) {
    this.proj = proj

    this.winType = Field.makeTypeSelect('Type', proj.windowTypes, null, {
      errorWhenEmpty: 'You must create a Window Type.',
    })
    this.inShadingType = Field.makeTypeSelect('Type', proj.interiorShadingTypes, null, {
      errorWhenEmpty: 'You must create an Interior Shading Type.',
    })
    this.outputIAC = new Field({
      name: 'Output IAC',
      type: FieldType.Decimal,
      isOutput: true,
    })
    this.outputIAC.makeUpdater((field) => {
      let iacRes = this.calculateIAC()
      field.value = iacRes.iac
      field.debugOutput = prettyJson(iacRes)
    })
  }

  calculateIAC() {
    console.log('Calculating IAC...')
    let iacValues = this.proj.windowsData.iacValues;
    let winType = this.winType.lookupValue()
    let inShadingType = this.inShadingType.lookupValue()
    let calculator = new IACCalculator(winType, inShadingType, iacValues)
    let res = calculator.computeIAC()
    console.log('IAC:', res)
    return res
  }
};
setupClass(IACDebugger)