
// This gets set to the global app object
export var gApp = null

export function setGlobalApp(app) {
  gApp = app;
}

export function DebugOn() {
  return gApp.proj().debugOn; 
}
