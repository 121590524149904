<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import SimpleCollapse from './SimpleCollapse.vue';
import DataTable from './DataTable.vue';

const props = defineProps({
  title: String,
  data: Object
})

let showData = ref(false);

</script>

<template>
  <div class="PopupRefTable">
    <button class="LinkBtn small-upper mb-xs" @click="showData=!showData">{{title}}</button>
    <div v-if="showData" class="Content">
      <DataTable :data="data" />
    </div>
  </div>
</template>

<style scoped>

.PopupRefTable {
}

.Content {
  border: 1px solid lightgrey;
  padding: var(--s);
  max-height: 600px;
  overflow-y: auto;
}

</style>
