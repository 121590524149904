
import { makeEnum, makeEnumWithDataAndLabels } from "./Base";

/*
Medical equipment
Laboratory equipment
Cooking appliances
Dishwashing equipment
Office equipment
Miscellaneous machines
*/
export let ApplianceCategory = makeEnum({
  MedicalEquipment: 'Medical equipment',
  LabratoryEquipment: 'Laboratory equipment',
  CookingAppliances: 'Cooking appliances',
  DishwashingEquipment: 'Dishwashing equipment',
  OfficeEquipment: 'Office equipment',
  MiscellaneousMachines: 'Miscellaneous machines',
})

/*
Unhooded
Hooded, gas
Hooded, electric
Hooded, solid fuel
*/
export let CookingApplianceTypes = makeEnum({
  Unhooded: 'Unhooded',
  HoodedGas: 'Hooded, gas',
  HoodedElectric: 'Hooded, electric',
  HoodedSolidFuel: 'Hooded, solid fuel',
})

/*
Unhooded cooking appliances:
Cabinet; hot serving (large), insulated
Cabinet; hot serving (large), uninsulate
Cabinet; proofing (large)
Cabinet; proofing (small 15-shelf)
Cheesemelter
Coffee brewing urn
Drawer warmers, 2-drawer (moist holding)
Egg cooker
Espresso machine
Food warmer: steam table (2-well-type)
Freezer (small)
Fryer, countertop, open deep fat
Griddle, countertop
Hot dog roller
Hot plate; single element
Hot-food case (dry holding)
Hot-food case (moist holding)
Induction hob, countertop
Microwave oven: commercial
Oven; countertop conveyorized
Panini
Popcorn popper
Rapid-cook oven (quartz-halogen)
Rapid-cook oven (microwave/convection)
Reach-in refrigerator
Refrigerated prep table
Rice cooker
Soup warmer
Steamer (bun)
Steamer, countertop
Toaster; 4-slice pop up (large): cooking
Toaster; contact (vertical)
Toaster; large conveyor
Toaster; small conveyor
Tortilla grill
Waffle iron
*/
export let UnhoodedCookingAppliances = makeEnum({
  CabinetHotServingLargeInsulated: 'Cabinet; hot serving (large), insulated',
  CabinetHotServingLargeUninsulated: 'Cabinet; hot serving (large), uninsulate',
  CabinetProofingLarge: 'Cabinet; proofing (large)',
  CabinetProofingSmall15Shelf: 'Cabinet; proofing (small 15-shelf)',
  Cheesemelter: 'Cheesemelter',
  CoffeeBrewingUrn: 'Coffee brewing urn',
  DrawerWarmers2DrawerMoistHolding: 'Drawer warmers, 2-drawer (moist holding)',
  EggCooker: 'Egg cooker',
  EspressoMachine: 'Espresso machine',
  FoodWarmerSteamTable2WellType: 'Food warmer: steam table (2-well-type)',
  FreezerSmall: 'Freezer (small)',
  FryerCountertopOpenDeepFat: 'Fryer, countertop, open deep fat',
  GriddleCountertop: 'Griddle, countertop',
  HotDogRoller: 'Hot dog roller',
  HotPlateSingleElement: 'Hot plate; single element',
  HotFoodCaseDryHolding: 'Hot-food case (dry holding)',
  HotFoodCaseMoistHolding: 'Hot-food case (moist holding)',
  InductionHobCountertop: 'Induction hob, countertop',
  MicrowaveOvenCommercial: 'Microwave oven: commercial',
  OvenCountertopConveyorized: 'Oven; countertop conveyorized',
  Panini: 'Panini',
  PopcornPopper: 'Popcorn popper',
  RapidCookOvenQuartzHalogen: 'Rapid-cook oven (quartz-halogen)',
  RapidCookOvenMicrowaveConvection: 'Rapid-cook oven (microwave/convection)',
  ReachInRefrigerator: 'Reach-in refrigerator',
  RefrigeratedPrepTable: 'Refrigerated prep table',
  RiceCooker: 'Rice cooker',
  SoupWarmer: 'Soup warmer',
  SteamerBun: 'Steamer (bun)',
  SteamerCountertop: 'Steamer, countertop',
  Toaster4SlicePopUpLargeCooking: 'Toaster; 4-slice pop up (large): cooking',
  ToasterContactVertical: 'Toaster; contact (vertical)',
  ToasterLargeConveyor: 'Toaster; large conveyor',
  ToasterSmallConveyor: 'Toaster; small conveyor',
  TortillaGrill: 'Tortilla grill',
  WaffleIron: 'Waffle iron',
})

/*
Hooded electric cooking appliances:
Broiler, batch
Broiler, chain (conveyor)
Broiler, overfired (upright)
Broiler, underfired
Fryer, doughnut
Fryer, open deep-fat, 1 vat
Fryer, pressure
Griddle, double sided 3 ft, clamshell down
Griddle, clamshell up
Griddle, flat 3 ft
Oven, combi: combi-mode
Oven, combi: convection mode
Oven, convection, full-size
Oven, conveyor (pizza)
Oven, deck
Oven, rack mini-rotating
Pasta cooker
Range top, top off/oven on
Range top, 3 burners on/oven off
Range top, 6 burners on/oven off
Range top, 6 burners on/oven on
Range: wok
Rethermalizer
Rice cooker
Salamander
Steam kettle, large (60 gal) simmer lid down
Steam kettle, small (10 gal) simmer lid down
Steam kettle, medium (40 gal) simmer lid down
Steamer: compartment: atmospheric
Tilting skillet/braising pan
*/
export let HoodedElectricCookingAppliances = makeEnum({
  BroilerBatch: 'Broiler, batch',
  BroilerChainConveyor: 'Broiler, chain (conveyor)',
  BroilerOverfiredUpright: 'Broiler, overfired (upright)',
  BroilerUnderfired: 'Broiler, underfired',
  FryerDoughnut: 'Fryer, doughnut',
  FryerOpenDeepFat1Vat: 'Fryer, open deep-fat, 1 vat',
  FryerPressure: 'Fryer, pressure',
  GriddleDoubleSided3FtClamshellDown: 'Griddle, double sided 3 ft, clamshell down',
  GriddleClamshellUp: 'Griddle, clamshell up',
  GriddleFlat3Ft: 'Griddle, flat 3 ft',
  OvenCombiCombiMode: 'Oven, combi: combi-mode',
  OvenCombiConvectionMode: 'Oven, combi: convection mode',
  OvenConvectionFullSize: 'Oven, convection, full-size',
  OvenConveyorPizza: 'Oven, conveyor (pizza)',
  OvenDeck: 'Oven, deck',
  OvenRackMiniRotating: 'Oven, rack mini-rotating',
  PastaCooker: 'Pasta cooker',
  RangeTopTopOffOvenOn: 'Range top, top off/oven on',
  RangeTop3BurnersOnOvenOff: 'Range top, 3 burners on/oven off',
  RangeTop6BurnersOnOvenOff: 'Range top, 6 burners on/oven off',
  RangeTop6BurnersOnOvenOn: 'Range top, 6 burners on/oven on',
  RangeWok: 'Range: wok',
  Rethermalizer: 'Rethermalizer',
  RiceCooker: 'Rice cooker',
  Salamander: 'Salamander',
  SteamKettleLarge60GalSimmerLidDown: 'Steam kettle, large (60 gal) simmer lid down',
  SteamKettleSmall10GalSimmerLidDown: 'Steam kettle, small (10 gal) simmer lid down',
  SteamKettleMedium40GalSimmerLidDown: 'Steam kettle, medium (40 gal) simmer lid down',
  SteamerCompartmentAtmospheric: 'Steamer: compartment: atmospheric',
  TiltingSkilletBraisingPan: 'Tilting skillet/braising pan',
})

/*
HoodedElectricCookingAppliances:
Broiler: underfired 3 ft
Cheesemelter
Fryer, kettle
Fryer, open deep-fat, 1-vat
Fryer, pressure
Griddle, double-sided 3 ft (clamshell down)
Griddle, (Clamshell up)
Griddle, flat 3 ft
Griddle, small 3 ft
Induction cooktop
Induction wok
Oven, combi: combi-mode
Oven, combi: convection mode
Oven, convection, full-sized
Oven, convection, half-sized
Pasta cooker
Range top, top off/oven on
Range top, 3 elements on/oven off
Range top, 6 elements on/oven off
Range top, 6 elements on/oven on
Range, hot-top
Rotisserie
Salamander
Steam kettle, large (60 gal) simmer lid down
Steam kettle, small (40 gal) simmer lid down
Steamer, compartment, atmospheric
Tilting skillet/braising pan
*/
export let HoodedElectircCookingAppliances = makeEnum({
  BroilerUnderfired3Ft: 'Broiler: underfired 3 ft',
  Cheesemelter: 'Cheesemelter',
  FryerKettle: 'Fryer, kettle',
  FryerOpenDeepFat1Vat: 'Fryer, open deep-fat, 1-vat',
  FryerPressure: 'Fryer, pressure',
  GriddleDoubleSided3FtClamshellDown: 'Griddle, double-sided 3 ft (clamshell down)',
  GriddleClamshellUp: 'Griddle, (Clamshell up)',
  GriddleFlat3Ft: 'Griddle, flat 3 ft',
  GriddleSmall3Ft: 'Griddle, small 3 ft',
  InductionCooktop: 'Induction cooktop',
  InductionWok: 'Induction wok',
  OvenCombiCombiMode: 'Oven, combi: combi-mode',
  OvenCombiConvectionMode: 'Oven, combi: convection mode',
  OvenConvectionFullSized: 'Oven, convection, full-sized',
  OvenConvectionHalfSized: 'Oven, convection, half-sized',
  PastaCooker: 'Pasta cooker',
  RangeTopTopOffOvenOn: 'Range top, top off/oven on',
  RangeTop3ElementsOnOvenOff: 'Range top, 3 elements on/oven off',
  RangeTop6ElementsOnOvenOff: 'Range top, 6 elements on/oven off',
  RangeTop6ElementsOnOvenOn: 'Range top, 6 elements on/oven on',
  RangeHotTop: 'Range, hot-top',
  Rotisserie: 'Rotisserie',
  Salamander: 'Salamander',
  SteamKettleLarge60GalSimmerLidDown: 'Steam kettle, large (60 gal) simmer lid down',
  SteamKettleSmall40GalSimmerLidDown: 'Steam kettle, small (40 gal) simmer lid down',
  SteamerCompartmentAtmospheric: 'Steamer, compartment, atmospheric',
  TiltingSkilletBraisingPan: 'Tilting skillet/braising pan',
})

/*
Hooded, solid-fuel cooking appliances:
Broiler: solid fuel: charcoal
Broiler: solid fuel: wood (mesquite)
*/
export let HoodedSolidFuelCookingAppliances = makeEnum({
  BroilerSolidFuelCharcoal: 'Broiler: solid fuel: charcoal',
  BroilerSolidFuelWoodMesquite: 'Broiler: solid fuel: wood (mesquite)',
})

/*
Office Equipment Type:
Desktop computer
Monitor
Laptop
Laptop with docking station
Tablet PC
Printer
*/
export let OfficeEquipmentTypes = makeEnum({
  DesktopComputer: 'Desktop computer',
  Monitor: 'Monitor',
  Laptop: 'Laptop',
  LaptopWithDockingStation: 'Laptop with docking station',
  TabletPC: 'Tablet PC',
  Printer: 'Printer',
})

/*
Desktop computers:
4 GB RAM
8 GB RAM
16 GB RAM
32 GB RAM
*/
export let DesktopComputers = makeEnum({
  FourGBRAM: '4 GB RAM',
  EightGBRAM: '8 GB RAM',
  SixteenGBRAM: '16 GB RAM',
  ThirtyTwoGBRAM: '32 GB RAM',
})

/*
Monitors:
Large
Medium
Small
*/
export let Monitors = makeEnum({
  Large: 'Large',
  Medium: 'Medium',
  Small: 'Small',
})

/*
Laptops:
4 GB RAM
8 GB RAM or larger
*/
export let Laptops = makeEnum({
  FourGBRAM: '4 GB RAM',
  EightGBRAMOrLarger: '8 GB RAM or larger',
})

/*
Laptops with docking stations:
4 GB RAM
8 GB RAM
16 GB RAM
32 GB RAM
*/
export let LaptopsWithDockingStations = makeEnum({
  FourGBRAM: '4 GB RAM',
  EightGBRAM: '8 GB RAM',
  SixteenGBRAM: '16 GB RAM',
  ThirtyTwoGBRAM: '32 GB RAM',
})

/*
Tablet PCs:
4 GB RAM
8 GB RAM
16 GB RAM
*/
export let TabletPCs = makeEnum({
  FourGBRAM: '4 GB RAM',
  EightGBRAM: '8 GB RAM',
  SixteenGBRAM: '16 GB RAM',
})

/*
Printers:
Multifunction printer (copy, print, scan); large, multiuser, office type
Multiuser, medium-office type
Desktop, small-office type
Monochrome printer; desktop, medium-office type
Color printer; desktop, medium-office type
Laser printer; desktop, small-office type
Plotter
Fax machine
*/
export let Printers = makeEnum({
  MultifunctionPrinterCopyPrintScanLargeMultiuserOfficeType: 'Multifunction printer (copy, print, scan); large, multiuser, office type',
  MultiuserMediumOfficeType: 'Multiuser, medium-office type',
  DesktopSmallOfficeType: 'Desktop, small-office type',
  MonochromePrinterDesktopMediumOfficeType: 'Monochrome printer; desktop, medium-office type',
  ColorPrinterDesktopMediumOfficeType: 'Color printer; desktop, medium-office type',
  LaserPrinterDesktopSmallOfficeType: 'Laser printer; desktop, small-office type',
  Plotter: 'Plotter',
  FaxMachine: 'Fax machine',
})

/*
Medical equipment:
Anesthesia system
Blanket warmer
Blood pressure meter
Blood warmer
ECG/RESP
Electrosurgery
Endoscope
Harmonical scalpel
Hysteroscopic pump
Laser sonics
Optical microscope
Pulse oximeter
Stress treadmill
Ultrasound system
Vacuum suction
X-ray system (small)
X-ray system (medium)
X-ray system (large)
*/
export let MedicalEquipment = makeEnum({
  AnesthesiaSystem: 'Anesthesia system',
  BlanketWarmer: 'Blanket warmer',
  BloodPressureMeter: 'Blood pressure meter',
  BloodWarmer: 'Blood warmer',
  ECGRESP: 'ECG/RESP',
  Electrosurgery: 'Electrosurgery',
  Endoscope: 'Endoscope',
  HarmonicalScalpel: 'Harmonical scalpel',
  HysteroscopicPump: 'Hysteroscopic pump',
  LaserSonics: 'Laser sonics',
  OpticalMicroscope: 'Optical microscope',
  PulseOximeter: 'Pulse oximeter',
  StressTreadmill: 'Stress treadmill',
  UltrasoundSystem: 'Ultrasound system',
  VacuumSuction: 'Vacuum suction',
  XRaySystemSmall: 'X-ray system (small)',
  XRaySystemMedium: 'X-ray system (medium)',
  XRaySystemLarge: 'X-ray system (large)',
})

/*
Labratory Equipment:
Analytical balance
Centrifuge (small)
Centrifuge (medium)
Centrifuge (large)
Electrochemical analyzer (small)
Electrochemical analyzer (large)
Flame photometer
Fluorescent microscope (small)
Fluorescent microscope (large)
Function generator
Incubator (small)
Incubator (medium)
Incubator (large)
Orbital shaker
Oscilloscope (small)
Oscilloscope (large)
Rotary evaporator (small)
Rotary evaporator (large)
Spectronics
Spectrophotometer (small)
Spectrophotometer (medium)
Spectrophotometer (large)
Spectro fluorometer
Thermocycler (small)
Thermocycler (large)
Tissue culture (small)
Tissue culture (large)
*/
export let LabratoryEquipment = makeEnum({
  AnalyticalBalance: 'Analytical balance',
  CentrifugeSmall: 'Centrifuge (small)',
  CentrifugeMedium: 'Centrifuge (medium)',
  CentrifugeLarge: 'Centrifuge (large)',
  ElectrochemicalAnalyzerSmall: 'Electrochemical analyzer (small)',
  ElectrochemicalAnalyzerLarge: 'Electrochemical analyzer (large)',
  FlamePhotometer: 'Flame photometer',
  FluorescentMicroscopeSmall: 'Fluorescent microscope (small)',
  FluorescentMicroscopeLarge: 'Fluorescent microscope (large)',
  FunctionGenerator: 'Function generator',
  IncubatorSmall: 'Incubator (small)',
  IncubatorMedium: 'Incubator (medium)',
  IncubatorLarge: 'Incubator (large)',
  OrbitalShaker: 'Orbital shaker',
  OscilloscopeSmall: 'Oscilloscope (small)',
  OscilloscopeLarge: 'Oscilloscope (large)',
  RotaryEvaporatorSmall: 'Rotary evaporator (small)',
  RotaryEvaporatorLarge: 'Rotary evaporator (large)',
  Spectronics: 'Spectronics',
  SpectrophotometerSmall: 'Spectrophotometer (small)',
  SpectrophotometerMedium: 'Spectrophotometer (medium)',
  SpectrophotometerLarge: 'Spectrophotometer (large)',
  SpectroFluorometer: 'Spectro fluorometer',
  ThermocyclerSmall: 'Thermocycler (small)',
  ThermocyclerLarge: 'Thermocycler (large)',
  TissueCultureSmall: 'Tissue culture (small)',
  TissueCultureLarge: 'Tissue culture (large)',
})

/*
Dishwasher appliances:
Pre-rinse spray valve
Pre-rinse spray valve
Pre-rinse spray valve
3-Compartment sink, rinsing
3-Compartment sink, idle
Power wash sink, rinsing
Power wash sink, idle
Scrapper
Scrapper with trough
Under counter dishwasher, low temperature
Under counter dishwasher, high temperature
Under counter dishwasher, high temperature with heat recovery
Upright door type dishwasher, low temperature dump and fill
Upright door type dishwasher, low temperature with tank
Upright door type, high temperature
Upright door type dishwasher, high temperature with heat recovery
Pot and pan washer
Pot and pan washer with heat recovery
44 in. Conveyor dishwasher, unvented
66 in. Conveyor dishwasher, unvented
Upright door type dishwasher, high temperature under a 3×3 ft hood at 300 cfm
Upright door type dishwasher, high temperature under a 5×4 ft hood at 500 cfm
44 in. Conveyor dishwasher, high temperature under a 10 ft hood at 1000 cfm
Ducted 66 in. conveyor dishwasher
Ducted Flight type conveyor dishwasher
Ducted Flight type conveyor dishwasher with heat recovery
Ducted Flight type conveyor dishwasher with blow dryer
*/
export let DishwasherAppliances = makeEnum({
  PreRinseSprayValve: 'Pre-rinse spray valve',
  CompartmentSinkRinsing: '3-Compartment sink, rinsing',
  CompartmentSinkIdle: '3-Compartment sink, idle',
  PowerWashSinkRinsing: 'Power wash sink, rinsing',
  PowerWashSinkIdle: 'Power wash sink, idle',
  Scrapper: 'Scrapper',
  ScrapperWithTrough: 'Scrapper with trough',
  UnderCounterDishwasherLowTemperature: 'Under counter dishwasher, low temperature',
  UnderCounterDishwasherHighTemperature: 'Under counter dishwasher, high temperature',
  UnderCounterDishwasherHighTemperatureWithHeatRecovery: 'Under counter dishwasher, high temperature with heat recovery',
  UprightDoorTypeDishwasherLowTemperatureDumpAndFill: 'Upright door type dishwasher, low temperature dump and fill',
  UprightDoorTypeDishwasherLowTemperatureWithTank: 'Upright door type dishwasher, low temperature with tank',
  UprightDoorTypeDishwasherHighTemperature: 'Upright door type, high temperature',
  UprightDoorTypeDishwasherHighTemperatureWithHeatRecovery: 'Upright door type dishwasher, high temperature with heat recovery',
  PotAndPanWasher: 'Pot and pan washer',
  PotAndPanWasherWithHeatRecovery: 'Pot and pan washer with heat recovery',
  FortyFourInConveyorDishwasherUnvented: '44 in. Conveyor dishwasher, unvented',
  SixtySixInConveyorDishwasherUnvented: '66 in. Conveyor dishwasher, unvented',
  UprightDoorTypeDishwasherHighTemperatureUnderThreeByThreeFtHoodAtThreeHundredCfm: 'Upright door type dishwasher, high temperature under a 3×3 ft hood at 300 cfm',
  UprightDoorTypeDishwasherHighTemperatureUnderFiveByFourFtHoodAtFiveHundredCfm: 'Upright door type dishwasher, high temperature under a 5×4 ft hood at 500 cfm',
  FortyFourInConveyorDishwasherHighTemperatureUnderTenFtHoodAtOneThousandCfm: '44 in. Conveyor dishwasher, high temperature under a 10 ft hood at 1000 cfm',
  DuctedSixtySixInConveyorDishwasher: 'Ducted 66 in. conveyor dishwasher',
  DuctedFlightTypeConveyorDishwasher: 'Ducted Flight type conveyor dishwasher',
  DuctedFlightTypeConveyorDishwasherWithHeatRecovery: 'Ducted Flight type conveyor dishwasher with heat recovery',
  DuctedFlightTypeConveyorDishwasherWithBlowDryer: 'Ducted Flight type conveyor dishwasher with blow dryer',
})

/*
Misc machines:
Vending machine; drinks
Vending machine; snacks
Vending machine; food
Thermal binding machine
Projector
Paper shredder
Electric stapler
Speakers
Temperature-controlled electronics soldering station
Cell phone charger
40 V Battery charger
AA Battery charger
Microwave oven; small
Microwave oven; large
Coffee maker; single cup
Coffee maker; 12 cups
Coffee maker; with grinder, single cup
Coffee grinder, up to 12 cups
Tea kettle
Dorm fridge
Freezer
Fridge, medium size
Ice maker and dispenser, 20 lb bin capacity
Top mounted bottled water cooler, small
Top mounted bottled water cooler, large
Cash register
Touch screen computer, 15 in. standard LCD
Self-checkout machine
*/
export let MiscMachines = makeEnum({
  VendingMachineDrinks: 'Vending machine; drinks',
  VendingMachineSnacks: 'Vending machine; snacks',
  VendingMachineFood: 'Vending machine; food',
  ThermalBindingMachine: 'Thermal binding machine',
  Projector: 'Projector',
  PaperShredder: 'Paper shredder',
  ElectricStapler: 'Electric stapler',
  Speakers: 'Speakers',
  TemperatureControlledElectronicsSolderingStation: 'Temperature-controlled electronics soldering station',
  CellPhoneCharger: 'Cell phone charger',
  FortyVBatteryCharger: '40 V Battery charger',
  AABatteryCharger: 'AA Battery charger',
  MicrowaveOvenSmall: 'Microwave oven; small',
  MicrowaveOvenLarge: 'Microwave oven; large',
  CoffeeMakerSingleCup: 'Coffee maker; single cup',
  CoffeeMakerTwelveCups: 'Coffee maker; 12 cups',
  CoffeeMakerWithGrinderSingleCup: 'Coffee maker; with grinder, single cup',
  CoffeeGrinderUpToTwelveCups: 'Coffee grinder, up to 12 cups',
  TeaKettle: 'Tea kettle',
  DormFridge: 'Dorm fridge',
  Freezer: 'Freezer',
  FridgeMediumSize: 'Fridge, medium size',
  IceMakerAndDispenserTwentyLbBinCapacity: 'Ice maker and dispenser, 20 lb bin capacity',
  TopMountedBottledWaterCoolerSmall: 'Top mounted bottled water cooler, small',
  TopMountedBottledWaterCoolerLarge: 'Top mounted bottled water cooler, large',
  CashRegister: 'Cash register',
  TouchScreenComputerFifteenInStandardLCD: 'Touch screen computer, 15 in. standard LCD',
  SelfCheckoutMachine: 'Self-checkout machine',
})

export let ApplianceOptionsMap = {
  type: ApplianceCategory,
  children: {
    [ApplianceCategory.MedicalEquipment]: MedicalEquipment,
    [ApplianceCategory.LabratoryEquipment]: LabratoryEquipment,
    [ApplianceCategory.CookingAppliances]: {
      type: CookingApplianceTypes,
      children: {
        [CookingApplianceTypes.Unhooded]: UnhoodedCookingAppliances,
        [CookingApplianceTypes.HoodedGas]: HoodedElectricCookingAppliances,
        [CookingApplianceTypes.HoodedElectric]: HoodedElectricCookingAppliances,
        [CookingApplianceTypes.HoodedSolidFuel]: HoodedSolidFuelCookingAppliances,
      }
    },
    [ApplianceCategory.DishwashingEquipment]: DishwasherAppliances,
    [ApplianceCategory.OfficeEquipment]: {
      type: OfficeEquipmentTypes,
      children: {
        [OfficeEquipmentTypes.DesktopComputer]: DesktopComputers,
        [OfficeEquipmentTypes.Monitor]: Monitors,
        [OfficeEquipmentTypes.Laptop]: Laptops,
        [OfficeEquipmentTypes.LaptopWithDockingStation]: LaptopsWithDockingStations,
        [OfficeEquipmentTypes.TabletPC]: TabletPCs,
        [OfficeEquipmentTypes.Printer]: Printers,
      },
    },
    [ApplianceCategory.MiscellaneousMachines]: MiscMachines,
  }
};

/*
Typical computer types:
100% laptop, docking station
50% laptop, docking station, 50% desktop
100% desktop
*/
export let TypicalComputerTypes = makeEnum({
  OneHundredPercentLaptopDockingStation: '100% laptop, docking station',
  FiftyPercentLaptopDockingStationFiftyPercentDesktop: '50% laptop, docking station, 50% desktop',
  OneHundredPercentDesktop: '100% desktop',
})

/*
Office Load Factors Data:
100% laptop, docking station
  Light	0.34
	Medium	0.46
	Medium, two screens	0.69
	Full-on, two screens	1.14
50% laptop, docking station, 50% desktop
  Light	0.44
	Medium	0.59
100% desktop
  Light	0.54
	Medium	0.72
	Medium, two screens	0.84
	Medium, three screens	0.96
	Heavy, two screens	1.02
	Heavy, three screens	1.16
	Full-on, two screens	1.33
	Full-on, three screens	1.53
*/
export let OfficeLoadIntensities = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  MediumTwoScreens: 'Medium, two screens',
  MediumThreeScreens: 'Medium, three screens',
  HeavyTwoScreens: 'Heavy, two screens',
  HeavyThreeScreens: 'Heavy, three screens',
  FullOnTwoScreens: 'Full-on, two screens',
  FullOnThreeScreens: 'Full-on, three screens',
})

export let OfficeLoadFactors = {
  [TypicalComputerTypes.OneHundredPercentLaptopDockingStation]: {
    Light: 0.34,
    Medium: 0.46,
    MediumTwoScreens: 0.69,
    FullOnTwoScreens: 1.14,
  },
  [TypicalComputerTypes.FiftyPercentLaptopDockingStationFiftyPercentDesktop]: {
    Light: 0.44,
    Medium: 0.59,
  },
  [TypicalComputerTypes.OneHundredPercentDesktop]: {
    Light: 0.54,
    Medium: 0.72,
    MediumTwoScreens: 0.84,
    MediumThreeScreens: 0.96,
    HeavyTwoScreens: 1.02,
    HeavyThreeScreens: 1.16,
    FullOnTwoScreens: 1.33,
    FullOnThreeScreens: 1.53,
  },
}