export let MasonryMaterials = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
"Brick, fired clay, high density",125,6.8,�,�,1
"Brick, fired clay, low density",95,4.4,�,�,1
"Clay tile, hollow",�,4.2,,0.21,1
Lightweight brick,49,1.45,�,�,1
Limestone aggregate,�,3.5,,�,1
Normal-weight aggregate,�,7.5,,�,1
Medium-weight aggregate,�,2.6,,�,1
"Expanded shale, clay, slate or slag, pumice",�,1.7,,�,1
"Stone, lime, or sand",180,72,�,�,1
"Quartzitic and sandstone, high density",160,43,�,�,1
"Quartzitic and sandstone, medium density",140,24,�,�,1
"Quartzitic and sandstone, low density",120,13,�,0.19,1
"Calcitic, dolomitic, limestone, marble, and granite, high density",155,21,�,0.19,1
"Calcitic, dolomitic, limestone, marble, and granite, low density",125,12.8,�,0.19,1
Limestone,150,5,�,0.2,1
Sand and gravel or stone aggregate concrete,150,15,�,�,1
Lightweight aggregate or limestone concrete,120,7.75,�,�,1
Gypsum/fiber concrete,51,1.66,�,0.2,1
"Cement/lime, mortar, and stucco",100,7,�,�,1
"Perlite, vermiculite, and polystyrene beads",50,1.3,�,0.19,1
"Foam concrete, high density",93,3.75,�,�,1
"Foam concrete, low density",40,1.4,�,�,1
Aerated concrete,38.5,1.4,�,0.2,1
Polystyrene concrete,33,2.54,�,0.2,1
Polymer concrete,130,9.3,�,�,1
Polymer cement,117,5.39,�,�,1
"Slag concrete, low density",70,1.9,�,�,1
"Slag concrete, high density",113,5.8,�,�,1`
