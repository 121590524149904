export let WallMaterials = `
Material,Thickness,Conductivity,Density,Specific Heat Capacity,Resistance,Mass,Thermal Capacity,Thickness Required
Wall air space resistance,�,�,�,�,0.87,�,�,0
Ceiling air space resistance,�,�,�,�,1,�,�,0
EIFS finish,0.375,5,116,0.2,0.08,3.63,0.73,0
1 in. stucco,1,5,116,0.2,0.2,9.67,1.93,0
Metal surface,0.03,314.4,489,0.12,0.0001,1.22,0.15,0
Opaque spandrel glass,0.25,6.9,158,0.21,0.04,3.29,0.69,0
1 in. stone,1,22,160,0.19,0.05,13.33,2.53,0
Wood siding,0.5,0.62,37,0.39,0.81,1.54,0.6,0
Asphalt shingles,0.125,0.28,70,0.3,0.44,0.73,0.22,0
Built-up roofing,0.375,1.13,70,0.35,0.33,2.19,0.77,0
Slate or tile,0.5,11,120,0.3,0.05,5,1.5,0
Wood shingles,0.25,0.27,37,0.31,0.94,0.77,0.24,0
Acoustic tile,0.75,0.42,23,0.14,1.79,1.44,0.2,0
Carpet,0.375,0.55,20,0.33,0.68,0.63,0.21,0
Terrazzo,1,12.5,160,0.19,0.08,13.33,2.53,0
Gypsum Board,0.625,1.11,50,0.26,0.56,2.6,0.68,1
Plywood,0.625,0.74,34,0.45,0.85,1.77,0.8,1
Fiberboard sheathing,0.5,0.47,25,0.31,1.06,1.04,0.32,1
Insulation board,1,0.2,2.5,0.35,5,0.21,0.07,1
Batt insulation,3.52,0.32,0.49,0.2,11,0.14,0.03,1
4 in. brick,4,6.2,120,0.19,0.65,40,7.6,0
6 in. LW concrete block,6,3.39,32,0.21,1.77,16,3.36,0
8 in. LW concrete block,8,3.14,29,0.21,2.55,19.33,4.06,0
12 in. LW concrete block,12,4.9,32,0.21,2.45,32,6.72,0
8 in. concrete block,8,7.72,50,0.22,1.04,33.33,7.33,0
12 in. concrete block,12,9.72,50,0.22,1.23,50,11,0
6 in. LW concrete block (filled),6,2,32,0.21,3,16,3.36,0
8 in. LW concrete block (filled),8,1.74,29,0.21,4.6,19.33,4.06,0
12 in. LW concrete block (filled),12,2.07,32,0.21,5.8,32,6.72,0
8 in. concrete block (filled),8,4.85,50,0.22,1.65,33.33,7.33,0
Lightweight concrete,4,3.7,80,0.2,1.08,26.67,5.33,1
Heavyweight concrete,6,13.5,140,0.22,0.44,70,15.4,1
2 in. LW concrete roof ballast,2,1.3,40,0.2,1.54,6.7,1.33,0`
