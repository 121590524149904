import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { valOr } from './SharedUtils.js'
import * as ser from './SerUtil.js'

import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { Season, Wall, Roof, Partition, HouseFloor,
  AutomaticOrManual, YesNo, makeNoYesField, makeYesNoField,
  SystemHeatingDesignTemps, SystemCoolingDesignTemps, 
} from './Components.js'
import { SystemDesignTemps } from './SystemDesignTempInputs.js'
import { FieldWithVariableUnits } from './FieldUtils.js'
import { Units } from './Units.js'

import { gApp, DebugOn } from './Globals.js'

import { SpaceType, BuildingMass, SpacePercentGlass, SpaceHasCarpet } from './SpaceEnums.js'
import { SpaceTypes, SpaceTypeCategory, GetSpaceTypesForCategory, DummySpaceData } from './SpaceTypes.js'
import { VentilationEffectivenessData, } from './VentilationEffectivenessData.js'
import { SpaceInternals } from './SpaceInternals.js'
import { DataTable } from './DataTable.js'
import { CalcContext } from './CalcContext.js'
import * as psy from './Psychrometrics.js'

import { SpaceHeatingCalculator } from './SpaceHeatingCalculator.js'
import { SpaceCoolingCalculator } from './SpaceCoolingCalculator.js'

import { DataCache } from './MaterialData/DataCache.js'
import * as MaterialDataTableRegistry from './MaterialData/MaterialDataTableRegistry.js'

import * as math from 'mathjs'

export let InfiltrationHours = makeEnum({
  AllHours: 'All hours',
  OccupiedHours: 'Only during occupied hours',
})

export class SpaceVentilation {
  init(space) {
    this.space = space

    // Breathing zone ventilation:
    this.ventilationType = Field.makeSelect('Ventilation', AutomaticOrManual)
    this.ventilationType.makeChoicesUpdater(() => {
      if (this.space.spaceCategory.value == 'Unknown') {
        return [AutomaticOrManual.Manual];
      } else {
        return [AutomaticOrManual.Automatic, AutomaticOrManual.Manual];
      }
    });
 
    // Automatic fields:
    this.automaticVentilation = new Field({
      name: 'Estimated Ventilation',
      isOutput: true,
      units: Units.AirFlow,
    })
    this.automaticVentilation.makeUpdater((field) => {
      let ctx = CalcContext.create();
      field.value = this.calcVentilation(ctx).V_bz;
    });
    this.automaticVentilation.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Automatic;
    })

    // Manual fields:
    this.manualVentilation = new FieldWithVariableUnits({
      name: 'Manual Ventilation',
      type: FieldType.AirFlow,
      units: Units.AirFlow,
      unitOptions: [Units.AirFlow, Units.AirFlowPerPerson, Units.AirFlowPerArea, Units.ACH],
    });
    this.manualVentilation.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Manual;
    });
    this.minimumVentilationRequirement = new Field({
      name: 'Minimum Ventilation Requirement',
      type: FieldType.AirFlow,
    });
    this.minimumVentilationRequirement.setVisibility(() => {
      return this.ventilationType.value == AutomaticOrManual.Manual;
    })

    // Effectiveness:
    this.ventilationEffectivenessCooling = new Field({
      name: 'Ventilation Effectiveness (cooling) (E<sub>z</sub>)',
      type: FieldType.Ratio,
    })
    this.ventilationEffectivenessHeating = new Field({
      name: 'Ventilation Effectiveness (heating) (E<sub>z</sub>)',
      type: FieldType.Ratio,
    })

    this.ventilationEffectivenessData = DataTable.create([
        {
          name: 'Ventilation Type',
          field: 'label',
        },
        {
          name: 'Ventilation Effectiveness (E_z)',
          field: 'value',
        }
      ], VentilationEffectivenessData)

    this.serFields = [
      'ventilationType',
      'automaticVentilation',
      'manualVentilation',
      'minimumVentilationRequirement',
      'ventilationEffectivenessCooling',
      'ventilationEffectivenessHeating',
    ]
    this.childObjs = '$auto'
  }

  getDataForSpace() {
    if (this.space.spaceCategory.value == 'Unknown') {
      return DummySpaceData;
    }
    let spaceCat = SpaceTypeCategory._labels[this.space.spaceCategory.value];
    // TODO - rework this. Should use multi-tiered select
    let data = null;
    try {
      data = lookupData(SpaceTypes, [spaceCat, this.space.spaceType.value]);
    } catch (ex) {
      return DummySpaceData
    }
    return data;
  }

  // Aka: Return {V_bz, V_bz_min}
  calcVentilation(ctx) {
    if (this.ventilationType.value == AutomaticOrManual.Automatic) {
      let data = this.getDataForSpace();
      console.log("Calculating automatic ventilation. Data: ", data);
      let numOccupants = this.space.internals.people.getNumOccupants();
      let spaceArea = this.space.floorArea.value;
      console.log("Occupants: ", numOccupants);
      console.log("Area: ", spaceArea);
      let ventilationByPerson = data['cfm/person'] * numOccupants;
      let ventilationByArea = data['cfm/ft^2'] * spaceArea;
      let V_bz = ventilationByPerson + ventilationByArea;
      let V_bz_min = ventilationByArea;
      return {V_bz, V_bz_min};
    } else {
      let units = this.manualVentilation.units;
      let V_bz_min = this.minimumVentilationRequirement.value;
      switch (units) {
        case Units.AirFlow: {
          let V_bz = this.manualVentilation.value;
          return {V_bz, V_bz_min};
        }
        case Units.AirFlowPerPerson: {
          let numOccupants = this.space.internals.people.getNumOccupants();
          let V_bz = this.manualVentilation.value * numOccupants;
          return {V_bz, V_bz_min};
        }
        case Units.AirFlowPerArea: {
          let V_bz = this.manualVentilation.value * this.space.floorArea.value;
          return {V_bz, V_bz_min};
        }
        case Units.ACH: {
          let ACH = this.manualVentilation.value;
          let spaceVolume = this.space.floorArea.value * this.space.avgCeilingHeight.value;
          let V_bz = ACH * spaceVolume / 60;
          return {V_bz, V_bz_min};
        }
        default: {
          throw new Error(`Unknown ventilation unit: ${units}`);
        }
      }
    }
  }

  calc_Ventilation_values(ctx) {
    ctx.startSection("Ventilation values")
    let ventilationValues = this.calcVentilation(ctx);
    ctx.V_bz = ventilationValues.V_bz;
    ctx.V_bz_min = ventilationValues.V_bz_min;

    ctx.V_oz_cooling = ctx.eval('V_bz/E_z', {
      E_z: this.ventilationEffectivenessCooling.value,
    }, 'V_oz_cooling');
    ctx.V_oz_cooling_min = ctx.eval('V_bz_min/E_z', {
      E_z: this.ventilationEffectivenessCooling.value,
    }, 'V_oz_cooling_min');
    // Minimum primary airflow rate
    ctx.V_pz_min_cooling = ctx.eval('1.5*V_oz_cooling', {
    }, 'V_pz_min_cooling');

    ctx.V_oz_heating = ctx.eval('V_bz/E_z', {
      E_z: this.ventilationEffectivenessHeating.value,
    }, 'V_oz_heating');
    ctx.V_oz_heating_min = ctx.eval('V_bz_min/E_z', {
      E_z: this.ventilationEffectivenessHeating.value,
    }, 'V_oz_heating_min');
    // Minimum primary airflow rate
    ctx.V_pz_min_heating = ctx.eval('1.5*V_oz_heating', {
    }, 'V_pz_min_heating');

    let res = {
      V_bz: ctx.V_bz,
      V_oz_cooling: ctx.V_oz_cooling,
      V_oz_cooling_min: ctx.V_oz_cooling_min,
      V_oz_heating: ctx.V_oz_heating,
      V_oz_heating_min: ctx.V_oz_heating_min,
      V_pz_min_cooling: ctx.V_pz_min_cooling,
      V_pz_min_heating: ctx.V_pz_min_heating,
    }
    ctx.endSection()
    return res;
  }

};
setupClass(SpaceVentilation)

export class SpaceLoadResults {
  init(calcContext) {
    this.calcContext = calcContext;
    this.results = {}
    this.error = null
  }
}
setupClass(SpaceLoadResults)

export class ZoneInfo {
  constructor(data) {
    this.t_i_summer_occupied = data.t_i_summer_occupied;
    this.t_i_summer_unoccupied = data.t_i_summer_unoccupied;
    this.t_i_winter_occupied = data.t_i_winter_occupied;
    this.t_i_winter_unoccupied = data.t_i_winter_unoccupied;
    this.zone_humidity = data.zone_humidity;
  }

  static createFromZone(zone) {
    let zoneInfo = new ZoneInfo({
      t_i_summer_occupied: zone.summerTempOccupied.value,
      t_i_summer_unoccupied: zone.summerTempUnoccupied.value, 
      t_i_winter_occupied: zone.winterTempOccupied.value,
      t_i_winter_unoccupied: zone.winterTempUnoccupied.value,
      zone_humidity: zone.humiditySetpoint.value,
    });
    return zoneInfo;
  }
}

export class Space {
  init(name, makeId) {
    this.name = Field.makeName(`Space Name`, name)
    this.id = makeId ? gApp.proj().makeId('Space') : 0;

    this.floorArea = new Field({
      name: 'Floor Area',
      type: FieldType.Area,
    })
    this.avgCeilingHeight = new Field({
      name: 'Average Ceiling Height',
      type: FieldType.Length,
    })
    this.spaceCategory = Field.makeSelect('Space Category', SpaceTypeCategory)
    this.spaceType = new Field({
      name: 'Space Type',
      type: FieldType.Select,
      choices: [],
    })
    this.spaceType.makeChoicesUpdater(() => {
      if (this.spaceCategory.value == 'Unknown') {
        return [];
      }
      return GetSpaceTypesForCategory(this.spaceCategory.value);
    })

    this.buildingMass = Field.makeSelect('Construction Weight', BuildingMass)
    this.spaceInteriorExterior = Field.makeSelect('Interior or Exterior', SpaceType)
    this.percentGlass = Field.makeSelect('Percent Glass', SpacePercentGlass)
    this.hasCarpet = Field.makeSelect('Has Carpet', SpaceHasCarpet)

    this.generalFields = [
      'floorArea',
      'avgCeilingHeight',
      'spaceCategory',
      'spaceType',
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
    ]

    this.ventilation = SpaceVentilation.create(this)

    let infiltrationUnitOptions = [
      Units.AirFlow,
      Units.CfmPerFt2WallArea,
      Units.CfmPerFtCrackLength,
      Units.ACH,
    ]
    this.summerInfiltration = new FieldWithVariableUnits({
      name: 'Summer Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
    });
    this.winterInfiltration = new FieldWithVariableUnits({
      name: 'Winter Infiltration',
      type: FieldType.AirFlow,
      unitOptions: infiltrationUnitOptions,
    });
    this.infiltrationHours = Field.makeSelect('Infiltration Hours', InfiltrationHours)

    this.walls = []
    this.roofs = []
    this.floors = []
    this.partitions = []

    this.internals = SpaceInternals.create(this)

    // Used for the debug calculator, only
    this.runningCalculations = false;

    // Used for the debug calculator, only
    this.loadResults = null

    this.debugCalcOptions = {
      Calc_latent_loads: true,
      Calc_sensible_loads: true,
    }

    this.serFields = [
      'name',
      'id',
      'floorArea',
      'avgCeilingHeight',
      'spaceCategory',
      'spaceType',
      'buildingMass',
      'spaceInteriorExterior',
      'percentGlass',
      'hasCarpet',
      'ventilation',
      'summerInfiltration',
      'winterInfiltration',
      'infiltrationHours',
      ser.arrayField('walls', () => { return Wall.create(); }),
      ser.arrayField('roofs', () => { return Roof.create(); }),
      ser.arrayField('floors', () => { return HouseFloor.create(); }),
      ser.arrayField('partitions', () => { return Partition.create(); }),
      'internals',
    ]
    this.childObjs = '$auto'
  }

  getSpaceTypeName() {
    let catName = SpaceTypeCategory._labels[this.spaceCategory.value];
    return `${catName}/${this.spaceType.value}`;
  }

  async calculateLoadsAsync(ctx, zoneInfo, opts) {
    opts = valOr(opts, {});  

    ctx.debugOptions = this.debugCalcOptions;

    ctx.startSection("Basic")

    ctx.t_i_summer_occupied = zoneInfo.t_i_summer_occupied;
    ctx.t_i_summer_unoccupied = zoneInfo.t_i_summer_unoccupied;
    ctx.t_i_winter_occupied = zoneInfo.t_i_winter_occupied;
    ctx.t_i_winter_unoccupied = zoneInfo.t_i_winter_unoccupied;
    ctx.zone_humidity = zoneInfo.zone_humidity;

    // TODO - are these set properly?
    ctx.require('summerIndoorRH')
    ctx.require('winterIndoorRH')
    ctx.endSection()

    ctx.startProgressSection("Space", {
      "Heating": {},
      "Cooling": {},
    })

    ctx.setProgress("Heating")
    let heatingCalculator = SpaceHeatingCalculator.create(this, ctx);
    let heatingResults = heatingCalculator.calcOutputs();

    ctx.setProgress("Cooling")
    let coolingCalculator = SpaceCoolingCalculator.create(this, ctx);
    let coolingResults = await coolingCalculator.calcOutputs();

    ctx.endProgressSection()

    let results = {
      heating: heatingResults,
      cooling: coolingResults,
    }

    return results;
  }

  async calculateDebugLoadsAsync() {
    if (this.runningCalculations) {
      console.log("Already running calculations. Ignoring.");
      return;
    }
    let ctx = CalcContext.create();
    this.loadResults = SpaceLoadResults.create(ctx);
    try {
      this.runningCalculations = true;

      let tablesCache = new DataCache();
      MaterialDataTableRegistry.registerCommercialTables(tablesCache);
      ctx.tablesCache = tablesCache;

      let proj = gApp.proj();
      let locationData = proj.buildingAndEnv.getLocationData();
      ctx.startSection("Proj values")
      console.log("Location data: ", locationData);
      ctx.toplevelData = {
        locationData: locationData.getOutputs(),
        dayOfMonth: 21,
      }
      ctx.buildingAndEnv = proj.buildingAndEnv.getOutputs();
      console.log("toplevel data: ", ctx.toplevelData);
      ctx.altitude  = ctx.toplevelData.locationData.elevation;
      ctx.P_loc = ctx.call(psy.calcP_loc, ctx.altitude)
      ctx.endSection()

      // We have to set some placeholder values, which would normally come from the System or Zone
      // inputs.
      ctx.startSection("Dummy system values")
      ctx.log("Using placeholder values...")

      ctx.designTemps = new SystemDesignTemps(
        SystemHeatingDesignTemps.Temp99p6,
        SystemCoolingDesignTemps.Temp0p4,
        ctx.toplevelData.locationData
      )

      let dummyZoneInfo = new ZoneInfo({
        t_i_summer_occupied: 75,
        t_i_summer_unoccupied: 75,
        t_i_winter_occupied: 70,
        t_i_winter_unoccupied: 70,
        zone_humidity: 50,
      });
      ctx.logValue("Dummy zone info", dummyZoneInfo);

      ctx.summerIndoorRH = 0.50;
      ctx.winterIndoorRH = 0.30;

      ctx.endSection()

      this.loadResults.results = await this.calculateLoadsAsync(ctx, dummyZoneInfo, {debugMode: true});
    } catch (err) {
      ctx.logFatalError(err);
      this.loadResults.error = err;
    } finally {
      this.runningCalculations = false;
    }
    return this.loadResults;
  }

  getDummyLoadResults(ctx, opts) {
    opts = valOr(opts, {});
    ctx.startSection("Space - calculate dummy load results")
    let heatingResults = {
      q_sensible: 100,
      q_latent: 200,
    }
    let q_sens_cooling = math.zeros(12, 24);
    let q_latent_cooling = math.zeros(12, 24);
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        q_sens_cooling.set([mo, hr], 100*mo + hr);
        q_latent_cooling.set([mo, hr], 200*mo + hr);
      }
    }
    let coolingResults = {
      q_sensible: q_sens_cooling,
      q_latent: q_latent_cooling,
    }

    let plenum_loads = math.zeros(12, 24);
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        plenum_loads.set([mo, hr], 300*mo + hr);
      }
    }

    ctx.results = {
      heating: heatingResults,
      cooling: coolingResults,
      plenum_loads: plenum_loads,
    }
    let results = ctx.results;

    ctx.endSection()
    return results;
  }

  getNumOccupants() {
    return this.internals.people.getNumOccupants();
  }

  isOccupied(hr) {
    return this.internals.people.getSchedule().getData()[hr] > 0;
  }

  getOccupancySchedule() {
    return this.internals.people.getSchedule();
  }

  getNumOccupantsAtHour(hourIndex) {
    let schedData = this.getOccupancySchedule().getData();
    return Math.ceil(schedData[hourIndex]*this.getNumOccupants());
  }

  getExposedArea(ctx) {
    let A_exp_walls = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      A_exp_walls += wall.getArea();
    }
    let A_exp_roofs = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      A_exp_roofs += roof.getArea();
    }
    let A_exp_floors = 0;
    for (let i = 0; i < this.floors.length; i++) {
      let floor = this.floors[i];
      if (floor.isFloorRaised()) {
        A_exp_floors += floor.getArea();
      }
    }
    return ctx.eval('A_exp_walls + A_exp_roofs + A_exp_floors', {
      A_exp_walls: A_exp_walls,
      A_exp_roofs: A_exp_roofs,
      A_exp_floors: A_exp_floors,
    }, 'A_exp');
  }

  getTotalCrackLength(ctx) {
    let L_crack_windows = 0;
    let L_crack_doors = 0;
    for (let i = 0; i < this.walls.length; i++) {
      let wall = this.walls[i];
      for (let j = 0; j < wall.windows.length; j++) {
        let window = wall.windows[j];
        let windowType = window.getWindowType();
        L_crack_windows += window.quantity.value * windowType.getPerimeter();
      }
      for (let j = 0; j < wall.doors.length; j++) {
        let door = wall.doors[j];
        let doorType = door.getDoorType();
        L_crack_doors += door.quantity.value * doorType.getPerimeter();
      }
    }
    let L_crack_skylights = 0;
    for (let i = 0; i < this.roofs.length; i++) {
      let roof = this.roofs[i];
      for (let j = 0; j < roof.skylights.length; j++) {
        let skylight = roof.skylights[j];
        let skylightType = skylight.getSkylightType();
        L_crack_skylights += skylight.quantity.value * skylightType.getPerimeter();
      }
    }
    return ctx.eval('L_crack_windows + L_crack_doors + L_crack_skylights', {
      L_crack_windows: L_crack_windows,
      L_crack_doors: L_crack_doors,
      L_crack_skylights: L_crack_skylights,
    }, 'L_crack');
  }

  calcInfiltrationFlowRate(ctx, season) {
    let infiltrationField = season == Season.Summer ? this.summerInfiltration : this.winterInfiltration;
    ctx.Inp = infiltrationField.value;
    ctx.InpUnits = infiltrationField.units;

    switch (ctx.InpUnits) {
      case Units.AirFlow: {
        ctx.Q_inf = ctx.eval('Inp', {}, 'Q_inf');
        break;
      }
      case Units.CfmPerFt2WallArea: {
        ctx.A_exp = this.getExposedArea(ctx);
        ctx.Q_inf = ctx.eval('Inp*A_exp', {}, 'Q_inf');
        break;
      }
      case Units.CfmPerFtCrackLength: {
        ctx.L_crack = this.getTotalCrackLength(ctx);
        ctx.Q_inf = ctx.eval('Inp*L_crack', {}, 'Q_inf');
        break;
      }
      case Units.ACH: {
        // Note: we use the user input here for floor area, instead of summing
        // the area of all floors.
        ctx.A_floor = this.floorArea.value;
        ctx.Q_inf = ctx.eval('Inp*A_floor*H_c/60', {
          H_c: this.avgCeilingHeight.value,
        }, 'Q_inf');
        break;
      }
      default:
        throw new Error(`Unknown infiltration unit: ${this.winterInfiltration.units}`);
    }
    return ctx.Q_inf;
  }
}
setupClass(Space)
