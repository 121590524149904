import { ref, reactive, watch, watchEffect } from 'vue'
import { WindowUvals } from './WindowUvals.js'
import { SHGCVals } from './SHGCVals.js'
import { IACData } from './IAC.js'
import { prettyJson, clearArray, valOr, getElemNames,
  addElem, removeElem, elemIn } from '../../SharedUtils.js'
import { lookupData } from '../../Base.js'
import { CsvHelper } from '../CsvHelper.js'


export let StdAssemblyColMap = {
  // Regular window types:
  Operable: {
    AluminumWithoutThermalBreak: 'F',
    AluminumWithThermalBreak: 'G',
    ReinforcedVinylSlashAluminumCladWood: 'H',
    WoodSlashVinyl: 'I',
    InsulatedFiberglassSlashVinyl: 'J',
  },
  Fixed: {
    AluminumWithoutThermalBreak: 'K',
    AluminumWithThermalBreak: 'L',
    ReinforcedVinylSlashAluminumCladWood: 'M',
    WoodSlashVinyl: 'N',
    InsulatedFiberglassSlashVinyl: 'O',
  },
  GardenWindow: {
    AluminumWithoutThermalBreak: 'P',
    WoodSlashVinyl: 'Q',
  },
  CurtainWall: {
    AluminumWithoutThermalBreak: 'R',
    AluminumWithThermalBreak: 'S',
    StructuralGlazing: 'T',
  },
  // Skylight types:
  ManufacturedSkylight: {
    AluminumWithoutThermalBreak: 'W',
    AluminumWithThermalBreak: 'X',
    ReinforcedVinylSlashAluminumCladWood: 'Y',
    WoodSlashVinyl: 'Z',
  },
  SiteAssembledSlopedSlashOverheadGlazing: {
    AluminumWithoutThermalBreak: 'AA',
    AluminumWithThermalBreak: 'AB',
    StructuralGlazing: 'AC',
  },
  SlopedCurtainWall: {
    AluminumWithoutThermalBreak: 'AA',
    AluminumWithThermalBreak: 'AB',
    StructuralGlazing: 'AC',
  },
};

export let FFsVals = {
  S: {'Single-family': 0.47, 'Multi-family': 0.53},
  SSW: {'Single-family': 0.525, 'Multi-family': 0.57},
  SW: {'Single-family': 0.58, 'Multi-family': 0.61},
  WSW: {'Single-family': 0.57, 'Multi-family': 0.63},
  W: {'Single-family': 0.56, 'Multi-family': 0.65},
  WNW: {'Single-family': 0.51, 'Multi-family': 0.61},
  NW: {'Single-family': 0.46, 'Multi-family': 0.57},
  NNW: {'Single-family': 0.52, 'Multi-family': 0.65},
  N: {'Single-family': 0.44, 'Multi-family': 0.27},
  NNE: {'Single-family': 0.325, 'Multi-family': 0.35},
  NE: {'Single-family': 0.21, 'Multi-family': 0.43},
  ENE: {'Single-family': 0.26, 'Multi-family': 0.495},
  E: {'Single-family': 0.31, 'Multi-family': 0.56},
  ESE: {'Single-family': 0.34, 'Multi-family': 0.55},
  SE: {'Single-family': 0.37, 'Multi-family': 0.54},
  SSE: {'Single-family': 0.42, 'Multi-family': 0.535},
  Horizontal: {'Single-family': 0.58, 'Multi-family': 0.73}
};

let SLFValues = {
  N: [2.8, 2.1, 1.4, 1.5, 1.7, 1, 0.8, 0.9, 0.8],
  'NNE/NNW': [2.1, 1.8, 1.5, 1.35, 1.5, 1.15, 0.85, 0.9, 0.8],
  'NE/NW': [1.4, 1.5, 1.6, 1.2, 1.3, 1.3, 0.9, 0.9, 0.8],
  'ENE/WNW': [1.3, 1.35, 1.35, 1.15, 1.2, 1.15, 0.95, 0.9, 0.8],
  'E/W': [1.2, 1.2, 1.1, 1.1, 1.1, 1, 1, 0.9, 0.8],
  'ESE/WSW': [1.65, 1.5, 1.55, 1.4, 1.3, 1.3, 1.2, 1.05, 0.95],
  'SE/SW': [2.1, 1.8, 2, 1.7, 1.5, 1.6, 1.4, 1.2, 1.1],
  'SSE/SSW': [11.05, 7.9, 4.45, 3.2, 2.4, 2.15, 1.75, 1.45, 1.25],
  S: [20, 14, 6.9, 4.7, 3.3, 2.7, 2.1, 1.7, 1.4]
};

let DirToSLFDir = {
  'N': 'N',
  'NNE': 'NNE/NNW',
  'NE': 'NE/NW',
  'ENE': 'ENE/WNW',
  'E': 'E/W',
  'ESE': 'ESE/WSW',
  'SE': 'SE/SW',
  'SSE': 'SSE/SSW',
  'S': 'S',
  'SSW': 'SSE/SSW',
  'SW': 'SE/SW',
  'WSW': 'ESE/WSW',
  'W': 'E/W',
  'WNW': 'ENE/WNW',
  'NW': 'NE/NW',
  'NNW': 'NNE/NNW',
};

function interpolateInDict(values, x) {
    const keys = Object.keys(values).map(Number).sort((a, b) => a - b);
    const firstKey = keys[0];
    const lastKey = keys[keys.length - 1];
    
    // Clamp x to the range defined by the first and last keys
    if (x <= firstKey) {
        return values[firstKey];
    } else if (x >= lastKey) {
        return values[lastKey];
    }
    
    let i = 0;
    while (keys[i] < x) {
        i++;
    }
    const x0 = keys[i - 1];
    const x1 = keys[i];
    const y0 = values[x0];
    const y1 = values[x1];
    return y0 + ((y1 - y0) * (x - x0)) / (x1 - x0);
}

function latArrayToDict(latvals) {
    const values = {};
    let latitude = 20;
    for (let i = 0; i < latvals.length; i++) {
        values[latitude] = latvals[i];
        latitude += 5;
    }
    return values;
}

export function getSLFVal(windowDir, latitude) {
  let slfDir = lookupData(DirToSLFDir, [windowDir]);
  let latVals = lookupData(SLFValues, [slfDir]);
  let latValsDicts = latArrayToDict(latVals);
  return interpolateInDict(latValsDicts, latitude);
}

export function getIACVal(winType, exteriorShading, interiorShading) {
  // TODO - impl IAC value finder
  return 0;
}

export class WindowsData {
  constructor() {
    this.uValues = new CsvHelper(WindowUvals);
    this.shgcValues = new CsvHelper(SHGCVals);
    this.iacValues = new CsvHelper(IACData);
  }
}

