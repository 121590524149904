export let Roofing = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
Asbestos/cement shingles,120,,0.21,0.24,0
Asphalt roll roofing,70,,0.15,0.36,0
Asphalt shingles,70,,0.44,0.3,0
Built-up roofing,70,,0.33,0.35,0
"Roofing felt, thin",141,8.32,0.01,,1
"Roofing felt, thick",141,8.32,0.02,,1
Slate 1/2 in.,,,0.05,0.3,0
"Straw thatch, 1/2""",15,,1,,0
"Wood shingles, plain and plastic-film-faced",,,0.94,0.31,0`
