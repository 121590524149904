import { createApp } from 'vue'
import './main.css'
import './utils.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import App from './App.vue'
import { initGlobalApp } from './State.js'

import { createRouter, createWebHashHistory } from 'vue-router'
import * as Sentry from "@sentry/vue";

import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css';

import HomeView from './HomeView.vue'

import ProjectView from './ProjectView.vue'
import SignInView from './SignInView.vue'

import HouseHomeView from './HouseHomeView.vue'
import BuildHouseView from './BuildHouseView.vue'
import WallsView from './WallsView.vue'
import WindowsView from './WindowsView.vue'
import DoorsView from './DoorsView.vue'
import SkylightsView from './SkylightsView.vue'
import ExternalShadingsView from './ExternalShadingsView.vue'
import InternalShadingsView from './InternalShadingsView.vue'
import BufferSpacesView from './BufferSpacesView.vue'

import BuildingHomeView from './BuildingHomeView.vue'
import BuildingAndEnvView from './BuildingAndEnvView.vue'
import SystemsView from './SystemsView.vue'
import SpacesView from './SpacesView.vue'
import SchedulesView from './SchedulesView.vue'
import SystemResultsPage from './SystemResultsPage.vue'

// Firebase Setup:
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkvN-t-cop25ALRWlW454tHQuJhMKT52Q",
  //authDomain: "buildingcalc.firebaseapp.com",
  authDomain: "app.heatwise-hvac.com",
  projectId: "buildingcalc",
  storageBucket: "buildingcalc.appspot.com",
  messagingSenderId: "815475876429",
  appId: "1:815475876429:web:cbfba3ce60e6d8807bf204",
  measurementId: "G-WTW9LP6FZ4"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const routes = [
  {
    path: '/', name: 'home', component: HomeView,
  },
  {
    path: '/signin', name: 'signin', component: SignInView,
    props: {signIn: true},
  },
  {
    path: '/signup', name: 'signup', component: SignInView,
    props: {signIn: false},
  },
  {
    path: '/house/:id', component: ProjectView,
    name: 'house',
    children: [
      {path: '', name: 'house', component: HouseHomeView,},
      {path: 'build', name: 'build', component: BuildHouseView,},
      {path: 'walls', name: 'walls', component: WallsView, props: {isRoof: false}},
      {path: 'roofs', name: 'roofs', component: WallsView, props: {isRoof: true}},
      {path: 'windows', name: 'windows', component: WindowsView,},
      {path: 'doors', name: 'doors', component: DoorsView,},
      {path: 'skylights', name: 'skylights', component: SkylightsView,},
      {path: 'externalshadings', name: 'externalshadings', component: ExternalShadingsView,},
      {path: 'internalshadings', name: 'internalshadings', component: InternalShadingsView,},
      {path: 'bufferspaces', name: 'bufferspaces', component: BufferSpacesView,},
    ]
  },
  {
    path: '/building/:id', component: ProjectView,
    name: 'building',
    // Note: 'name' must be unique across all routes, even for nested routes
    children: [
      {path: '', name: 'building', component: BuildingHomeView,},
      {path: 'building-and-env', name: 'building-and-env', component: BuildingAndEnvView,},
      {path: 'systems', name: 'systems', component: SystemsView},
      {path: 'results', name: 'results', component: SystemResultsPage},
      {path: 'spaces', name: 'spaces', component: SpacesView},
      {path: 'schedules', name: 'schedules', component: SchedulesView},
      {path: 'walls', name: 'building-walls', component: WallsView, props: {isRoof: false}},
      {path: 'roofs', name: 'building-roofs', component: WallsView, props: {isRoof: true}},
      {path: 'windows', name: 'building-windows', component: WindowsView,},
      {path: 'doors', name: 'building-doors', component: DoorsView,},
      {path: 'skylights', name: 'building-skylights', component: SkylightsView,},
      {path: 'externalshadings', name: 'building-externalshadings', component: ExternalShadingsView,},
      {path: 'internalshadings', name: 'building-internalshadings', component: InternalShadingsView,},
      {path: 'bufferspaces', name: 'building-bufferspaces', component: BufferSpacesView,},
    ]
  },
  {
    path: '/psychrometrics', name: 'psychrometrics', component: () => import('./PsychrometricsCalculatorView.vue'),
  },
  {
    path: '/solar-calculator', name: 'solar-calculator', component: () => import('./SolarCalculatorView.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://b49291e1e5c2501e97f979c1e1892468@o4507087356821504.ingest.us.sentry.io/4507087358656512",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: window.location.hostname !== 'localhost',
});

app.use(router)
app.use(ToastPlugin)
// app.component(VueFeather.name, VueFeather)

let builderApp = initGlobalApp(firebaseApp, router,
  app.config.globalProperties.$toast);
builderApp.run();

let curErrorHandler = app.config.errorHandler;
if (!curErrorHandler) {
  curErrorHandler = (err, instance, info) => {
  }
}
app.config.errorHandler = (err, instance, info) => {
  builderApp.onGlobalError(err, instance, info);
  return curErrorHandler(err, instance, info);
}

app.mount('#app')

