export let Woods = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
Oak,44,1.8,�,0.39,1
Birch,44,1.19,�,0.39,1
Maple,42,1.14,�,0.39,1
Ash,40,1.1,�,0.39,1
Southern pine,38.5,1.06,�,0.39,1
Southern yellow pine,31,1.11,�,0.39,1
Eastern white pine,25,0.895,�,0.39,1
Douglas fir/larch,35,0.985,�,0.39,1
Southern cypress,31.5,0.91,�,0.39,1
"Hem/fir, spruce/pine/fir",27.5,0.82,�,0.39,1
Spruce,25,0.795,�,0.39,1
Western red cedar,22,0.845,�,0.39,1
"West coast woods, cedars",26.5,0.79,�,0.39,1
Eastern white cedar,23,0.855,�,0.39,1
California redwood,26,0.78,�,0.39,1
Pine (oven-dried),23,0.64,�,0.45,1
Spruce (oven-dried),25,0.69,�,0.45,1`
