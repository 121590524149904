
<script setup>
import { ref, reactive, computed, watch, } from 'vue'
import { gApp, } from './State.js'
import { System, AirHandlerFan, } from './System.js'
import { Zone, ZoneSpace, } from './Zone.js'
import { Space } from './Space.js'
import { formatNum, removeElem, generateItemName, isDupName, prettyJson, } from './SharedUtils.js'
import { Units, getLabel, makeValStr, } from './Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import SelectOrManualInput from './SelectOrManualInput.vue'
import PopupRefTable from './PopupRefTable.vue'

import WallsSection from './WallsSection.vue'
import RoofsSection from './RoofsSection.vue'
import PartitionsSection from './PartitionsSection.vue'
import FloorsSection from './FloorsSection.vue'
import CommercialInternalsSection from './CommercialInternalsSection.vue'
import CalcContextView from './CalcContextView.vue'
import DebugOptions from './DebugOptions.vue'

import { GetConstructionWeightsReference } from './ConstructionWeightsReference.js'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSpace = ref(null);
let deleteModal = ref(null);

let spaces = computed(() => {
  return proj.spaces;
})

function selectSpace(space) {
  selectedSpace.value = space;
}

function deleteSpace(space) {
  deleteModal.value.showModal("Delete", `Delete ${space.name.value}?`, () => {
    removeElem(spaces.value, space);
  });
}

function addSpace() {
  spaces.value.push(Space.create(generateItemName('Space', proj.spaces, 'TYPE_NAME-CTR'), true))
  selectSpace(spaces.value[spaces.value.length - 1]);
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SpacesView">
      <h4 class="mb-s">Spaces</h4>
      <p class="mb-m">
        Use this page to build different  types for use in the Build House tab.
      </p>
      <p>
        The <b>R-value</b> of the  will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.
        <br><br>
          Consult your local building code for minimum requirements of <b>R-value</b>. For <b>absorptance</b>, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results.
        <br><br>

        When using the <b>Wall Builder</b>, certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall.
        <br><br>

        The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.
        <br><br>

         built in this section can be used as  types  in the Build House tab.

      </p>
    </HelpSection>
    <div v-if="selectedSpace">
      <div class="mb-m">
        <button @click="selectedSpace=null" class="LinkBtn mb-m"><i class="bi-arrow-left-circle mr-xxs"></i>Done Editing</button>
        <div class="Flex">
          <h1 class="">{{ selectedSpace.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <SimpleCollapse class="Section Emphasis" title="General" @onOpen="gApp.proj().setHelpId('GeneralData')">
        <FieldInput v-model="selectedSpace.name" class="MarginBotXS" />
        <WarnText v-if="isDupName(selectedSpace.name.value, spaces)">There is already a system with that name. Please choose another.</WarnText>
        <FieldList :obj="selectedSpace" :fieldNames="selectedSpace.generalFields" />
        <div class="mt-s">
          <PopupRefTable title="Construction weights reference" :data="GetConstructionWeightsReference()" />
        </div>
      </SimpleCollapse>
      <SimpleCollapse class="Section Emphasis" title="Ventilation" @onOpen="gApp.proj().setHelpId('Ventilation')">
        <div class="mb-m">
          <p class="mute-header Container">Breathing Zone</p>
          <FieldInput v-model="selectedSpace.ventilation.ventilationType" />
          <div>
            <div v-if="selectedSpace.ventilation.ventilationType.value == 'Automatic'" class="mb-s">
              <p>Ventilation data for <u>{{selectedSpace.getSpaceTypeName()}}</u>:</p>
              <p>- Ventilation/person: {{ makeValStr(selectedSpace.ventilation.getDataForSpace()['cfm/person'], Units.AirFlowPerPerson) }}</p>
              <p>- Ventilation/area: {{ makeValStr(selectedSpace.ventilation.getDataForSpace()['cfm/ft^2'], Units.AirFlowPerArea) }}</p>
            </div>
            <FieldInput v-model="selectedSpace.ventilation.automaticVentilation" />
          </div>
          <div>
            <FieldInput v-model="selectedSpace.ventilation.manualVentilation" />
            <FieldInput v-model="selectedSpace.ventilation.minimumVentilationRequirement" />
          </div>
        </div>
        <div>
          <p class="mute-header Container">Effectiveness</p>
          <FieldInput v-model="selectedSpace.ventilation.ventilationEffectivenessCooling" />
          <FieldInput v-model="selectedSpace.ventilation.ventilationEffectivenessHeating" />
          <PopupRefTable title="Ventilation effectiveness reference data" :data="selectedSpace.ventilation.ventilationEffectivenessData" />
        </div>
        <!--
        <SelectOrManualInput v-model="selectedSpace.ventilationEffectiveness" />
        <FieldInput v-model="selectedSpace.manualVentilation" />
        -->
      </SimpleCollapse>
      <SimpleCollapse class="Section Emphasis" title="Infiltration" @onOpen="gApp.proj().setHelpId('Infiltration')">
        <FieldInput v-model="selectedSpace.summerInfiltration" />
        <FieldInput v-model="selectedSpace.winterInfiltration" />
        <FieldInput v-model="selectedSpace.infiltrationHours" />
      </SimpleCollapse>
      <WallsSection :space="selectedSpace" />
      <RoofsSection :space="selectedSpace" />
      <PartitionsSection :space="selectedSpace" />
      <FloorsSection :space="selectedSpace" />
      <CommercialInternalsSection :space="selectedSpace" />
      <div class="mt-l" v-if="gApp.isAdminUser()">
        <SimpleCollapse title="[Admin only] Space Loads">
          <div class="mb-s">
            <p><b>Debug Options:</b></p>
            <DebugOptions v-model="selectedSpace.debugCalcOptions" />
          </div>
          <button @click="selectedSpace.calculateDebugLoadsAsync()" class="mb-m">Recalculate</button>
          <div v-if="selectedSpace.loadResults">
            <div v-if="selectedSpace.runningCalculations" class="mb-m">
              <p>Recalculating...</p>
              <p>{{ selectedSpace.loadResults.calcContext.getProgressText() }}</p>
            </div>
            <div v-else>
              <div v-if="selectedSpace.loadResults.error" class="mb-m">
                <p class="delete-red">
                  Error during calculations (see log for details).
                </p>
              </div>
              <CalcContextView :ctx="selectedSpace.loadResults.calcContext"/>
            </div>
          </div>
          <div v-else>
            <p>Click 'Recalculate' to calculate space loads.</p>
          </div>
        </SimpleCollapse>
      </div>
    </div>
    <div v-else>
      <h1 class="PageH1">Spaces</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text">
          Add a <b></b> for each different type of  used in your project. Then, go to the <b>Build House</b> page to add  
          to your house (which will reference these types).
        </p>
      </div>
      <button @click="addSpace()" class="MarginBotXS CreateBtn">Add Space</button>
      <table class="SimpleTable" v-if="spaces.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="space in spaces" class="WallSection MarginBotL">
          <td>{{ space.name.value }}</td>
          <td>
            <button @click="selectSpace(space)">Edit</button>
          </td>
          <td>
            <button @click="deleteSpace(space)">Delete</button>
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}

.Result {
  color: blue;
}
</style>
