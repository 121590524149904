import {
  CurtainOpenness,
  CurtainColour,
  NumPanes,
  Glazing,
  SlatsLocation,
  ShadeReflectiveness,
  ShadesOrientation,
  LouverAngle,
  RollerShadesDetail,
  RollerShadesColour,
  InteriorShadeType,
  WindowStyle,
} from './Components.js';
import { lookupData, lerp, } from './Base.js';
import { 
  StrictParseNumber,
} from './SharedUtils.js'
import { gApp, } from './Globals.js'


export class IACCalculator {
  constructor(winType, inShading, iacValues) {
    this.winType = winType;
    this.inShading = inShading;
    this.iacValues = iacValues;
  }

  _computeGlazingIdForBuiltWindow(winType) {
    // All built windows should have a glazingId  
    let shgc = winType.computeShgc();
    let glazingIdStr = shgc.glazingId;
    if (!glazingIdStr) {
      throw new Error(`Unexpected GlazingId: ${glazingIdStr}`);
    }
    let glazingId = this._parseGlazingIdStr(glazingIdStr);
    console.log("GlazingID: ", glazingId);
    return glazingId;
  }

  _computeFabricCurtainIACData(winType, inShading) {
    console.log("Computing FabricCurtain IAC data");
    let section = 3;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;
    let col = null;
    let extras = {}

    let openness = inShading.fabricCurtainGroup.getField('openness');
    let opennessDict = {
      [CurtainOpenness.Open]: 6,
      [CurtainOpenness.SemiOpen]: 3,
      [CurtainOpenness.Closed]: 0,
    };
    detail1 = lookupData(opennessDict, [openness.value]);

    let colour = inShading.fabricCurtainGroup.getField('colour').value;
    let colourDict = {
      [CurtainColour.Light]: 2,
      [CurtainColour.Medium]: 1,
      [CurtainColour.Dark]: 0,
    }
    detail2 = lookupData(colourDict, [colour])
    
    let builder = winType.getWindowBuilderIfUsing();
    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 11,
          [Glazing.E0_6]: 11,
          [Glazing.E0_4]: 22,
          [Glazing.E0_2]: 22,
          [Glazing.E0_1]: 33,
          [Glazing.E0_05]: 44,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 44,
          _ELSE: 55,
        },
        [NumPanes.N4]: {
          [Glazing.None]: 44,
          _ELSE: 55,
        }
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value
      ])
      let glazingId = this._computeGlazingIdForBuiltWindow(winType)
      col = this._getIACColForBuiltWindow(glazingId)
      extras.glazingId = glazingId
    } else {
      let idNum = this._computeManualEntryIdNum(winType);
      winRow = this._computeWinRowFromIdNum(idNum, inShading);
      col = this._getIACColForManualWindow(idNum);
    }

    return {section, detail1, detail2, detail3, winRow, col, ...extras};
  }

  _computeLouveredShadesIACData(winType, inShading) {
    console.log("Computing LouveredShades IAC data");
    let section = 73;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;
    let col = null;
    let extras = {}

    let detail1Dict = {
      [NumPanes.N1]: {
        [SlatsLocation.IndoorSide]: 0,
        [SlatsLocation.BetweenGlazing]: 0,
        [SlatsLocation.OutdoorSide]: 30,
      },
      // For panes 2-4, or if manual input type
      _ELSE: {
        [SlatsLocation.IndoorSide]: 0,
        [SlatsLocation.BetweenGlazing]: 30,
        [SlatsLocation.OutdoorSide]: 60,
      }
    };
    let slatsLot = inShading.louveredShadesGroup.getField('location').value;
    let builder = winType.getWindowBuilderIfUsing();
    let numPanes = builder ? builder.numPanes.value : 'Unknown';
    detail1 = lookupData(detail1Dict, [numPanes, slatsLot]);

    let detail2Dict = {
      [ShadeReflectiveness.R0_15]: 0,
      [ShadeReflectiveness.R0_5]: 10,
      [ShadeReflectiveness.R0_8]: 20,
    }
    let reflectiveness = inShading.louveredShadesGroup.getField('reflectiveness').value;
    detail2 = lookupData(detail2Dict, [reflectiveness]);

    let detail3Dict = {
      [LouverAngle.Unknown]: 0,
      [LouverAngle.ZeroDeg]: 2,
      [LouverAngle.TrackSolarAngle]: 4,
      [LouverAngle.FortyFiveDeg]: 6,
      [LouverAngle.FullyClosed]: 8,
    }
    let louverAngle = inShading.louveredShadesGroup.getField('louverAngle').value;
    detail3 = lookupData(detail3Dict, [louverAngle]);

    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 65,
          [Glazing.E0_6]: 65,
          [Glazing.E0_4]: 158,
          [Glazing.E0_2]: 158,
          [Glazing.E0_1]: 251,
          [Glazing.E0_05]: 344,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 344,
          _ELSE: 437,
        },
        [NumPanes.N4]: {
          _ELSE: 437,
        },
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value,
      ])
      let glazingId = this._computeGlazingIdForBuiltWindow(winType)
      col = this._getIACColForBuiltWindow(glazingId)
      extras.glazingId = glazingId
    } else {
      let idNum = this._computeManualEntryIdNum(winType);
      winRow = this._computeWinRowFromIdNum(idNum, inShading);
      col = this._getIACColForManualWindow(idNum);
      extras.idNum = idNum;
    }

    return {section, detail1, detail2, detail3, winRow, col, ...extras};
  }

  _computeRollerShadesIACData(winType, inShading) {
    let section = 604;
    let detail1 = 0;
    let detail2 = 0;
    let detail3 = 0;
    let winRow = null;
    let col = null;
    let extras = {}

    let detail1Dict = {
      [RollerShadesDetail.Opaque]: {
        [RollerShadesColour.Light]: 1,
        [RollerShadesColour.LightGrey]: 3,
        [RollerShadesColour.DarkGrey]: 2,
        [RollerShadesColour.ReflectiveWhite]: 5,
      },
      [RollerShadesDetail.Translucent]: {
        [RollerShadesColour.Light]: 0,
        [RollerShadesColour.LightGrey]: 3,
        [RollerShadesColour.DarkGrey]: 4,
        [RollerShadesColour.ReflectiveWhite]: 6,
      },
    }
    // TODO see note about bugScreen or screen door
    detail1 = lookupData(detail1Dict, [
      inShading.rollerShadesGroup.getField('detail').value,
      inShading.rollerShadesGroup.getField('colour').value,
    ])

    let builder = winType.getWindowBuilderIfUsing();
    if (builder) {
      let winRowDict = {
        [NumPanes.N1]: {
          _ELSE: 0,
        },
        [NumPanes.N2]: {
          [Glazing.None]: 10,
          [Glazing.E0_6]: 10,
          [Glazing.E0_4]: 20,
          [Glazing.E0_2]: 20,
          [Glazing.E0_1]: 30,
          [Glazing.E0_05]: 40,
        },
        [NumPanes.N3]: {
          [Glazing.None]: 40,
          _ELSE: 50,
        },
        [NumPanes.N4]: {
          _ELSE: 50,
        },
      }
      winRow = lookupData(winRowDict, [
        builder.numPanes.value,
        builder.glazing.value,
      ])
      let glazingId = this._computeGlazingIdForBuiltWindow(winType)
      col = this._getIACColForBuiltWindow(glazingId)
      extras.glazingId = glazingId
    } else {
      let idNum = this._computeManualEntryIdNum(winType);
      winRow = this._computeWinRowFromIdNum(idNum, inShading);
      col = this._getIACColForManualWindow(idNum);
    }

    return {section, detail1, detail2, detail3, winRow, col, ...extras};
  }

  _getIACColForBuiltWindow(glazingId) {
    // TODO - figure out how to do Matches here
    // Maybe '_Matches_...' string, with a _Values item.
    let colDict = {
      [1]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        _ELSE: 'L',
      },
      [5]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        _ELSE: 'L',
      },
      [17]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        j: 'M',
        k: 'N',
        _ELSE: 'N',
      },
      [21]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        g: 'J',
        h: 'K',
        i: 'L',
        j: 'M',
        k: 'N',
        _ELSE: 'N',
      },
      [25]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        e: 'H',
        f: 'I',
        _ELSE: 'I',
      },
      [29]: {
        a: 'J',
        b: 'K',
        _ELSE: 'K',
      },
      [32]: {
        a: 'D',
        b: 'E',
        c: 'F',
        d: 'G',
        _ELSE: 'G',
      },
      [40]: {
        a: 'H',
        b: 'I',
        c: 'J',
        d: 'K',
        _ELSE: 'K',
      },
    };
    return lookupData(colDict, [glazingId.num, glazingId.letter])
  }

  _computeManualEntryIdNum(winType) {
    let idNum = null;
    let uValueData = winType.computeUValue();
    // For doors, which have uValueGlass and uValueDoor, use uValueGlass. For windows, we
    // only have 'uValue'.
    let uValue = ('uValueGlass' in uValueData) ? uValueData.uValueGlass : uValueData.uValue;
    let isResidential = gApp.proj().isResidential();
    if (winType.getWindowStyle() == WindowStyle.Operable) {
      if (isResidential) {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.5) {
          idNum = 5;
        } else if (uValue > 0.45) {
          idNum = 17;
        } else {
          idNum = 40;
        }
      } else {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.6) {
          idNum = 5;
        } else if (uValue > 0.55) {
          idNum = 17;
        } else {
          idNum = 40;
        }
      }
    } else {
      if (isResidential) {
        if (uValue > 0.9) {
          idNum = 1;
        } else if (uValue > 0.6) {
          idNum = 5;
        } else if (uValue > 0.55) {
          idNum = 17;
        } else if (uValue > 0.5) {
          idNum = 21;
        } else {
          idNum = 32;
        }
      } else {
        if (uValue > 1) {
          idNum = 1;
        } else if (uValue > 0.75) {
          idNum = 5;
        } else if (uValue > 0.7) {
          idNum = 17;
        } else if (uValue > 0.6) {
          idNum = 21;
        } else {
          idNum = 32;
        }
      }
    }
    return idNum;
  }

  _computeWinRowFromIdNum(idNum, inShading) {
    let idNumToWinRowDict = {
      [InteriorShadeType.FabricCurtain]: {
        1: 0,
        5: 11,
        17: 22,
        21: 33,
        25: 44,
        29: 44,
        32: 55,
        40: 55,
      },
      [InteriorShadeType.LouveredShades]: {
        1: 0,
        5: 65,
        17: 158,
        21: 251,
        25: 354,
        29: 354,
        32: 437,
        40: 437,
      },
      [InteriorShadeType.RollerShades]: {
        1: 0,
        5: 10,
        17: 20,
        21: 30,
        25: 40,
        29: 40,
        32: 50,
        40: 50,
      },
    };
    return lookupData(idNumToWinRowDict, [
      inShading.type.value,
      idNum,
    ])
  }

  _getIACColForManualWindow(idNum) {
    if (this.inShading.type.value == InteriorShadeType.FabricCurtain ||
      this.inShading.type.value == InteriorShadeType.RollerShades) {
      let idNumToColDict = {
        1: 'P',
        5: 'P',
        17: 'P',
        21: 'P',
        25: 'P',
        32: 'P',
        29: 'S',
        40: 'S',
      }
      return lookupData(idNumToColDict, [idNum])
    } else {
      // Note: this gives the col of the the iac0 value. The iac60 and iacDiff
      // values are the two columns after that.
      let idNumToColDict = {
        1: 'P',
        5: 'P',
        17: 'P',
        21: 'P',
        25: 'P',
        29: 'P',
        32: 'T',
        40: 'X',
      }
      return lookupData(idNumToColDict, [idNum])
    }
  }

  _parseGlazingIdStr(glazingIdStr) {
    const regex = /^(\d+)([a-zA-Z])$/;
    const match = glazingIdStr.match(regex);
    if (match) {
      const num = parseInt(match[1]);
      const letter = match[2];
      return { num, letter };
    } else {
      throw new Error("Unexpected GlazingId: " + glazingIdStr);
    }
  }

  _parseIACStr(iacStr) {
    let result = {};
    if (iacStr.includes(', ')) {
      result.iac = StrictParseNumber(iacStr.split(', ')[0]);
    } else if (iacStr.includes('/')) {
      // Parse a string of the form 'IAC0 (IAC60)/IACdiff'. Ex: '0.93 (0.05)/0.41'
      let parts = iacStr.split(' ');
      result.iac0 = StrictParseNumber(parts[0]);
      let iacParts = parts[1].split('/');
      result.iac60 = StrictParseNumber(iacParts[0].slice(1, -1));
      result.iacDiff = StrictParseNumber(iacParts[1]);
      result.iac = result.iac0
    } else {
      result.iac = StrictParseNumber(iacStr);
    }
    return result
  }

  _lookupIACValuesFromData(iacData) {
    console.log("Looking up IAC values for data: ", iacData);
    let result = {}
    let row = iacData.section + iacData.detail1 + iacData.detail2 + iacData.detail3
      + iacData.winRow;
    let col = iacData.col;
    console.log("Using row and col: ", row, col);
    if (row == null || col == null) {
      throw Error("Unexpected: IAC row or col is null");
    }
    result.row = row;
    result.col = col;

    // Note: for FabricCurtain and Roller shades, there is only one IAC value.
    // For LouveredShades, there are three values.
    let iacs = null
    if (this.inShading.type.value !== InteriorShadeType.LouveredShades) {
      let iacStr = this.iacValues.lookupValue(row, col, String);
      iacs = this._parseIACStr(iacStr);
    } else {
      let builder = this.winType.getWindowBuilderIfUsing();
      if (builder) {
        // The three values are in a single string
        let iacStr = this.iacValues.lookupValue(row, col, String);
        iacs = this._parseIACStr(iacStr);
      } else {
        // The three values are in separate columns
        let startCol = this.iacValues.colStrToIndex(col);
        iacs = {
          iac0: this.iacValues.lookupValue(row, startCol, Number),
          iac60: this.iacValues.lookupValue(row, startCol + 1, Number),
          iacDiff: this.iacValues.lookupValue(row, startCol + 2, Number),
        };
        iacs.iac = iacs.iac0;
      }
    }
    result = {...result, ...iacs};

    return result
  }

  computeIAC() {
    console.log("Computing IAC");
    let winType = this.winType;
    let inShading = this.inShading;

    let result = {};

    let iacData = null;
    if (inShading.type.value == InteriorShadeType.FabricCurtain) {
      iacData = this._computeFabricCurtainIACData(winType, inShading);
    } else if (inShading.type.value == InteriorShadeType.LouveredShades) {
      iacData = this._computeLouveredShadesIACData(winType, inShading);
    } else if (inShading.type.value == InteriorShadeType.RollerShades) {
      iacData = this._computeRollerShadesIACData(winType, inShading);
    } else {
      throw new Error("Unknown interior shading type: ", inShading.type.value);
    }
    result.iacData = iacData;

    let iacs = this._lookupIACValuesFromData(iacData);
    result = {...result, ...iacs};

    return result;
  }

  computeIACForSolarPosition(ctx, solarValues) {
    ctx.startSection('IAC Calculation');
    let iacRes = this.computeIAC();
    if (this.inShading.type.value == InteriorShadeType.FabricCurtain || 
      this.inShading.type.value == InteriorShadeType.RollerShades) {
      // There is only a single IAC value, and IAC_diff = IAC
      ctx.result = {iac: iacRes.iac, iacDiff: iacRes.iac};
    } else if (this.inShading.type.value == InteriorShadeType.LouveredShades) {
      // For Louvered shades, the IAC depends on the solar angles
      if (this.inShading.orientation.value == ShadesOrientation.Horizontal) {
        ctx.Omega_rads = ctx.eval('arctan(tan(beta_rads) / cos(gamma_rads))', {
            arctan: Math.atan,
            beta_rads: solarValues.beta_rads,
            gamma_rads: solarValues.gamma_rads,
        }, 'Omega_rads')
      } else if (this.inShading.orientation.value == ShadesOrientation.Vertical) {
        ctx.Omega_rads = ctx.eval('gamma_rads', {
            gamma_rads: solarValues.gamma_rads,
        }, 'Omega_rads')
      } else {
        throw new Error("Unknown orientation: ", this.inShading.orientation.value);
      }
      ctx.IAC_theta = ctx.eval('lerp(0, 60, iac0, iac60, theta_degs)', {
        lerp: lerp,
        iac0: iacRes.iac0,
        iac60: iacRes.iac60,
        theta_degs: solarValues.theta_degs,
      }, 'IAC_theta'); 
      ctx.IAC_theta_Omega = ctx.eval('IAC_0 + (IAC_theta - IAC_0) * min(1.2*Omega_rads, 60)/60', {
        IAC_0: iacRes.iac0,
      }, 'IAC_theta_Omega');
      ctx.result = {iac: ctx.IAC_theta_Omega, iacDiff: iacRes.iacDiff};
    } else {
      throw new Error("Unknown interior shading type: ", this.inShading.type.value);
    }
    let result = ctx.result;
    ctx.endSection();
    return result;
  }
}