<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { gApp, lookupLocationData, } from './State.js'
import { makeValStr } from './Units.js'
import { WeatherDataMap } from './WeatherDataMap.js'
import BasicSelector from './BasicSelector.vue'
import FieldInput from './FieldInput.vue'
import FieldList from './FieldList.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import HelpButton from './HelpButton.vue'

const props = defineProps({
  modelValue: Object,
})
const emit = defineEmits(['update:modelValue'])

let showLocationChooser = ref(false);
let subPath = ref(null);

let locDataVisible = ref(false);

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

function onValueChanged(newVal) {
  value.value.value = newVal;
}

function toggleLocationChooser() {
  if (!showLocationChooser.value) {
    console.log("Turning on");
    showLocationChooser.value = true;
    subPath.value = [];
  } else {
    console.log("Turning off");
    showLocationChooser.value = false;
  }
}

function makeColData(locParent, path) {
  let data = [];
  for (const elem of locParent.children) {
    data.push({
      name: elem.name,
      path: [...path, elem.name],
      isLeaf: !('children' in elem),
      data: elem,
    });
  }
  return data;
}

function lookupByName(locMap, locName) {
  for (const locElem of locMap.children) {
    if (locElem.name == locName) {
      return locElem;
    }
  }
  throw new Error(`Location not found: ${locName}`);
}

let colData = computed(() => {
  let cols = [];
  cols.push(makeColData(WeatherDataMap, []));

  let curMap = WeatherDataMap;
  let pathSoFar = [];
  for (let i = 0; i < subPath.value.length; ++i) {
    let part = subPath.value[i];
    pathSoFar.push(part);
    curMap = lookupByName(curMap, part);
    if (!curMap.children) {
      break;
    }
    cols.push(makeColData(curMap, pathSoFar));
  }
  return cols;
})

function onClickPathElem(pathElem) {
  if (pathElem.isLeaf) {
    console.log("Selected path: ", pathElem.path);
    showLocationChooser.value = false;
    props.modelValue.setLocation(pathElem.data);
  } else {
    subPath.value = pathElem.path;
  }
}

let fields = computed(() => {
  return props.modelValue.fields.map((fieldName) => props.modelValue[fieldName])
})

function addOverride(field) {
  field.data.useDefault = false;
  field.value = field.data.defaultValue;
}

function removeOverride(field) {
  field.data.useDefault = true;
  field.value = field.data.defaultValue;
}

</script>

<template>
  <div class="Location">
    <div class="LocSelector MarginBotXS">
      <div v-if="!showLocationChooser" class="Flex GapS">
        <button @click="toggleLocationChooser" class="f-m MarginBotXS LinkBtn SetLocBtn">{{ value.locPath ? value.locPath.fullName : 'Set Location' }}</button>
        <button @click="locDataVisible = !locDataVisible" class="LinkBtn ShowLocDataBtn" v-if="value.locPath">{{!locDataVisible ? 'Show Data' : 'Close Data'}}</button>
      </div>
      <div v-else class="LocChooser MarginBotS">
        <p class="help-text mb-xxs">Choose a new location:</p>
        <div class="LocTable MarginBotXXS">
          <div v-for="(col,i) of colData" class="LocCol">
            <button v-for="entry in col" class="LocBtn LinkBtn MarginBotXXS" :class="{IsSelected:subPath[i] === entry.name}"
              @click="onClickPathElem(entry)">
              {{ entry.name }}
            </button>
          </div>
        </div>
        <button @click="showLocationChooser = false">Cancel</button>
      </div>
    </div>
    <div v-if="value.locData">
      <div v-if="locDataVisible" title="Location Data" class="LocationData">
        <table class="SimpleTable">
          <tr>
            <th>Name</th>
            <th>Default</th>
            <th>Override</th>
          </tr>
          <tr v-for="(field, index) in fields">
            <td>{{ field.name }}</td>
            <td>{{ makeValStr(field.data.defaultValue, field.units) }}</td>
            <td>
              <button v-if="field.data.useDefault" @click="addOverride(field)" class="LinkBtn">Override</button>
              <div v-else class="Flex">
                <FieldInput v-model="modelValue[modelValue.fields[index]]" :compact="true" />
                <button @click="removeOverride(field)" class="LinkBtn">X</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>

<style scoped>
.Location {
  max-width: 600px;
}

.FieldInput {
  display: flex;
  gap: 8px;
}

.LocChooser {
}

.LocTable {
  /* padding: var(--space-xs); */
  /* border: 1px solid var(--b-20); */
  display: flex;
  flex-flow: row nowrap;
  min-height: 320px;
  max-height: 500px;
}

.LocCol {
  padding: var(--space-s);
  border: 1px solid var(--b-20);
  min-width: 160px;
  overflow-y: scroll;
}

.LocCol + .LocCol {
  border-left: none;
}

.LocBtn {
}

.LocBtn.IsSelected {
  color: var(--red);
}

.LocationData {
  /* width: 600px; */
}

.SetLocBtn {
  color: var(--pc);
}

.ShowLocDataBtn {
}
</style>
