<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import CalcLogItemV2 from './CalcLogItemV2.vue';
import { prettyJson } from './SharedUtils';

const props = defineProps({
  ctx: Object
})

let logDict = computed(() => {
  return props.ctx.getStructuredLogDict();
});

</script>

<template>
  <div class="LogText">
    <CalcLogItemV2 :item="logDict" />
  </div>
</template>

<style scoped>
.LogText {
  white-space: pre;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
}
</style>
