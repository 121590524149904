<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick, } from 'vue'
import { gApp } from './State.js'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  styles: {
    default: {},
  },
  hasSlot: {
    default: true,
  }
});

let hasTicked = ref(false);
let isHovering = ref(false);

/*
function toggleHelpWin() {
  if (gApp.proj().helpPaneId == props.id) {
    gApp.proj().helpPaneId = null;
  } else {
    gApp.proj().helpPaneId = props.id;
  }
}
*/

function openHelp() {
  gApp.proj().setHelpId(props.id);
}

onMounted(() => {
  nextTick(() => {
    hasTicked.value = true;
  })
})

</script>

<template>
  <div class="Container">
    <div class="HelpBtn hover-grow" @click="openHelp" @mouseover="isHovering=true" @mouseout="isHovering=false">
      <i class="text-btn" :class="isHovering ? 'bi-question-circle-fill' : 'bi-question-circle'"></i>
    </div>
    <Teleport v-if="hasTicked" to="#HelpContent">
      <div v-if="hasSlot && gApp.proj().helpPaneId == id">
        <slot></slot>
      </div>
    </Teleport>
  </div>
</template>

<style scoped>
.Container {
}

.HelpWin {
}

.HelpBtn {
  font-size: var(--f-m2);
  /* color: var(--grey-20); */
  color: var(--pc);
}

</style>
