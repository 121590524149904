export let Luminaires_Data = `Luminaire type,"Space fraction (lower, middle, upper)",,,"Radiative fraction (lower, middle, upper)",,,
Recessed fluorescent luminaire without lens,0.64,0.69,0.74,0.48,0.58,0.68,"Use middle values in most situations
May use higher space fraction, and lower radiative fraction for luminaire with side-slot returns
May use lower values of both fractions for direct/indirect luminaire
May use higher values of both fractions for ducted returns"
Recessed fluorescent luminaire with lens,0.4,0.45,0.5,0.61,0.67,0.73,May adjust values in the same way as for recessed fluorescent luminaire without lens
Downlight compact fluorescent luminaire,0.12,0.18,0.24,0.95,0.975,1,"Use middle or high values if detailed features are unknown
Use low value for space fraction and high value for radiative fraction if there are large holes in luminaire’s reflector"
Downlight incandescent luminaire,0.7,0.75,0.8,0.95,0.975,1,"Use middle values if lamp type is unknown
Use low value for space fraction if standard lamp (i.e. A-lamp) is used
Use high value for space fraction if reflector lamp (i.e. BR-lamp) is used"
Non-in-ceiling fluorescent luminaire,1,1,1,0.5,0.535,0.57,"Use lower value for radiative fraction for surface-mounted luminaire
Use higher value for radiative fraction for pendant luminaire"
Recessed LED troffer partial aperture diffuser,0.49,0.565,0.64,0.37,0.42,0.47,"Use middle value in most cases
May use higher space fraction for ducted return configuration and lower space fraction for high supply air temperature
May use higher radiant value for ducted return configuration and lower value for large supply airflow rate"
Recessed LED troffer uniform diffuser,0.44,0.55,0.66,0.32,0.365,0.41,"Use middle value in most cases.
May use higher space fraction for smaller supply airflow rate and lower value for larger supply airflow rate.
May use higher radiant value for ducted return configuration and lower value for larger supply airflow rate."
Recessed high-efficacy LED troffer,0.59,0.59,0.59,0.51,0.51,0.51,
Recessed LED downlight,0.4,0.48,0.56,0.15,0.165,0.18,"Use middle value in most cases.
May use higher space fraction value for high supply air temperature and lower value for smaller air flowrate.
May use higher radiant value for dimming control and lower value for large supply air flowrate."
Recessed LED retrofit kit 2×4,0.41,0.47,0.53,0.31,0.365,0.42,"Use middle value in most cases.
May use higher space fraction value for large supply air flowrate and lower value for ducted return configuration.
May use higher radiant value for ducted return configuration and lower value for larger supply airflow rate."
Recessed LED color tuning fixture,0.53,0.545,0.56,0.4,0.41,0.42,Use middle value in most cases.
High-bay LED fixture,1,1,1,0.42,0.465,0.51,Use middle value in most cases.
Linear pendant LED fixture,1,1,1,0.55,0.575,0.6,Use middle value in most cases.
Unknown,0.59,0.64,0.68,0.50,0.54,0.58,`