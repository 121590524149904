<script setup>
import { ref, onMounted, watch, computed, } from 'vue'
import { gApp } from './State.js'
import { ProjectTypes } from './Project.js'
import { prettyJson } from './SharedUtils.js'
import BasicModal from './BasicModal.vue'
import ProjectsTable from './ProjectsTable.vue'
import InputGroup from './InputGroup.vue'
import RadioSelector from './RadioSelector.vue'

let createProjModal = ref(null);
let newProjName = ref("");
let newProjType = ref(ProjectTypes.Residential);

let debugProjects = [
  {id: '0'},
  {id: '1'},
  {id: '2'},
];

function startCreateProject() {
  newProjName.value = "";
  newProjType.value = ProjectTypes.Residential;
  createProjModal.value.showModal();
}

async function createProject() {
  await gApp.createProject(newProjName.value, newProjType.value);
}

async function sendEmailVerification() {
  await gApp.sendEmailVerification(true);
}

let myProjects = computed(() => {
  return gApp.projects.filter((proj) => { return proj.isMine; })
})

let sharedProjects = computed(() => {
  return gApp.projects.filter((proj) => { return !proj.isMine; })
})

onMounted(() => {
  if (gApp.user.value) {
    gApp.closeProject();
    gApp.reloadProjects();
  }
})

</script>

<template>
  <div class="Home PaddingM">
    <div v-if="gApp.authState.value === 'SignedIn'" class="Projects ScrollY">
      <div class="TextAlignCenter">
        <h2 class="fw-normal2">Welcome to HeatWise!</h2>
        <p class="big-help-text mb-s">Create a new project to start calculating loads.</p>
        <button @click="startCreateProject" class="CreateProjBtn BigCreateBtn mlr-auto">Create Project</button>
      </div>
      <div class="MarginBotM mt-xl">
        <InputGroup v-if="!gApp.user.value.emailVerified">
          <p class="mute-header">Verify Your Email</p>
          <p class="MarginBotS">Please verify your email before continuing. We sent a verification link to <b>{{gApp.user.value.email}}</b>.<br>Don't see it? Check your spam.</p>
          <button @click="sendEmailVerification" class="LinkBtn">Resend</button>
        </InputGroup>
      </div>
      <div class="MarginBotM">
        <div class="MarginBotM">
          <h3 class="mb-xxs">My Projects</h3>
          <ProjectsTable :projects="myProjects" />
        </div>
        <div class="MarginBotM">
          <h3 class="mb-xxs">Shared with me</h3>
          <ProjectsTable :projects="sharedProjects" />
        </div>
        <div v-if="gApp.isAdminUser()" class="MarginBotM">
          <h3 class="mb-xxs">Beta Features</h3>
          <p>(This section is only visible to beta users)</p>
          <div>
            <router-link to="/psychrometrics" class="LinkBtn">Psychrometrics Calculator</router-link>
          </div>
          <div>
            <router-link to="/solar-calculator" class="LinkBtn">Solar Calculator</router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="gApp.authState.value === 'Checking'">
      <h4 class="upper">Loading...</h4>
    </div>
    <div v-else class="SignIn FlexCol GapXS AlignCenter">
      <h2 class="MarginBotM">You are signed out</h2>
      <button @click="gApp.router.replace('/signin')" class="">Sign in here</button>
    </div>
    <BasicModal ref="createProjModal" title="Create Project" doneText="Create" @onDone="createProject">
      <p class="mute-header">Name</p>
      <input v-model="newProjName" class="mb-s"/>
      <p class="mute-header mb-xxs">Type</p>
      <div v-if="!gApp.isBetaUser()">
        <p class="mb-xxs"><i class="bi-square-fill pc f-s mr-xs"></i>Residential Building</p>
        <p class="mb-s"><i class="bi-square pc f-s mr-xs"></i>Commercial Building (coming soon)</p>
        <p class="help-text">Commercial building support is coming soon. Inquire by email for early access.</p>
      </div>
      <div v-else>
        <RadioSelector 
          v-model="newProjType" 
          :options="[
            { value: 'Commercial', label: 'Commercial Building' },
            { value: 'Residential', label: 'Residential Building' },
          ]" 
        />
      </div>
    </BasicModal>
  </div>
</template>

<style scoped>
.Home {
}

.Projects {
  margin: 0 auto;
  max-width: 1200px;
}

.CreateProjBtn {
  border-radius: var(--br-m);
  padding: var(--s) var(--m);
}

</style>
