import { CsvHelper } from './CsvHelper.js';

/**
 * MaterialDataTable
 * 
 * tableDesc: {
 *  csvData: string
 * // TODO - add more fields
 * }
 */
export class MaterialDataTable {
  constructor(tableDesc) {
    this.tableDesc = tableDesc
    // Load the data
    // TODO - async load from a remote file?
    this.csvHelper = new CsvHelper(tableDesc.data)

    // Add all the tableDesc funcs to this object, as helper funcs
    for (const funcName in this.tableDesc.funcs) {
      this[funcName] = (...args) => {
        return this.tableDesc.funcs[funcName](this.csvHelper, ...args)
      }
    }
  }

  static getLoader(tableDesc) {
    return () => new MaterialDataTable(tableDesc)
  }

};
