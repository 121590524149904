import { ref, reactive } from 'vue'
import { valOr } from './SharedUtils.js'

/*
Serializer Utils
*/

function hasProp(obj, propName) {
  // return obj !== null && typeof obj == 'object' && obj.hasOwnProperty(propName);
  let hasProp = obj !== null && (typeof obj == 'object') && (propName in obj);
  // console.log(`hasProp ${propName}: ${hasProp}`)
  return hasProp;
}

export function arrayField(name, elemCtor) {
  return {name: name, type: 'ObjArray', elemCtor: elemCtor}
}

export function dateField(name) {
  return {name: name, type: 'Date'}
}

/*
let kTypeMap = {
  'Date': {
    writeToJson: (obj) => {
      return obj.toJSON();
    },
    createFromJson: (data) => {
      return new Date(data);
    }
  }
}
*/

function logIf(condition, ...args) {
  if (condition) {
    console.log(...args);
  }
}

function addPath(opts, fieldName) {
  let newOpts = {...opts};
  newOpts.path = newOpts.path + `/${fieldName}`;
  return newOpts;
}

export function writeToJson(obj, opts) {
  opts = valOr(opts, {})
  opts.path = opts.path || 'root';
  logIf(opts.debug, `Writing ${opts.path}`);
  if (hasProp(obj, 'writeToJson')) {
    logIf(obj.debug, "Using 'writeToJson' func")
    return obj.writeToJson(opts);
  } else if (hasProp(obj, 'serFields')) {
    logIf(obj.debug, "Using 'serFields'")
    let data = {};
    for (const field of obj.serFields) {
      if (typeof field == 'string') {
        data[field] = writeToJson(obj[field], addPath(opts, field));
      } else {
        if (field.type == "ObjArray") {
          let arr = [];
          for (let i = 0; i < obj[field.name].length; ++i) {
            let elem = obj[field.name][i];
            let elemData = writeToJson(elem, addPath(opts, `${field.name}[${i}]`));
            arr.push(elemData);
          }
          data[field.name] = arr;
        } else if (field.type == "Date") {
          logIf(opts.debug, `Writing '${opts.path + '/' + field.name}' (Date)`)
          data[field.name] = obj[field.name].toJSON();
        } else {
          throw new Error("Invalid serField: ", field);
        }
      }
    }
    return data;
  } else {
    // logIf(opts.debug, "Using value");
    /*
    if (obj.constructor.name in kTypeMap) {
      console.log(`Using custom writer for ${obj.constructor.name}`);
      return kTypeMap[typeof obj].writeToJson();
    } else {
      return obj;
    }
    */
    return obj;
  }
}

export function readFromJson(targetObj, data, opts) {
  opts = valOr(opts, {})
  let debug = false;
  opts.path = opts.path || 'root';
  logIf(debug, `${opts.path}`);
  if (hasProp(targetObj, 'readFromJson')) {
    logIf(debug, `Using readFromJson`)
    targetObj.readFromJson(data);
  } else if (hasProp(targetObj, 'serFields')) {
    for (const field of targetObj.serFields) {
      if (typeof field == 'string') {
        logIf(debug, `- ${field}`);

        // Simple serField item (ex. 'title')
        if (!hasProp(data, field)) {
          // The targetObj wants a field that's not in the data. Keep the default.
          continue;
        }
        if (hasProp(targetObj[field], 'readFromJson') || hasProp(targetObj[field], 'serFields')) {
          // Complex object. Recurse
          readFromJson(targetObj[field], data[field], addPath(opts, field));
        } else {
          // Plain object
          logIf(debug, `-- ${data[field]}`);
          targetObj[field] = data[field];
        }
      } else {
        // Complex serField item (ex. {name: 'posts', type: 'ObjArray', elemCtor: Post})
        if (!hasProp(data, field.name)) {
          // The targetObj wants a field that's not in the data. Keep the default.
          continue;
        }
        if (field.type == 'ObjArray') {
          // Clear cur array and append (do not invalidate existing array references)
          logIf(debug, `- ${field.name} (ObjArray)`);
          let arr = targetObj[field.name];
          arr.length = 0;
          for (let i = 0; i < data[field.name].length; ++i) {
            let elemData = data[field.name][i];
            let elem = field.elemCtor();
            readFromJson(elem, elemData, addPath(opts, `${field.name}[${i}]`));
            arr.push(elem);
          }
        } else if (field.type == 'Date') {
          logIf(debug, `- ${field.name} (Date)`);
          targetObj[field.name] = new Date(data[field.name]);
        } else {
          throw new Error("Invalid serField: " + field);
        }
      }
    }
  } else {
    throw new Error("No way to readFromJson for the given obj");
  }
}

