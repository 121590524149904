<script setup>
import { gApp } from './State.js'
import { ref, onMounted, reactive, computed } from 'vue'
import BasicModal from './BasicModal.vue'
import InputGroup from './InputGroup.vue'
import { ProjectTypes } from './Project';

let openProjModal = ref(null);
let projToOpen = ref({name: '', lockedBy: ''});

const props = defineProps({
  projects: Array,
})

function getTrimmedSharedUsers(proj) {
  let maxUsers = 2;
  let users = proj.sharedUsers.slice(0, maxUsers);
  if (proj.sharedUsers.length > maxUsers) {
    users.push(`(And ${proj.sharedUsers.length - maxUsers} more...)`);
  }
  /*
  for (let i = 0; i < users.length; ++i) {
    if (users[i] == gApp.getUser().email) {
      users[i] = 'Me'
    }
  }
   */
  return users;
}

async function tryOpenProj(proj) {
  if (proj.lockedBy === null) {
    gApp.router.push(proj.route)
  } else {
    projToOpen.value = proj;
    openProjModal.value.showModal();
  }
}

async function forceOpenProj(proj) {
  await gApp.stealProjectLock(proj.id);
  gApp.router.push(proj.route)
}

function getFirstLetter(str) {
  return str[0];
}

</script>

<template>
  <div class="TableWrapper">
    <p class="help-text" v-if="projects.length == 0">No projects here yet.</p>
    <table class="NiceTable x-Cols4 ProjectsTable w-100" v-else>
      <col style="width:35%">
      <col style="width:10%">
      <col style="width:10%">
      <col style="width:10%">
      <tr>
        <th class="NameRow">Name</th>
        <th>Owner</th>
        <th>Shared With</th>
        <th>Lock</th>
      </tr>
      <tr v-for="project in projects" :id="project.id">
        <td>
          <button @click="tryOpenProj(project)" class="LinkBtn OpenProjBtn"><i class="bi-box pc mr-xs"></i>{{project.name}}</button>
        </td>
        <td>
          <div class="Flex RowNoWrap">
            <div class="ProfileBtn mr-xs">
              <p class="upper white f-xs">{{getFirstLetter(project.userEmail || '?')}}</p>
            </div>
            <p class="">{{project.userEmail || 'Unknown'}}</p>
          </div>
        </td>
        <td>
          <p v-for="user in getTrimmedSharedUsers(project)">{{user}}</p>
        </td>
        <td>
          {{project.lockedBy || ''}}
        </td>
      </tr>
    </table>
    <BasicModal ref="openProjModal" title="Project Locked" :showCancel="false">
      <div class="MarginBotM">
        <h4>
          {{projToOpen.lockedBy || 'Someone'}} is currently editing {{projToOpen.name}}.
        </h4>
        <p>
          Only one user can edit a project at a time. Please wait until they are done.
        </p>
      </div>
      <InputGroup title="Open anyways..." class="OpenAnyways">
        <p class="MarginBotS">
          If you believe {{projToOpen.lockedBy || 'someone'}} has left the project open, you can boot them out and open it now.
        </p>
        <button @click="forceOpenProj(projToOpen)" class="LinkBtn">Boot {{projToOpen.lockedBy}}</button>
      </InputGroup>
    </BasicModal>
  </div>
</template>

<style scoped>
.TableWrapper {
  /* width: 80%; */
}

.ProjectsTable {
}

.OpenAnyways {
  
}

.OpenProjBtn {
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
}

.ProfileBtn {
  height: 26px;
  width: 26px;
  background-color: var(--pc);

  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 100%; */
  border-radius: var(--br-l);

  font-weight: 500;
  text-align: center;
}
</style>
