export let DishwasherAppliances_Data = `Equipment Type,"Supply Water Flow Rate, gpm","Operating Water Temperature, °F","Rate of Heat Gain to Space, Btu/h",,,,Usage Factor FU
,,,Sensible Radiant,Sensible Convective,Latent,Total,
"Pre-rinse spray valve, small",0.65,120,0,200,8200,8400,1
"Pre-rinse spray valve, medium",1.2,120,0,300,"11,700","12,000",1
"Pre-rinse spray valve, large",4,120,0,1100,"13,800","14,900",1
"3-Compartment sink, rinsing",NA,120,0,900,4900,5800,1
"Power wash sink, rinsing",NA,120,0,2000,"3,100","5,100",1
Scrapper,18,120,0,1200,"11,000","12,200",1
Scrapper with trough,70,120,0,2800,"13,900","16,700",1
"Under counter dishwasher, low temperature",0.8,140,0,2200,4900,7100,1
"Under counter dishwasher, high temperature",0.8,180,0,4000,4600,8600,1
"Under counter dishwasher, high temperature with heat recovery, unhooded",0.6,180,0,2200,1100,3300,1
"Upright door type dishwasher, low temperature dump and fill, unhooded",1,120,0,3200,3500,6700,1
"Upright door type dishwasher, low temperature with tank, unhooded",0.7,140,0,3900,13200,17100,1
"Upright door type, high temperature, unhooded",0.9,180,0,8000,21400,29400,1
"Upright door type dishwasher, high temperature with heat recovery, unhooded",0.9,180,0,4800,13000,17800,1
"Pot and pan washer, unhooded",2.4,180,0,6000,23500,29500,1
"Pot and pan washer with heat recovery, unhooded",2.4,180,0,5500,19000,24500,1
"44 in. Conveyor dishwasher, unvented",1.8,180,0,10000,59300,69300,1
"66 in. Conveyor dishwasher, unvented",1.7,180,0,16100,45000,61100,1
"Upright door type dishwasher, high temperature under a 3×3 ft hood at 300 cfm",NA,180,0,3500,13000,16500,1
"Upright door type dishwasher, high temperature under a 5×4 ft hood at 500 cfm",NA,180,0,1600,7900,9500,1
"44 in. Conveyor dishwasher, high temperature under a 10 ft hood at 1000 cfm",1.8,190,0,1000,20000,21000,1
Ducted 66 in. conveyor dishwasher,1.7,180,0,10600,6300,16900,1
Ducted Flight type conveyor dishwasher,1,190,0,13400,8900,22300,1
Ducted Flight type conveyor dishwasher with heat recovery,1,190,0,12300,3600,15900,1
Ducted Flight type conveyor dishwasher with blow dryer,1,190,0,21200,15700,36900,1`