import { makeEnum, makeEnumWithData, makeOptions,
  setupClass, lookupData, Matches,
} from './Base.js'
import { valOr } from './SharedUtils.js'
import { Field, FieldType, FieldGroup, SelectOrManualInput, } from './Field.js'
import { YesNo, makeNoYesField, makeYesNoField } from './Components.js'

import { gApp, DebugOn } from './Globals.js'

/*
A schedule input, with optional diversity factor.
*/
export class ScheduleInput {
  init(opts) {
    this.opts = valOr(opts, {})
    this.enableDiversityFactor = valOr(this.opts.enableDiversityFactor, true)

    this.schedule = Field.makeTypeSelect('Schedule', gApp.proj().schedules, null, {
      errorWhenEmpty: 'You must create a Schedule in the Schedules tab',
    })

    this.useDiversityFactor = makeYesNoField('Use diversity factor in peak calculations')
    this.diversityFactor = new Field({
      name: 'Diversity Factor',
      type: FieldType.Percent,
    })
    this.diversityFactor.setVisibility(() => {
      return this.useDiversityFactor.value == YesNo.Yes
    })

    if (!this.enableDiversityFactor) {
      this.useDiversityFactor.value = YesNo.No;
      this.useDiversityFactor.visible = false;
    }

    this.serFields = [
      'schedule',
      'useDiversityFactor',
      'diversityFactor',
    ]
    this.childObjs = '$auto'
  }

  getSchedule() {
    return this.schedule.lookupValue();
  }

  getDiversityFactor() {
    return this.useDiversityFactor.value == YesNo.Yes ? this.diversityFactor.value / 100.0 : 1.0;
  }
};
setupClass(ScheduleInput)