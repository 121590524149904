<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import { kFieldTypesData, Units, } from './State.js'
import BasicSelector from './BasicSelector.vue'
import MultiTieredSelectModal from './MultiTieredSelectModal.vue';

let debugOn = ref(false);

let showDebugOutput = ref(false);
let showErrorMsg = ref(false);

let numberInput = ref(null);

let multiTieredSelectModal = ref(null);

const props = defineProps({
  modelValue: [String, Number, Object],
  labelWidth: {
    type: Number,
    default: 250,
  },
  compact: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  isOptional: Boolean,
  forceShowName: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['update:modelValue'])

let unitNames = {
};

function getInputType() {
  return props.modelValue.getInputType();
}

const optionalValue = computed({
  get() {
    return props.modelValue !== null;
  },
  set(value) {
    if (value) {
      //console.log("DefaultValue: "+props.defaultValue);
      emit('update:modelValue', props.defaultValue);
    } else {
      emit('update:modelValue', null);
    }
  }
})

let units = computed(() => {
  let units = props.modelValue.getDisplayUnits();
  if (units) {
    if (!(units in Units._data)) {
      throw new Error(`Units '${units}' not found in Units map.`);
    }
    return Units._data[units].label;
  } else {
    return '';
  }
})

function onValueChanged(newVal) {
  props.modelValue.value = newVal;
}

let selectChoices = computed(() => {
  // console.log("Recomputing select choices for: " + props.modelValue.name);
  return props.modelValue.getChoices();
})

function onNumberChange(event) {
  let newValue = event.target.valueAsNumber;
  // TODO - should convert the units here (display -> data)

  /*
  if (event.target.validity.badinput) {
    newValue = event.target.valueAsNumber;
  }
   */
  // console.log("New value: ", newValue);
  // props.modelValue.value = newValue

  // Note: we must manually set teh event.target.value in a couple places to trigger the input
  // to update its text.
  if (isNaN(newValue)) {
    // console.log("Detected NaN");
    event.target.value = `${props.modelValue.value}`
    return;
  }
  let min = props.modelValue.getMin();
  if (min !== null && newValue < min) {
    // console.log(`New value ${newValue} below min ${min}. Curval: ${props.modelValue.value}`);
    props.modelValue.value = min;
    event.target.value = `${props.modelValue.value}`;
    return;
  }
  let max = props.modelValue.getMax();
  if (max !== null && newValue > max) {
    // console.log(`New value ${newValue} above max ${max}. Curval: ${props.modelValue.value}`);
    props.modelValue.value = max;
    event.target.value = `${props.modelValue.value}`;
    return;
  }
  // console.log(`New value: ${newValue}`);
  // props.modelValue.setValueInDisplayUnits(newValue);
  props.modelValue.value = newValue;
}

function getNumericValueStr() {
  // console.log("Getting fixed of: ", props.modelValue.value);
  let displayUnits = props.modelValue.getDisplayUnits()
  return props.modelValue.getValueStr(displayUnits);
}

function getSelectorClasses() {
  if (props.modelValue.bold) {
    return {
      Bold: true
    }
  } else {
    return {}
  }
}

let unitOptions = computed(() => {
  let options = [];
  for (let unit of props.modelValue.unitOptions) {
    if (!(unit in Units._data)) {
      throw new Error(`Units '${unit}' not found in Units map.`);
    }
    options.push({
      value: unit,
      label: Units._data[unit].label,
    });
  }
  return options;
})

function onUnitsChanged(newUnits) {
  props.modelValue.units = newUnits;
}

function getNameHtml() {
  if (props.forceShowName || props.modelValue.showName) {
    return props.modelValue.name;
  } else {
    return '';
  }
}

</script>

<template>
  <div v-if="modelValue.visible" class="FieldInput StdInput MarginBotXXS" :class="{Compact: compact}">
    <div v-if="!compact" class="InputLabel" :style="{width: labelWidth + 'px'}" v-html="getNameHtml()">
    </div>
    <div>
      <div class="Flex">
        <div v-if="!modelValue.isOutput && !readonly">
          <input v-if="isOptional" class="OptionalToggle" v-model="optionalValue" type="checkbox" name="optionalToggle"/>
          <div v-if="getInputType() == 'Number'" class="NumberParent">
            <input ref="numberInput" class="BasicTextInput InputChild" type="number" :value="modelValue.getValueInDisplayUnits()" @change="onNumberChange">
            <p v-if="!modelValue.unitOptions">{{units}}</p>
            <div v-else>
              <BasicSelector :value="modelValue.units" :options="unitOptions" @change="onUnitsChanged" class="UnitsSelector" />
            </div>
            <p v-if="debugOn">{{modelValue.type}}</p>
          </div>
          <div v-else-if="getInputType() == 'Select'">
            <BasicSelector :value="modelValue.value" :options="selectChoices" @change="onValueChanged" class="Selector" :class="getSelectorClasses()"
              :errorWhenEmpty="modelValue.errorWhenEmpty" />
          </div>
          <div v-else-if="getInputType() == 'String'">
            <input class="BasicTextInput" type="text" v-model="modelValue.value" />
          </div>
          <div v-else-if="modelValue.type == 'MultiTieredSelect'" class="Flex GapXS">
            <p>{{ modelValue.getShortValue() }}</p>
            <button @click="multiTieredSelectModal.open()">{{ modelValue.value ? 'Change' : 'Choose' }}</button>
            <MultiTieredSelectModal ref="multiTieredSelectModal" :field="modelValue" />
          </div>
        </div>
        <div v-else>
          <div class="OutputParent">
            <div v-if="getInputType() == 'Number'" class="OutputNumberParent">
              <p>{{getNumericValueStr()}}</p>
              <p>{{units}}</p>
            </div>
            <div v-else-if="getInputType() == 'Select'">
              <p>{{ modelValue.getSelectLabelStr() }}</p>
            </div>
            <div v-else-if="getInputType() == 'String'">
              <p>{{ modelValue.value }}</p>
            </div>
          </div>
        </div>
        <div v-if="modelValue.debugOutput" class="ExtraBtn">
          <button @click="showDebugOutput = !showDebugOutput" class="LinkBtn ml-xs">Debug</button>
        </div>
        <div v-if="modelValue.errorMsg" class="ExtraBtn ErrorBtn">
          <button @click="showErrorMsg = !showErrorMsg" class="LinkBtn ml-xs">Error</button>
        </div>
      </div>
      <div v-if="modelValue.debugOutput && showDebugOutput" class="DebugOutput">
        {{modelValue.debugOutput}}
      </div>
      <div v-if="modelValue.errorMsg && showErrorMsg" class="DebugOutput">
        {{modelValue.errorMsg}}
      </div>
    </div>
  </div>
</template>

<style scoped>
.FieldInput {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.InputLabel {
  padding-right: var(--m);
}

.NumberParent {
  display: flex;
  align-items: baseline;
  gap: 8px;
}

.OptionalToggle {
  margin-right: 8px;
}

.StdInput {
  margin-right: 16px;
}

.InputChild {
  /*display: inline-block;*/
  flex: 1;
  /* width: 120px; */
}

.FieldInput.Compact .InputChild {
  width: 80px;
}

.Selector {
  min-width: 175px;
}

.FieldInput.Compact .Selector {
  min-width: auto;
  max-width: 120px;
}

.Selector.Bold {
}

.UnitsSelector {

}

.OutputNumberParent {
  display: flex;
  align-items: baseline;
  gap: 8px;
  color: blue;
}

.ExtraBtn {
  margin-left: 4px;
}

.ErrorBtn {
  margin-left: 4px;
  background-color: red;
}

.DebugOutput {
  padding: 8px;
  background-color: lightgrey;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
}

</style>
