<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick, } from 'vue'
import { gApp } from './State.js'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

// Hack to prevent Teleporting before the target is rendered
let hasTicked = ref(false);

onMounted(() => {
  nextTick(() => {
    hasTicked.value = true;
  })
})

</script>

<template>
  <Teleport v-if="hasTicked" to="#HelpContent">
    <div v-if="gApp.proj().helpPaneId == id" class="Paragraphs">
      <slot></slot>
    </div>
  </Teleport>
</template>

<style scoped>
</style>
