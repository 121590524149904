export let IACData = `
Glazing ID:,,,1a,1b,1c,1d,1e,1f,1g,1h,1i,,,,,,,,,,,,,,
Shade,Fabric Designator,Fullness,"IAC, FRd",,,,,,,,,,,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.71, 0.50","0.71, 0.49","0.72, 0.47","0.74, 0.45","0.72, 0.47","0.74, 0.44","0.72, 0.47","0.74, 0.44","0.74, 0.45",,,,0.726667,0.012472191,,,,,,,,,
Medium Closed Weave,IIIM,1,"0.59, 0.53","0.60, 0.52","0.62, 0.49","0.65, 0.46","0.63, 0.49","0.66, 0.46","0.63, 0.49","0.66, 0.45","0.65, 0.46",,,,0.632222,0.023934066,,,,,,,,,
Light Closed Weave,IIIL,1,"0.45, 0.62","0.46, 0.60","0.50, 0.56","0.55, 0.50","0.51, 0.54","0.56, 0.50","0.51, 0.54","0.56, 0.49","0.55, 0.51",,,,0.516667,0.039440532,,,,,,,,,
Dark Semiopen Weave,IID,1,"0.75, 0.55","0.75, 0.54","0.76, 0.52","0.78, 0.49","0.76, 0.52","0.78, 0.49","0.76, 0.52","0.78, 0.49","0.78, 0.49",,,,0.766667,0.012472191,,,,,,,,,
Medium Semiopen Weave,IIM,1,"0.65, 0.63","0.66, 0.62","0.68, 0.59","0.70, 0.55","0.68, 0.58","0.71, 0.54","0.68, 0.58","0.71, 0.54","0.70, 0.55",,,,0.685556,0.020061633,,,,,,,,,
Light Semiopen Weave,IIL,1,"0.56, 0.79","0.57, 0.77","0.60, 0.71","0.64, 0.65","0.61, 0.70","0.65, 0.64","0.61, 0.70","0.65, 0.63","0.64, 0.65",,,,0.614444,0.031661793,,,,,,,,,
Dark Open Weave,ID,1,"0.80, 0.63","0.80, 0.62","0.81, 0.60","0.82, 0.57","0.82, 0.59","0.83, 0.56","0.82, 0.59","0.83, 0.56","0.82, 0.57",,,,0.816667,0.010540926,,,,,,,,,
Medium Open Weave,IM,1,"0.71, 0.73","0.72, 0.72","0.73, 0.69","0.76, 0.64","0.74, 0.68","0.76, 0.63","0.74, 0.68","0.76, 0.63","0.76, 0.64",,,,0.742222,0.018121674,,,,,,,,,
Light Open Weave,IL,1,"0.64, 0.87","0.65, 0.85","0.68, 0.80","0.71, 0.73","0.68, 0.78","0.71, 0.72","0.68, 0.78","0.72, 0.71","0.71, 0.73",,,,0.686667,0.026666667,,,,,,,,,
Sheer,,1,"0.73, 0.89","0.73, 0.88","0.75, 0.83","0.77, 0.77","0.75, 0.82","0.78, 0.76","0.75, 0.82","0.78, 0.75","0.77, 0.77",,,,,,,,,,,,,,
Glazing ID:,,,5a,5b,5c,5d,5e,5f,5g,5h,5i,,,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.81, 0.46","0.82, 0.45","0.82, 0.44","0.83, 0.42","0.82, 0.44","0.83, 0.42","0.82, 0.44","0.84, 0.42","0.83, 0.42",,,,0.824444,0.008314794,,,,,,,,,
Medium Closed Weave,IIIM,1,"0.70, 0.48","0.72, 0.46","0.72, 0.46","0.75, 0.43","0.72, 0.46","0.75, 0.43","0.72, 0.46","0.75, 0.43","0.75, 0.43",,,,0.731111,0.017916128,,,,,,,,,
Light Closed Weave,IIIL,1,"0.57, 0.54","0.60, 0.52","0.59, 0.52","0.64, 0.47","0.60, 0.51","0.65, 0.47","0.60, 0.51","0.65, 0.47","0.64, 0.47",,,,0.615556,0.0279329,,,,,,,,,
Dark Semiopen Weave,IID,1,"0.84, 0.51","0.85, 0.50","0.85, 0.49","0.86, 0.47","0.85, 0.49","0.86, 0.47","0.85, 0.49","0.86, 0.46","0.86, 0.47",,,,0.853333,0.006666667,,,,,,,,,
Medium Semiopen Weave,IIM,1,"0.75, 0.57","0.76, 0.55","0.76, 0.55","0.79, 0.51","0.76, 0.55","0.79, 0.51","0.76, 0.55","0.79, 0.51","0.79, 0.52",,,,0.772222,0.016178022,,,,,,,,,
Light Semiopen Weave,IIL,1,"0.65, 0.70","0.68, 0.67","0.67, 0.67","0.71, 0.61","0.68, 0.66","0.72, 0.60","0.68, 0.66","0.72, 0.60","0.72, 0.61",,,,0.692222,0.024393887,,,,,,,,,
Dark Open Weave,ID,1,"0.88, 0.59","0.88, 0.57","0.88, 0.57","0.89, 0.54","0.88, 0.57","0.89, 0.54","0.88, 0.57","0.89, 0.54","0.89, 0.54",,,,0.884444,0.00496904,,,,,,,,,
Medium Open Weave,IM,1,"0.79, 0.68","0.80, 0.65","0.80, 0.65","0.82, 0.61","0.80, 0.65","0.82, 0.60","0.80, 0.65","0.82, 0.60","0.82, 0.61",,,,0.807778,0.011331154,,,,,,,,,
Light Open Weave,IL,1,"0.72, 0.79","0.74, 0.76","0.73, 0.76","0.77, 0.69","0.74, 0.75","0.77, 0.69","0.74, 0.75","0.77, 0.68","0.77, 0.69",,,,0.75,0.018856181,,,,,,,,,
Sheer,,1,"0.78, 0.83","0.8, 0.8","0.8, 0.8","0.82, 0.73","0.8, 0.79","0.82, 0.73","0.8, 0.79","0.82, 0.73","0.82, 0.74",,,,,,,,,,,,,,
Glazing ID:,,,17a,17b,17c,17d,17e,17f,17g,17h,17i,17j,17k,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.85, 0.45","0.86, 0.43","0.86, 0.44","0.87, 0.43","0.86, 0.43","0.88, 0.41","0.87, 0.42","0.88, 0.40","0.87, 0.42","0.88, 0.40","0.88, 0.41",,0.867778,0.010304021,,,,,,,,,
Medium Closed Weave,IIIM,1,"0.74, 0.47","0.76, 0.45","0.76, 0.45","0.79, 0.44","0.77, 0.44","0.80, 0.42","0.78, 0.44","0.80, 0.41","0.78, 0.44","0.80, 0.41","0.80, 0.42",,0.776667,0.02,,,,,,,,,
Light Closed Weave,IIIL,1,"0.60, 0.52","0.64, 0.50","0.64, 0.50","0.68, 0.47","0.66, 0.48","0.71, 0.44","0.66, 0.48","0.71, 0.44","0.66, 0.48","0.71, 0.44","0.71, 0.45",,0.665556,0.03593547,,,,,,,,,
Dark Semiopen Weave,IID,1,"0.88, 0.50","0.89, 0.48","0.88, 0.49","0.89, 0.47","0.89, 0.48","0.90, 0.45","0.89, 0.47","0.90, 0.45","0.89, 0.47","0.90, 0.45","0.90, 0.45",,0.891111,0.007370277,,,,,,,,,
Medium Semiopen Weave,IIM,1,"0.78, 0.56","0.8, 0.54","0.8, 0.54","0.82, 0.52","0.81, 0.52","0.83, 0.49","0.81, 0.52","0.83, 0.49","0.81, 0.52","0.83, 0.49","0.83, 0.49",,0.811111,0.015947445,,,,,,,,,
Light Semiopen Weave,IIL,1,"0.68, 0.68","0.71, 0.64","0.71, 0.64","0.74, 0.61","0.72, 0.62","0.76, 0.57","0.73, 0.62","0.77, 0.57","0.73, 0.62","0.77, 0.57","0.76, 0.57",,0.73,0.027487371,,,,,,,,,
Dark Open Weave,ID,1,"0.90, 0.58","0.91, 0.56","0.91, 0.56","0.91, 0.55","0.91, 0.55","0.92, 0.52","0.91, 0.55","0.92, 0.52","0.91, 0.55","0.92, 0.52","0.92, 0.52",,0.912222,0.006285394,,,,,,,,,
Medium Open Weave,IM,1,"0.81, 0.66","0.83, 0.64","0.83, 0.64","0.85, 0.61","0.84, 0.62","0.86, 0.58","0.84, 0.62","0.86, 0.58","0.84, 0.62","0.86, 0.58","0.86, 0.58",,0.841111,0.015947445,,,,,,,,,
Light Open Weave,IL,1,"0.74, 0.77","0.76, 0.73","0.76, 0.73","0.79, 0.69","0.77, 0.71","0.81, 0.65","0.78, 0.71","0.81, 0.65","0.78, 0.71","0.81, 0.65","0.81, 0.65",,0.78,0.024037009,,,,,,,,,
Sheer,,1,"0.8, 0.81","0.82, 0.77","0.82, 0.78","0.84, 0.74","0.83, 0.75","0.85, 0.7","0.83, 0.75","0.85, 0.69","0.83, 0.75","0.85, 0.69","0.85, 0.7",,,,,,,,,,,,
Glazing ID:,,,21a,21b,21c,21d,21e,21f,21g,21h,21i,21j,21k,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.87, 0.44","0.88, 0.43","0.88, 0.43","0.88, 0.42","0.88, 0.42","0.89, 0.40","0.88, 0.42","0.89, 0.40","0.88, 0.42","0.89, 0.40","0.89, 0.40",,0.882222,0.006285394,,,,,,,,,
Medium Closed Weave,IIIM,1,"0.77, 0.46","0.79, 0.45","0.79, 0.45","0.81, 0.43","0.80, 0.43","0.82, 0.41","0.80, 0.43","0.82, 0.41","0.80, 0.43","0.82, 0.41","0.82, 0.41",,0.801111,0.015947445,,,,,,,,,
Light Closed Weave,IIIL,1,"0.64, 0.52","0.67, 0.49","0.68, 0.49","0.70, 0.47","0.69, 0.47","0.73, 0.45","0.70, 0.47","0.73, 0.44","0.70, 0.47","0.73, 0.44","0.73, 0.45",,0.696667,0.029059326,,,,,,,,,
Dark Semiopen Weave,IID,1,"0.89, 0.49","0.90, 0.48","0.90, 0.48","0.90, 0.47","0.90, 0.47","0.91, 0.45","0.90, 0.47","0.91, 0.45","0.90, 0.47","0.91, 0.45","0.91, 0.45",,0.902222,0.006285394,,,,,,,,,
Medium Semiopen Weave,IIM,1,"0.8, 0.55","0.82, 0.53","0.82, 0.53","0.83, 0.51","0.83, 0.52","0.85, 0.49","0.83, 0.51","0.85, 0.49","0.83, 0.51","0.85, 0.49","0.85, 0.49",,0.831111,0.015947445,,,,,,,,,
Light Semiopen Weave,IIL,1,"0.71, 0.67","0.74, 0.64","0.74, 0.63","0.76, 0.61","0.75, 0.61","0.78, 0.57","0.76, 0.61","0.78, 0.57","0.76, 0.61","0.78, 0.57","0.78, 0.57",,0.755556,0.022166597,,,,,,,,,
Dark Open Weave,ID,1,"0.92, 0.57","0.92, 0.56","0.92, 0.55","0.92, 0.54","0.92, 0.54","0.93, 0.52","0.92, 0.54","0.93, 0.52","0.92, 0.54","0.93, 0.52","0.93, 0.52",,0.923333,0.004714045,,,,,,,,,
Medium Open Weave,IM,1,"0.83, 0.65","0.85, 0.63","0.85, 0.63","0.86, 0.61","0.86, 0.61","0.87, 0.58","0.86, 0.61","0.87, 0.58","0.86, 0.61","0.87, 0.58","0.87, 0.58",,0.857778,0.012272623,,,,,,,,,
Light Open Weave,IL,1,"0.76, 0.77","0.78, 0.73","0.79, 0.72","0.81, 0.69","0.80, 0.70","0.82, 0.65","0.80, 0.69","0.82, 0.65","0.80, 0.69","0.82, 0.65","0.82, 0.66",,0.798889,0.019116278,,,,,,,,,
Sheer,,1,"0.82, 0.81","0.83, 0.77","0.84, 0.76","0.85, 0.74","0.85, 0.74","0.86, 0.7","0.85, 0.74","0.86, 0.69","0.85, 0.74","0.86, 0.69","0.86, 0.7",,,,,,,,,,,,
Glazing ID:,,,25a,25b,25c,26d,25e,25f,29a,29b,,,,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.88, 0.43","0.89, 0.42","0.90, 0.40","0.90, 0.40","0.91, 0.39","0.90, 0.40","0.86, 0.44","0.87, 0.42",,,,,0.896666667,0.00942809,,0.865,0.005,,,,,,
Medium Closed Weave,IIIM,1,"0.80, 0.45","0.82, 0.44","0.85, 0.41","0.84, 0.41","0.85, 0.40","0.84, 0.42","0.77, 0.45","0.80, 0.43",,,,,0.833333333,0.017950549,,0.742128,0.015,,,,,,
Light Closed Weave,IIIL,1,"0.68, 0.51","0.72, 0.48","0.76, 0.44","0.76, 0.45","0.77, 3","0.76, 0.45","0.65, 0.50","0.69, 0.47",,,,,0.741666667,0.031841622,,0.656351,0.02,,,,,,
Dark Semiopen Weave,IID,1,"0.91, 0.48","0.91, 0.47","0.92, 0.44","0.92, 0.45","0.92, 0.43","0.92, 0.45","0.89, 0.48","0.90, 0.47",,,,,0.916666667,0.004714045,,0.821138,0.005,,,,,,
Medium Semiopen Weave,IIM,1,"0.83, 0.54","0.85, 0.52","0.87, 0.48","0.86, 0.49","0.87, 0.47","0.86, 0.49","0.81, 0.54","0.83, 0.51",,,,,0.856666667,0.013743685,,0.765041,0.01,,,,,,
Light Semiopen Weave,IIL,1,"0.75, 0.66","0.78, 0.63","0.81, 0.57","0.81, 0.58","0.82, 0.55","0.81, 0.58","0.72, 0.65","0.76, 0.60",,,,,0.796666667,0.024267033,,0.708093,0.02,,,,,,
Dark Open Weave,ID,1,"0.93, 0.56","0.93, 0.55","0.94, 0.51","0.94, 0.52","0.94, 0.50","0.94, 0.52","0.91, 0.56","0.92, 0.54",,,,,0.936666667,0.004714045,,0.839138,0.005,,,,,,
Medium Open Weave,IM,1,"0.86, 0.65","0.87, 0.62","0.89, 0.57","0.89, 0.58","0.89, 0.56","0.89, 0.59","0.84, 0.64","0.85, 0.60",,,,,0.881666667,0.012133516,,0.78738,0.005,,,,,,
Light Open Weave,IL,1,"0.79, 0.75","0.82, 0.72","0.85, 0.65","0.84, 0.66","0.85, 0.63","0.84, 0.67","0.77, 0.73","0.80, 0.69",,,,,0.831666667,0.021147629,,0.741281,0.015,,,,,,
Sheer,,1,"0.84, 0.8","0.86, 0.76","0.88, 0.69","0.88, 0.7","0.89, 0.67","0.88, 0.71","0.83, 0.78","0.85, 0.73",,,,,,,,,,,,,,,
Glazing ID:,,,32a,32b,32c,32d,40a,40b,40c,40d,,,,,,,,,,,,,,,
Dark Closed Weave,IIID,1,"0.89, 0.43","0.90, 0.41","0.91, 0.42","0.92, 0.39","0.93, 0.41","0.94, 0.37","0.90, 0.42","0.91, 0.40",,,,,0.905,0.01118034,,0.92,0.015811,,,,,,
Medium Closed Weave,IIIM,1,"0.80, 0.44","0.83, 0.42","0.82, 0.42","0.84, 0.39","0.84, 0.40","0.87, 0.37","0.82, 0.43","0.85, 0.41",,,,,0.8225,0.014790199,,0.845,0.018028,,,,,,
Light Closed Weave,IIIL,1,"0.69, 0.48","0.73, 0.45","0.69, 0.44","0.73, 0.40","0.73, 0.42","0.77, 0.38","0.71, 0.47","0.76, 0.44",,,,,0.71,0.02,,0.7425,0.023848,,,,,,
Dark Semiopen Weave,IID,1,"0.91, 0.47","0.92, 0.46","0.93, 0.46","0.94, 0.43","0.94, 0.45","0.95, 0.41","0.92, 0.46","0.93, 0.45",,,,,0.925,0.01118034,,0.935,0.01118,,,,,,
Medium Semiopen Weave,IIM,1,"0.83, 0.52","0.85, 0.50","0.84, 0.50","0.86, 0.46","0.86, 0.48","0.89, 0.44","0.85, 0.51","0.87, 0.48",,,,,0.845,0.01118034,,0.8675,0.01479,,,,,,
Light Semiopen Weave,IIL,1,"0.75, 0.62","0.79, 0.58","0.75, 0.58","0.79, 0.52","0.78, 0.55","0.82, 0.49","0.77, 0.60","0.80, 0.56",,,,,0.77,0.02,,0.7925,0.019203,,,,,,
Dark Open Weave,ID,1,"0.93, 0.55","0.93, 0.53","0.95, 0.53","0.95, 0.50","0.96, 0.51","0.96, 0.47","0.94, 0.54","0.94, 0.52",,,,,0.94,0.01,,0.95,0.01,,,,,,
Medium Open Weave,IM,1,"0.86, 0.62","0.87, 0.59","0.87, 0.59","0.88, 0.54","0.88, 0.56","0.90, 0.51","0.87, 0.60","0.89, 0.57",,,,,0.87,0.007071068,,0.885,0.01118,,,,,,
Light Open Weave,IL,1,"0.80, 0.71","0.83, 0.66","0.80, 0.66","0.82, 0.60","0.82, 0.63","0.85, 0.57","0.81, 0.69","0.84, 0.64",,,,,0.8125,0.012990381,,0.83,0.015811,,,,,,
Sheer,,1,"0.84, 0.75","0.87, 0.7","0.85, 0.71","0.87, 0.65","0.86, 0.68","0.89, 0.61","0.86, 0.73","0.88, 0.68",,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Louvered shades,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,1a,1b,1c,1d,1e,1f,1g,1h,1i,,,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,"IAC 0 ( IAC 60)/IACdiff , FRd",,,,,,,,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.98 (0.97)/0.86,0.98 (0.97)/0.86,0.98 (0.96)/0.86,0.97 (0.95)/0.87,0.98 (0.96)/0.87,0.97 (0.95)/0.87,0.98 (0.96)/0.87,0.97 (0.95)/0.87,0.97 (0.95)/0.87,,,,0.975556,0.957778,0.866667,,,,,,,,
,,,0.92,0.91,0.88,0.82,0.87,0.82,0.87,0.81,0.83,,,,,,,,,,,,,,
,,0°,0.98 (0.78)/0.87,0.98 (0.79)/0.87,0.98 (0.80)/0.88,0.97 (0.82)/0.88,0.98 (0.80)/0.88,0.97 (0.82)/0.89,0.98 (0.80)/0.88,0.97 (0.82)/0.89,0.97 (0.82)/0.88,,,,0.975556,0.805556,0.88,,,,,,,,
,,,0.69,0.68,0.66,0.64,0.66,0.63,0.66,0.63,0.64,,,,,,,,,,,,,,
,,Excluded Beamb,0.73 (0.78)/0.87,0.74 (0.79)/0.87,0.75 (0.80)/0.88,0.77 (0.82)/0.88,0.76 (0.80)/0.88,0.77 (0.82)/0.88,0.76 (0.80)/0.88,0.78 (0.82)/0.88,0.77 (0.82)/0.88,,,,0.758889,0.805556,0.877778,,,,,,,,
,,,0.43,0.43,0.42,0.41,0.42,0.41,0.42,0.41,0.41,,,,,,,,,,,,,,
,,45°,0.80 (0.74)/0.83,0.80 (0.75)/0.83,0.81 (0.76)/0.84,0.82 (0.78)/0.85,0.81 (0.77)/0.84,0.83 (0.79)/0.85,0.81 (0.77)/0.84,0.83 (0.79)/0.85,0.82 (0.78)/0.85,,,,0.814444,0.77,0.842222,,,,,,,,
,,,0.47,0.46,0.45,0.44,0.45,0.43,0.45,0.43,0.44,,,,,,,,,,,,,,
,,Closed,0.70 (0.70)/0.73,0.70 (0.70)/0.74,0.72 (0.72)/0.75,0.74 (0.74)/0.76,0.72 (0.72)/0.75,0.74 (0.74)/0.77,0.72 (0.72)/0.75,0.74 (0.74)/0.77,0.74 (0.74)/0.76,,,,0.724444,0.724444,0.753333,,,,,,,,
,,,0.44,0.44,0.42,0.4,0.42,0.4,0.42,0.4,0.4,,,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.98 (0.96)/0.80,0.97 (0.96)/0.80,0.97 (0.96)/0.81,0.97 (0.95)/0.83,0.97 (0.96)/0.82,0.97 (0.95)/0.83,0.97 (0.96)/0.82,0.97 (0.95)/0.83,0.97 (0.95)/0.83,,,,0.971111,0.955556,0.818889,,,,,,,,
,,,0.94,0.93,0.89,0.83,0.88,0.83,0.88,0.82,0.84,,,,,,,,,,,,,,
,,0°,0.98 (0.70)/0.83,0.97 (0.70)/0.84,0.97 (0.72)/0.84,0.97 (0.75)/0.86,0.97 (0.73)/0.85,0.97 (0.76)/0.86,0.97 (0.73)/0.85,0.97 (0.76)/0.86,0.97 (0.75)/0.86,,,,0.971111,0.733333,0.85,,,,,,,,
,,,0.74,0.73,0.71,0.67,0.7,0.67,0.7,0.66,0.67,,,,,,,,,,,,,,
,,Excluded Beamb,0.59 (0.70)/0.82,0.60 (0.70)/0.83,0.63 (0.72)/0.84,0.67 (0.75)/0.85,0.64 (0.73)/0.84,0.67 (0.76)/0.85,0.64 (0.73)/0.84,0.67 (0.76)/0.85,0.67 (0.75)/0.85,,,,0.642222,7.33E-01,0.841111,,,,,,,,
,,,0.5,0.5,0.48,0.46,0.48,0.46,0.48,0.46,0.46,,,,,,,,,,,,,,
,,45°,0.69 (0.58)/0.74,0.70 (0.59)/0.75,0.72 (0.62)/0.76,0.75 (0.66)/0.79,0.73 (0.63)/0.77,0.75 (0.67)/0.79,0.73 (0.63)/0.77,0.75 (0.67)/0.79,0.75 (0.66)/0.79,,,,0.73,0.634444,0.772222,,,,,,,,
,,,0.53,0.52,0.5,0.48,0.5,0.47,0.5,0.47,0.48,,,,,,,,,,,,,,
,,Closed,0.51 (0.49)/0.58,0.52 (0.50)/0.58,0.55 (0.53)/0.61,0.60 (0.58)/0.65,0.56 (0.54)/0.62,0.60 (0.59)/0.65,0.56 (0.54)/0.62,0.61 (0.59)/0.66,0.60 (0.58)/0.65,,,,0.567778,0.548889,0.624444,,,,,,,,
,,,0.46,0.45,0.43,0.4,0.42,0.4,0.42,0.39,0.4,,,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.97 (0.96)/0.73,0.97 (0.96)/0.74,0.97 (0.95)/0.76,0.96 (0.95)/0.78,0.97 (0.95)/0.76,0.96 (0.95)/0.79,0.97 (0.95)/0.76,0.96 (0.95)/0.79,0.96 (0.95)/0.78,,,,0.965556,9.52E-01,0.765556,,,,,,,,
,,,0.95,0.94,0.9,0.85,0.89,0.84,0.89,0.83,0.85,,,,,,,,,,,,,,
,,0°,0.97 (0.60)/0.78,0.97 (0.61)/0.79,0.97 (0.64)/0.80,0.96 (0.68)/0.82,0.97 (0.65)/0.81,0.96 (0.69)/0.83,0.97 (0.65)/0.81,0.96 (0.69)/0.83,0.96 (0.68)/0.82,,,,0.965556,0.654444,0.81,,,,,,,,
,,,0.82,0.81,0.78,0.73,0.77,0.72,0.77,0.72,0.73,,,,,,,,,,,,,,
,,Excluded Beamb,0.45 (0.60)/0.77,0.47 (0.61)/0.77,0.51 (0.64)/0.79,0.57 (0.68)/0.81,0.52 (0.65)/0.79,0.57 (0.69)/0.81,0.52 (0.65)/0.79,0.58 (0.69)/0.82,0.57 (0.68)/0.81,,,,0.528889,6.54E-01,0.795556,,,,,,,,
,,,0.66,0.65,0.61,0.56,0.59,0.55,0.59,0.55,0.56,,,,,,,,,,,,,,
,,45°,0.59 (0.42)/0.66,0.60 (0.43)/0.67,0.63 (0.48)/0.69,0.67 (0.54)/0.73,0.64 (0.49)/0.70,0.68 (0.55)/0.73,0.64 (0.49)/0.70,0.68 (0.56)/0.73,0.68 (0.54)/0.73,,,,0.645556,5.00E-01,0.704444,,,,,,,,
,,,0.66,0.65,0.61,0.56,0.59,0.55,0.59,0.55,0.56,,,,,,,,,,,,,,
,,Closed,0.33 (0.29)/0.43,0.35 (0.31)/0.44,0.40 (0.37)/0.49,0.47 (0.44)/0.54,0.42 (0.38)/0.50,0.48 (0.45)/0.55,0.42 (0.38)/0.50,0.49 (0.46)/0.55,0.47 (0.44)/0.54,,,,0.425556,0.391111,0.504444,,,,,,,,
,,,0.52,0.51,0.46,0.41,0.45,0.41,0.45,0.41,0.42,,,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.37,0.93 (0.89)/0.36,0.93 (0.89)/0.37,0.93 (0.89)/0.36,0.93 (0.89)/0.37,0.93 (0.89)/0.37,,,,0.93,8.90E-01,0.364444,,,,,,,,
,,,0.98,0.97,0.95,0.92,0.94,0.91,0.94,0.91,0.92,,,,,,,,,,,,,,
,,0°,0.93 (0.05)/0.41,0.93 (0.06)/0.41,0.93 (0.06)/0.42,0.93 (0.06)/0.42,0.93 (0.06)/0.42,0.93 (0.06)/0.42,0.93 (0.06)/0.42,0.93 (0.07)/0.42,0.93 (0.06)/0.42,,,,0.93,6.00E-02,0.417778,,,,,,,,
,,,0.9,0.89,0.87,0.84,0.87,0.84,0.87,0.83,0.84,,,,,,,,,,,,,,
,,Excluded Beamb,0.04 (0.05)/0.39,0.04 (0.06)/0.40,0.04 (0.06)/0.40,0.05 (0.06)/0.40,0.04 (0.06)/0.40,0.05 (0.06)/0.40,0.04 (0.06)/0.40,0.05 (0.07)/0.40,0.05 (0.06)/0.40,,,,0.044444,0.06,0.398889,,,,,,,,
,,,0.77,0.76,0.74,0.72,0.74,0.72,0.74,0.72,0.72,,,,,,,,,,,,,,
,,45°,0.20 (0.04)/0.29,0.20 (0.04)/0.30,0.21 (0.04)/0.30,0.21 (0.05)/0.30,0.21 (0.04)/0.30,0.21 (0.05)/0.30,0.21 (0.04)/0.30,0.21 (0.05)/0.30,0.21 (0.05)/0.30,,,,0.207778,0.044444,0.298889,,,,,,,,
,,,0.83,0.82,0.81,0.78,0.8,0.78,0.8,0.77,0.78,,,,,,,,,,,,,,
,,Closed,0.03 (0.03)/0.11,0.03 (0.04)/0.11,0.04 (0.04)/0.11,0.04 (0.05)/0.12,0.04 (0.04)/0.11,0.05 (0.05)/0.12,0.04 (0.04)/0.11,0.05 (0.05)/0.12,0.04 (0.05)/0.12,,,,0.04,0.043333,0.114444,,,,,,,,
,,,0.65,0.65,0.65,0.64,0.64,0.64,0.64,0.64,0.64,,,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,0.94 (0.95)/0.44,,,,0.94,9.50E-01,0.44,,,,,,,,
,,,0.98,0.97,0.95,0.92,0.94,0.91,0.94,0.91,0.92,,,,,,,,,,,,,,
,,0°,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,,,,0.94,1.50E-01,0.5,,,,,,,,
,,,0.96,0.96,0.93,0.9,0.92,0.89,0.92,0.89,0.9,,,,,,,,,,,,,,
,,Excluded Beamb,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,0.09 (0.15)/0.48,,,,0.087778,0.15,0.48,,,,,,,,
,,,0.92,0.91,0.89,0.85,0.88,0.85,0.88,0.84,0.85,,,,,,,,,,,,,,
,,45°,0.26 (0.07)/0.36,0.26 (0.07)/0.36,0.26 (0.07)/0.36,0.26 (0.07)/0.37,0.26 (0.07)/0.36,0.26 (0.07)/0.37,0.26 (0.07)/0.36,0.26 (0.07)/0.37,0.26 (0.07)/0.37,,,,0.26,0.07,0.364444,,,,,,,,
,,,0.93,0.92,0.89,0.86,0.89,0.85,0.89,0.85,0.86,,,,,,,,,,,,,,
,,Closed,0.05 (0.03)/0.14,0.05 (0.03)/0.14,0.05 (0.03)/0.14,0.05 (0.04)/0.15,0.05 (0.04)/0.14,0.06 (0.04)/0.15,0.05 (0.04)/0.14,0.06 (0.04)/0.15,0.05 (0.04)/0.15,,,,0.052222,0.036667,0.144444,,,,,,,,
,,,0.8,0.8,0.77,0.75,0.77,0.74,0.77,0.74,0.75,,,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.02)/0.54,0.95 (1.02)/0.54,0.95 (1.01)/0.54,0.95 (1.00)/0.54,0.95 (1.01)/0.54,0.95 (1.00)/0.54,0.95 (1.01)/0.54,0.95 (1.00)/0.54,0.95 (1.01)/0.54,,,,0.95,1.01E+00,0.54,,,,,,,,
,,,0.98,0.98,0.95,0.92,0.95,0.91,0.95,0.91,0.92,,,,,,,,,,,,,,
,,0°,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,,,,0.95,2.80E-01,0.61,,,,,,,,
,,,0.98,0.97,0.95,0.91,0.94,0.91,0.94,0.91,0.91,,,,,,,,,,,,,,
,,Excluded Beamb,0.17 (0.28)/0.59,0.17 (0.28)/0.59,0.17 (0.28)/0.58,0.17 (0.28)/0.58,0.17 (0.28)/0.58,0.17 (0.28)/0.58,0.17 (0.28)/0.58,0.17 (0.28)/0.59,0.17 (0.28)/0.59,,,,0.17,2.80E-01,0.584444,,,,,,,,
,,,0.97,0.96,0.94,0.9,0.93,0.89,0.93,0.89,0.9,,,,,,,,,,,,,,
,,45°,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.34 (0.12)/0.46,0.35 (0.12)/0.46,,,,0.341111,0.12,0.46,,,,,,,,
,,,0.97,0.96,0.94,0.9,0.93,0.9,0.93,0.89,0.9,,,,,,,,,,,,,,
,,Closed,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.09 (0.04)/0.20,0.09 (0.04)/0.21,0.09 (0.04)/0.20,0.09 (0.04)/0.21,0.09 (0.04)/0.20,0.09 (0.04)/0.21,0.09 (0.04)/0.21,,,,0.087778,0.04,0.206667,,,,,,,,
,,,0.93,0.92,0.89,0.86,0.89,0.85,0.89,0.85,0.86,,,,,,,,,,,,,,
Sheer,,100%,0.7,0.71,0.72,0.74,0.72,0.74,0.72,0.74,0.74,,,,,,,,,,,,,,
,,,0.5,0.49,0.47,0.45,0.47,0.44,0.47,0.44,0.45,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,5a,5b,5c,5d,5e,5f,5g,5h,5i,,,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,"IAC 0 ( IAC 60)/IACdiff, FRd",,,,,,,,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.99 (0.98)/0.92,0.99 (0.98)/0.92,0.99 (0.97)/0.92,0.98 (0.97)/0.93,0.99 (0.97)/0.92,0.98 (0.97)/0.93,0.99 (0.97)/0.92,0.98 (0.97)/0.93,0.98 (0.97)/0.93,,,,0.985556,0.972222,0.924444,,,,,,,,
,,,0.87,0.84,0.84,0.79,0.83,0.78,0.83,0.78,0.79,,,,,,,,,,,,,,
,,0°,0.99 (0.88)/0.93,0.99 (0.89)/0.93,0.99 (0.89)/0.93,0.98 (0.90)/0.93,0.99 (0.89)/0.93,0.98 (0.90)/0.94,0.99 (0.89)/0.93,0.98 (0.90)/0.94,0.98 (0.90)/0.93,,,,0.985556,0.893333,0.932222,,,,,,,,
,,,0.67,0.65,0.65,0.62,0.65,0.62,0.65,0.62,0.62,,,,,,,,,,,,,,
,,Excluded Beamb,0.84 (0.88)/0.93,0.84 (0.89)/0.93,0.84 (0.89)/0.93,0.86 (0.90)/0.93,0.85 (0.89)/0.93,0.86 (0.90)/0.93,0.85 (0.89)/0.93,0.86 (0.90)/0.93,0.86 (0.90)/0.93,,,,0.851111,0.893333,0.93,,,,,,,,
,,,0.42,0.41,0.41,0.4,0.41,0.4,0.41,0.4,0.4,,,,,,,,,,,,,,
,,45°,0.88 (0.85)/0.90,0.88 (0.85)/0.90,0.88 (0.85)/0.90,0.89 (0.87)/0.91,0.88 (0.86)/0.90,0.89 (0.87)/0.91,0.88 (0.86)/0.90,0.89 (0.87)/0.91,0.89 (0.87)/0.91,,,,0.884444,0.861111,0.904444,,,,,,,,
,,,0.45,0.44,0.44,0.42,0.44,0.42,0.44,0.42,0.42,,,,,,,,,,,,,,
,,Closed,0.81 (0.81)/0.83,0.82 (0.82)/0.84,0.82 (0.82)/0.84,0.83 (0.84)/0.85,0.82 (0.82)/0.84,0.83 (0.84)/0.85,0.82 (0.82)/0.84,0.83 (0.84)/0.85,0.83 (0.84)/0.85,,,,0.823333,0.827778,0.843333,,,,,,,,
,,,0.41,0.4,0.4,0.38,0.4,0.38,0.4,0.38,0.38,,,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.98 (0.97)/0.86,0.98 (0.97)/0.87,0.98 (0.97)/0.87,0.98 (0.97)/0.88,0.98 (0.97)/0.87,0.98 (0.97)/0.89,0.98 (0.97)/0.87,0.98 (0.97)/0.89,0.98 (0.97)/0.88,,,,0.98,0.97,0.875556,,,,,,,,
,,,0.88,0.86,0.85,0.8,0.84,0.79,0.84,0.79,0.8,,,,,,,,,,,,,,
,,0°,0.98 (0.80)/0.89,0.98 (0.82)/0.90,0.98 (0.81)/0.90,0.98 (0.84)/0.91,0.98 (0.82)/0.90,0.98 (0.84)/0.91,0.98 (0.82)/0.90,0.98 (0.84)/0.91,0.98 (0.84)/0.91,,,,0.98,0.825556,0.903333,,,,,,,,
,,,0.71,0.69,0.69,0.65,0.68,0.65,0.68,0.65,0.65,,,,,,,,,,,,,,
,,Excluded Beamb,0.70 (0.80)/0.88,0.72 (0.82)/0.89,0.72 (0.81)/0.89,0.75 (0.84)/0.90,0.73 (0.82)/0.89,0.76 (0.84)/0.90,0.73 (0.82)/0.89,0.76 (0.84)/0.90,0.75 (0.84)/0.90,,,,0.735556,0.825556,0.893333,,,,,,,,
,,,0.48,0.47,0.47,0.45,0.46,0.45,0.46,0.45,0.45,,,,,,,,,,,,,,
,,45°,0.78 (0.70)/0.82,0.80 (0.72)/0.83,0.79 (0.72)/0.83,0.82 (0.76)/0.85,0.80 (0.73)/0.84,0.82 (0.76)/0.85,0.80 (0.73)/0.84,0.82 (0.76)/0.85,0.82 (0.76)/0.85,,,,0.805556,0.737778,0.84,,,,,,,,
,,,0.5,0.49,0.48,0.46,0.48,0.46,0.48,0.46,0.46,,,,,,,,,,,,,,
,,Closed,0.63 (0.63)/0.69,0.66 (0.65)/0.71,0.65 (0.65)/0.71,0.70 (0.70)/0.74,0.66 (0.66)/0.71,0.70 (0.70)/0.75,0.66 (0.66)/0.71,0.70 (0.70)/0.75,0.70 (0.70)/0.74,,,,0.673333,0.672222,0.723333,,,,,,,,
,,,0.42,0.4,0.4,0.38,0.4,0.38,0.4,0.38,0.38,,,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.97 (0.96)/0.80,0.97 (0.96)/0.81,0.97 (0.96)/0.81,0.97 (0.96)/0.84,0.97 (0.96)/0.82,0.97 (0.96)/0.84,0.97 (0.96)/0.82,0.97 (0.96)/0.84,0.97 (0.96)/0.84,,,,0.97,0.96,0.824444,,,,,,,,
,,,0.9,0.87,0.87,0.81,0.86,0.8,0.86,0.8,0.81,,,,,,,,,,,,,,
,,0°,0.97 (0.71)/0.84,0.97 (0.73)/0.85,0.97 (0.73)/0.85,0.97 (0.77)/0.87,0.97 (0.73)/0.85,0.97 (0.77)/0.87,0.97 (0.73)/0.85,0.97 (0.77)/0.87,0.97 (0.77)/0.87,,,,0.97,0.745556,0.857778,,,,,,,,
,,,0.78,0.75,0.75,0.7,0.74,0.7,0.74,0.7,0.71,,,,,,,,,,,,,,
,,Excluded Beamb,0.57 (0.71)/0.83,0.60 (0.73)/0.84,0.60 (0.73)/0.84,0.65 (0.77)/0.86,0.61 (0.73)/0.84,0.66 (0.77)/0.86,0.61 (0.73)/0.84,0.66 (0.77)/0.86,0.65 (0.77)/0.86,,,,0.623333,0.745556,0.847778,,,,,,,,
,,,0.6,0.58,0.58,0.54,0.57,0.53,0.57,0.53,0.54,,,,,,,,,,,,,,
,,45°,0.68 (0.56)/0.74,0.71 (0.60)/0.76,0.70 (0.59)/0.76,0.74 (0.65)/0.79,0.71 (0.60)/0.76,0.75 (0.66)/0.79,0.71 (0.60)/0.76,0.75 (0.66)/0.79,0.74 (0.65)/0.79,,,,0.721111,0.618889,0.771111,,,,,,,,
,,,0.6,0.58,0.58,0.54,0.57,0.53,0.57,0.53,0.54,,,,,,,,,,,,,,
,,Closed,0.47 (0.45)/0.55,0.51 (0.50)/0.59,0.50 (0.49)/0.58,0.57 (0.56)/0.64,0.51 (0.50)/0.59,0.57 (0.57)/0.64,0.51 (0.50)/0.59,0.57 (0.57)/0.65,0.57 (0.56)/0.64,,,,0.531111,0.522222,0.607778,,,,,,,,
,,,0.46,0.43,0.43,0.4,0.43,0.39,0.43,0.39,0.4,,,,,,,,,,,,,,
Between Glazingsc,0.15,Worsta,0.97 (0.98)/0.66,0.97 (0.99)/0.67,0.96 (0.97)/0.67,0.95 (0.96)/0.69,0.95 (0.97)/0.67,0.95 (0.95)/0.69,0.95 (0.97)/0.67,0.95 (0.95)/0.69,0.95 (0.96)/0.69,,,,0.955556,0.966667,0.677778,,,,,,,,
,,,0.93,0.91,0.91,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,,,
,,0°,0.97 (0.50)/0.70,0.97 (0.51)/0.71,0.96 (0.52)/0.70,0.95 (0.55)/0.72,0.95 (0.52)/0.70,0.95 (0.55)/0.72,0.95 (0.52)/0.70,0.95 (0.55)/0.72,0.95 (0.55)/0.72,,,,0.955556,0.53,0.71,,,,,,,,
,,,0.81,0.8,0.8,0.78,0.79,0.78,0.79,0.78,0.78,,,,,,,,,,,,,,
,,Excluded Beamb,0.43 (0.50)/0.69,0.45 (0.51)/0.69,0.46 (0.52)/0.69,0.49 (0.55)/0.71,0.46 (0.52)/0.69,0.49 (0.55)/0.71,0.46 (0.52)/0.69,0.49 (0.55)/0.71,0.49 (0.55)/0.71,,,,0.468889,0.53,0.698889,,,,,,,,
,,,0.66,0.66,0.66,0.65,0.65,0.65,0.65,0.65,0.65,,,,,,,,,,,,,,
,,45°,0.54 (0.47)/0.62,0.55 (0.48)/0.63,0.56 (0.49)/0.63,0.58 (0.52)/0.65,0.56 (0.49)/0.63,0.58 (0.52)/0.65,0.56 (0.49)/0.63,0.59 (0.52)/0.65,0.58 (0.52)/0.65,,,,0.566667,0.5,0.637778,,,,,,,,
,,,0.7,0.7,0.7,0.69,0.69,0.68,0.69,0.68,0.69,,,,,,,,,,,,,,
,,Closed,0.42 (0.45)/0.50,0.44 (0.47)/0.51,0.44 (0.47)/0.52,0.47 (0.50)/0.54,0.45 (0.48)/0.52,0.48 (0.51)/0.54,0.45 (0.48)/0.52,0.48 (0.51)/0.54,0.47 (0.50)/0.54,,,,0.455556,0.485556,0.525556,,,,,,,,
,,,0.65,0.65,0.65,0.64,0.64,0.64,0.64,0.64,0.64,,,,,,,,,,,,,,
Between Glazingsc,0.5,Worsta,0.97 (1.01)/0.67,0.97 (1.02)/0.67,0.96 (1.00)/0.67,0.95 (0.98)/0.69,0.95 (0.99)/0.68,0.95 (0.98)/0.69,0.95 (0.99)/0.68,0.95 (0.98)/0.69,0.95 (0.98)/0.69,,,,0.955556,0.992222,0.681111,,,,,,,,
,,,0.94,0.92,0.92,0.89,0.92,0.89,0.92,0.89,0.89,,,,,,,,,,,,,,
,,0°,0.97 (0.49)/0.71,0.97 (0.50)/0.72,0.96 (0.51)/0.72,0.95 (0.54)/0.73,0.95 (0.52)/0.72,0.95 (0.55)/0.73,0.95 (0.52)/0.72,0.95 (0.55)/0.73,0.95 (0.54)/0.73,,,,0.955556,0.524444,0.723333,,,,,,,,
,,,0.84,0.83,0.83,0.8,0.82,0.8,0.82,0.8,0.8,,,,,,,,,,,,,,
,,Excluded Beamb,0.38 (0.49)/0.70,0.39 (0.50)/0.70,0.40 (0.51)/0.70,0.44 (0.54)/0.72,0.41 (0.52)/0.71,0.45 (0.55)/0.72,0.41 (0.52)/0.71,0.45 (0.55)/0.72,0.44 (0.54)/0.72,,,,0.418889,0.524444,0.711111,,,,,,,,
,,,0.72,0.71,0.71,0.69,0.71,0.69,0.71,0.69,0.69,,,,,,,,,,,,,,
,,45°,0.51 (0.38)/0.61,0.52 (0.40)/0.62,0.53 (0.41)/0.62,0.56 (0.45)/0.64,0.53 (0.42)/0.62,0.56 (0.46)/0.64,0.53 (0.42)/0.62,0.56 (0.46)/0.64,0.56 (0.45)/0.64,,,,0.54,0.427778,0.627778,,,,,,,,
,,,0.75,0.74,0.73,0.72,0.73,0.71,0.73,0.71,0.72,,,,,,,,,,,,,,
,,Closed,0.32 (0.32)/0.42,0.33 (0.33)/0.43,0.35 (0.35)/0.44,0.39 (0.39)/0.47,0.36 (0.36)/0.45,0.39 (0.40)/0.48,0.36 (0.36)/0.45,0.40 (0.40)/0.48,0.39 (0.39)/0.47,,,,0.365556,0.366667,0.454444,,,,,,,,
,,,0.67,0.66,0.66,0.65,0.66,0.65,0.66,0.65,0.66,,,,,,,,,,,,,,
Between Glazingsc,0.8,Worsta,0.97 (1.04)/0.68,0.97 (1.04)/0.68,0.96 (1.02)/0.69,0.95 (1.01)/0.70,0.96 (1.02)/0.69,0.95 (1.00)/0.70,0.96 (1.02)/0.69,0.95 (1.00)/0.70,0.95 (1.01)/0.70,,,,0.957778,1.017778,0.692222,,,,,,,,
,,,0.94,0.93,0.93,0.9,0.93,0.9,0.93,0.89,0.9,,,,,,,,,,,,,,
,,0°,0.97 (0.49)/0.73,0.97 (0.50)/0.74,0.96 (0.51)/0.74,0.95 (0.55)/0.75,0.96 (0.52)/0.74,0.95 (0.55)/0.75,0.96 (0.52)/0.74,0.95 (0.55)/0.75,0.95 (0.55)/0.75,,,,0.957778,0.526667,0.743333,,,,,,,,
,,,0.89,0.88,0.87,0.84,0.87,0.84,0.87,0.84,0.84,,,,,,,,,,,,,,
,,Excluded Beamb,0.35 (0.49)/0.72,0.36 (0.50)/0.72,0.37 (0.51)/0.72,0.42 (0.55)/0.74,0.38 (0.52)/0.72,0.42 (0.55)/0.74,0.38 (0.52)/0.72,0.42 (0.55)/0.74,0.42 (0.55)/0.74,,,,0.391111,0.526667,0.728889,,,,,,,,
,,,0.82,0.81,0.8,0.76,0.79,0.76,0.79,0.76,0.77,,,,,,,,,,,,,,
,,45°,0.50 (0.32)/0.60,0.51 (0.33)/0.61,0.52 (0.35)/0.62,0.55 (0.40)/0.64,0.53 (0.36)/0.62,0.55 (0.41)/0.64,0.53 (0.36)/0.62,0.56 (0.41)/0.64,0.55 (0.40)/0.64,,,,0.533333,0.371111,0.625556,,,,,,,,
,,,0.83,0.81,0.8,0.77,0.8,0.77,0.8,0.77,0.77,,,,,,,,,,,,,,
,,Closed,0.24 (0.20)/0.36,0.25 (0.22)/0.37,0.27 (0.25)/0.39,0.32 (0.30)/0.43,0.28 (0.26)/0.40,0.33 (0.31)/0.43,0.28 (0.26)/0.40,0.33 (0.31)/0.43,0.32 (0.30)/0.43,,,,0.291111,0.267778,0.404444,,,,,,,,
,,,0.74,0.73,0.71,0.69,0.71,0.69,0.71,0.69,0.69,,,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.89)/0.35,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,0.93 (0.89)/0.36,,,,0.93,0.89,0.358889,,,,,,,,
,,,0.95,0.94,0.93,0.9,0.93,0.89,0.93,0.89,0.9,,,,,,,,,,,,,,
,,0°,0.93 (0.05)/0.41,0.93 (0.05)/0.41,0.93 (0.05)/0.41,0.93 (0.05)/0.41,0.93 (0.05)/0.41,0.93 (0.06)/0.41,0.93 (0.05)/0.41,0.93 (0.06)/0.41,0.93 (0.05)/0.41,,,,0.93,0.052222,0.41,,,,,,,,
,,,0.9,0.88,0.88,0.84,0.87,0.84,0.87,0.84,0.84,,,,,,,,,,,,,,
,,Excluded Beamb,0.03 (0.05)/0.39,0.03 (0.05)/0.39,0.03 (0.05)/0.39,0.03 (0.05)/0.39,0.03 (0.05)/0.39,0.03 (0.06)/0.39,0.03 (0.05)/0.39,0.04 (0.06)/0.40,0.03 (0.05)/0.39,,,,0.031111,0.052222,0.391111,,,,,,,,
,,,0.78,0.77,0.76,0.73,0.76,0.73,0.76,0.73,0.73,,,,,,,,,,,,,,
,,45°,0.19 (0.03)/0.29,0.19 (0.03)/0.29,0.20 (0.03)/0.29,0.20 (0.04)/0.29,0.20 (0.03)/0.29,0.20 (0.04)/0.29,0.20 (0.03)/0.29,0.20 (0.04)/0.30,0.20 (0.04)/0.29,,,,0.197778,0.034444,0.291111,,,,,,,,
,,,0.84,0.82,0.82,0.79,0.81,0.78,0.81,0.78,0.79,,,,,,,,,,,,,,
,,Closed,0.02 (0.02)/0.10,0.02 (0.02)/0.10,0.02 (0.03)/0.10,0.03 (0.03)/0.11,0.03 (0.03)/0.10,0.03 (0.03)/0.11,0.03 (0.03)/0.10,0.03 (0.03)/0.11,0.03 (0.03)/0.11,,,,0.026667,0.027778,0.104444,,,,,,,,
,,,0.66,0.66,0.65,0.64,0.65,0.64,0.65,0.64,0.64,,,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (0.98)/0.44,0.94 (0.98)/0.44,0.94 (0.97)/0.44,0.94 (0.96)/0.44,0.94 (0.96)/0.44,0.94 (0.95)/0.44,0.94 (0.96)/0.44,0.94 (0.95)/0.44,0.94 (0.96)/0.44,,,,0.94,0.963333,0.44,,,,,,,,
,,,0.95,0.94,0.93,0.9,0.93,0.9,0.93,0.89,0.9,,,,,,,,,,,,,,
,,0°,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,,,,0.94,0.147778,0.5,,,,,,,,
,,,0.94,0.93,0.92,0.89,0.92,0.88,0.92,0.88,0.89,,,,,,,,,,,,,,
,,Excluded Beamb,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,,,,0.077778,0.147778,0.48,,,,,,,,
,,,0.91,0.9,0.89,0.85,0.88,0.85,0.88,0.84,0.85,,,,,,,,,,,,,,
,,45°,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.07)/0.36,0.25 (0.06)/0.36,0.25 (0.07)/0.36,0.25 (0.06)/0.36,0.25 (0.07)/0.36,0.25 (0.07)/0.36,,,,0.25,0.064444,0.36,,,,,,,,
,,,0.92,0.9,0.9,0.86,0.89,0.85,0.89,0.85,0.86,,,,,,,,,,,,,,
,,Closed,0.04 (0.02)/0.14,0.04 (0.03)/0.14,0.04 (0.03)/0.14,0.04 (0.03)/0.14,0.04 (0.03)/0.14,0.05 (0.03)/0.14,0.04 (0.03)/0.14,0.05 (0.03)/0.14,0.04 (0.03)/0.14,,,,0.042222,0.028889,0.14,,,,,,,,
,,,0.82,0.81,0.8,0.76,0.79,0.76,0.79,0.76,0.76,,,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.08)/0.55,0.95 (1.07)/0.55,0.95 (1.04)/0.55,0.95 (1.02)/0.54,0.95 (1.04)/0.55,0.95 (1.02)/0.54,0.95 (1.04)/0.55,0.95 (1.02)/0.54,0.95 (1.03)/0.54,,,,0.95,1.04,0.545556,,,,,,,,
,,,0.95,0.94,0.93,0.9,0.93,0.9,0.93,0.89,0.9,,,,,,,,,,,,,,
,,0°,0.95 (0.29)/0.62,0.95 (0.29)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,,,,0.95,0.282222,0.611111,,,,,,,,
,,,0.95,0.94,0.94,0.9,0.93,0.9,0.93,0.89,0.9,,,,,,,,,,,,,,
,,Excluded Beamb,0.16 (0.29)/0.59,0.16 (0.29)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,,,,0.16,0.282222,0.59,,,,,,,,
,,,0.94,0.93,0.92,0.89,0.92,0.88,0.92,0.88,0.89,,,,,,,,,,,,,,
,,45°,0.34 (0.12)/0.47,0.34 (0.12)/0.47,0.33 (0.12)/0.47,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.34 (0.12)/0.46,,,,0.333333,0.12,0.463333,,,,,,,,
,,,0.95,0.93,0.93,0.89,0.92,0.88,0.92,0.88,0.89,,,,,,,,,,,,,,
,,Closed,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,,,,0.08,0.04,0.21,,,,,,,,
,,,0.92,0.9,0.89,0.86,0.89,0.85,0.89,0.85,0.86,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,17a,17b,17c,17d,17e,17f,17g,17h,17i,17j,17k,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,"IAC 0 ( IAC 60)/IACdiff, FRd",,,,,,,,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.99 (0.98)/0.94,0.99 (0.98)/0.94,0.99 (0.98)/0.94,0.99 (0.98)/0.94,0.99 (0.98)/0.94,0.99 (0.98)/0.95,0.99 (0.98)/0.94,0.99 (0.98)/0.95,0.99 (0.98)/0.94,0.99 (0.98)/0.95,0.99 (0.98)/0.95,,0.99,0.98,0.943636,,,,,,,,
,,,0.86,0.83,0.83,0.79,0.81,0.76,0.8,0.75,0.8,0.75,0.76,,,,,,,,,,,,
,,0°,0.99 (0.91)/0.95,0.99 (0.91)/0.95,0.99 (0.91)/0.95,0.99 (0.92)/0.95,0.99 (0.92)/0.95,0.99 (0.93)/0.95,0.99 (0.92)/0.95,0.99 (0.93)/0.95,0.99 (0.92)/0.95,0.99 (0.93)/0.95,0.99 (0.93)/0.95,,0.99,0.920909,0.95,,,,,,,,
,,,0.66,0.64,0.65,0.63,0.63,0.61,0.63,0.6,0.63,0.6,0.61,,,,,,,,,,,,
,,Excluded Beamb,0.87 (0.91)/0.94,0.88 (0.91)/0.95,0.88 (0.91)/0.95,0.89 (0.92)/0.95,0.88 (0.92)/0.95,0.90 (0.93)/0.95,0.88 (0.92)/0.95,0.90 (0.93)/0.95,0.88 (0.92)/0.95,0.90 (0.93)/0.95,0.90 (0.93)/0.95,,0.887273,0.920909,0.949091,,,,,,,,
,,,0.41,0.41,0.41,0.41,0.41,0.4,0.41,0.4,0.41,0.4,0.4,,,,,,,,,,,,
,,45°,0.90 (0.88)/0.92,0.91 (0.89)/0.93,0.91 (0.89)/0.93,0.92 (0.89)/0.93,0.91 (0.89)/0.93,0.92 (0.90)/0.93,0.91 (0.89)/0.93,0.92 (0.90)/0.93,0.91 (0.89)/0.93,0.92 (0.90)/0.93,0.92 (0.90)/0.93,,0.913636,0.892727,0.929091,,,,,,,,
,,,0.44,0.43,0.44,0.43,0.43,0.42,0.43,0.42,0.43,0.42,0.42,,,,,,,,,,,,
,,Closed,0.85 (0.85)/0.87,0.86 (0.86)/0.88,0.85 (0.86)/0.88,0.86 (0.87)/0.88,0.86 (0.86)/0.88,0.87 (0.88)/0.89,0.86 (0.86)/0.88,0.87 (0.88)/0.89,0.86 (0.86)/0.88,0.87 (0.88)/0.89,0.87 (0.88)/0.89,,0.861818,0.867273,0.882727,,,,,,,,
,,,0.4,0.39,0.4,0.39,0.39,0.37,0.39,0.37,0.39,0.37,0.37,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.98 (0.98)/0.88,0.98 (0.98)/0.89,0.98 (0.98)/0.89,0.98 (0.98)/0.90,0.98 (0.98)/0.90,0.98 (0.98)/0.91,0.98 (0.98)/0.90,0.98 (0.98)/0.91,0.98 (0.98)/0.90,0.98 (0.98)/0.91,0.98 (0.98)/0.91,,0.98,0.98,0.9,,,,,,,,
,,,0.87,0.84,0.84,0.8,0.82,0.76,0.81,0.76,0.81,0.76,0.77,,,,,,,,,,,,
,,0°,0.98 (0.83)/0.91,0.98 (0.85)/0.91,0.98 (0.85)/0.91,0.98 (0.86)/0.92,0.98 (0.85)/0.92,0.98 (0.87)/0.93,0.98 (0.85)/0.92,0.98 (0.87)/0.93,0.98 (0.85)/0.92,0.98 (0.87)/0.93,0.98 (0.87)/0.93,,0.98,8.56E-01,0.920909,,,,,,,,
,,,0.7,0.68,0.68,0.66,0.67,0.63,0.66,0.63,0.66,0.63,0.64,,,,,,,,,,,,
,,Excluded Beamb,0.74 (0.83)/0.90,0.76 (0.85)/0.91,0.76 (0.85)/0.91,0.79 (0.86)/0.92,0.77 (0.85)/0.91,0.80 (0.87)/0.92,0.77 (0.85)/0.91,0.80 (0.87)/0.92,0.77 (0.85)/0.91,0.81 (0.87)/0.92,0.80 (0.87)/0.92,,0.779091,8.56E-01,0.913636,,,,,,,,
,,,0.47,0.46,0.46,0.45,0.45,0.44,0.45,0.44,0.45,0.44,0.44,,,,,,,,,,,,
,,45°,0.81 (0.74)/0.85,0.83 (0.76)/0.86,0.83 (0.76)/0.86,0.84 (0.79)/0.87,0.83 (0.77)/0.86,0.86 (0.81)/0.88,0.83 (0.77)/0.87,0.86 (0.81)/0.88,0.83 (0.77)/0.87,0.86 (0.81)/0.88,0.86 (0.81)/0.88,,0.84,0.781818,0.869091,,,,,,,,
,,,0.49,0.48,0.48,0.47,0.47,0.45,0.47,0.45,0.47,0.45,0.45,,,,,,,,,,,,
,,Closed,0.67 (0.67)/0.73,0.70 (0.70)/0.75,0.70 (0.70)/0.75,0.73 (0.73)/0.78,0.71 (0.71)/0.76,0.75 (0.75)/0.79,0.72 (0.71)/0.76,0.76 (0.75)/0.79,0.72 (0.71)/0.76,0.76 (0.76)/0.80,0.75 (0.75)/0.79,,0.724545,0.721818,0.769091,,,,,,,,
,,,0.41,0.39,0.4,0.38,0.39,0.37,0.38,0.37,0.38,0.37,0.37,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.98 (0.97)/0.82,0.98 (0.97)/0.83,0.98 (0.97)/0.83,0.98 (0.97)/0.85,0.98 (0.97)/0.84,0.98 (0.97)/0.86,0.98 (0.97)/0.84,0.98 (0.97)/0.87,0.98 (0.97)/0.84,0.98 (0.97)/0.87,0.98 (0.97)/0.87,,0.98,0.97,0.847273,,,,,,,,
,,,0.88,0.85,0.85,0.82,0.83,0.78,0.83,0.77,0.83,0.77,0.78,,,,,,,,,,,,
,,0°,0.98 (0.74)/0.86,0.98 (0.76)/0.87,0.98 (0.76)/0.87,0.98 (0.79)/0.88,0.98 (0.77)/0.88,0.98 (0.81)/0.89,0.98 (0.77)/0.88,0.98 (0.81)/0.89,0.98 (0.77)/0.88,0.98 (0.81)/0.89,0.98 (0.81)/0.89,,0.98,7.82E-01,0.88,,,,,,,,
,,,0.76,0.74,0.74,0.71,0.72,0.68,0.72,0.68,0.72,0.68,0.68,,,,,,,,,,,,
,,Excluded Beamb,0.61 (0.74)/0.84,0.64 (0.76)/0.86,0.64 (0.76)/0.86,0.68 (0.79)/0.87,0.66 (0.77)/0.86,0.71 (0.81)/0.88,0.66 (0.77)/0.87,0.71 (0.81)/0.89,0.66 (0.77)/0.87,0.72 (0.81)/0.89,0.71 (0.81)/0.88,,0.672727,7.82E-01,0.87,,,,,,,,
,,,0.59,0.56,0.56,0.54,0.55,0.51,0.54,0.51,0.54,0.51,0.51,,,,,,,,,,,,
,,45°,0.71 (0.60)/0.77,0.74 (0.64)/0.79,0.74 (0.64)/0.79,0.77 (0.68)/0.81,0.75 (0.66)/0.80,0.79 (0.71)/0.83,0.75 (0.66)/0.80,0.79 (0.71)/0.83,0.75 (0.66)/0.80,0.79 (0.72)/0.83,0.79 (0.71)/0.83,,0.760909,0.671818,0.807273,,,,,,,,
,,,0.59,0.56,0.56,0.54,0.55,0.51,0.54,0.51,0.54,0.51,0.51,,,,,,,,,,,,
,,Closed,0.51 (0.50)/0.59,0.55 (0.54)/0.63,0.55 (0.55)/0.63,0.60 (0.60)/0.67,0.57 (0.57)/0.65,0.64 (0.64)/0.70,0.58 (0.57)/0.65,0.64 (0.64)/0.70,0.58 (0.57)/0.65,0.64 (0.64)/0.70,0.64 (0.64)/0.70,,0.590909,0.587273,0.660909,,,,,,,,
,,,0.44,0.42,0.42,0.4,0.41,0.38,0.4,0.38,0.4,0.38,0.38,,,,,,,,,,,,
Between Glazingsc,0.15,Worsta,0.97 (1.02)/0.78,0.98 (1.02)/0.78,0.96 (0.96)/0.59,0.96 (0.96)/0.60,0.95 (0.95)/0.60,0.95 (0.95)/0.62,0.95 (0.95)/0.60,0.95 (0.95)/0.62,0.95 (0.95)/0.60,0.95 (0.95)/0.62,0.95 (0.95)/0.62,,0.956364,0.964545,0.639091,,,,,,,,
,,,0.92,0.9,0.91,0.89,0.9,0.87,0.9,0.87,0.9,0.87,0.87,,,,,,,,,,,,
,,0°,0.97 (0.66)/0.80,0.98 (0.67)/0.81,0.96 (0.39)/0.63,0.96 (0.40)/0.64,0.95 (0.41)/0.64,0.95 (0.44)/0.65,0.95 (0.41)/0.64,0.95 (0.44)/0.65,0.95 (0.41)/0.64,0.95 (0.44)/0.65,0.95 (0.44)/0.65,,0.956364,0.464545,0.672727,,,,,,,,
,,,0.8,0.79,0.79,0.78,0.79,0.77,0.79,0.77,0.79,0.77,0.77,,,,,,,,,,,,
,,Excluded Beamb,0.59 (0.66)/0.79,0.60 (0.67)/0.80,0.33 (0.39)/0.62,0.34 (0.40)/0.62,0.35 (0.41)/0.62,0.38 (0.44)/0.64,0.36 (0.41)/0.63,0.39 (0.44)/0.64,0.36 (0.41)/0.63,0.39 (0.44)/0.64,0.38 (0.44)/0.64,,0.406364,0.464545,0.660909,,,,,,,,
,,,0.66,0.66,0.66,0.66,0.65,0.65,0.65,0.65,0.65,0.65,0.65,,,,,,,,,,,,
,,45°,0.67 (0.62)/0.74,0.68 (0.63)/0.75,0.46 (0.36)/0.54,0.46 (0.37)/0.55,0.47 (0.38)/0.55,0.50 (0.41)/0.57,0.47 (0.38)/0.56,0.50 (0.41)/0.57,0.47 (0.38)/0.56,0.50 (0.41)/0.58,0.49 (0.41)/0.57,,0.515455,0.432727,0.594545,,,,,,,,
,,,0.69,0.69,0.7,0.7,0.7,0.69,0.7,0.69,0.7,0.69,0.69,,,,,,,,,,,,
,,Closed,0.57 (0.60)/0.64,0.58 (0.61)/0.65,0.32 (0.34)/0.40,0.33 (0.35)/0.41,0.34 (0.36)/0.42,0.37 (0.40)/0.44,0.35 (0.37)/0.42,0.38 (0.40)/0.45,0.35 (0.37)/0.42,0.38 (0.40)/0.45,0.37 (0.39)/0.44,,0.394545,0.417273,0.467273,,,,,,,,
,,,0.65,0.65,0.64,0.64,0.64,0.64,0.64,0.64,0.64,0.64,0.64,,,,,,,,,,,,
Between Glazingsc,0.5,Worsta,0.97 (1.03)/0.75,0.97 (1.03)/0.75,0.96 (1.01)/0.61,0.96 (1.01)/0.62,0.96 (1.00)/0.62,0.95 (0.99)/0.64,0.95 (0.99)/0.62,0.95 (0.98)/0.64,0.95 (0.99)/0.62,0.95 (0.98)/0.64,0.95 (0.99)/0.64,,0.956364,1,0.65,,,,,,,,
,,,0.92,0.91,0.91,0.89,0.91,0.87,0.9,0.87,0.9,0.87,0.88,,,,,,,,,,,,
,,0°,0.97 (0.61)/0.79,0.97 (0.62)/0.79,0.96 (0.41)/0.66,0.96 (0.42)/0.67,0.96 (0.43)/0.67,0.95 (0.45)/0.68,0.95 (0.43)/0.67,0.95 (0.46)/0.68,0.95 (0.43)/0.67,0.95 (0.46)/0.69,0.95 (0.45)/0.68,,0.956364,0.47,0.695455,,,,,,,,
,,,0.83,0.82,0.83,0.82,0.82,0.8,0.82,0.8,0.82,0.8,0.8,,,,,,,,,,,,
,,Excluded Beamb,0.49 (0.61)/0.77,0.50 (0.62)/0.78,0.31 (0.41)/0.65,0.32 (0.42)/0.65,0.33 (0.43)/0.65,0.36 (0.45)/0.67,0.33 (0.43)/0.66,0.37 (0.46)/0.67,0.33 (0.43)/0.66,0.37 (0.46)/0.67,0.36 (0.45)/0.67,,0.37,0.47,0.681818,,,,,,,,
,,,0.7,0.7,0.72,0.72,0.71,0.7,0.71,0.7,0.71,0.7,0.7,,,,,,,,,,,,
,,45°,0.61 (0.49)/0.69,0.62 (0.50)/0.70,0.45 (0.31)/0.55,0.46 (0.32)/0.56,0.47 (0.33)/0.56,0.49 (0.37)/0.58,0.47 (0.34)/0.56,0.50 (0.37)/0.58,0.47 (0.34)/0.56,0.50 (0.37)/0.58,0.49 (0.37)/0.58,,0.502727,0.373636,0.590909,,,,,,,,
,,,0.73,0.72,0.75,0.74,0.74,0.72,0.74,0.72,0.74,0.72,0.72,,,,,,,,,,,,
,,Closed,0.42 (0.42)/0.52,0.43 (0.43)/0.53,0.25 (0.25)/0.35,0.26 (0.26)/0.36,0.28 (0.27)/0.37,0.31 (0.31)/0.40,0.28 (0.28)/0.38,0.32 (0.32)/0.41,0.28 (0.28)/0.38,0.32 (0.32)/0.41,0.31 (0.31)/0.40,,0.314545,0.313636,0.41,,,,,,,,
,,,0.66,0.66,0.67,0.66,0.66,0.66,0.66,0.65,0.66,0.65,0.66,,,,,,,,,,,,
Between Glazingsc,0.8,Worsta,0.97 (1.05)/0.72,0.97 (1.05)/0.72,0.97 (1.05)/0.65,0.97 (1.05)/0.66,0.96 (1.04)/0.66,0.96 (1.02)/0.67,0.96 (1.03)/0.66,0.95 (1.02)/0.67,0.96 (1.03)/0.66,0.95 (1.02)/0.68,0.96 (1.02)/0.67,,0.961818,1.034545,0.674545,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.91,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.97 (0.55)/0.77,0.97 (0.56)/0.78,0.97 (0.45)/0.71,0.97 (0.46)/0.72,0.96 (0.46)/0.72,0.96 (0.49)/0.73,0.96 (0.47)/0.72,0.95 (0.49)/0.73,0.96 (0.47)/0.72,0.95 (0.50)/0.73,0.96 (0.49)/0.73,,0.961818,0.49,0.732727,,,,,,,,
,,,0.88,0.86,0.88,0.86,0.87,0.84,0.86,0.83,0.86,0.83,0.84,,,,,,,,,,,,
,,Excluded Beamb,0.40 (0.55)/0.75,0.41 (0.56)/0.76,0.31 (0.45)/0.69,0.32 (0.46)/0.70,0.33 (0.46)/0.70,0.37 (0.49)/0.71,0.34 (0.47)/0.70,0.37 (0.49)/0.71,0.34 (0.47)/0.70,0.37 (0.50)/0.71,0.37 (0.49)/0.71,,0.357273,0.49,0.712727,,,,,,,,
,,,0.8,0.78,0.82,0.81,0.8,0.77,0.8,0.77,0.8,0.77,0.77,,,,,,,,,,,,
,,45°,0.55 (0.37)/0.65,0.56 (0.39)/0.65,0.47 (0.28)/0.58,0.48 (0.29)/0.58,0.49 (0.31)/0.59,0.51 (0.34)/0.61,0.49 (0.31)/0.59,0.51 (0.35)/0.61,0.49 (0.31)/0.59,0.52 (0.35)/0.61,0.51 (0.34)/0.61,,0.507273,0.330909,0.606364,,,,,,,,
,,,0.81,0.79,0.83,0.81,0.81,0.77,0.8,0.77,0.8,0.77,0.78,,,,,,,,,,,,
,,Closed,0.29 (0.25)/0.41,0.30 (0.27)/0.42,0.21 (0.17)/0.33,0.22 (0.18)/0.34,0.24 (0.20)/0.35,0.27 (0.25)/0.38,0.24 (0.21)/0.36,0.28 (0.25)/0.39,0.24 (0.21)/0.36,0.28 (0.26)/0.39,0.27 (0.25)/0.38,,0.258182,0.227273,0.373636,,,,,,,,
,,,0.72,0.71,0.75,0.73,0.72,0.7,0.72,0.7,0.72,0.69,0.7,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.36,0.93 (0.89)/0.35,,0.93,0.89,0.350909,,,,,,,,
,,,0.95,0.93,0.93,0.91,0.92,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.05)/0.41,,0.93,4.36E-02,0.41,,,,,,,,
,,,0.9,0.88,0.89,0.87,0.87,0.84,0.87,0.83,0.87,0.83,0.84,,,,,,,,,,,,
,,Excluded Beamb,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.03 (0.05)/0.39,,0.023636,4.36E-02,0.39,,,,,,,,
,,,0.8,0.79,0.8,0.78,0.77,0.74,0.77,0.74,0.77,0.74,0.74,,,,,,,,,,,,
,,45°,0.19 (0.02)/0.28,0.19 (0.02)/0.29,0.19 (0.02)/0.28,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.19 (0.03)/0.29,0.19 (0.03)/0.29,0.20 (0.03)/0.29,0.19 (0.03)/0.29,0.20 (0.03)/0.29,0.19 (0.03)/0.29,,0.191818,0.025455,0.288182,,,,,,,,
,,,0.84,0.83,0.84,0.82,0.82,0.79,0.81,0.78,0.81,0.78,0.79,,,,,,,,,,,,
,,Closed,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.10,0.02 (0.02)/0.09,0.02 (0.03)/0.10,0.02 (0.02)/0.09,0.02 (0.03)/0.10,0.02 (0.02)/0.10,,0.02,0.021818,0.093636,,,,,,,,
,,,0.67,0.66,0.67,0.66,0.66,0.65,0.66,0.65,0.66,0.65,0.65,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (0.98)/0.43,0.94 (0.98)/0.43,0.94 (0.99)/0.44,0.94 (0.98)/0.44,0.94 (0.97)/0.43,0.94 (0.95)/0.43,0.94 (0.96)/0.43,0.94 (0.95)/0.43,0.94 (0.96)/0.43,0.94 (0.95)/0.43,0.94 (0.96)/0.43,,0.94,0.966364,0.431818,,,,,,,,
,,,0.95,0.93,0.93,0.91,0.92,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.49,0.94 (0.14)/0.49,0.94 (0.14)/0.49,0.94 (0.14)/0.49,0.94 (0.14)/0.49,0.94 (0.14)/0.49,0.94 (0.14)/0.49,,0.94,1.40E-01,0.493636,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.91,0.87,0.91,0.87,0.91,0.87,0.88,,,,,,,,,,,,
,,Excluded Beamb,0.07 (0.14)/0.47,0.07 (0.14)/0.47,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.47,0.07 (0.14)/0.47,0.07 (0.14)/0.47,0.07 (0.14)/0.47,0.07 (0.14)/0.47,0.08 (0.14)/0.47,0.07 (0.14)/0.47,,0.070909,1.40E-01,0.471818,,,,,,,,
,,,0.91,0.9,0.9,0.88,0.88,0.85,0.88,0.84,0.88,0.84,0.85,,,,,,,,,,,,
,,45°,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,,0.25,0.06,0.36,,,,,,,,
,,,0.92,0.9,0.91,0.88,0.89,0.85,0.88,0.85,0.88,0.85,0.85,,,,,,,,,,,,
,,Closed,0.04 (0.02)/0.13,0.04 (0.02)/0.13,0.04 (0.02)/0.13,0.04 (0.02)/0.13,0.04 (0.02)/0.13,0.04 (0.03)/0.13,0.04 (0.02)/0.13,0.04 (0.03)/0.14,0.04 (0.02)/0.13,0.04 (0.03)/0.14,0.04 (0.03)/0.14,,0.04,0.023636,0.132727,,,,,,,,
,,,0.84,0.82,0.83,0.81,0.81,0.77,0.8,0.77,0.8,0.77,0.77,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.07)/0.55,0.95 (1.07)/0.55,0.95 (1.08)/0.55,0.95 (1.08)/0.55,0.95 (1.04)/0.55,0.95 (1.02)/0.54,0.95 (1.04)/0.54,0.95 (1.02)/0.54,0.95 (1.04)/0.54,0.95 (1.02)/0.54,0.95 (1.03)/0.54,,0.95,1.046364,0.544545,,,,,,,,
,,,0.95,0.93,0.93,0.91,0.92,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.29)/0.62,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,,0.95,2.81E-01,0.610909,,,,,,,,
,,,0.95,0.93,0.93,0.91,0.92,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,Excluded Beamb,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.29)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.58,0.16 (0.28)/0.59,0.16 (0.28)/0.58,0.16 (0.28)/0.59,0.16 (0.28)/0.58,0.16 (0.28)/0.59,,0.16,2.81E-01,0.587273,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.91,0.87,0.9,0.87,0.9,0.87,0.87,,,,,,,,,,,,
,,45°,0.34 (0.12)/0.47,0.34 (0.12)/0.47,0.34 (0.12)/0.47,0.34 (0.12)/0.47,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,0.33 (0.12)/0.46,,0.333636,0.12,0.463636,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.91,0.88,0.91,0.87,0.91,0.87,0.88,,,,,,,,,,,,
,,Closed,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.20,0.08 (0.04)/0.21,0.08 (0.04)/0.20,0.08 (0.04)/0.21,0.08 (0.04)/0.20,0.08 (0.04)/0.20,,0.08,0.04,0.206364,,,,,,,,
,,,0.92,0.9,0.91,0.88,0.89,0.85,0.88,0.85,0.88,0.85,0.85,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,21a,21b,21c,21d,21e,21f,21g,21h,21i,21j,21k,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,"IAC 0 ( IAC 60)/IACdiff, FRd",,,,,,,,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.99 (0.98)/0.94,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.95,,0.99,0.98,0.949091,,,,,,,,
,,,0.85,0.82,0.82,0.8,0.8,0.75,0.79,0.75,0.79,0.75,0.76,,,,,,,,,,,,
,,0°,0.99 (0.92)/0.95,0.99 (0.92)/0.95,0.99 (0.93)/0.96,0.99 (0.93)/0.96,0.99 (0.93)/0.96,0.99 (0.93)/0.96,0.99 (0.93)/0.96,0.99 (0.94)/0.96,0.99 (0.93)/0.96,0.99 (0.94)/0.96,0.99 (0.93)/0.96,,0.99,0.93,0.958182,,,,,,,,
,,,0.66,0.64,0.64,0.63,0.63,0.61,0.63,0.6,0.63,0.6,0.61,,,,,,,,,,,,
,,Excluded Beamb,0.89 (0.92)/0.95,0.89 (0.92)/0.95,0.90 (0.93)/0.95,0.90 (0.93)/0.96,0.90 (0.93)/0.95,0.91 (0.93)/0.96,0.90 (0.93)/0.96,0.91 (0.94)/0.96,0.90 (0.93)/0.96,0.91 (0.94)/0.96,0.91 (0.93)/0.96,,0.901818,0.93,0.956364,,,,,,,,
,,,0.41,0.41,0.41,0.41,0.4,0.4,0.4,0.4,0.4,0.4,0.4,,,,,,,,,,,,
,,45°,0.92 (0.89)/0.93,0.92 (0.90)/0.93,0.92 (0.90)/0.93,0.93 (0.91)/0.94,0.92 (0.91)/0.94,0.93 (0.91)/0.94,0.93 (0.91)/0.94,0.93 (0.91)/0.94,0.93 (0.91)/0.94,0.93 (0.91)/0.94,0.93 (0.91)/0.94,,0.926364,0.906364,0.937273,,,,,,,,
,,,0.44,0.43,0.43,0.43,0.43,0.42,0.42,0.41,0.42,0.41,0.42,,,,,,,,,,,,
,,Closed,0.86 (0.87)/0.88,0.87 (0.87)/0.89,0.87 (0.88)/0.89,0.88 (0.88)/0.90,0.88 (0.88)/0.89,0.89 (0.89)/0.90,0.88 (0.88)/0.90,0.89 (0.89)/0.90,0.88 (0.88)/0.90,0.89 (0.89)/0.90,0.89 (0.89)/0.90,,0.88,0.881818,0.895455,,,,,,,,
,,,0.4,0.39,0.39,0.39,0.38,0.37,0.38,0.37,0.38,0.37,0.37,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.99 (0.98)/0.89,0.99 (0.98)/0.90,0.99 (0.98)/0.91,0.99 (0.98)/0.91,0.99 (0.98)/0.91,0.99 (0.98)/0.92,0.99 (0.98)/0.91,0.99 (0.98)/0.92,0.99 (0.98)/0.91,0.99 (0.98)/0.92,0.99 (0.98)/0.92,,0.99,0.98,0.910909,,,,,,,,
,,,0.86,0.84,0.83,0.81,0.81,0.76,0.8,0.76,0.8,0.76,0.76,,,,,,,,,,,,
,,0°,0.99 (0.85)/0.92,0.99 (0.86)/0.92,0.99 (0.87)/0.93,0.99 (0.87)/0.93,0.99 (0.87)/0.93,0.99 (0.89)/0.94,0.99 (0.87)/0.93,0.99 (0.89)/0.94,0.99 (0.87)/0.93,0.99 (0.89)/0.94,0.99 (0.89)/0.94,,0.99,0.874545,0.931818,,,,,,,,
,,,0.7,0.68,0.67,0.66,0.66,0.63,0.66,0.63,0.66,0.63,0.64,,,,,,,,,,,,
,,Excluded Beamb,0.77 (0.85)/0.91,0.79 (0.86)/0.92,0.79 (0.87)/0.92,0.81 (0.87)/0.92,0.80 (0.87)/0.92,0.82 (0.89)/0.93,0.80 (0.87)/0.92,0.82 (0.89)/0.93,0.80 (0.87)/0.92,0.82 (0.89)/0.93,0.82 (0.89)/0.93,,0.803636,0.874545,0.922727,,,,,,,,
,,,0.47,0.46,0.46,0.45,0.45,0.44,0.45,0.44,0.45,0.44,0.44,,,,,,,,,,,,
,,45°,0.83 (0.77)/0.86,0.84 (0.79)/0.87,0.85 (0.79)/0.88,0.86 (0.81)/0.88,0.85 (0.80)/0.88,0.87 (0.82)/0.89,0.86 (0.80)/0.88,0.87 (0.83)/0.89,0.86 (0.80)/0.88,0.87 (0.83)/0.89,0.87 (0.82)/0.89,,0.857273,0.805455,0.880909,,,,,,,,
,,,0.49,0.48,0.48,0.47,0.47,0.45,0.47,0.45,0.47,0.45,0.45,,,,,,,,,,,,
,,Closed,0.71 (0.70)/0.75,0.73 (0.73)/0.77,0.74 (0.73)/0.78,0.75 (0.75)/0.79,0.75 (0.75)/0.79,0.77 (0.77)/0.81,0.75 (0.75)/0.79,0.78 (0.78)/0.81,0.75 (0.75)/0.79,0.78 (0.78)/0.81,0.77 (0.77)/0.81,,0.752727,0.750909,0.790909,,,,,,,,
,,,0.41,0.39,0.39,0.38,0.39,0.37,0.38,0.37,0.38,0.37,0.37,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.98 (0.97)/0.84,0.98 (0.97)/0.85,0.98 (0.97)/0.86,0.98 (0.97)/0.87,0.98 (0.97)/0.86,0.98 (0.97)/0.88,0.98 (0.97)/0.86,0.98 (0.97)/0.88,0.98 (0.97)/0.86,0.98 (0.97)/0.88,0.98 (0.97)/0.88,,0.98,0.97,0.865455,,,,,,,,
,,,0.88,0.85,0.84,0.82,0.82,0.77,0.81,0.77,0.81,0.77,0.78,,,,,,,,,,,,
,,0°,0.98 (0.76)/0.87,0.98 (0.78)/0.88,0.98 (0.79)/0.89,0.98 (0.81)/0.89,0.98 (0.80)/0.89,0.98 (0.83)/0.90,0.98 (0.81)/0.89,0.98 (0.83)/0.90,0.98 (0.81)/0.89,0.98 (0.83)/0.90,0.98 (0.83)/0.90,,0.98,0.807273,0.890909,,,,,,,,
,,,0.76,0.74,0.73,0.71,0.72,0.68,0.71,0.68,0.71,0.68,0.68,,,,,,,,,,,,
,,Excluded Beamb,0.64 (0.76)/0.86,0.67 (0.78)/0.87,0.69 (0.79)/0.88,0.71 (0.81)/0.89,0.70 (0.80)/0.88,0.73 (0.83)/0.90,0.70 (0.81)/0.88,0.74 (0.83)/0.90,0.70 (0.81)/0.88,0.74 (0.83)/0.90,0.73 (0.83)/0.90,,0.704545,0.807273,0.885455,,,,,,,,
,,,0.59,0.56,0.56,0.54,0.55,0.52,0.54,0.52,0.54,0.52,0.52,,,,,,,,,,,,
,,45°,0.74 (0.64)/0.79,0.76 (0.67)/0.81,0.77 (0.68)/0.81,0.79 (0.71)/0.83,0.78 (0.70)/0.82,0.80 (0.74)/0.84,0.78 (0.70)/0.82,0.81 (0.74)/0.84,0.78 (0.70)/0.82,0.81 (0.74)/0.84,0.81 (0.74)/0.84,,0.784545,0.705455,0.823636,,,,,,,,
,,,0.59,0.57,0.56,0.54,0.55,0.52,0.55,0.52,0.55,0.51,0.52,,,,,,,,,,,,
,,Closed,0.54 (0.53)/0.62,0.59 (0.58)/0.66,0.60 (0.59)/0.67,0.63 (0.62)/0.69,0.62 (0.61)/0.68,0.66 (0.66)/0.72,0.62 (0.62)/0.69,0.67 (0.66)/0.72,0.62 (0.62)/0.69,0.67 (0.67)/0.72,0.66 (0.66)/0.72,,0.625455,0.62,0.689091,,,,,,,,
,,,0.45,0.42,0.42,0.4,0.41,0.38,0.41,0.38,0.41,0.38,0.38,,,,,,,,,,,,
Between Glazingsc,0.15,Worsta,0.98 (1.01)/0.80,0.98 (1.01)/0.80,0.96 (0.99)/0.60,0.96 (0.99)/0.61,0.96 (0.98)/0.61,0.95 (0.97)/0.63,0.95 (0.98)/0.61,0.95 (0.97)/0.63,0.95 (0.98)/0.61,0.95 (0.97)/0.63,0.95 (0.97)/0.62,,0.958182,0.983636,0.65,,,,,,,,
,,,0.91,0.9,0.9,0.89,0.89,0.87,0.89,0.86,0.89,0.86,0.87,,,,,,,,,,,,
,,0°,0.98 (0.69)/0.82,0.98 (0.70)/0.83,0.96 (0.40)/0.64,0.96 (0.40)/0.65,0.96 (0.42)/0.65,0.95 (0.44)/0.66,0.95 (0.42)/0.65,0.95 (0.45)/0.66,0.95 (0.42)/0.65,0.95 (0.45)/0.66,0.95 (0.44)/0.66,,0.958182,0.475455,0.684545,,,,,,,,
,,,0.8,0.79,0.79,0.78,0.78,0.77,0.78,0.77,0.78,0.77,0.77,,,,,,,,,,,,
,,Excluded Beamb,0.63 (0.69)/0.81,0.64 (0.70)/0.82,0.34 (0.40)/0.63,0.35 (0.40)/0.63,0.36 (0.42)/0.64,0.39 (0.44)/0.65,0.37 (0.42)/0.64,0.39 (0.45)/0.65,0.37 (0.42)/0.64,0.40 (0.45)/0.65,0.39 (0.44)/0.65,,0.420909,0.475455,0.673636,,,,,,,,
,,,0.66,0.66,0.66,0.65,0.65,0.65,0.65,0.65,0.65,0.65,0.65,,,,,,,,,,,,
,,45°,0.71 (0.66)/0.77,0.71 (0.67)/0.78,0.47 (0.36)/0.55,0.47 (0.37)/0.56,0.48 (0.38)/0.56,0.50 (0.41)/0.58,0.49 (0.39)/0.57,0.51 (0.42)/0.58,0.49 (0.39)/0.57,0.51 (0.42)/0.58,0.50 (0.41)/0.58,,0.530909,0.443636,0.607273,,,,,,,,
,,,0.69,0.69,0.7,0.7,0.69,0.69,0.69,0.68,0.69,0.68,0.69,,,,,,,,,,,,
,,Closed,0.61 (0.64)/0.68,0.63 (0.65)/0.69,0.33 (0.35)/0.41,0.34 (0.36)/0.41,0.35 (0.37)/0.42,0.38 (0.40)/0.45,0.35 (0.37)/0.43,0.38 (0.40)/0.45,0.35 (0.37)/0.43,0.38 (0.40)/0.45,0.38 (0.40)/0.45,,0.407273,0.428182,0.479091,,,,,,,,
,,,0.65,0.65,0.64,0.64,0.64,0.64,0.64,0.64,0.64,0.64,0.64,,,,,,,,,,,,
Between Glazingsc,0.5,Worsta,0.97 (1.03)/0.77,0.97 (1.03)/0.78,0.97 (1.06)/0.63,0.97 (1.06)/0.63,0.96 (1.05)/0.64,0.95 (1.03)/0.65,0.96 (1.04)/0.64,0.95 (1.03)/0.65,0.96 (1.04)/0.64,0.95 (1.03)/0.65,0.95 (1.03)/0.65,,0.96,1.039091,0.666364,,,,,,,,
,,,0.92,0.9,0.91,0.89,0.9,0.87,0.89,0.87,0.89,0.87,0.87,,,,,,,,,,,,
,,0°,0.97 (0.65)/0.81,0.97 (0.66)/0.81,0.97 (0.42)/0.68,0.97 (0.42)/0.68,0.96 (0.44)/0.69,0.95 (0.46)/0.69,0.96 (0.44)/0.69,0.95 (0.46)/0.70,0.96 (0.44)/0.69,0.95 (0.47)/0.70,0.95 (0.46)/0.69,,0.96,0.483636,0.711818,,,,,,,,
,,,0.83,0.82,0.83,0.82,0.82,0.8,0.81,0.8,0.81,0.79,0.8,,,,,,,,,,,,
,,Excluded Beamb,0.54 (0.65)/0.80,0.55 (0.66)/0.80,0.32 (0.42)/0.66,0.32 (0.42)/0.67,0.34 (0.44)/0.67,0.37 (0.46)/0.68,0.34 (0.44)/0.67,0.37 (0.46)/0.68,0.34 (0.44)/0.67,0.38 (0.47)/0.68,0.37 (0.46)/0.68,,0.385455,0.483636,0.696364,,,,,,,,
,,,0.7,0.7,0.72,0.71,0.71,0.7,0.71,0.7,0.71,0.7,0.7,,,,,,,,,,,,
,,45°,0.64 (0.54)/0.72,0.65 (0.55)/0.73,0.47 (0.31)/0.56,0.47 (0.32)/0.57,0.48 (0.34)/0.58,0.50 (0.37)/0.59,0.49 (0.34)/0.58,0.51 (0.37)/0.59,0.49 (0.34)/0.58,0.51 (0.38)/0.59,0.50 (0.37)/0.59,,0.519091,0.384545,0.607273,,,,,,,,
,,,0.73,0.72,0.75,0.74,0.74,0.72,0.73,0.72,0.73,0.72,0.72,,,,,,,,,,,,
,,Closed,0.47 (0.47)/0.56,0.48 (0.48)/0.57,0.26 (0.25)/0.36,0.27 (0.26)/0.37,0.28 (0.28)/0.38,0.32 (0.32)/0.41,0.29 (0.29)/0.39,0.32 (0.32)/0.41,0.29 (0.29)/0.39,0.33 (0.32)/0.42,0.32 (0.32)/0.41,,0.33,0.327273,0.424545,,,,,,,,
,,,0.66,0.66,0.67,0.66,0.66,0.65,0.66,0.65,0.66,0.65,0.66,,,,,,,,,,,,
Between Glazingsc,0.8,Worsta,0.97 (1.04)/0.74,0.97 (1.04)/0.75,0.97 (1.12)/0.67,0.97 (1.12)/0.68,0.96 (1.11)/0.68,0.96 (1.09)/0.69,0.96 (1.11)/0.68,0.96 (1.09)/0.69,0.96 (1.11)/0.68,0.95 (1.09)/0.69,0.96 (1.09)/0.69,,0.962727,1.091818,0.694545,,,,,,,,
,,,0.93,0.92,0.91,0.9,0.9,0.88,0.9,0.88,0.9,0.88,0.88,,,,,,,,,,,,
,,0°,0.97 (0.60)/0.79,0.97 (0.60)/0.80,0.97 (0.46)/0.73,0.97 (0.46)/0.73,0.96 (0.47)/0.73,0.96 (0.50)/0.74,0.96 (0.48)/0.73,0.96 (0.50)/0.74,0.96 (0.48)/0.73,0.95 (0.50)/0.74,0.96 (0.50)/0.74,,0.962727,0.504545,0.745455,,,,,,,,
,,,0.88,0.86,0.87,0.86,0.86,0.84,0.86,0.83,0.86,0.83,0.84,,,,,,,,,,,,
,,Excluded Beamb,0.45 (0.60)/0.78,0.46 (0.60)/0.78,0.33 (0.46)/0.71,0.33 (0.46)/0.71,0.35 (0.47)/0.71,0.38 (0.50)/0.72,0.35 (0.48)/0.72,0.38 (0.50)/0.72,0.35 (0.48)/0.72,0.39 (0.50)/0.72,0.38 (0.50)/0.72,,0.377273,0.504545,0.728182,,,,,,,,
,,,0.8,0.79,0.82,0.81,0.8,0.77,0.79,0.77,0.79,0.77,0.77,,,,,,,,,,,,
,,45°,0.59 (0.43)/0.68,0.59 (0.44)/0.68,0.49 (0.29)/0.60,0.50 (0.30)/0.60,0.51 (0.31)/0.61,0.53 (0.35)/0.62,0.51 (0.32)/0.61,0.53 (0.35)/0.63,0.51 (0.32)/0.61,0.53 (0.36)/0.63,0.53 (0.35)/0.62,,0.529091,0.347273,0.626364,,,,,,,,
,,,0.81,0.8,0.82,0.81,0.8,0.78,0.8,0.77,0.8,0.77,0.78,,,,,,,,,,,,
,,Closed,0.33 (0.30)/0.44,0.34 (0.31)/0.45,0.22 (0.17)/0.34,0.23 (0.18)/0.35,0.25 (0.21)/0.37,0.28 (0.25)/0.40,0.25 (0.22)/0.37,0.29 (0.26)/0.40,0.25 (0.22)/0.37,0.29 (0.26)/0.41,0.28 (0.25)/0.40,,0.273636,0.239091,0.390909,,,,,,,,
,,,0.73,0.72,0.75,0.74,0.72,0.7,0.72,0.7,0.72,0.7,0.7,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.90)/0.35,0.93 (0.90)/0.35,0.93 (0.90)/0.35,0.93 (0.90)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.36,0.93 (0.89)/0.35,0.93 (0.89)/0.36,0.93 (0.89)/0.35,,0.93,0.893636,0.351818,,,,,,,,
,,,0.94,0.93,0.92,0.91,0.91,0.88,0.9,0.88,0.9,0.88,0.88,,,,,,,,,,,,
,,0°,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,0.93 (0.05)/0.41,,0.93,0.043636,0.41,,,,,,,,
,,,0.9,0.88,0.88,0.87,0.87,0.84,0.86,0.84,0.86,0.83,0.84,,,,,,,,,,,,
,,Excluded Beamb,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.02 (0.04)/0.39,0.03 (0.05)/0.39,0.03 (0.05)/0.39,,0.023636,0.043636,0.39,,,,,,,,
,,,0.8,0.79,0.79,0.78,0.77,0.74,0.77,0.74,0.77,0.74,0.74,,,,,,,,,,,,
,,45°,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.20 (0.03)/0.29,0.19 (0.03)/0.29,0.20 (0.03)/0.29,0.19 (0.03)/0.29,0.20 (0.03)/0.29,0.20 (0.03)/0.29,,0.193636,0.025455,0.29,,,,,,,,
,,,0.84,0.83,0.83,0.82,0.81,0.79,0.81,0.78,0.81,0.78,0.79,,,,,,,,,,,,
,,Closed,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.10,0.02 (0.02)/0.09,0.02 (0.03)/0.10,0.02 (0.02)/0.09,0.02 (0.03)/0.10,0.02 (0.02)/0.10,,0.02,0.021818,0.093636,,,,,,,,
,,,0.67,0.66,0.67,0.66,0.66,0.65,0.66,0.65,0.66,0.65,0.65,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (1.02)/0.44,0.94 (1.01)/0.44,0.94 (1.01)/0.44,0.94 (1.01)/0.44,0.94 (0.98)/0.44,0.94 (0.96)/0.44,0.94 (0.98)/0.44,0.94 (0.96)/0.44,0.94 (0.98)/0.44,0.94 (0.96)/0.44,0.94 (0.97)/0.44,,0.94,0.985455,0.44,,,,,,,,
,,,0.94,0.93,0.92,0.91,0.91,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,,0.94,0.14,0.5,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.9,0.87,0.9,0.87,0.9,0.87,0.88,,,,,,,,,,,,
,,Excluded Beamb,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.08 (0.14)/0.48,0.07 (0.14)/0.48,0.08 (0.14)/0.48,0.07 (0.14)/0.48,0.08 (0.14)/0.48,0.08 (0.14)/0.48,,0.073636,0.14,0.48,,,,,,,,
,,,0.91,0.9,0.89,0.88,0.88,0.85,0.87,0.84,0.87,0.84,0.85,,,,,,,,,,,,
,,45°,0.25 (0.06)/0.37,0.25 (0.06)/0.37,0.25 (0.06)/0.37,0.25 (0.06)/0.37,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,0.25 (0.06)/0.36,,0.25,0.06,0.363636,,,,,,,,
,,,0.92,0.9,0.9,0.89,0.88,0.85,0.88,0.85,0.88,0.85,0.85,,,,,,,,,,,,
,,Closed,0.04 (0.02)/0.14,0.04 (0.02)/0.14,0.04 (0.02)/0.14,0.04 (0.02)/0.14,0.04 (0.02)/0.14,0.04 (0.03)/0.14,0.04 (0.02)/0.14,0.04 (0.03)/0.14,0.04 (0.02)/0.14,0.04 (0.03)/0.14,0.04 (0.03)/0.14,,0.04,0.023636,0.14,,,,,,,,
,,,0.84,0.82,0.83,0.81,0.8,0.77,0.8,0.77,0.8,0.77,0.77,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.14)/0.56,0.95 (1.12)/0.56,0.95 (1.13)/0.56,0.95 (1.12)/0.56,0.95 (1.07)/0.55,0.95 (1.04)/0.55,0.95 (1.07)/0.55,0.95 (1.04)/0.54,0.95 (1.07)/0.55,0.95 (1.03)/0.54,0.95 (1.05)/0.55,,0.95,1.08,0.551818,,,,,,,,
,,,0.94,0.93,0.92,0.91,0.91,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,0°,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,0.95 (0.28)/0.61,,0.95,0.283636,0.613636,,,,,,,,
,,,0.95,0.93,0.92,0.91,0.91,0.88,0.91,0.88,0.91,0.88,0.88,,,,,,,,,,,,
,,Excluded Beamb,0.17 (0.29)/0.60,0.17 (0.29)/0.60,0.17 (0.29)/0.60,0.17 (0.29)/0.60,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,0.16 (0.28)/0.59,,0.163636,0.283636,0.593636,,,,,,,,
,,,0.94,0.92,0.91,0.9,0.9,0.87,0.9,0.87,0.9,0.87,0.87,,,,,,,,,,,,
,,45°,0.35 (0.13)/0.49,0.35 (0.13)/0.48,0.35 (0.13)/0.48,0.35 (0.13)/0.48,0.34 (0.12)/0.47,0.34 (0.12)/0.46,0.34 (0.12)/0.47,0.34 (0.12)/0.46,0.34 (0.12)/0.47,0.34 (0.12)/0.46,0.34 (0.12)/0.47,,0.343636,0.123636,0.471818,,,,,,,,
,,,0.94,0.92,0.92,0.9,0.9,0.88,0.9,0.87,0.9,0.87,0.88,,,,,,,,,,,,
,,Closed,0.08 (0.04)/0.22,0.08 (0.04)/0.22,0.08 (0.04)/0.22,0.08 (0.04)/0.22,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,,0.08,0.04,0.213636,,,,,,,,
,,,0.92,0.9,0.9,0.89,0.88,0.85,0.88,0.85,0.88,0.85,0.85,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,25a,25b,25c,25d,25e,25f,29a,29b,,,,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,"IAC 0 ( IAC 60)/IACdiff, FRd",,,,,,"IAC 0 ( IAC 60)/IACdiff, FRd",,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.99 (0.99)/0.95,0.99 (0.98)/0.95,0.99 (0.98)/0.96,0.99 (0.98)/0.96,0.99 (0.98)/0.96,0.99 (0.98)/0.96,0.99 (0.98)/0.94,0.99 (0.98)/0.95,,,,,0.99,0.981667,0.956667,,,,,,,,
,,,0.84,0.81,0.74,0.76,0.72,0.76,0.82,0.78,,,,,,,,,,,,,,,
,,0°,0.99 (0.93)/0.96,0.99 (0.93)/0.96,0.99 (0.94)/0.96,0.99 (0.94)/0.96,0.99 (0.95)/0.96,0.99 (0.94)/0.96,0.99 (0.92)/0.95,0.99 (0.93)/0.95,,,,,0.99,0.938333,0.96,,,,,,,,
,,,0.65,0.64,0.6,0.61,0.59,0.61,0.65,0.62,,,,,,,,,,,,,,,
,,Excluded Beamb,0.90 (0.93)/0.96,0.91 (0.93)/0.96,0.92 (0.94)/0.96,0.92 (0.94)/0.96,0.92 (0.95)/0.96,0.92 (0.94)/0.96,0.88 (0.92)/0.95,0.89 (0.93)/0.95,,,,,0.915,9.38E-01,0.96,,,,,,,,
,,,0.41,0.4,0.39,0.4,0.39,0.4,0.41,0.4,,,,,,,,,,,,,,,
,,45°,0.93 (0.91)/0.94,0.93 (0.91)/0.94,0.94 (0.93)/0.95,0.94 (0.92)/0.95,0.94 (0.93)/0.95,0.94 (0.92)/0.95,0.91 (0.89)/0.93,0.92 (0.90)/0.93,,,,,0.936667,9.20E-01,0.946667,,,,,,,,
,,,0.43,0.43,0.41,0.42,0.41,0.42,0.43,0.43,,,,,,,,,,,,,,,
,,Closed,0.88 (0.88)/0.90,0.89 (0.89)/0.90,0.90 (0.90)/0.91,0.90 (0.90)/0.91,0.90 (0.91)/0.92,0.90 (0.90)/0.91,0.86 (0.87)/0.88,0.87 (0.88)/0.89,,,,,0.895,0.896667,0.908333,,,,,,,,
,,,0.39,0.39,0.36,0.37,0.36,0.37,0.39,0.38,,,,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.99 (0.98)/0.91,0.99 (0.98)/0.92,0.99 (0.98)/0.93,0.99 (0.98)/0.93,0.99 (0.98)/0.93,0.99 (0.98)/0.93,0.98 (0.98)/0.90,0.98 (0.98)/0.91,,,,,0.99,0.98,0.925,,,,,,,,
,,,0.86,0.82,0.75,0.77,0.73,0.77,0.83,0.79,,,,,,,,,,,,,,,
,,0°,0.99 (0.87)/0.93,0.99 (0.89)/0.94,0.99 (0.91)/0.95,0.99 (0.90)/0.94,0.99 (0.91)/0.95,0.99 (0.90)/0.94,0.98 (0.86)/0.92,0.98 (0.87)/0.93,,,,,0.99,0.896667,0.941667,,,,,,,,
,,,0.69,0.67,0.63,0.64,0.62,0.64,0.68,0.65,,,,,,,,,,,,,,,
,,Excluded Beamb,0.80 (0.87)/0.92,0.82 (0.89)/0.93,0.85 (0.91)/0.94,0.85 (0.90)/0.94,0.86 (0.91)/0.94,0.84 (0.90)/0.94,0.77 (0.86)/0.91,0.80 (0.87)/0.92,,,,,0.836667,8.97E-01,0.935,,,,,,,,
,,,0.47,0.46,0.44,0.44,0.43,0.45,0.46,0.45,,,,,,,,,,,,,,,
,,45°,0.85 (0.80)/0.88,0.87 (0.83)/0.90,0.89 (0.85)/0.91,0.89 (0.85)/0.91,0.89 (0.86)/0.91,0.89 (0.85)/0.91,0.83 (0.78)/0.87,0.85 (0.81)/0.88,,,,,0.88,8.40E-01,0.903333,,,,,,,,
,,,0.49,0.48,0.45,0.46,0.45,0.46,0.48,0.46,,,,,,,,,,,,,,,
,,Closed,0.74 (0.74)/0.79,0.77 (0.77)/0.81,0.81 (0.81)/0.84,0.80 (0.80)/0.83,0.82 (0.82)/0.85,0.80 (0.80)/0.83,0.71 (0.72)/0.77,0.74 (0.75)/0.79,,,,,0.79,0.79,0.825,,,,,,,,
,,,0.4,0.39,0.37,0.37,0.36,0.38,0.39,0.38,,,,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.98 (0.98)/0.86,0.98 (0.98)/0.88,0.98 (0.97)/0.90,0.98 (0.98)/0.90,0.98 (0.97)/0.90,0.98 (0.98)/0.89,0.98 (0.97)/0.85,0.98 (0.97)/0.87,,,,,0.98,0.976667,0.888333,,,,,,,,
,,,0.87,0.84,0.76,0.78,0.74,0.78,0.84,0.8,,,,,,,,,,,,,,,
,,0°,0.98 (0.80)/0.89,0.98 (0.83)/0.91,0.98 (0.86)/0.92,0.98 (0.85)/0.92,0.98 (0.86)/0.92,0.98 (0.85)/0.92,0.98 (0.78)/0.88,0.98 (0.81)/0.89,,,,,0.98,0.841667,0.913333,,,,,,,,
,,,0.76,0.73,0.68,0.69,0.66,0.69,0.74,0.7,,,,,,,,,,,,,,,
,,Excluded Beamb,0.69 (0.80)/0.88,0.73 (0.83)/0.90,0.78 (0.86)/0.91,0.77 (0.85)/0.91,0.78 (0.86)/0.92,0.77 (0.85)/0.91,0.66 (0.78)/0.87,0.70 (0.81)/0.89,,,,,7.53E-01,8.42E-01,0.905,,,,,,,,
,,,0.59,0.56,0.52,0.53,0.51,0.53,0.56,0.53,,,,,,,,,,,,,,,
,,45°,0.78 (0.69)/0.82,0.81 (0.73)/0.84,0.84 (0.78)/0.87,0.83 (0.77)/0.86,0.84 (0.79)/0.87,0.83 (0.77)/0.86,0.75 (0.67)/0.80,0.78 (0.71)/0.83,,,,,8.22E-01,7.55E-01,0.853333,,,,,,,,
,,,0.59,0.57,0.52,0.53,0.51,0.54,0.56,0.53,,,,,,,,,,,,,,,
,,Closed,0.60 (0.59)/0.67,0.65 (0.65)/0.71,0.71 (0.71)/0.76,0.70 (0.70)/0.75,0.72 (0.72)/0.77,0.70 (0.69)/0.75,0.57 (0.58)/0.65,0.62 (0.64)/0.70,,,,,0.68,0.676667,0.735,,,,,,,,
,,,0.45,0.42,0.39,0.4,0.38,0.4,0.42,0.4,,,,,,,,,,,,,,,
Between Glazingsc,0.15,Worsta,0.97 (1.00)/0.80,0.97 (0.99)/0.81,0.95 (0.96)/0.80,0.95 (0.96)/0.80,0.94 (0.95)/0.80,0.96 (0.96)/0.80,0.97 (1.01)/0.63,0.97 (1.02)/0.64,,,,,0.956667,0.97,0.801667,,,,,,,,
,,,0.91,0.89,0.86,0.86,0.85,0.86,0.9,0.87,,,,,,,,,,,,,,,
,,0°,0.97 (0.71)/0.82,0.97 (0.72)/0.82,0.95 (0.73)/0.82,0.95 (0.73)/0.82,0.94 (0.73)/0.82,0.96 (0.73)/0.82,0.97 (0.44)/0.66,0.97 (0.46)/0.68,,,,,0.956667,0.725,0.82,,,,,,,,
,,,0.79,0.78,0.76,0.77,0.76,0.77,0.79,0.78,,,,,,,,,,,,,,,
,,Excluded Beamb,0.66 (0.71)/0.82,0.67 (0.72)/0.82,0.69 (0.73)/0.81,0.69 (0.73)/0.81,0.70 (0.73)/0.81,0.69 (0.73)/0.81,0.36 (0.44)/0.65,0.38 (0.46)/0.67,,,,,0.683333,0.725,0.813333,,,,,,,,
,,,0.65,0.65,0.65,0.65,0.64,0.65,0.66,0.65,,,,,,,,,,,,,,,
,,45°,0.73 (0.69)/0.78,0.73 (0.69)/0.78,0.75 (0.71)/0.78,0.75 (0.71)/0.78,0.75 (0.72)/0.79,0.75 (0.71)/0.78,0.48 (0.41)/0.59,0.50 (0.43)/0.60,,,,,0.743333,0.705,0.781667,,,,,,,,
,,,0.68,0.68,0.67,0.67,0.67,0.67,0.7,0.69,,,,,,,,,,,,,,,
,,Closed,0.65 (0.67)/0.70,0.66 (0.68)/0.71,0.68 (0.70)/0.72,0.68 (0.70)/0.72,0.69 (0.71)/0.73,0.68 (0.70)/0.72,0.36 (0.41)/0.46,0.38 (0.43)/0.48,,,,,0.673333,0.693333,0.716667,,,,,,,,
,,,0.65,0.65,0.64,0.64,0.64,0.64,0.64,0.64,,,,,,,,,,,,,,,
Between Glazingsc,0.5,Worsta,0.97 (1.01)/0.79,0.97 (1.00)/0.79,0.95 (0.97)/0.80,0.95 (0.98)/0.80,0.94 (0.96)/0.80,0.95 (0.98)/0.80,0.97 (1.06)/0.65,0.98 (1.06)/0.66,,,,,0.955,0.983333,0.796667,,,,,,,,
,,,0.92,0.9,0.86,0.87,0.85,0.87,0.9,0.88,,,,,,,,,,,,,,,
,,0°,0.97 (0.68)/0.82,0.97 (0.69)/0.82,0.95 (0.71)/0.82,0.95 (0.71)/0.82,0.94 (0.72)/0.82,0.95 (0.71)/0.82,0.97 (0.45)/0.69,0.98 (0.47)/0.71,,,,,0.955,0.703333,0.82,,,,,,,,
,,,0.82,0.81,0.79,0.79,0.78,0.79,0.83,0.81,,,,,,,,,,,,,,,
,,Excluded Beamb,0.58 (0.68)/0.81,0.60 (0.69)/0.81,0.64 (0.71)/0.81,0.63 (0.71)/0.81,0.65 (0.72)/0.81,0.63 (0.71)/0.81,0.33 (0.45)/0.68,0.34 (0.47)/0.69,,,,,0.621667,0.703333,0.81,,,,,,,,
,,,0.7,0.7,0.68,0.69,0.68,0.69,0.71,0.7,,,,,,,,,,,,,,,
,,45°,0.68 (0.59)/0.74,0.69 (0.61)/0.75,0.71 (0.65)/0.76,0.71 (0.64)/0.76,0.72 (0.66)/0.77,0.71 (0.64)/0.76,0.47 (0.35)/0.59,0.49 (0.37)/0.60,,,,,0.703333,0.631667,0.756667,,,,,,,,
,,,0.73,0.72,0.7,0.71,0.7,0.71,0.74,0.73,,,,,,,,,,,,,,,
,,Closed,0.52 (0.52)/0.60,0.54 (0.54)/0.61,0.59 (0.59)/0.65,0.58 (0.58)/0.64,0.60 (0.60)/0.65,0.58 (0.58)/0.64,0.28 (0.29)/0.39,0.29 (0.31)/0.41,,,,,0.568333,0.568333,0.631667,,,,,,,,
,,,0.66,0.66,0.65,0.66,0.65,0.66,0.66,0.66,,,,,,,,,,,,,,,
Between Glazingsc,0.8,Worsta,0.97 (1.02)/0.77,0.97 (1.01)/0.78,0.95 (0.98)/0.79,0.95 (0.98)/0.79,0.94 (0.97)/0.79,0.95 (0.99)/0.79,0.97 (1.11)/0.68,0.98 (1.11)/0.69,,,,,0.955,0.991667,0.785,,,,,,,,
,,,0.93,0.91,0.87,0.88,0.86,0.88,0.91,0.89,,,,,,,,,,,,,,,
,,0°,0.97 (0.65)/0.81,0.97 (0.67)/0.82,0.95 (0.70)/0.82,0.95 (0.69)/0.82,0.94 (0.71)/0.82,0.95 (0.69)/0.82,0.97 (0.48)/0.74,0.98 (0.49)/0.75,,,,,0.955,0.685,0.818333,,,,,,,,
,,,0.87,0.86,0.83,0.83,0.82,0.84,0.87,0.85,,,,,,,,,,,,,,,
,,Excluded Beamb,0.51 (0.65)/0.80,0.53 (0.67)/0.81,0.59 (0.70)/0.81,0.58 (0.69)/0.81,0.60 (0.71)/0.81,0.58 (0.69)/0.81,0.32 (0.48)/0.72,0.33 (0.49)/0.73,,,,,0.565,0.685,0.808333,,,,,,,,
,,,0.8,0.79,0.76,0.76,0.75,0.76,0.81,0.79,,,,,,,,,,,,,,,
,,45°,0.64 (0.50)/0.71,0.65 (0.53)/0.73,0.69 (0.59)/0.74,0.68 (0.58)/0.74,0.69 (0.60)/0.75,0.68 (0.58)/0.74,0.48 (0.31)/0.61,0.49 (0.32)/0.62,,,,,0.671667,0.563333,0.735,,,,,,,,
,,,0.81,0.8,0.76,0.77,0.76,0.77,0.82,0.79,,,,,,,,,,,,,,,
,,Closed,0.39 (0.36)/0.49,0.42 (0.39)/0.51,0.49 (0.47)/0.57,0.48 (0.46)/0.56,0.51 (0.49)/0.58,0.48 (0.46)/0.56,0.22 (0.19)/0.36,0.23 (0.21)/0.37,,,,,0.461667,0.438333,0.545,,,,,,,,
,,,0.73,0.73,0.7,0.71,0.7,0.71,0.73,0.72,,,,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.92)/0.36,0.93 (0.92)/0.36,0.93 (0.90)/0.36,0.93 (0.89)/0.36,0.93 (0.90)/0.36,0.93 (0.89)/0.36,0.93 (0.90)/0.35,0.93 (0.90)/0.35,,,,,0.93,0.903333,0.36,,,,,,,,
,,,0.94,0.92,0.87,0.88,0.86,0.89,0.92,0.9,,,,,,,,,,,,,,,
,,0°,0.93 (0.05)/0.41,0.93 (0.05)/0.41,0.93 (0.06)/0.41,0.93 (0.05)/0.41,0.93 (0.06)/0.42,0.93 (0.05)/0.41,0.93 (0.04)/0.41,0.93 (0.05)/0.41,,,,,0.93,0.053333,0.411667,,,,,,,,
,,,0.89,0.87,0.82,0.83,0.81,0.84,0.88,0.86,,,,,,,,,,,,,,,
,,Excluded Beamb,0.03 (0.05)/0.39,0.03 (0.05)/0.39,0.04 (0.06)/0.40,0.03 (0.05)/0.39,0.04 (0.06)/0.40,0.03 (0.05)/0.39,0.02 (0.04)/0.39,0.02 (0.05)/0.39,,,,,0.033333,0.053333,0.393333,,,,,,,,
,,,0.78,0.77,0.72,0.73,0.71,0.74,0.78,0.76,,,,,,,,,,,,,,,
,,45°,0.20 (0.03)/0.29,0.20 (0.03)/0.29,0.20 (0.04)/0.30,0.20 (0.03)/0.29,0.20 (0.04)/0.30,0.20 (0.03)/0.29,0.19 (0.02)/0.29,0.19 (0.03)/0.29,,,,,0.2,0.033333,0.293333,,,,,,,,
,,,0.83,0.82,0.77,0.78,0.76,0.78,0.83,0.81,,,,,,,,,,,,,,,
,,Closed,0.02 (0.02)/0.10,0.02 (0.02)/0.10,0.03 (0.03)/0.11,0.03 (0.03)/0.10,0.03 (0.04)/0.11,0.03 (0.03)/0.10,0.02 (0.02)/0.09,0.02 (0.02)/0.09,,,,,0.026667,0.028333,0.103333,,,,,,,,
,,,0.66,0.66,0.64,0.65,0.64,0.65,0.66,0.66,,,,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (1.08)/0.45,0.94 (1.06)/0.45,0.94 (0.99)/0.44,0.94 (0.96)/0.44,0.94 (0.98)/0.44,0.94 (0.97)/0.44,0.94 (1.00)/0.44,0.94 (0.99)/0.44,,,,,0.94,1.01E+00,0.443333,,,,,,,,
,,,0.94,0.92,0.87,0.88,0.86,0.89,0.92,0.9,,,,,,,,,,,,,,,
,,0°,0.94 (0.15)/0.51,0.94 (0.15)/0.51,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.15)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,,,,,0.94,0.15,0.503333,,,,,,,,
,,,0.93,0.91,0.86,0.87,0.85,0.88,0.92,0.89,,,,,,,,,,,,,,,
,,Excluded Beamb,0.08 (0.15)/0.49,0.08 (0.15)/0.49,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.08 (0.15)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,,,,,8.00E-02,1.50E-01,0.483333,,,,,,,,
,,,0.9,0.88,0.83,0.84,0.82,0.85,0.89,0.86,,,,,,,,,,,,,,,
,,45°,0.26 (0.06)/0.38,0.26 (0.06)/0.38,0.26 (0.07)/0.37,0.25 (0.06)/0.36,0.26 (0.07)/0.37,0.25 (0.06)/0.36,0.24 (0.06)/0.36,0.25 (0.06)/0.36,,,,,2.57E-01,6.33E-02,0.37,,,,,,,,
,,,0.91,0.89,0.84,0.85,0.82,0.85,0.9,0.87,,,,,,,,,,,,,,,
,,Closed,0.04 (0.03)/0.15,0.04 (0.03)/0.15,0.05 (0.03)/0.14,0.04 (0.03)/0.14,0.05 (0.03)/0.14,0.04 (0.03)/0.14,0.04 (0.02)/0.14,0.04 (0.02)/0.14,,,,,0.043333,0.03,0.143333,,,,,,,,
,,,0.82,0.81,0.75,0.76,0.74,0.76,0.82,0.79,,,,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.25)/0.59,0.95 (1.21)/0.58,0.95 (1.08)/0.56,0.95 (1.04)/0.55,0.95 (1.06)/0.55,0.95 (1.05)/0.55,0.95 (1.11)/0.56,0.95 (1.10)/0.56,,,,,0.95,1.115,0.563333,,,,,,,,
,,,0.94,0.92,0.87,0.88,0.86,0.89,0.92,0.9,,,,,,,,,,,,,,,
,,0°,0.95 (0.30)/0.64,0.95 (0.30)/0.64,0.95 (0.29)/0.62,0.95 (0.28)/0.61,0.95 (0.29)/0.61,0.95 (0.28)/0.61,0.95 (0.29)/0.62,0.95 (0.29)/0.62,,,,,0.95,0.29,0.621667,,,,,,,,
,,,0.94,0.92,0.88,0.88,0.86,0.89,0.93,0.9,,,,,,,,,,,,,,,
,,Excluded Beamb,0.18 (0.30)/0.62,0.18 (0.30)/0.62,0.17 (0.29)/0.60,0.16 (0.28)/0.59,0.17 (0.29)/0.59,0.17 (0.28)/0.59,0.16 (0.29)/0.60,0.16 (0.29)/0.60,,,,,0.171667,2.90E-01,0.601667,,,,,,,,
,,,0.93,0.91,0.86,0.87,0.85,0.88,0.91,0.88,,,,,,,,,,,,,,,
,,45°,0.37 (0.13)/0.51,0.37 (0.13)/0.50,0.35 (0.13)/0.48,0.34 (0.12)/0.47,0.35 (0.13)/0.47,0.34 (0.12)/0.47,0.33 (0.13)/0.48,0.33 (0.13)/0.48,,,,,0.353333,1.27E-01,0.483333,,,,,,,,
,,,0.93,0.91,0.86,0.87,0.85,0.88,0.92,0.89,,,,,,,,,,,,,,,
,,Closed,0.09 (0.04)/0.24,0.09 (0.04)/0.24,0.09 (0.04)/0.22,0.08 (0.04)/0.21,0.09 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.22,0.08 (0.04)/0.22,,,,,0.086667,0.04,0.221667,,,,,,,,
,,,0.91,0.89,0.83,0.85,0.82,0.85,0.89,0.87,,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,32a,32b,32c,32d,40a,40b,40c,40d,,,,,,,,,,,,,,,
Louver Location,Louver Reflection,ϕ,,,,,,,,,,,,,,,,,,,,,,,
Indoor Side,0.15,Worsta,0.99 (0.98)/0.95,0.99 (0.98)/0.96,1.00 (1.00)/0.97,1.00 (1.00)/0.97,1.00 (1.00)/0.98,1.00 (1.00)/0.98,0.99 (0.99)/0.96,0.99 (0.99)/0.96,,,,,0.99,0.98,0.945,,0.995,0.99,0.9625,,0.995,0.995,0.97
,,,0.8,0.75,0.76,0.71,0.73,0.67,0.78,0.73,,,,,,,,,,,,,,,
,,0°,0.99 (0.94)/0.96,0.99 (0.94)/0.96,1.00 (0.96)/0.98,1.00 (0.96)/0.98,1.00 (0.96)/0.98,1.00 (0.97)/0.99,0.99 (0.95)/0.97,0.99 (0.95)/0.97,,,,,0.99,0.925,0.95,,0.995,0.95,0.97,,0.995,0.9575,0.9775
,,,0.64,0.61,0.6,0.56,0.58,0.54,0.63,0.6,,,,,,,,,,,,,,,
,,Excluded Beamb,0.90 (0.94)/0.96,0.91 (0.94)/0.96,0.93 (0.96)/0.97,0.93 (0.96)/0.98,0.94 (0.96)/0.98,0.95 (0.97)/0.98,0.92 (0.95)/0.97,0.93 (0.95)/0.97,,,,,0.885,0.925,0.95,,0.9175,0.95,0.9675,,0.935,0.9575,0.975
,,,0.41,0.4,0.39,0.36,0.37,0.35,0.4,0.4,,,,,,,,,,,,,,,
,,45°,0.93 (0.91)/0.94,0.93 (0.92)/0.95,0.95 (0.93)/0.96,0.95 (0.94)/0.97,0.95 (0.94)/0.97,0.96 (0.95)/0.97,0.94 (0.92)/0.95,0.94 (0.93)/0.96,,,,,0.915,0.895,0.93,,0.94,0.925,0.955,,0.9475,0.935,0.9625
,,,0.43,0.42,0.41,0.38,0.39,0.37,0.42,0.41,,,,,,,,,,,,,,,
,,Closed,0.88 (0.89)/0.90,0.89 (0.90)/0.91,0.91 (0.91)/0.93,0.92 (0.92)/0.94,0.92 (0.93)/0.94,0.93 (0.94)/0.95,0.90 (0.90)/0.92,0.91 (0.91)/0.92,,,,,0.865,0.875,0.885,,0.9,0.905,0.92,,0.915,0.92,0.9325
,,,0.39,0.37,0.37,0.35,0.36,0.33,0.38,0.37,,,,,,,,,,,,,,,
Indoor Side,0.5,Worsta,0.99 (0.98)/0.91,0.99 (0.98)/0.92,0.99 (0.99)/0.92,1.00 (1.00)/0.93,0.99 (1.00)/0.93,1.00 (1.00)/0.95,0.99 (0.98)/0.92,0.99 (0.98)/0.93,,,,,0.98,0.98,0.905,,0.9925,0.9875,0.92,,0.9925,0.99,0.9325
,,,0.81,0.76,0.77,0.72,0.74,0.68,0.79,0.74,,,,,,,,,,,,,,,
,,0°,0.99 (0.88)/0.93,0.99 (0.90)/0.94,0.99 (0.89)/0.94,1.00 (0.91)/0.95,0.99 (0.91)/0.95,1.00 (0.92)/0.96,0.99 (0.89)/0.94,0.99 (0.91)/0.95,,,,,0.98,0.865,0.925,,0.9925,0.895,0.94,,0.9925,0.9075,0.95
,,,0.67,0.64,0.62,0.58,0.6,0.56,0.66,0.63,,,,,,,,,,,,,,,
,,Excluded Beamb,0.80 (0.88)/0.93,0.83 (0.90)/0.94,0.81 (0.89)/0.94,0.83 (0.91)/0.95,0.83 (0.91)/0.95,0.86 (0.92)/0.96,0.82 (0.89)/0.93,0.84 (0.91)/0.94,,,,,0.785,0.865,0.915,,0.8175,0.895,0.94,,0.8375,0.9075,0.945
,,,0.45,0.44,0.42,0.39,0.4,0.37,0.45,0.44,,,,,,,,,,,,,,,
,,45°,0.85 (0.81)/0.89,0.87 (0.84)/0.90,0.86 (0.82)/0.90,0.88 (0.84)/0.91,0.88 (0.84)/0.91,0.90 (0.87)/0.93,0.87 (0.83)/0.90,0.89 (0.85)/0.91,,,,,0.84,0.795,0.875,,0.865,0.8275,0.9,,0.885,0.8475,0.9125
,,,0.47,0.45,0.43,0.4,0.41,0.38,0.46,0.45,,,,,,,,,,,,,,,
,,Closed,0.75 (0.76)/0.80,0.78 (0.79)/0.82,0.76 (0.76)/0.81,0.79 (0.80)/0.83,0.78 (0.80)/0.83,0.82 (0.83)/0.86,0.77 (0.78)/0.81,0.80 (0.81)/0.84,,,,,0.725,0.735,0.78,,0.77,0.7775,0.815,,0.7925,0.805,0.835
,,,0.39,0.37,0.35,0.33,0.34,0.31,0.38,0.36,,,,,,,,,,,,,,,
Indoor Side,0.8,Worsta,0.98 (0.97)/0.86,0.98 (0.97)/0.88,0.99 (0.99)/0.87,0.99 (0.99)/0.89,0.99 (0.99)/0.88,0.99 (0.99)/0.90,0.98 (0.98)/0.87,0.98 (0.98)/0.89,,,,,0.98,0.97,0.86,,0.985,0.98,0.875,,0.985,0.985,0.885
,,,0.82,0.77,0.79,0.73,0.75,0.69,0.8,0.75,,,,,,,,,,,,,,,
,,0°,0.98 (0.81)/0.89,0.98 (0.84)/0.91,0.99 (0.81)/0.90,0.99 (0.83)/0.91,0.99 (0.83)/0.91,0.99 (0.86)/0.93,0.98 (0.82)/0.90,0.98 (0.85)/0.92,,,,,0.98,0.795,0.885,,0.985,0.8225,0.9025,,0.985,0.84,0.915
,,,0.72,0.68,0.67,0.62,0.64,0.59,0.71,0.67,,,,,,,,,,,,,,,
,,Excluded Beamb,0.69 (0.81)/0.88,0.74 (0.84)/0.90,0.69 (0.81)/0.89,0.73 (0.83)/0.90,0.72 (0.83)/0.90,0.77 (0.86)/0.92,0.71 (0.82)/0.89,0.76 (0.85)/0.91,,,,,0.68,0.795,0.88,,0.7125,0.8225,0.8925,,0.74,0.84,0.905
,,,0.55,0.52,0.49,0.45,0.46,0.42,0.54,0.51,,,,,,,,,,,,,,,
,,45°,0.77 (0.71)/0.82,0.81 (0.75)/0.85,0.77 (0.70)/0.83,0.80 (0.74)/0.85,0.80 (0.74)/0.85,0.83 (0.78)/0.87,0.79 (0.73)/0.84,0.82 (0.77)/0.86,,,,,0.765,0.69,0.815,,0.7875,0.725,0.8375,,0.81,0.755,0.855
,,,0.55,0.52,0.49,0.44,0.46,0.42,0.54,0.51,,,,,,,,,,,,,,,
,,Closed,0.61 (0.63)/0.69,0.67 (0.68)/0.74,0.60 (0.62)/0.69,0.66 (0.67)/0.73,0.65 (0.66)/0.72,0.71 (0.72)/0.77,0.64 (0.65)/0.71,0.69 (0.71)/0.76,,,,,0.595,0.61,0.675,,0.635,0.65,0.7125,,0.6725,0.685,0.74
,,,0.41,0.38,0.35,0.31,0.33,0.29,0.4,0.38,,,,,,,,,,,,,,,
Between Glazingsc,0.15,Worsta,0.98 (1.05)/0.76,0.98 (1.05)/0.77,0.97 (1.01)/0.60,0.98 (1.02)/0.62,0.98 (1.05)/0.73,0.99 (1.05)/0.75,0.98 (1.04)/0.71,0.99 (1.04)/0.73,,,,,0.97,1.015,0.635,,0.9775,1.0325,0.6875,,0.985,1.045,0.73
,,,0.88,0.85,0.79,0.74,0.75,0.7,0.87,0.84,,,,,,,,,,,,,,,
,,0°,0.98 (0.62)/0.78,0.98 (0.63)/0.80,0.97 (0.40)/0.64,0.98 (0.42)/0.66,0.98 (0.57)/0.76,0.99 (0.60)/0.78,0.98 (0.55)/0.74,0.99 (0.58)/0.76,,,,,0.97,0.45,0.67,,0.9775,0.5175,0.72,,0.985,0.575,0.76
,,,0.78,0.77,0.59,0.56,0.57,0.54,0.78,0.76,,,,,,,,,,,,,,,
,,Excluded Beamb,0.52 (0.62)/0.77,0.54 (0.63)/0.79,0.32 (0.40)/0.63,0.35 (0.42)/0.65,0.48 (0.57)/0.75,0.50 (0.60)/0.77,0.46 (0.55)/0.73,0.49 (0.58)/0.75,,,,,0.37,0.45,0.66,,0.4325,0.5175,0.71,,0.4825,0.575,0.75
,,,0.66,0.65,0.34,0.33,0.33,0.33,0.65,0.65,,,,,,,,,,,,,,,
,,45°,0.62 (0.58)/0.72,0.64 (0.60)/0.73,0.45 (0.37)/0.56,0.47 (0.40)/0.58,0.58 (0.54)/0.69,0.60 (0.57)/0.71,0.57 (0.52)/0.68,0.59 (0.55)/0.69,,,,,0.49,0.42,0.595,,0.545,0.4875,0.6475,,0.585,0.545,0.6925
,,,0.69,0.68,0.43,0.41,0.4,0.39,0.69,0.68,,,,,,,,,,,,,,,
,,Closed,0.51 (0.57)/0.61,0.53 (0.58)/0.63,0.32 (0.37)/0.43,0.34 (0.40)/0.45,0.46 (0.53)/0.57,0.49 (0.55)/0.60,0.45 (0.51)/0.56,0.48 (0.53)/0.58,,,,,0.37,0.42,0.47,,0.425,0.48,0.53,,0.47,0.53,0.5775
,,,0.65,0.65,0.31,0.31,0.32,0.32,0.64,0.64,,,,,,,,,,,,,,,
Between Glazingsc,0.5,Worsta,0.98 (1.08)/0.74,0.98 (1.08)/0.75,0.97 (1.07)/0.63,0.98 (1.07)/0.65,0.98 (1.09)/0.72,0.99 (1.09)/0.74,0.98 (1.08)/0.71,0.99 (1.08)/0.72,,,,,0.975,1.06,0.655,,0.9775,1.075,0.6925,,0.985,1.085,0.7225
,,,0.89,0.86,0.8,0.75,0.77,0.71,0.88,0.85,,,,,,,,,,,,,,,
,,0°,0.98 (0.58)/0.78,0.98 (0.60)/0.79,0.97 (0.42)/0.68,0.98 (0.44)/0.69,0.98 (0.55)/0.77,0.99 (0.57)/0.78,0.98 (0.54)/0.75,0.99 (0.56)/0.77,,,,,0.975,0.46,0.7,,0.9775,0.51,0.735,,0.985,0.555,0.7675
,,,0.81,0.79,0.66,0.62,0.62,0.59,0.81,0.79,,,,,,,,,,,,,,,
,,Excluded Beamb,0.44 (0.58)/0.77,0.46 (0.60)/0.78,0.30 (0.42)/0.66,0.32 (0.44)/0.68,0.41 (0.55)/0.75,0.43 (0.57)/0.77,0.40 (0.54)/0.74,0.42 (0.56)/0.75,,,,,0.335,0.46,0.685,,0.38,0.51,0.7225,,0.415,0.555,0.7525
,,,0.7,0.69,0.46,0.43,0.42,0.4,0.7,0.69,,,,,,,,,,,,,,,
,,45°,0.57 (0.47)/0.68,0.59 (0.48)/0.69,0.45 (0.32)/0.57,0.47 (0.35)/0.59,0.55 (0.44)/0.66,0.56 (0.46)/0.68,0.54 (0.43)/0.65,0.56 (0.45)/0.67,,,,,0.48,0.36,0.595,,0.52,0.405,0.6325,,0.5525,0.445,0.665
,,,0.72,0.71,0.51,0.48,0.47,0.44,0.72,0.71,,,,,,,,,,,,,,,
,,Closed,0.38 (0.40)/0.50,0.40 (0.41)/0.51,0.25 (0.27)/0.37,0.27 (0.29)/0.39,0.35 (0.37)/0.48,0.37 (0.39)/0.49,0.34 (0.36)/0.46,0.36 (0.38)/0.48,,,,,0.285,0.3,0.4,,0.325,0.3425,0.4425,,0.355,0.375,0.4775
,,,0.66,0.66,0.36,0.35,0.35,0.34,0.66,0.65,,,,,,,,,,,,,,,
Between Glazingsc,0.8,Worsta,0.97 (1.11)/0.73,0.98 (1.11)/0.73,0.98 (1.12)/0.67,0.98 (1.12)/0.69,0.98 (1.12)/0.72,0.99 (1.12)/0.73,0.98 (1.11)/0.71,0.99 (1.11)/0.72,,,,,0.975,1.11,0.685,,0.9775,1.115,0.705,,0.985,1.115,0.72
,,,0.9,0.87,0.81,0.76,0.79,0.73,0.89,0.86,,,,,,,,,,,,,,,
,,0°,0.97 (0.55)/0.78,0.98 (0.56)/0.79,0.98 (0.47)/0.73,0.98 (0.48)/0.74,0.98 (0.54)/0.78,0.99 (0.55)/0.79,0.98 (0.53)/0.77,0.99 (0.54)/0.78,,,,,0.975,0.485,0.745,,0.9775,0.515,0.76,,0.985,0.54,0.78
,,,0.85,0.83,0.75,0.7,0.71,0.66,0.85,0.82,,,,,,,,,,,,,,,
,,Excluded Beamb,0.38 (0.55)/0.76,0.39 (0.56)/0.77,0.31 (0.47)/0.71,0.32 (0.48)/0.72,0.37 (0.54)/0.76,0.38 (0.55)/0.77,0.36 (0.53)/0.75,0.38 (0.54)/0.76,,,,,0.325,0.485,0.725,,0.35,0.515,0.74,,0.3725,0.54,0.76
,,,0.78,0.76,0.63,0.59,0.58,0.54,0.78,0.76,,,,,,,,,,,,,,,
,,45°,0.54 (0.37)/0.65,0.55 (0.38)/0.66,0.47 (0.30)/0.60,0.49 (0.32)/0.62,0.52 (0.36)/0.65,0.54 (0.37)/0.66,0.52 (0.35)/0.64,0.53 (0.36)/0.65,,,,,0.485,0.315,0.615,,0.5125,0.3425,0.6325,,0.5275,0.36,0.65
,,,0.79,0.77,0.65,0.6,0.59,0.55,0.79,0.77,,,,,,,,,,,,,,,
,,Closed,0.27 (0.24)/0.41,0.29 (0.26)/0.42,0.21 (0.18)/0.35,0.22 (0.20)/0.37,0.26 (0.23)/0.40,0.28 (0.25)/0.41,0.25 (0.23)/0.39,0.27 (0.24)/0.40,,,,,0.225,0.2,0.365,,0.2475,0.22,0.3875,,0.265,0.2375,0.4
,,,0.71,0.7,0.49,0.46,0.45,0.43,0.72,0.7,,,,,,,,,,,,,,,
Outdoor Side,0.15,Worsta,0.93 (0.90)/0.35,0.93 (0.89)/0.35,0.93 (0.90)/0.35,0.93 (0.90)/0.35,0.93 (0.90)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,0.93 (0.89)/0.35,,,,,0.93,0.9,0.35,,0.93,0.8975,0.35,,0.93,0.8925,0.35
,,,0.91,0.88,0.83,0.78,0.81,0.75,0.9,0.87,,,,,,,,,,,,,,,
,,0°,0.93 (0.04)/0.40,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.41,0.93 (0.04)/0.40,0.93 (0.04)/0.41,0.93 (0.04)/0.40,0.93 (0.04)/0.40,,,,,0.93,0.045,0.41,,0.93,0.04,0.4075,,0.93,0.04,0.4025
,,,0.88,0.85,0.77,0.72,0.76,0.7,0.87,0.84,,,,,,,,,,,,,,,
,,Excluded Beamb,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,0.02 (0.04)/0.39,,,,,0.02,0.045,0.39,,0.02,0.04,0.39,,0.02,0.04,0.39
,,,0.79,0.77,0.59,0.55,0.59,0.55,0.79,0.77,,,,,,,,,,,,,,,
,,45°,0.19 (0.02)/0.28,0.19 (0.02)/0.28,0.19 (0.02)/0.29,0.19 (0.02)/0.29,0.19 (0.02)/0.28,0.19 (0.02)/0.28,0.19 (0.02)/0.28,0.19 (0.02)/0.28,,,,,0.19,0.025,0.29,,0.19,0.02,0.285,,0.19,0.02,0.28
,,,0.83,0.81,0.67,0.63,0.66,0.62,0.83,0.8,,,,,,,,,,,,,,,
,,Closed,0.01 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.02 (0.02)/0.09,0.01 (0.01)/0.09,0.01 (0.02)/0.09,0.01 (0.01)/0.09,0.01 (0.02)/0.09,,,,,0.02,0.02,0.09,,0.0175,0.02,0.09,,0.01,0.015,0.09
,,,0.67,0.66,0.36,0.35,0.37,0.35,0.67,0.66,,,,,,,,,,,,,,,
Outdoor Side,0.5,Worsta,0.94 (0.99)/0.44,0.94 (0.99)/0.44,0.94 (1.00)/0.44,0.94 (1.00)/0.44,0.94 (1.00)/0.44,0.94 (0.99)/0.44,0.94 (0.99)/0.44,0.94 (0.99)/0.43,,,,,0.94,0.995,0.44,,0.94,0.995,0.44,,0.94,0.9925,0.4375
,,,0.91,0.88,0.84,0.79,0.81,0.75,0.9,0.87,,,,,,,,,,,,,,,
,,0°,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,0.94 (0.14)/0.50,,,,,0.94,0.14,0.5,,0.94,0.14,0.5,,0.94,0.14,0.5
,,,0.91,0.88,0.83,0.78,0.81,0.75,0.9,0.87,,,,,,,,,,,,,,,
,,Excluded Beamb,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.48,0.07 (0.14)/0.47,,,,,0.07,0.14,0.48,,0.07,0.14,0.48,,0.07,0.14,0.4775
,,,0.88,0.85,0.78,0.73,0.76,0.7,0.88,0.85,,,,,,,,,,,,,,,
,,45°,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,0.24 (0.06)/0.36,,,,,0.245,0.06,0.36,,0.24,0.06,0.36,,0.24,0.06,0.36
,,,0.89,0.86,0.8,0.74,0.78,0.72,0.88,0.85,,,,,,,,,,,,,,,
,,Closed,0.03 (0.02)/0.13,0.04 (0.02)/0.13,0.03 (0.02)/0.14,0.04 (0.02)/0.14,0.03 (0.02)/0.13,0.03 (0.02)/0.13,0.03 (0.02)/0.13,0.03 (0.02)/0.13,,,,,0.04,0.02,0.14,,0.035,0.02,0.135,,0.03,0.02,0.13
,,,0.82,0.79,0.65,0.61,0.65,0.6,0.82,0.79,,,,,,,,,,,,,,,
Outdoor Side,0.8,Worsta,0.95 (1.10)/0.56,0.95 (1.08)/0.55,0.95 (1.11)/0.56,0.95 (1.10)/0.56,0.95 (1.10)/0.56,0.95 (1.09)/0.55,0.95 (1.10)/0.55,0.95 (1.08)/0.55,,,,,0.95,1.105,0.56,,0.95,1.0975,0.5575,,0.95,1.0925,0.5525
,,,0.91,0.88,0.84,0.79,0.81,0.75,0.9,0.87,,,,,,,,,,,,,,,
,,0°,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,0.95 (0.29)/0.62,,,,,0.95,0.29,0.62,,0.95,0.29,0.62,,0.95,0.29,0.62
,,,0.92,0.89,0.84,0.79,0.82,0.76,0.9,0.87,,,,,,,,,,,,,,,
,,Excluded Beamb,0.16 (0.29)/0.60,0.16 (0.29)/0.59,0.15 (0.29)/0.60,0.15 (0.29)/0.60,0.15 (0.29)/0.60,0.15 (0.29)/0.60,0.15 (0.29)/0.60,0.15 (0.29)/0.59,,,,,0.16,0.29,0.6,,0.155,0.29,0.5975,,0.15,0.29,0.5975
,,,0.9,0.87,0.82,0.76,0.79,0.73,0.89,0.86,,,,,,,,,,,,,,,
,,45°,0.33 (0.12)/0.48,0.33 (0.12)/0.47,0.33 (0.13)/0.48,0.33 (0.13)/0.48,0.33 (0.12)/0.48,0.33 (0.12)/0.47,0.33 (0.12)/0.48,0.33 (0.12)/0.47,,,,,0.33,0.13,0.48,,0.33,0.125,0.4775,,0.33,0.12,0.475
,,,0.91,0.88,0.83,0.77,0.8,0.74,0.9,0.87,,,,,,,,,,,,,,,
,,Closed,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.22,0.08 (0.04)/0.22,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,0.08 (0.04)/0.21,,,,,0.08,0.04,0.22,,0.08,0.04,0.215,,0.08,0.04,0.21
,,,0.89,0.86,0.79,0.74,0.77,0.71,0.88,0.85,,,,,,,,,,,,,,,
,,,,,,,,,,,,,,,,,,,,,,,,,
Roller Shades and Insect Screens,,,,,,,,,,,,,,,,,,,,,,,,,
Shade/Screen,Openness,Refl./Trans.,,,,,,,,,,,,,,,,,,,,,,,
Glazing ID:,,,1a,1b,1c,1d,1e,1f,1g,1h,1i,,,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.44, 0.74","0.45, 0.72","0.49, 0.66","0.55, 0.59","0.51, 0.65","0.56, 0.59","0.51, 0.65","0.57, 0.58","0.55, 0.6",,,,0.51,0.045552,,,,,,,,,
White Opaque,0,0.65/0.00,"0.34, 0.45","0.35, 0.44","0.4, 0.41","0.47, 0.38","0.42, 0.4","0.48, 0.38","0.42, 0.4","0.49, 0.38","0.47, 0.38",,,,0.42125,0.053254,,,,,,,,,
Dark Opaque,0,0.20/0.00,"0.64, 0.48","0.65, 0.47","0.67, 0.45","0.69, 0.43","0.67, 0.45","0.7, 0.42","0.67, 0.45","0.7, 0.42","0.69, 0.43",,,,0.67375,0.020578,,,,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.61, 0.57","0.62, 0.57","0.64, 0.54","0.68, 0.51","0.65, 0.53","0.68, 0.5","0.65, 0.53","0.69, 0.5","0.68, 0.51",,,,0.6525,0.027272,,,,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.71, 0.58","0.72, 0.58","0.73, 0.55","0.76, 0.52","0.74, 0.55","0.76, 0.52","0.74, 0.55","0.76, 0.52","0.76, 0.52",,,,0.74,0.018028,,,,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.3, 0.71","0.32, 0.68","0.38, 0.6","0.45, 0.53","0.39, 0.58","0.46, 0.52","0.39, 0.58","0.47, 0.52","0.45, 0.53",,,,0.395,0.058949,,,,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.23, 0.42","0.25, 0.41","0.31, 0.38","0.39, 0.36","0.33, 0.37","0.4, 0.35","0.33, 0.37","0.41, 0.35","0.39, 0.36",,,,0.33125,0.062936,,,,,,,,,
Outdoor Insect Screen,,,"0.64, 0.98","0.64, 0.98","0.64, 0.95","0.64, 0.92","0.64, 0.95","0.64, 0.91","0.64, 0.95","0.64, 0.91","0.64, 0.92",,,,0.64,0,,,,,,,,,
Indoor Insect Screen,,,"0.88, 0.81","0.88, 0.8","0.89, 0.78","0.9, 0.75","0.89, 0.78","0.9, 0.75","0.89, 0.78","0.9, 0.75","0.9, 0.76",,,,0.89125,0.007806,,,,,,,,,
Glazing ID:,,,5a,5b,5c,5d,5e,5f,5g,5h,5i,,,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.55, 0.65","0.58, 0.62","0.58, 0.62","0.63, 0.56","0.58, 0.61","0.64, 0.56","0.58, 0.61","0.64, 0.56","0.63, 0.56",,,,0.5975,0.031918,,,,,,,,,
White Opaque,0,0.65/0.00,"0.48, 0.4","0.52, 0.39","0.51, 0.39","0.57, 0.37","0.52, 0.39","0.58, 0.37","0.52, 0.39","0.58, 0.36","0.57, 0.37",,,,0.535,0.034641,,,,,,,,,
Dark Opaque,0,0.20/0.00,"0.76, 0.44","0.77, 0.43","0.77, 0.43","0.8, 0.41","0.78, 0.43","0.8, 0.41","0.78, 0.43","0.8, 0.4","0.8, 0.41",,,,0.7825,0.01479,,,,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.72, 0.53","0.74, 0.51","0.74, 0.51","0.77, 0.48","0.74, 0.51","0.77, 0.48","0.74, 0.51","0.77, 0.48","0.77, 0.48",,,,0.74875,0.017633,,,,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.81, 0.54","0.82, 0.53","0.82, 0.53","0.84, 0.5","0.82, 0.52","0.84, 0.5","0.82, 0.52","0.84, 0.5","0.84, 0.5",,,,0.82625,0.01111,,,,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.43, 0.6","0.47, 0.55","0.46, 0.56","0.54, 0.5","0.47, 0.55","0.55, 0.49","0.47, 0.55","0.55, 0.49","0.54, 0.5",,,,0.4925,0.043804,,,,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.37, 0.38","0.42, 0.36","0.41, 0.36","0.49, 0.34","0.42, 0.36","0.5, 0.34","0.42, 0.36","0.5, 0.34","0.49, 0.34",,,,0.44125,0.045672,,,,,,,,,
Outdoor Insect Screen,,,"0.64, 0.96","0.64, 0.94","0.64, 0.94","0.64, 0.91","0.64, 0.94","0.64, 0.9","0.64, 0.94","0.64, 0.9","0.64, 0.91",,,,0.64,0,,,,,,,,,
Indoor Insect Screen,,,"0.92, 0.78","0.93, 0.77","0.93, 0.76","0.93, 0.74","0.93, 0.76","0.93, 0.74","0.93, 0.76","0.93, 0.73","0.93, 0.74",,,,0.92875,0.003307,,,,,,,,,
Glazing ID:,,,17a,17b,17c,17d,17e,17f,17g,17h,17i,17j,17k,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.58, 0.63","0.62, 0.6","0.62, 0.6","0.67, 0.56","0.64, 0.58","0.69, 0.53","0.64, 0.57","0.7, 0.53","0.64, 0.57","0.7, 0.53","0.7, 0.53",,0.645,0.037417,,,,,,,,,
White Opaque,0,0.65/0.00,"0.52, 0.39","0.56, 0.38","0.57, 0.38","0.61, 0.37","0.59, 0.37","0.65, 0.36","0.59, 0.37","0.65, 0.36","0.59, 0.37","0.65, 0.35","0.65, 0.36",,0.5925,0.041458,,,,,,,,,
Dark Opaque,0,0.20/0.00,"0.8, 0.43","0.82, 0.42","0.82, 0.42","0.83, 0.41","0.82, 0.41","0.84, 0.39","0.82, 0.41","0.84, 0.39","0.82, 0.41","0.84, 0.39","0.84, 0.39",,0.82375,0.012183,,,,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.76, 0.52","0.78, 0.5","0.78, 0.5","0.8, 0.48","0.78, 0.49","0.81, 0.46","0.79, 0.49","0.82, 0.46","0.79, 0.49","0.82, 0.46","0.81, 0.46",,0.79,0.018028,,,,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.84, 0.53","0.85, 0.52","0.85, 0.52","0.86, 0.5","0.86, 0.51","0.87, 0.48","0.86, 0.51","0.88, 0.48","0.86, 0.51","0.88, 0.48","0.87, 0.48",,0.85875,0.011659,,,,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.46, 0.57","0.52, 0.53","0.52, 0.53","0.58, 0.5","0.54, 0.51","0.61, 0.47","0.55, 0.51","0.62, 0.46","0.55, 0.51","0.62, 0.46","0.61, 0.47",,0.55,0.049244,,,,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.41, 0.37","0.47, 0.36","0.47, 0.36","0.53, 0.35","0.49, 0.35","0.57, 0.34","0.5, 0.35","0.58, 0.34","0.5, 0.35","0.58, 0.33","0.57, 0.34",,0.5025,0.052619,,,,,,,,,
Outdoor Insect Screen,,,"0.64, 0.95","0.64, 0.93","0.64, 0.94","0.64, 0.91","0.64, 0.92","0.64, 0.89","0.64, 0.92","0.64, 0.89","0.64, 0.92","0.64, 0.89","0.64, 0.89",,0.64,0,,,,,,,,,
Indoor Insect Screen,,,"0.94, 0.77","0.94, 0.76","0.94, 0.76","0.94, 0.74","0.94, 0.75","0.95, 0.72","0.94, 0.75","0.95, 0.72","0.94, 0.75","0.95, 0.72","0.95, 0.72",,0.9425,0.00433,,,,,,,,,
Glazing ID:,,,21a,21b,21c,21d,21e,21f,21g,21h,21i,21j,21k,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.61, 0.63","0.65, 0.59","0.66, 0.59","0.69, 0.56","0.68, 0.57","0.72, 0.53","0.68, 0.57","0.72, 0.53","0.68, 0.57","0.72, 0.53","0.72, 0.53",,0.67625,0.034255,,,,,,,,,
White Opaque,0,0.65/0.00,"0.55, 0.39","0.6, 0.38","0.61, 0.38","0.64, 0.37","0.63, 0.37","0.67, 0.36","0.63, 0.37","0.67, 0.36","0.63, 0.37","0.68, 0.35","0.67, 0.36",,0.625,0.036742,,,,,,,,,
Dark Opaque,0,0.20/0.00,"0.82, 0.43","0.84, 0.42","0.84, 0.42","0.85, 0.41","0.85, 0.41","0.86, 0.39","0.85, 0.41","0.86, 0.39","0.85, 0.41","0.86, 0.39","0.86, 0.39",,0.84625,0.012183,,,,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.78, 0.51","0.8, 0.5","0.8, 0.5","0.82, 0.48","0.81, 0.48","0.83, 0.46","0.81, 0.48","0.83, 0.46","0.81, 0.48","0.83, 0.46","0.83, 0.46",,0.81,0.015811,,,,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.86, 0.53","0.87, 0.51","0.87, 0.51","0.88, 0.5","0.88, 0.5","0.89, 0.48","0.88, 0.5","0.89, 0.48","0.88, 0.5","0.89, 0.48","0.89, 0.48",,0.8775,0.009682,,,,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.5, 0.56","0.55, 0.53","0.56, 0.52","0.6, 0.5","0.58, 0.51","0.63, 0.47","0.59, 0.5","0.64, 0.47","0.59, 0.5","0.64, 0.47","0.64, 0.47",,0.58125,0.04226,,,,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.44, 0.36","0.5, 0.35","0.51, 0.35","0.56, 0.35","0.54, 0.35","0.6, 0.34","0.54, 0.35","0.6, 0.34","0.54, 0.35","0.6, 0.33","0.6, 0.34",,0.53625,0.049984,,,,,,,,,
Outdoor Insect Screen,,,"0.64, 0.95","0.64, 0.93","0.64, 0.93","0.64, 0.91","0.64, 0.92","0.64, 0.89","0.64, 0.91","0.64, 0.89","0.64, 0.91","0.64, 0.89","0.64, 0.89",,0.64,0,,,,,,,,,
Indoor Insect Screen,,,"0.94, 0.77","0.95, 0.75","0.95, 0.75","0.95, 0.74","0.95, 0.74","0.95, 0.72","0.95, 0.74","0.95, 0.72","0.95, 0.74","0.95, 0.72","0.95, 0.72",,0.94875,0.003307,,,,,,,,,
Glazing ID:,,,25a,25b,25c,26d,25e,25f,29a,29b,,,,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.66, 0.62","0.71, 0.58","0.75, 0.53","0.75, 0.54","0.77, 0.52","0.74, 0.55","0.64, 0.6","0.68, 0.56",,,,,0.73,0.036055513,,0.66,0.02,,,,,,
White Opaque,0,0.65/0.00,"0.6, 0.38","0.66, 0.37","0.71, 0.35","0.7, 0.36","0.72, 0.35","0.7, 0.36","0.58, 0.38","0.63, 0.37",,,,,0.681666667,0.04099458,,0.605,0.025,,,,,,
Dark Opaque,0,0.20/0.00,"0.85, 0.42","0.86, 0.41","0.88, 0.39","0.88, 0.39","0.88, 0.38","0.87, 0.39","0.82, 0.42","0.84, 0.41",,,,,0.87,0.011547005,,0.83,0.01,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.81, 0.5","0.83, 0.49","0.86, 0.46","0.85, 0.46","0.86, 0.45","0.85, 0.47","0.78, 0.5","0.81, 0.48",,,,,0.843333333,0.017950549,,0.795,0.015,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.88, 0.52","0.89, 0.51","0.9, 0.47","0.9, 0.48","0.91, 0.47","0.9, 0.48","0.86, 0.52","0.87, 0.5",,,,,0.896666667,0.00942809,,0.865,0.005,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.55, 0.55","0.61, 0.52","0.68, 0.47","0.67, 0.48","0.69, 0.46","0.66, 0.48","0.53, 0.53","0.59, 0.49",,,,,0.643333333,0.048876261,,0.56,0.03,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.5, 0.36","0.57, 0.35","0.64, 0.33","0.62, 0.34","0.65, 0.33","0.62, 0.34","0.48, 0.36","0.55, 0.35",,,,,0.6,0.051316014,,0.515,0.035,,,,,,
Outdoor Insect Screen,,,"0.65, 0.95","0.65, 0.93","0.64, 0.88","0.64, 0.89","0.64, 0.87","0.64, 0.89","0.64, 0.94","0.64, 0.91",,,,,0.643333333,0.004714045,,0.64,0,,,,,,
Indoor Insect Screen,,,"0.95, 0.76","0.96, 0.75","0.96, 0.72","0.96, 0.72","0.96, 0.71","0.96, 0.72","0.94, 0.76","0.95, 0.74",,,,,0.958333333,0.00372678,,0.945,0.005,,,,,,
Glazing ID:,,,32a,32b,32c,32d,40a,40b,40c,40d,,,,,,,,,,,,,,,
Light Translucent,0.14,0.60/0.25,"0.67, 0.58","0.72, 0.54","0.67, 0.52","0.71, 0.46","0.7, 0.49","0.75, 0.43","0.69, 0.56","0.74, 0.52",,,,,0.6925,0.022776084,,0.72,0.025495098,,,,,,
White Opaque,0,0.65/0.00,"0.62, 0.37","0.68, 0.36","0.62, 0.33","0.67, 0.3","0.67, 0.31","0.72, 0.29","0.65, 0.37","0.71, 0.36",,,,,0.6475,0.027726341,,0.6875,0.028613808,,,,,,
Dark Opaque,0,0.20/0.00,"0.85, 0.41","0.87, 0.4","0.87, 0.4","0.89, 0.38","0.89, 0.39","0.91, 0.36","0.87, 0.41","0.88, 0.39",,,,,0.87,0.014142136,,0.8875,0.014790199,,,,,,
Light Gray Translucent,0.1,0.31/0.15,"0.81, 0.49","0.84, 0.47","0.82, 0.46","0.85, 0.43","0.85, 0.44","0.87, 0.41","0.83, 0.48","0.85, 0.46",,,,,0.83,0.015811388,,0.85,0.014142136,,,,,,
Dark Gray Translucent,0.14,0.17/0.19,"0.88, 0.51","0.89, 0.49","0.9, 0.49","0.91, 0.45","0.91, 0.47","0.92, 0.43","0.89, 0.5","0.91, 0.48",,,,,0.895,0.01118034,,0.9075,0.010897247,,,,,,
Reflective White Opaque,0,0.84/0.00,"0.57, 0.51","0.64, 0.47","0.57, 0.44","0.63, 0.39","0.61, 0.41","0.68, 0.36","0.6, 0.5","0.67, 0.46",,,,,0.6025,0.032691742,,0.64,0.035355339,,,,,,
Reflective White Translucent,0.07,0.75/0.16,"0.53, 0.35","0.61, 0.34","0.52, 0.28","0.59, 0.26","0.58, 0.27","0.65, 0.25","0.56, 0.35","0.64, 0.34",,,,,0.5625,0.038324274,,0.6075,0.038324274,,,,,,
Outdoor Insect Screen,,,"0.64, 0.92","0.64, 0.9","0.64, 0.86","0.64, 0.8","0.64, 0.83","0.64, 0.77","0.64, 0.91","0.64, 0.88",,,,,0.64,0,,0.64,0,,,,,,
Indoor Insect Screen,,,"0.95, 0.75","0.96, 0.73","0.95, 0.7","0.95, 0.67","0.96, 0.68","0.96, 0.64","0.96, 0.74","0.96, 0.72",,,,,0.9525,0.004330127,,0.96,0,,,,,,
`