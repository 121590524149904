export let AbsorptanceData = `
Outer Layer,Absorptance
"Brick, red",0.63
"Paint, red",0.63
"Paint, black",0.94
"Paint, sandstone",0.5
"Paint, white acrylic",0.26
"Sheet metal, galvanized, new",0.65
"Sheet metal, galvanized, weathered",0.8
"Shingles, gray",0.82
"Shingles, brown",0.91
"Shingles, black",0.97
"Shingles, white",0.75
Concrete,0.72`
