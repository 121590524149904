import { ref, reactive } from 'vue'
import {PI, cos, sin, toRads, arctan, sqrt, log, ln, exp, } from './Math.js'
import { describeFunc, Units, } from './CalcContext.js'
import { makeEnum, lookupData, interpolateInMap, } from './Base.js'

export function calc_q(q_s, q_l) {
  return q_s + q_l;
}

export function calc_q_from_parts(q_A, q_E, q_I) {
  return q_A + q_E + q_I;
}

export function calc_q_A_s(Q, Cs, t_o, t_i) {
  return Q*C_S*(t_o - t_i);
}

export function calc_q_A_l(Q, C_l, W_o, W_i) {
  return Q*C_l*(W_o - W_i);
}

export function calc_Q(Q_I, Q_V) {
  return Q_I + Q_V;
}

export function calc_q_occ_s(N_occ) {
  return 75*N_occ;
}

export function calc_q_occ_l(N_occ) {
  return 41*N_occ;
}

export function calc_q_I_s(A_floor) {
  return 464 + 0.7*A_floor;
}

export function calc_q_I_l(A_floor) {
  return 68 + 0.07*A_floor;
}

export function calc_q_I(q_I_s, q_I_l) {
  return q_I_s + q_I_l;
}

// A: area of the opaque part of the surface
// CF_opq: cooling factor of the opaque surface
export function calc_q_opq(A, CF_opq) {
  return A*CF_opq;
}

export let SurfaceTypeFactors = {
  RoofAdjToAttic: {
    OF_t: 0.62,
    OF_b: (alpha_roof) => { return 25.7*alpha_roof - 8.1; },
    OF_r: -0.19,
  },
  RoofNoAttic: {
    OF_t: 1,
    OF_b: (alpha_roof) => { return 68.9*alpha_roof - 12.6; },
    OF_r: -0.36,
  },
  WallOrDoorNotMostlyShaded: {
    OF_t: 1,
    OF_b: () => 14.8,
    OF_r: -0.36,
  },
  WallOrDoorMostlyShaded: {
    OF_t: 1,
    OF_b: () => 0, 
    OF_r: -0.36,
  },
  FloorRaisedOffGround: {
    OF_t: 1,
    OF_b: () => 0,
    OF_r: -0.06,
  },
  FloorOverCrawlSpace: {
    OF_t: 0.33,
    OF_b: () => 0,
    OF_r: -0.28,
  },
}

// U
// deltaT: t_o - t_i
// DR: Daily Cooling Range (F), from weather data
/*
export function calc_CF_opq(U, deltaT, DR, OF_t, OF_b, OF_r) {
  return U*(OF_t*deltaT + OF_b + OF_r*DR)
}
*/

export let calcCF_opq = describeFunc('calcCF_opq', {
  U: 'UValue',
  t_i: 'F',
  t_o: 'F',
  surfaceType: 'None',
  absorptance: 'None',
  DR: 'F',
}, 'None', (ctx, U, t_i, t_o, surfaceType, absorptance, DR) => {
  let OF_t = lookupData(SurfaceTypeFactors, [surfaceType, 'OF_t'])
  let OF_b = lookupData(SurfaceTypeFactors, [surfaceType, 'OF_b'])(absorptance)
  let OF_r = lookupData(SurfaceTypeFactors, [surfaceType, 'OF_r'])
  return ctx.eval('U*(OF_t*(t_o-t_i) + OF_b + OF_r*DR)',
    {U, t_i, t_o, OF_t, OF_b, OF_r, DR}, 'CF');  
});

// hr_srf: effective surface conductance
export function calc_CF_opq_SlabOnGrade(h_srf) {
  return 0.59 - 2.5*h_srf;
}

// R_cvr: R-value of the floor covering + R-value
// of the floor insulation.
export function calc_h_srf(R_cvr) {
  return 1.0 / (R_cvr + 0.68);
}

export function calc_CF_opq_partition(U, t_b, t_i) {
  return U*(t_b - t_i);
}

// A: area of the transparent part of the surface
export function calc_q_fen(A, CF_fen) {
  return A*CF_fen;
}

export let calc_CF_full = describeFunc('calc_CF_full', {
  U: 'UValue',
  t_o: 'F',
  t_i: 'F',
  DR: 'F',
  PXI: 'None',
  SHGC: 'None',
  IAC: 'None',
  FF_s: 'None'
}, 'None', (ctx, U, t_o, t_i, DR, PXI, SHGC, IAC, FF_s) => {
  return ctx.eval('U*((t_o - t_i) - 0.46*DR) + PXI*SHGC*IAC*FF_s',
    {U, t_o, t_i, DR, PXI, SHGC, IAC, FF_s}, 'CF');
});

// U: U-value of the transparent part of the surface
// PXI: Peak exterior irradiance
// SHGC: SHGC at 0 degrees (direct beam)
// IAC: Interior Attenuation Coefficient
// FF_s: Fenestration Solar Load Factor
export function calc_CF_fen(U, deltaT, DR) {
    return U*(deltaT - 0.46*DR) + PXI*SHGC*IAC*FF_s;
}

// T_x: shading transmission
// E_t: peak total irradiance
export function calc_PXI_methodA(T_x, E_t) {
  return T_x*E_t;
}

// T_x: shading transmission
// E_d: diffuse irradiance
// F_shd: shading factor
// E_D: direct beam irradiance
export function calc_PXI_methodB(T_x, E_d, E_D, F_shd) {
  return T_x*(E_d + (1 - F_shd)*E_D);
}

// SLF: Shade line factor
// D_oh: Depth of overhang (ft)
// X_oh: distance of overhang from window/door (ft)
// h: height of window
export function calc_F_shd(SLF, D_oh, X_oh, h) {
  return Math.min(1, Math.max(0, (SLF * D_oh - X_oh)/h));  
}

// TODO - calculate SLF from the CSV data (see doc)

// Calculating E_t and E_d:
// L: absolute value of latitude (degrees)
// phi: |bigPsi/180|
// bigPsi: degrees (see solar calculation doc)

export function calc_E_t_skylight(ctx, L) {
  L = Math.abs(L);
  return ctx.eval('258.7 + 3.233*L - 0.0572*L^2',
    {L}, 'E_t');
}

export function calc_E_t_window(ctx, phi, L) {
  L = Math.abs(L);
  return ctx.eval('134.2 + 393*phi - 1630*phi^3 + 1011*phi^4 + 11.17*phi*L' +
    ' + 0.0452*phi*L^2 - 4.086*L - 0.1956*L^2 + (0.2473*L^2)/(phi + 1)',
    {phi, L}, 'E_t');
}

export function calc_E_d_skylight(E_t) {
  return Math.min(E_t, 53.9);
}

export function calc_E_d_window(ctx, phi, L) {
  return ctx.eval('112.9 - 11.96*phi^2 + 0.0958*phi*L - 36.744*(L^(1/4.0))/(phi + 1)', {phi, L},
    'E_d')
}

export function calc_E_D(E_t, E_d) {
  return E_t - E_d;
}

// TODO - calc FF_S from the FFsVals.csv

let DuctLossFactors = {
};

// TODO - calculate the duct loss factors

