<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import BasicModal from './BasicModal.vue'

const props = defineProps({
  doneText: {
    default: 'Yes',
  },
});

let modal = ref(null);
let title = ref("");
let body = ref("")
let doneFunc = null;

function showModal(argTitle, argBody, argDoneFunc) {
  title.value = argTitle;
  body.value = argBody;
  doneFunc = argDoneFunc;
  modal.value.showModal();
}

function closeModal() {
  modal.value.closeModal();
}

function onDone() {
  if (doneFunc) {
    doneFunc();
  }
}

function onCancel() {
}

defineExpose({
  showModal, closeModal,
})

</script>

<template>
  <BasicModal ref="modal" doneText="Yes" @onDone="onDone" :title="title">
    <p class="mute-header">Confirm</p>
    <p>{{ body }}</p>
  </BasicModal>
</template>

<style scoped>

</style>
