import { collection, query, where, or, doc, getDoc,
  getDocs, setDoc, addDoc, updateDoc, deleteDoc, } from "firebase/firestore";
import * as ser from './SerUtil.js'
import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import objectHash from 'object-hash'

import { prettyJson, clearArray, valOr, getElemNames,
  getElemWithNameValue,
  addElem, removeElem, elemIn,
  extendArray, extendMap, isObject,
  downloadTextFile,
  runAfterDelay, waitMillis, awaitAll,
  valueOrFuncRes,
} from './SharedUtils.js'

import { ProjectLock } from './ProjectLock.js'

import { gApp, DebugOn } from './Globals.js'

export let ProjectTypes = makeEnum({
  Residential: "Residential",
  Commercial: "Commercial",
})

/**
 * Project base-class
 */
export class Project {
  init(id) {
    this.id = id;
    // This is the project owner id and email
    this.userId = 0;
    this.userEmail = "Unknown";
    this.name = "MyProj";
    this.type = ProjectTypes.Residential;
    this.sharedUsers = [];
    this.lockedBy = null;
    this.lastOpenedTime = Date.now();
    this.saving = false;
    this.lastSaveHash = null;
    this.debugOn = false;
    this.helpPaneId = null;
    this.helpPaneVisible = true;

    this.idsMap = new IdsMap();

    this.projectErrors = null;

    this.checkLockTimer = new IntervalTimer(() => {
      this.updateLockStatus();
    }, 2, {onlyWhenVisible: true})

    this.updateProjectErrorsTimer = new IntervalTimer(() => {
      this.updateProjectErrors();
    }, 1, {onlyWhenVisible: true})

    this.childObjs = '$auto'
  }

  get serFields() {
    return [
      'userId',
      'userEmail',
      'name',
      'type',
      'sharedUsers',
      'lastOpenedTime',
      'debugOn',
      'idsMap',
    ];
  }

  async setupNewProject() {
    // Call this upon creating a new project. Sets some defaults correctly
    console.log("Setting up new project...");
    await this.onSetupNewProject();
  }

  async onSetupNewProject() {
    // Override in subclasses
  }

  isCommercial() {
    return this.type == ProjectTypes.Commercial;
  }

  isResidential() {
    return this.type == ProjectTypes.Residential;
  }

  getBaseRoute() {
    return new Error("Override in subclass");
  }

  makeId(typeName) {
    return this.idsMap.makeId(typeName);
  }

  async close() {
    this.checkLockTimer.stop();
    this.updateProjectErrorsTimer.stop();

    console.log("Closing proj: " + this.name);
    await this.save();

    await ProjectLock.tryReleaseLock(this.id);
  }

  getUiName() {
    return this.name || "Untitled-Project";
  }

  setHelpId(helpId, open) {
    this.helpPaneId = helpId;
    if (valOr(open, true)) {
      this.helpPaneVisible = true;
    }
  }

  setHelpPaneVisible(isVisible) {
    this.helpPaneVisible = isVisible;
  }

  async updateLockStatus() {
    /*
    // TODO - this is for when read+write support comes
    let lockData = await ProjectLock.getLockData(this.id);
    console.log("UPDATING LOCK STATUS: ", lockData);
    this.lockedBy = lockData ? lockData.userEmail : null;
    */
    let curOwner = await ProjectLock.updateOwnership(this.id);
    if (curOwner !== gApp.getUser().email) {
      console.log(`Another user (${curOwner}) booted you from the project!`);
      gApp.router.replace('/');
    }
    this.lockedBy = curOwner
  }

  async save() {
    if (this.saving) {
      console.log("Already saving proj.")
      return;
    }
    this.saving = true;
    try {
      // We only save if something has changed (indicated by a changed
      // hash value). It's cheap to serialize the project, so can check
      // this often.
      // console.log("Checking if save required: " + this.id);

      let debug = true;
      let jsonData = ser.writeToJson(this, {debug: false});
      let hashVal = objectHash(jsonData);
      if (!this.lastSaveHash || hashVal !== this.lastSaveHash) {
        console.log(`Project change detected for Proj ${this.id}. Saving. ${this.lastSaveHash} -> ${hashVal}`);
        if (debug) {
          //console.log("Save data: ", prettyJson(jsonData));
          console.log(`Project data: `, jsonData);
        }

        // Check that user has the lock
        let hasLock = await ProjectLock.hasLock(this.id);
        if (!hasLock) {
          console.log(`Ignoring save. Does not have lock for ${this.id}`);
          return;
        }

        await setDoc(doc(gApp.db, 'projects', this.id), jsonData);
        console.log("Save complete");
        this.lastSaveHash = hashVal;
      }
    } finally {
      this.saving = false;
    }
  }

  async updateLastOpenedTime() {
    console.log("Updating lastOpenedTime");
    this.lastOpenedTime = Date.now();
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      lastOpenedTime: this.lastOpenedTime,
    }) 
  }

  async updateSharedUsers() {
    console.log("Updating sharedUsers");
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      sharedUsers: this.sharedUsers,
    }) 
  }

  addSharedUser(userEmail) {
    console.log("Adding shared user!");
    if (userEmail == this.userEmail) {
      gApp.toast(`${userEmail} can already access the project.`, {type: 'error'})
      return;
    }
    if (!elemIn(userEmail, this.sharedUsers)) {
      this.sharedUsers.push(userEmail);
    }
    this.updateSharedUsers();
    gApp.toast(`Shared the project with: ${userEmail}`)
    gApp.sendUserNotif(userEmail, {type: 'AddedToProject', owner: this.userEmail, name: this.name})
  }

  removeSharedUser(userEmail) {
    removeElem(this.sharedUsers, userEmail);
    this.updateSharedUsers();
    gApp.toast(`Removed ${userEmail} from the project.`);
    gApp.sendUserNotif(userEmail, {type: 'RemovedFromProject', owner: this.userEmail, name: this.name})
  }

  async rename(newName) {
    this.name = newName;
    await updateDoc(doc(gApp.db, 'projects', this.id), {
      name: this.name,
    }) 
    gApp.toast(`Renamed project`)
  }

  static _getChildErrors(parentObj, curPath, errors, visited) {
    if (visited.has(parentObj)) {
      return;
    }
    visited.add(parentObj);

    // console.log(`@'${curPath}'`)
    if (parentObj.getObjErrors) {
      let parentErrors = parentObj.getObjErrors().map((elem) => {
        return {path: curPath, msg: elem};
      });
      extendArray(errors, parentErrors);
    }
    if (parentObj.childObjs == '$none') {
      return;
    } else if (parentObj.childObjs == '$auto') {
      for (const key of Object.keys(parentObj)) {
        let childObj = parentObj[key];
        // console.log(`- ${key} (${typeof childObj})`)
        if (isObject(childObj) && childObj.childObjs && childObj.enabled !== false) {
          let childName = childObj.objInfo ? valueOrFuncRes(childObj.objInfo._name) : key;
          let newPath = childName ? `${curPath ? curPath + '/' : ''}${childName}` : curPath;
          this._getChildErrors(childObj, newPath, errors, visited);
        } else if (Array.isArray(childObj)) {
          // Look in arrays for objects that have 'childObjs'
          for (let i = 0; i < childObj.length; ++i) {
            if (isObject(childObj[i]) && childObj[i].childObjs && childObj.enabled !== false) {
              // let newPath = `${curPath}/${key}#${i + 1}`
              let childName = childObj[i].objInfo ? valueOrFuncRes(childObj[i].objInfo._name) : key;
              let newPath = childName ? `${curPath ? curPath + '/' : ''}${childName} ${i + 1}` : curPath;
              this._getChildErrors(childObj[i], newPath, errors, visited)
            }
          }
        }
      }
    } else {
      throw new Error(`Unexpected value for 'childObjs': '${this.childObjs}'`)
    }
  }
  
  getAllErrors() {
    return this.projectErrors;
  }

  updateProjectErrors() {
    /*
    Note: we run this with a timer instead of making reactive b/c, the way it is setup with probing the objects, it
    will update whenever any field changes in the object hierarchy.
    */
    // Visit child objects hierarchy to collect all errors
    // console.log("===============UPDATING PROJECT ERRORS===============");
    let errors = [];
    Project._getChildErrors(this, '', errors, new Set());
    // console.log("===============DONE===============");
    this.projectErrors = errors.length > 0 ? errors : null;
  }
};
setupClass(Project)