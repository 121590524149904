export const DailyFractionalDBRange = {
  0: 0.88,
  1: 0.92,
  2: 0.95,
  3: 0.98,
  4: 1,
  5: 0.98,
  6: 0.91,
  7: 0.74,
  8: 0.55,
  9: 0.38,
  10: 0.23,
  11: 0.13,
  12: 0.05,
  13: 0,
  14: 0,
  15: 0.06,
  16: 0.14,
  17: 0.24,
  18: 0.39,
  19: 0.5,
  20: 0.59,
  21: 0.68,
  22: 0.75,
  23: 0.82
};