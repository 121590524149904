export let KitchenAppliances_Unhooded_Data = `Appliance,,"Energy Rate, Btu/h",Idle,,Idle,"Rate of Heat Gain, Btu/h",Idle,Idle,Usage Factor FU,Radiation Factor FR
,Cooking,Rated,Standby,,Sensible Radiant,Sensible Convective,Latent,Total,,
"Cabinet; hot serving (large), insulated",N/A,"6,800","1,200",,400,800,0,"1,200",0.18,0.33
"Cabinet; hot serving (large), uninsulate",N/A,"6,800","3,500",,700,"2,800",0,"3,500",0.51,0.2
Cabinet; proofing (large),N/A,"17,400","1,400",,"1,200",0,200,"1,400",0.08,0.86
Cabinet; proofing (small 15-shelf),N/A,"14,300","3,900",,0,900,"3,000","3,900",0.27,0
Cheesemelter,"9,300","8,200","3,300",,"1,500","1,800",0,"3,300",0.41,0.45
Coffee brewing urn,,"13,000","1,200",,200,300,700,"1,200",0.09,0.17
"Drawer warmers, 2-drawer (moist holding)",,"4,100",500,,0,0,200,200,0.12,0
Egg cooker,"4,100","8,100",850,,200,650,0,850,0.1,0.26
Espresso machine,,"8,200","1,200",,400,800,0,"1,200",0.15,0.33
Food warmer: steam table (2-well-type),,"5,100","3,500",,300,600,"2,600","3,500",0.69,0.09
Freezer (small),,"2,700","1,100",,500,600,0,"1,100",0.41,0.45
"Fryer, countertop, open deep fat","13,000","15,700","1,500",,700,800,0,"1,500",0.09,0.47
"Griddle, countertop","11,200","27,300","6,100",,"2,900","3,200",0,"6,100",0.22,0.48
Hot dog roller,"5,400","5,500","4,200",,900,"3,300",0,"4,200",0.77,0.22
Hot plate; single element,"3,400","3,800","3,400",,"1,100","2,300",0,"3,400",0.89,0.32
Hot-food case (dry holding),,"31,100","2,500",,900,"1,600",0,"2,500",0.08,0.36
Hot-food case (moist holding),,"31,100","3,300",,900,"1,800",600,"3,300",0.11,0.27
"Induction hob, countertop","2,200","17,100",0,,0,0,0,0,0,0
Microwave oven: commercial,"8,100","5,800",0,,0,0,0,0,0,0
Oven; countertop conveyorized,"14,600","17,100","13,500",,"2,500","11,000",0,"13,500",0.79,0.18
Panini,"4,700","6,100","2,300",,700,"1,600",0,"2,300",0.37,0.29
Popcorn popper,"2,000","2,900",400,,100,300,0,400,0.14,0.24
Rapid-cook oven (quartz-halogen),,"41,000",0,,0,0,0,0,0,0
Rapid-cook oven (microwave/convection),"7,900","19,400","3,900",,300,"3,600",0,"3,900",0.2,0.08
Reach-in refrigerator,,"4,800","1,200",,300,900,0,"1,200",0.25,0.25
Refrigerated prep table,,"2,000",900,,600,300,0,900,0.45,0.67
Rice cooker,"4,000","5,300",300,,50,250,0,300,0.05,0.17
Soup warmer,"2,900","2,700","1,300",,0,200,"1,100","1,300",0.49,0
Steamer (bun),"2,700","5,100",700,,100,600,0,700,0.13,0.16
"Steamer, countertop","26,400","28,300","1,200",,0,800,400,"1,200",0.04,0
Toaster; 4-slice pop up (large): cooking,,"6,100","3,000",,200,"1,400","1,000","2,600",0.49,0.07
Toaster; contact (vertical),"6,300","8,900","2,600",,600,"2,000",0,"2,600",0.29,0.24
Toaster; large conveyor,,"32,800","10,300",,"3,000","7,300",0,"10,300",0.31,0.29
Toaster; small conveyor,"5,800","6,000","5,800",,"1,200","4,600",0,"5,800",0.98,0.21
Tortilla grill,"7,500","7,500","3,600",,900,"2,700",0,"3,600",0.47,0.25
Waffle iron,"4,000","9,200",900,,200,700,0,900,0.1,0.22`