import { DataTable } from '../DataTable.js'

export let MotorEfficiencyCSV = `Number of Poles,2,2,4,4,6,6,8,8
Motor Type,Enclosed,Open,Enclosed,Open,Enclosed,Open,Enclosed,Open
Motor Horsepower,,,,,,,,
1,77,77,85.5,85.5,82.5,82.5,75.5,75.5
1.5,84,84,86.5,86.5,87.5,86.5,78.5,77
2,85.5,85.5,86.5,86.5,88.5,87.5,84,86.5
3,86.5,85.5,89.5,89.5,89.5,88.5,85.5,87.5
5,88.5,86.5,89.5,89.5,89.5,89.5,86.5,88.5
7.5,89.5,88.5,91.7,91,91,90.2,86.5,89.5
10,90.2,89.5,91.7,91.7,91,91.7,89.5,90.2
15,91,90.2,92.4,93,91.7,91.7,89.5,90.2
20,91,91,93,93,91.7,92.4,90.2,91
25,91.7,91.7,93.6,93.6,93,93,90.2,91
30,91.7,91.7,93.6,94.1,93,93.6,91.7,91.7
40,92.4,92.4,94.1,94.1,94.1,94.1,91.7,91.7
50,93,93,94.5,94.5,94.1,94.1,92.4,92.4
60,93.6,93.6,95,95,94.5,94.5,92.4,93
75,93.6,93.6,95.4,95,94.5,94.5,93.6,94.1
100,94.1,93.6,95.4,95.4,95,95,93.6,94.1
125,95,94.1,95.4,95.4,95,95,94.1,94.1
150,95,94.1,95.8,95.8,95.8,95.4,94.1,94.1
200,95.4,95,96.2,95.8,95.8,95.4,94.5,94.1
250,95.8,95,96.2,95.8,95.8,95.8,95,95
300,95.8,95.4,96.2,95.8,95.8,95.8,NA,NA
350,95.8,95.4,96.2,95.8,95.8,95.8,NA,NA
400,95.8,95.8,96.2,95.8,NA,NA,NA,NA
450,95.8,96.2,96.2,96.2,NA,NA,NA,NA
500,95.8,96.2,96.2,96.2,NA,NA,NA,NA
`

export function GetMotorEfficiencyData() {
  return DataTable.createFromCSV(MotorEfficiencyCSV)
}