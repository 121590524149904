import { ref, reactive } from 'vue'
import {PI, cos, sin, toRads, arctan, sqrt, log, ln, exp, } from './Math.js'
import { describeFunc, Units, } from './CalcContext.js'

export let P_std = 14.69595;

// Note: these are input by the user. Defaults given here
export let RH_winter_indoor = 30
export let RH_summer_indoor = 50

export let RH_winter_outdoor = 50
export let RH_summer_outdoor = 50

export function calcLocalPressure(altFt) {
  // console.log(`Calculating P_loc: ${altFt}`);
  let res = 14.69595*((1 - altFt*6.8754*(10**(-6)))**5.2559);
  // console.log("res: ", res);
  return res;
}

// Same as calcLocalPressure, but will show up in calc logs
export let calcP_loc = describeFunc('calcP_loc', {
  altitude_ft: 'ft',
}, 'psi', (ctx, altitude_ft) => {
  return ctx.eval('P_std*((1 - altitude_ft*6.8754*(10^(-6)))^5.2559)', {
    P_std: P_std,
    altitude_ft: altitude_ft,
  }, 'P_loc');
});

export let adjustForAltitude = describeFunc('adjustForAltitude', {
  value: 'None',
  P_loc: 'psi',
}, 'None', (ctx, value, P_loc) => {
  return ctx.eval('value*(P_loc/P_std)', {
    value: value,
    P_loc: P_loc,
    P_std: P_std,
  }, 'adjusted');
});

export function toCelsius(tempF) {
  return (tempF - 32) * 5/9.0
}

export function toFahr(tempC) {
  return tempC*9/5.0 + 32;
}

export function toRankine(tempF) {
  return tempF + 459.67;
}

// Tdb in C
// RH in [0-100]
// Result in C
export let calcTwb = describeFunc('calcTwb', {
    Tdb: 'C', RH: 'Percent'
  }, 'C', (ctx, Tdb, RH) => {
    return ctx.eval('Tdb * atan(0.151977 * sqrt(RH + 8.313659)) +' +
      'atan(Tdb + RH) - atan(RH - 1.676331) + 0.00391838 * (RH^1.5) * atan(0.023101 * RH) - 4.686035',
      {Tdb, RH}, 'Twb');
});

const C1 = -10214.165
const C2 = -4.8932428
const C3 = -0.005376579
const C4 = 0.00000019202377
const C5 = 3.5575832E-10
const C6 = -9.0344688E-14
const C7 = 4.1635019
const C8 = -10440.397
const C9 = -11.29465
const C10 = -0.027022355
const C11 = 0.00001289036
const C12 = -2.4780681E-09
const C13 = 6.5459673

// Saturation pressure of water
// Twb_R: Twb in R
export let calcPsat_W = describeFunc('calcPsat_W', {
  Twb_R: 'R'}, 'psi',
  (ctx, Twb_R) => {
    let X = ctx.eval('C8/Twb_R + C9 + C10*Twb_R + C11*(Twb_R^2) +' +
      'C12*(Twb_R^3) + C13*ln(Twb_R)', {
        Twb_R, C8, C9, C10, C11, C12, C13, ln,
      }, 'X');
    return ctx.eval('exp(X)', {X}, 'Psat_W');
});

// Saturation pressure of ice
// Twb_R: Twb in R
export let calcPsat_I = describeFunc('calcPsat_I', {
  Twb_R: 'R'}, 'psi',
  (ctx, Twb_R) => {
    let X = ctx.eval('C1/Twb_R + C2 + C3*Twb_R + C4*(Twb_R^2) +' +
      'C5*(Twb_R^3) + C6*(Twb_R^4) + C7*ln(Twb_R)', {
        Twb_R, C1, C2, C3, C4, C5, C6, C7, ln,
    }, 'X');
    return ctx.eval('exp(X)', {X}, 'Psat_I');
});

// Saturation pressure of water
export let calcPws = describeFunc('calcPws', {Twb_F: 'F'}, 'psi',
  (ctx, Twb_F) => {
    if (Twb_F > 32) {
      return ctx.call(calcPsat_W, toRankine(Twb_F));
    }  else {
      return ctx.call(calcPsat_I, toRankine(Twb_F));
    }
});

// Degree of saturation
// RH: [0-100]
export let calcDegSat = describeFunc('calcDegSat', {
    RH: 'Percent', Pws: 'psi', Ps: 'psi',
  }, 'None',
  (ctx, RH, Pws, Ps) => {
    let res = ctx.eval('RH/100.0 * ((1.0 - Pws/Ps) / (1.0 - (RH/100.0)*Pws/Ps))', {
      RH: RH, Pws: Pws, Ps: Ps,
    }, "u");
    return res;
});

// Humidity ratio at saturation
export let calcWs = describeFunc('calcWs', {
    Pws: 'psi', Ps: 'psi',
  }, 'None',
  (ctx, Pws, Ps) => {
    return ctx.eval('0.621945 * Pws/(Ps - Pws)', {
      Pws: Pws, Ps: Ps,
    }, 'Ws')
});

/**
 * Humidity ratio (W)
 */
export let calcHumidityRatio = describeFunc('calcHumidityRatio', {
  t_db_F: 'F',
  t_wb_F: 'F',
  W_s_star: 'None',
}, 'None', (ctx, t_db_F, t_wb_F, W_s_star) => {
  if (t_db_F <= 32) {
    return ctx.eval('((1220 - 0.04*t_wb_F)*W_s_star - 0.24*(t_db_F - t_wb_F))/(1220 + 0.444*t_db_F - 0.48*t_wb_F)', {
      t_db_F: t_db_F,
      t_wb_F: t_wb_F,
      W_s_star: W_s_star,
    }, 'W');
  } else {
    return ctx.eval('((1093 - 0.556*t_wb_F)*W_s_star - 0.24*(t_db_F - t_wb_F))/(1093 + 0.444*t_db_F - t_wb_F)', {
      t_db_F: t_db_F,
      t_wb_F: t_wb_F,
      W_s_star: W_s_star,
    }, 'W');
  }
});

/**
 * Relative humidity (theta)
 * Output: Ratio, [0-1]
 */
export let calcRelativeHumidity = describeFunc('calcRelativeHumidity', {
  // degSat must be in [0-1]
  degSat: 'None',
  p_ws: 'psi',
  p_loc: 'psi',
}, 'Ratio', (ctx, degSat, p_ws, p_loc) => {
  return ctx.eval('degSat / (1-(1-degSat)*(p_ws/p_loc))', {degSat, p_ws, p_loc}, 'RH');
});

export let calcDegSatSimple = describeFunc('calcDegSatSimple', {
  W: 'None',
  Ws: 'None',
}, 'None', (ctx, W, Ws) => {
  return ctx.eval('W/Ws', {W, Ws}, 'u');
});

// t_wb_C: temp in C
// RH: [0-100]
export let calcWFull = describeFunc('calcWFull', {
    t_wb_C: 'C',
    RH: 'Percent',
    Ploc: 'psi',
  }, 'None', (ctx, t_wb_C, RH, Ploc) => {
  let Pws = ctx.call(calcPws, toFahr(t_wb_C));
  let degSat = ctx.call(calcDegSat, RH, Pws, Ploc);
  let Ws = ctx.call(calcWs, Pws, Ploc);
  return ctx.eval('Ws*degSat', {Ws, degSat}, 'W');
});

export let calcWFullKnownTwb = describeFunc('calcWFullKnownTwb', {
  t_db_C: 'C',
  t_wb_C: 'C',
  Ploc: 'psi',
}, 'None', (ctx, t_db_C, t_wb_C, Ploc) => {
  ctx.P_ws_star = ctx.call(calcPws, toFahr(t_wb_C));
  ctx.W_s_star = ctx.call(calcWs, ctx.P_ws_star, Ploc);
  return ctx.eval('((1093-0.556*T_wb)*W_s_star-0.24*(T_db-T_wb)) / (1093+0.444*T_db-T_wb)', {
    T_wb: toFahr(t_wb_C),
    T_db: toFahr(t_db_C),
    W_s_star: ctx.W_s_star,
  }, 'W');
});

// Enthalpy (h)
export let calcI = describeFunc('calcI', {
  Tdb: 'F', W: 'None'}, 'None',
  (ctx, Tdb, W) => {
    return ctx.eval('0.24*Tdb + W*(1061 + 0.444*Tdb)', {
      Tdb: Tdb, W: W,
    }, 'I');
});

export let calcIFull = describeFunc('calcIFull', {
  t_db_F: 'F',
  RH: 'Percent',
  P_loc: 'psi',
}, 'None', (ctx, t_db_F, RH, P_loc) => {
    let t_db_C = toCelsius(t_db_F);
    let t_wb_C = ctx.call(calcTwb, t_db_C, RH);
    let W = ctx.call(calcWFull, t_wb_C, RH, P_loc);
    let I = ctx.call(calcI, t_db_F, W);
    return [I, W];
})

export let calcIFullKnownTwb = describeFunc('calcIFullKnownTwb', {
  t_db_F: 'F',
  t_wb_F: 'F',
  P_loc: 'psi',
}, 'None', (ctx, t_db_F, t_wb_F, P_loc) => {
  let W = ctx.call(calcWFullKnownTwb, toCelsius(t_db_F), toCelsius(t_wb_F), P_loc);
  let I = ctx.call(calcI, t_db_F, W);
  return [I, W];
})

export let DewpointConsts = {
  C_14: 100.45, 
  C_15: 33.193,
  C_16: 2.319,
  C_17: 0.17074,
  C_18: 1.2063,
  C_19: 90.12,
  C_20: 26.142,
  C_21: 0.8927,
}

export let calcDewpointTemp = describeFunc('calcDewpointTemp', {
  t_db_F: 'F',
  W: 'None',
  P_loc: 'psi',
}, 'F', (ctx, t_db_F, W, P_loc) => {
  let p_w = ctx.eval('W*P_loc/(0.621945 + W)', {W, P_loc}, 'p_w');
  let alpha = ctx.eval('ln(p_w)', {p_w, ln}, 'alpha');
  if (t_db_F > 32) {
    return ctx.eval('C_14 + C_15*alpha + C_16*(alpha^2) + C_17*(alpha^3) + C_18*(p_w^(0.1984))', {
      alpha, p_w, ...DewpointConsts,
    }, 't_dp_F');
  } else {
    return ctx.eval('C_19 + C_20*alpha + C_21*(alpha^2)', {
      alpha, p_w, ...DewpointConsts,
    }, 't_dp_F');
  }
});

export let calcSpecificVolume = describeFunc('calcSpecificVolume', {
  t_db_F: 'F',
  W: 'None',
  P_loc: 'psi',
}, 'SpecificVolume', (ctx, t_db_F, W, P_loc) => {
  return ctx.eval('0.370486 * t_db_R * (1 + 1.607858*W) / P_loc', {
    t_db_R: toRankine(t_db_F),
    W: W,
    P_loc: P_loc,
  }, 'v');
})

/**
 * Inputs must contain:
 * - t_db_F: Dry bulb temperature in F
 * - altitude_ft: Altitude in feet
 * - Some of the following:
 * TODO
 */
/*
export let CalculatePsychrometrics = describeFunc('CalculatePsychrometrics', {
  inputs: 'None',
}, 'None', (ctx, inputs) => {
})
*/

export let CalcWithWetBulb = describeFunc('CalcWithWetBulb', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  t_wb_F: 'F',
}, 'None', (ctx, t_db_F, altitude_ft, t_wb_F) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let p_s_star = ctx.call(calcPws, t_wb_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);
  let W_s_star = ctx.call(calcWs, p_s_star, P_loc);
  let W = ctx.call(calcHumidityRatio, t_db_F, t_wb_F, W_s_star);
  let degSat = ctx.call(calcDegSatSimple, W, W_s);
  let RH = ctx.call(calcRelativeHumidity, degSat, p_s, P_loc);
  let t_dp_F = ctx.call(calcDewpointTemp, t_db_F, W, P_loc);
  let v = ctx.call(calcSpecificVolume, t_db_F, W, P_loc);
  let h = ctx.call(calcI, t_db_F, W);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let calcTwbIteratively = describeFunc('calcTwbIteratively', {
  t_db_F: 'F',
  W: 'None',
  P_loc: 'psi',
}, 'F', (ctx, t_db_F, W, P_loc) => {
  // Bi-section method to find t_wb_F
  let t_wb_F_cur = t_db_F;
  let t_wb_F_prev = null;
  let C_prev = null;
  let maxIters = 20;
  for (let i = 0; i < maxIters; ++i) {
    let p_ws_star = ctx.call(calcPws, t_wb_F_cur);
    let W_s_star = ctx.call(calcWs, p_ws_star, P_loc);
    let W_test = ctx.call(calcHumidityRatio, t_db_F, t_wb_F_cur, W_s_star);
    let C = ctx.eval('W_test - W', {W, W_test}, 'C');
    if (i == 0) {
      t_wb_F_prev = t_wb_F_cur;
      C_prev = C;
      t_wb_F_cur = t_wb_F_cur - 10;
    } else {
      if (C_prev*C < 0) {
        let t_wb_F_next = ctx.eval('t_wb_F_cur - (t_wb_F_cur - t_wb_F_prev)/2', {
          t_wb_F_cur: t_wb_F_cur,
          t_wb_F_prev: t_wb_F_prev,
        }, 't_wb_F_next')
        t_wb_F_prev = t_wb_F_cur;
        t_wb_F_cur = t_wb_F_next;
        C_prev = C;
      } else if (C_prev*C > 0) {
        let t_wb_F_next = ctx.eval('t_wb_F_cur - (t_wb_F_prev - t_wb_F_cur)', {
          t_wb_F_cur: t_wb_F_cur,
          t_wb_F_prev: t_wb_F_prev,
        }, 't_wb_F_next')
        t_wb_F_prev = t_wb_F_cur;
        t_wb_F_cur = t_wb_F_next;
        C_prev = C;
      } else if (C_prev*C == 0) {
        break;
      }
      if (Math.abs(t_wb_F_cur - t_wb_F_prev) < 0.01) {
        break;
      }
    }
  }

  return t_wb_F_cur;
});

export let CalcWithRelativeHumidity = describeFunc('CalcWithRelativeHumidity', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  // RH in [0-1]
  RH: 'Ratio',
}, 'None', (ctx, t_db_F, altitude_ft, RH) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);

  // Humidity ratio:
  let degSat = ctx.eval('RH*(1 - p_s/P_loc) / (1 - RH*p_s/P_loc)', {
    RH: RH,
    p_s: p_s,
    P_loc: P_loc,
  }, 'degSat');
  let W = ctx.eval('degSat*W_s', {degSat, W_s}, 'W');

  let t_wb_F = ctx.call(calcTwbIteratively, t_db_F, W, P_loc);

  let t_dp_F = ctx.call(calcDewpointTemp, t_db_F, W, P_loc);
  let v = ctx.call(calcSpecificVolume, t_db_F, W, P_loc);
  let h = ctx.call(calcI, t_db_F, W);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let CalcWithHumidityRatio = describeFunc('CalcWithHumidityRatio', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  W: 'None',
}, 'None', (ctx, t_db_F, altitude_ft, W) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);

  // Humidity ratio:
  let degSat = ctx.call(calcDegSatSimple, W, W_s);
  let RH = ctx.call(calcRelativeHumidity, degSat, p_s, P_loc);

  let t_wb_F = ctx.call(calcTwbIteratively, t_db_F, W, P_loc);

  let t_dp_F = ctx.call(calcDewpointTemp, t_db_F, W, P_loc);
  let v = ctx.call(calcSpecificVolume, t_db_F, W, P_loc);
  let h = ctx.call(calcI, t_db_F, W);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let CalcWithDewpointTemp = describeFunc('CalcWithDewpointTemp', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  t_dp_F: 'F',
}, 'None', (ctx, t_db_F, altitude_ft, t_dp_F) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);

  // Humidity ratio:
  let p_ws_dp = ctx.call(calcPws, t_dp_F);
  let W = ctx.eval('0.621945 * p_ws_dp / (P_loc - p_ws_dp)', {p_ws_dp, P_loc}, 'W');

  let t_wb_F = ctx.call(calcTwbIteratively, t_db_F, W, P_loc);
  let degSat = ctx.call(calcDegSatSimple, W, W_s);
  let RH = ctx.call(calcRelativeHumidity, degSat, p_s, P_loc);

  let v = ctx.call(calcSpecificVolume, t_db_F, W, P_loc);
  let h = ctx.call(calcI, t_db_F, W);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let CalcWithSpecificVolume = describeFunc('CalcWithSpecificVolume', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  v: 'SpecificVolume',
}, 'None', (ctx, t_db_F, altitude_ft, v) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);

  // Humidity ratio:
  let W = ctx.eval('(1/0.370486)*(v*P_loc)/t_db_R', {v, P_loc, t_db_R: toRankine(t_db_F)}, 'W');

  let t_wb_F = ctx.call(calcTwbIteratively, t_db_F, W, P_loc);
  let degSat = ctx.call(calcDegSatSimple, W, W_s);
  let RH = ctx.call(calcRelativeHumidity, degSat, p_s, P_loc);

  let t_dp_F = ctx.call(calcDewpointTemp, t_db_F, W, P_loc);
  let h = ctx.call(calcI, t_db_F, W);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let CalcWithEnthalpy = describeFunc('CalcWithEnthalpy', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  h: 'None',
}, 'None', (ctx, t_db_F, altitude_ft, h) => {
  let P_loc = ctx.call(calcP_loc, altitude_ft);
  let p_s = ctx.call(calcPws, t_db_F);
  let W_s = ctx.call(calcWs, p_s, P_loc);

  // Humidity ratio:
  let W = ctx.eval('(h - 0.24*t_db_F) / (1061 + 0.444*t_db_F)', {h, t_db_F}, 'W');

  let t_wb_F = ctx.call(calcTwbIteratively, t_db_F, W, P_loc);
  let degSat = ctx.call(calcDegSatSimple, W, W_s);
  let RH = ctx.call(calcRelativeHumidity, degSat, p_s, P_loc);

  let t_dp_F = ctx.call(calcDewpointTemp, t_db_F, W, P_loc);
  let v = ctx.call(calcSpecificVolume, t_db_F, W, P_loc);

  return {
    t_wb_F: t_wb_F,    
    RH: RH,
    W: W,
    t_dp_F: t_dp_F,
    v: v,
    h: h,
  };
})

export let PsyCalcMethod = {
  CalcWithWetBulbTemp: 'CalcWithWetBulbTemp',
  CalcWithRelativeHumidity: 'CalcWithRelativeHumidity',
  CalcWithHumidityRatio: 'CalcWithHumidityRatio',
  CalcWithDewpointTemp: 'CalcWithDewpointTemp',
  CalcWithSpecificVolume: 'CalcWithSpecificVolume',
  CalcWithEnthalpy: 'CalcWithEnthalpy',
};

export let CalcPsychrometrics = describeFunc('CalcPsychrometrics', {
  t_db_F: 'F',
  altitude_ft: 'ft',
  method: 'None',
  inputs: 'None',
}, 'None', (ctx, t_db_F, altitude_ft, method, inputs) => {
  if (method == PsyCalcMethod.CalcWithWetBulbTemp) {
    return ctx.call(CalcWithWetBulb, t_db_F, altitude_ft, inputs.t_wb_F);
  } else if (method == PsyCalcMethod.CalcWithRelativeHumidity) {
    return ctx.call(CalcWithRelativeHumidity, t_db_F, altitude_ft, inputs.RH);
  } else if (method == PsyCalcMethod.CalcWithHumidityRatio) {
    return ctx.call(CalcWithHumidityRatio, t_db_F, altitude_ft, inputs.W);
  } else if (method == PsyCalcMethod.CalcWithDewpointTemp) {
    return ctx.call(CalcWithDewpointTemp, t_db_F, altitude_ft, inputs.t_dp_F);
  } else if (method == PsyCalcMethod.CalcWithSpecificVolume) {
    return ctx.call(CalcWithSpecificVolume, t_db_F, altitude_ft, inputs.v);
  } else if (method == PsyCalcMethod.CalcWithEnthalpy) {
    return ctx.call(CalcWithEnthalpy, t_db_F, altitude_ft, inputs.h);
  } else {
    throw new Error(`Unknown method: ${method}`);
  }
})
