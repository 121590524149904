export let InsulatingMaterials = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
"Glass-fiber batt, low density",0.65,0.3,�,0.2,1
"Glass-fiber batt, high density",1.4,0.23,�,0.2,1
Rock and slag wool batt,2.4,0.25,�,0.2,1
"Mineral wool, felted",4,0.26,�,0.2,1
Cellular glass board,7.5,0.29,�,0.2,1
"Cement fiber slabs, shredded wood with Portland cement binder",24,0.54,�,0.2,1
Glass fiber board,4,0.22,�,0.2,1
Expanded rubber (rigid),4,0.2,�,0.4,1
Extruded polystyrene,2.2,0.2,�,0.35,1
Expanded polystyrene,1.4,0.25,�,0.35,1
"Mineral fiberboard, wet felted",10,0.26,�,0.2,1
"Rock wool board, (floors/walls)",6,0.24,�,0.2,1
"Rock wool board, (roofing)",10.5,0.28,�,0.2,1
Acoustical tile,22,0.365,�,0.165,1
Perlite board,9,0.36,�,0.165,1
Polyisocyanurate,1.95,0.16,�,0.35,1
Phenolic foam board with facers,0.195,0.15,�,0.35,1
Cellulose fiber,2,0.3,�,0.33,1
"Perlite, expanded",6,0.34,�,0.26,1
"Glass fiber, attic",0.5,0.36,�,0.26,1
"Glass fiber, closed attic or wall cavities",2.05,0.255,�,0.26,1
"Rock and slag wool, attics",1.6,0.33,�,0.26,1
"Rock and slag wool, closed attic or wall cavities",4,0.28,�,0.26,1
"Vermiculite, exfoliated",6,0.455,�,0.32,1
"Cellulose, sprayed into open wall cavities",2.2,0.275,�,0.32,1
"Glass fiber, sprayed into open wall or attic cavities",1.7,0.26,�,0.32,1
"Polyurethane foam, open cell",0.55,0.275,�,0.35,1
"Polyurethane foam, closed cell",2.55,0.17,�,0.35,1`
