import {WallMaterials} from './Wall Materials.js'
import {Roofing} from './Roofing.js'
import {BuildingBoardAndSiding} from './Building Board and Siding.js'
import {InsulatingMaterials} from './Insulating Materials.js'
import {MasonryMaterials} from './Masonry Materials.js'
import {PlasteringMaterials} from './Plastering Materials.js'
import {Woods} from './Woods.js'
import {makeEnumWithDataAndLabels} from '../../Base.js'

import {AbsorptanceData} from './Absorptance Data.js'

import Papa from 'papaparse';

function parseSimpleTable(rawCsv) {
  let csv = Papa.parse(rawCsv);
  let objs = [];
  let colNames = csv.data[1];
  for (let row = 2; row < csv.data.length; ++row) {
    let rowData = csv.data[row];
    let obj = {};
    for (let col = 0; col < rowData.length; ++col) {
      obj[colNames[col]] = rowData[col];
    }
    objs.push(obj);
  }
  return objs;
}

export class WallAndRoofData {
  static _instance = null;

  static instance() {
    if (!this._instance) {
      this._instance = new WallAndRoofData();
    }
    return this._instance;
  }

  constructor() {
    this.Defaults = [
      { 'Material': 'Outdoor Surface Resistance',
        'Resistance': 0.25,
        'Thickness': null,
        'Density': 0,
        'Thickness Required': '0',
      },
      {
        'Material': 'Indoor Layer Air Resistance',
        'Resistance': 0.68,
        'Thickness': null,
        'Density': 0,
        'Thickness Required': '0',
      }
    ];
    this.WallMaterials = parseSimpleTable(WallMaterials);
    this.Roofing = parseSimpleTable(Roofing);
    this.BuildingBoardAndSiding = parseSimpleTable(BuildingBoardAndSiding);
    this.InsulatingMaterials = parseSimpleTable(InsulatingMaterials);
    this.MasonryMaterials = parseSimpleTable(MasonryMaterials);
    this.PlasteringMaterials = parseSimpleTable(PlasteringMaterials);
    this.Woods = parseSimpleTable(Woods);
    this.wallDataMap = {
      'Wall Materials': this.WallMaterials,
      'Roofing': this.Roofing,
      'Building Board and Siding': this.BuildingBoardAndSiding,
      'Insulating Materials': this.InsulatingMaterials,
      'Masonry Materials': this.MasonryMaterials,
      'Plastering Materials': this.PlasteringMaterials,
      'Woods': this.Woods,
      'Defaults': this.Defaults,
    }

    this.AbsorptanceData = parseSimpleTable(AbsorptanceData);

    let materials = {};
    for (const row of this.AbsorptanceData) {
      let name = row['Outer Layer'];
      let absorptance = row['Absorptance'];
      materials[name] = {
        label: `${name} (${absorptance})`,
        value: Number(absorptance),
      }
    }
    this.MaterialAbsorptance = makeEnumWithDataAndLabels(materials);
  }

  getWallLayerCategories() {
    return Object.keys(this.wallDataMap);
  }

  getWallLayerTypes(categoryName) {
    if (!this.wallDataMap[categoryName]) {
      return [];
    }
    let materialNames = [];
    for (const row of this.wallDataMap[categoryName]) {
      materialNames.push(row.Material);
    }
    return materialNames;
  }

  lookupWallMaterial(categoryName, materialName) {
    let table = this.wallDataMap[categoryName];
    if (!table) {
      return null;
    }
    return table.find((elem) => elem.Material == materialName);
  }
};

