<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import FieldInput from './FieldInput.vue'

const props = defineProps({
  modelValue: [String, Number, Object],
  labelWidth: {
    type: Number,
    default: 250,
  },
})
const emit = defineEmits(['update:modelValue'])

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

</script>

<template>
  <div class="">
    <div class="InputLabel">{{value.name}}</div>
    <div v-for="(field, i) in value.fields" :key="field.key">
      <FieldInput v-model="value.fields[i]" :labelWidth="labelWidth" />
    </div>
  </div>
</template>

<style scoped>
</style>
