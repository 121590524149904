<script setup>
import { ref, onMounted, reactive, computed } from 'vue'

const props = defineProps({
  item: Object,
})

let isOpen = ref(false);

</script>

<template>
  <div class="LogItem">
    <button class="CollapseBtn" @click="isOpen = !isOpen">{{item.name}}</button>
    <div v-if="isOpen" class="SubItems">
      <div v-for="(subItem, i) in item.log" class="SubItem">
        <p v-if="typeof subItem === 'string'" class="DebugLog">{{subItem}}</p>
        <CalcLogItem v-else :item="subItem" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.SubItems {
  /* padding-left: 8px; */
  padding: 8px;
  border: 1px solid #777;
}

.SubItem {
  margin-bottom: 8px;
}

.CollapseBtn {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: var(--space-xxs);
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  /* font-size: 22px; */
}

.CollapseBtn:hover {
  background-color: #555;
}

.DebugLog {
  white-space: pre;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
  padding: 4px;
  background-color: rgb(200, 200, 200);
}


</style>
