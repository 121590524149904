export let SHGCVals = `
1a,,0.13,CLR,0.9,SHGC,,0.86,0.84,0.82,0.78,0.67,0.42,0.78,,0.78,0.79,0.7,0.76,,0.8,0.81,0.72,0.79
,,,,,T,,0.83,0.82,0.8,0.75,0.64,0.39,0.75,,,,,,,,,,
,,,,,R  f,,0.08,0.08,0.1,0.14,0.25,0.51,0.14,,,,,,,,,,
,,,,,R b,,0.08,0.08,0.1,0.14,0.25,0.51,0.14,,,,,,,,,,
,,,,,,,0.09,0.1,0.1,0.11,0.11,0.11,0.1,,,,,,,,,,
1b,,0.25,CLR,0.88,SHGC,,0.81,0.8,0.78,0.73,0.62,0.39,0.73,,0.74,0.74,0.66,0.72,,0.78,0.79,0.7,0.77
,,,,,T,,0.77,0.75,0.73,0.68,0.58,0.35,0.69,,,,,,,,,,
,,,,,R  f,,0.07,0.08,0.09,0.13,0.24,0.48,0.13,,,,,,,,,,
,,,,,R b,,0.07,0.08,0.09,0.13,0.24,0.48,0.13,,,,,,,,,,
,,,,,,,0.16,0.17,0.18,0.19,0.19,0.17,0.17,,,,,,,,,,
1c,,0.13,BRZ,0.68,SHGC,,0.73,0.71,0.68,0.64,0.55,0.34,0.65,,0.67,0.67,0.59,0.65,,0.61,0.61,0.54,0.6
,,,,,T,,0.65,0.62,0.59,0.55,0.46,0.27,0.56,,,,,,,,,,
,,,,,R  f,,0.06,0.07,0.08,0.12,0.22,0.45,0.12,,,,,,,,,,
,,,,,R b,,0.06,0.07,0.08,0.12,0.22,0.45,0.12,,,,,,,,,,
,,,,,,,0.29,0.31,0.32,0.33,0.33,0.29,0.31,,,,,,,,,,
1d,,0.25,BRZ,0.54,SHGC,,0.62,0.59,0.57,0.53,0.45,0.29,0.54,,0.57,0.57,0.5,0.55,,0.48,0.49,0.43,0.48
,,,,,T,,0.49,0.45,0.43,0.39,0.32,0.18,0.41,,,,,,,,,,
,,,,,R  f,,0.05,0.06,0.07,0.11,0.19,0.42,0.1,,,,,,,,,,
,,,,,R b,,0.05,0.68,0.66,0.62,0.53,0.33,0.1,,,,,,,,,,
,,,,,,,0.46,0.49,0.5,0.51,0.49,0.41,0.48,,,,,,,,,,
1e,,0.13,GRN,0.82,SHGC,,0.7,0.68,0.66,0.62,0.53,0.33,0.63,,0.64,0.64,0.57,0.62,,0.73,0.74,0.66,0.72
,,,,,T,,0.61,0.58,0.56,0.52,0.43,0.25,0.53,,,,,,,,,,
,,,,,R  f,,0.06,0.07,0.08,0.12,0.21,0.45,0.11,,,,,,,,,,
,,,,,R b,,0.06,0.07,0.08,0.12,0.21,0.45,0.11,,,,,,,,,,
,,,,,,,0.33,0.35,0.36,0.37,0.36,0.31,0.35,,,,,,,,,,
1f,,0.25,GRN,0.76,SHGC,,0.6,0.58,0.56,0.52,0.45,0.29,0.54,,0.55,0.55,0.49,0.53,,0.68,0.68,0.61,0.67
,,,,,T,,0.47,0.44,0.42,0.38,0.32,0.18,0.4,,,,,,,,,,
,,,,,R  f,,0.05,0.06,0.07,0.11,0.2,0.42,0.1,,,,,,,,,,
,,,,,R b,,0.05,0.06,0.07,0.11,0.2,0.42,0.1,,,,,,,,,,
,,,,,,,0.47,0.5,0.51,0.51,0.49,0.4,0.49,,,,,,,,,,
1g,,0.13,GRY,0.62,SHGC,,0.7,0.68,0.66,0.61,0.53,0.33,0.63,,0.64,0.64,0.57,0.62,,0.55,0.56,0.5,0.55
,,,,,T,,0.61,0.58,0.56,0.51,0.42,0.24,0.53,,,,,,,,,,
,,,,,R  f,,0.06,0.07,0.08,0.12,0.21,0.44,0.11,,,,,,,,,,
,,,,,R b,,0.06,0.07,0.08,0.12,0.21,0.44,0.11,,,,,,,,,,
,,,,,,,0.33,0.36,0.37,0.37,0.37,0.32,0.35,,,,,,,,,,
1h,,0.25,GRY,0.46,SHGC,,0.59,0.57,0.55,0.51,0.44,0.28,0.52,,0.54,0.54,0.48,0.52,,0.41,0.41,0.37,0.4
,,,,,T,,0.46,0.42,0.4,0.36,0.29,0.16,0.38,,,,,,,,,,
,,,,,R  f,,0.05,0.06,0.07,0.1,0.19,0.41,0.1,,,,,,,,,,
,,,,,R b,,0.05,0.06,0.07,0.1,0.19,0.41,0.1,,,,,,,,,,
,,,,,,,0.49,0.52,0.54,0.54,0.52,0.43,0.51,,,,,,,,,,
1i,,0.25,BLUGRN,0.75,SHGC,,0.62,0.59,0.57,0.54,0.46,0.3,0.55,,0.57,0.57,0.5,0.55,,0.67,0.68,0.6,0.66
,,,,,T,,0.49,0.46,0.44,0.4,0.33,0.19,0.42,,,,,,,,,,
,,,,,R  f,,0.06,0.06,0.07,0.11,0.2,0.43,0.11,,,,,,,,,,
,,,,,R b,,0.06,0.06,0.07,0.11,0.2,0.43,0.11,,,,,,,,,,
,,,,,,,0.45,0.48,0.49,0.49,0.47,0.38,0.48,,,,,,,,,,
1j,,0.25,SS on CLR 8%,0.08,SHGC,,0.19,0.19,0.19,0.18,0.16,0.1,0.18,,0.18,0.18,0.16,0.17,,0.07,0.07,0.06,0.07
,,,,,T,,0.06,0.06,0.06,0.05,0.04,0.03,0.05,,,,,,,,,,
,,,,,R  f,,0.33,0.34,0.35,0.37,0.44,0.61,0.36,,,,,,,,,,
,,,,,R b,,0.5,0.5,0.51,0.53,0.58,0.71,0.52,,,,,,,,,,
,,,,,,,0.61,0.61,0.6,0.58,0.52,0.37,0.57,,,,,,,,,,
1k,,0.25,SS on CLR 14%,0.14,SHGC,,0.25,0.25,0.24,0.23,0.2,0.13,0.23,,0.24,0.24,0.21,0.22,,0.12,0.13,0.11,0.12
,,,,,T,,0.11,0.1,0.1,0.09,0.07,0.04,0.09,,,,,,,,,,
,,,,,R  f,,0.26,0.27,0.28,0.31,0.38,0.57,0.3,,,,,,,,,,
,,,,,R b,,0.44,0.44,0.45,0.47,0.52,0.67,0.46,,,,,,,,,,
,,,,,,,0.63,0.63,0.62,0.6,0.55,0.39,0.6,,,,,,,,,,
1l,,0.25,SS on CLR 20%,0.2,SHGC,,0.31,0.3,0.3,0.28,0.24,0.16,0.28,,0.29,0.29,0.26,0.28,,0.18,0.18,0.16,0.18
,,,,,T,,0.15,0.15,0.14,0.13,0.11,0.06,0.13,,,,,,,,,,
,,,,,R  f,,0.21,0.22,0.23,0.26,0.34,0.54,0.25,,,,,,,,,,
,,,,,R b,,0.38,0.38,0.39,0.41,0.48,0.64,0.41,,,,,,,,,,
,,,,,,,0.64,0.64,0.63,0.61,0.56,0.4,0.6,,,,,,,,,,
1m,,0.25,SS on GRN 14%,0.12,SHGC,,0.25,0.25,0.24,0.23,0.21,0.14,0.23,,0.24,0.24,0.21,0.22,,0.11,0.11,0.1,0.11
,,,,,T,,0.06,0.06,0.06,0.06,0.04,0.03,0.06,,,,,,,,,,
,,,,,R  f,,0.14,0.14,0.16,0.19,0.27,0.49,0.18,,,,,,,,,,
,,,,,R b,,0.44,0.44,0.45,0.47,0.52,0.67,0.46,,,,,,,,,,
,,,,,,,0.8,0.8,0.78,0.76,0.68,0.48,0.75,,,,,,,,,,
1n,,0.25,TI on CLR 20%,0.2,SHGC,,0.29,0.29,0.28,0.27,0.23,0.15,0.27,,0.27,0.27,0.24,0.26,,0.18,0.18,0.16,0.18
,,,,,T,,0.14,0.13,0.13,0.12,0.09,0.06,0.12,,,,,,,,,,
,,,,,R  f,,0.22,0.22,0.24,0.26,0.34,0.54,0.26,,,,,,,,,,
,,,,,R b,,0.4,0.4,0.42,0.44,0.5,0.65,0.43,,,,,,,,,,
,,,,,,,0.65,0.65,0.64,0.62,0.57,0.4,0.62,,,,,,,,,,
1o,,0.25,TI on CLR 30%,0.3,SHGC,,0.39,0.38,0.37,0.35,0.3,0.2,0.35,,0.36,0.36,0.32,0.35,,0.27,0.27,0.24,0.26
,,,,,T,,0.23,0.22,0.21,0.19,0.16,0.09,0.2,,,,,,,,,,
,,,,,R  f,,0.15,0.15,0.17,0.2,0.28,0.5,0.19,,,,,,,,,,
,,,,,R b,,0.32,0.33,0.34,0.36,0.43,0.6,0.36,,,,,,,,,,
,,,,,,,0.63,0.65,0.64,0.62,0.57,0.4,0.62,,,,,,,,,,
Uncoated Double Glazing,,,,,,,,,,,,,,,,,,,,,,,
5a,,0.13,CLR CLR,0.81,SHGC,,0.76,0.74,0.71,0.64,0.5,0.26,0.66,,0.69,0.7,0.62,0.67,,0.72,0.73,0.65,0.71
,,,,,T,,0.7,0.68,0.65,0.58,0.44,0.21,0.6,,,,,,,,,,
,,,,,R  f,,0.13,0.14,0.16,0.23,0.36,0.61,0.21,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.23,0.36,0.61,0.21,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.12,0.13,0.13,0.11,,,,,,,,,,
,,,,,,,0.07,0.08,0.08,0.08,0.07,0.05,0.07,,,,,,,,,,
5b,,0.25,CLR CLR,0.78,SHGC,,0.7,0.67,0.64,0.58,0.45,0.23,0.6,,0.64,0.64,0.57,0.62,,0.69,0.7,0.62,0.69
,,,,,T,,0.61,0.58,0.55,0.48,0.36,0.17,0.51,,,,,,,,,,
,,,,,R  f,,0.11,0.12,0.15,0.2,0.33,0.57,0.18,,,,,,,,,,
,,,,,R b,,0.11,0.12,0.15,0.2,0.33,0.57,0.18,,,,,,,,,,
,,,,,,,0.17,0.18,0.19,0.2,0.21,0.2,0.19,,,,,,,,,,
,,,,,,,0.11,0.12,0.12,0.12,0.1,0.07,0.11,,,,,,,,,,
5c,,0.13,BRZ CLR,0.62,SHGC,,0.62,0.6,0.57,0.51,0.39,0.2,0.53,,0.57,0.57,0.5,0.55,,0.55,0.56,0.5,0.55
,,,,,T,,0.55,0.51,0.48,0.42,0.31,0.14,0.45,,,,,,,,,,
,,,,,R  f,,0.09,0.1,0.12,0.16,0.27,0.49,0.15,,,,,,,,,,
,,,,,R b,,0.12,0.13,0.15,0.21,0.35,0.59,0.19,,,,,,,,,,
,,,,,,,0.3,0.33,0.34,0.36,0.37,0.34,0.33,,,,,,,,,,
,,,,,,,0.06,0.06,0.06,0.06,0.05,0.03,0.06,,,,,,,,,,
5d,,0.25,BRZ CLR,0.47,SHGC,,0.49,0.46,0.44,0.39,0.31,0.17,0.41,,0.45,0.45,0.4,0.43,,0.42,0.42,0.38,0.41
,,,,,T,,0.38,0.35,0.32,0.27,0.2,0.08,0.3,,,,,,,,,,
,,,,,R  f,,0.07,0.08,0.09,0.13,0.22,0.44,0.12,,,,,,,,,,
,,,,,R b,,0.1,0.11,0.13,0.19,0.31,0.55,0.17,,,,,,,,,,
,,,,,,,0.48,0.51,0.52,0.53,0.53,0.45,0.5,,,,,,,,,,
,,,,,,,0.07,0.07,0.07,0.07,0.06,0.04,0.07,,,,,,,,,,
5e,,0.13,GRN CLR,0.75,SHGC,,0.6,0.57,0.54,0.49,0.38,0.2,0.51,,0.55,0.55,0.49,0.53,,0.67,0.68,0.6,0.66
,,,,,T,,0.52,0.49,0.46,0.4,0.3,0.13,0.43,,,,,,,,,,
,,,,,R  f,,0.09,0.1,0.12,0.16,0.27,0.5,0.15,,,,,,,,,,
,,,,,R b,,0.12,0.13,0.15,0.21,0.35,0.6,0.19,,,,,,,,,,
,,,,,,,0.34,0.37,0.38,0.39,0.39,0.35,0.37,,,,,,,,,,
,,,,,,,0.05,0.05,0.05,0.04,0.04,0.03,0.04,,,,,,,,,,
5f,,0.25,GRN CLR,0.68,SHGC,,0.49,0.46,0.44,0.39,0.31,0.17,0.41,,0.45,0.45,0.4,0.43,,0.61,0.61,0.54,0.6
,,,,,T,,0.39,0.36,0.33,0.29,0.21,0.09,0.31,,,,,,,,,,
,,,,,R  f,,0.08,0.08,0.1,0.14,0.23,0.45,0.13,,,,,,,,,,
,,,,,R b,,0.1,0.11,0.13,0.19,0.31,0.55,0.17,,,,,,,,,,
,,,,,,,0.49,0.51,0.05,0.53,0.52,0.43,0.5,,,,,,,,,,
,,,,,,,0.05,0.05,0.05,0.05,0.04,0.03,0.05,,,,,,,,,,
5g,,0.13,GRY CLR,0.56,SHGC,,0.6,0.57,0.54,0.48,0.37,0.2,0.51,,0.55,0.55,0.49,0.53,,0.5,0.5,0.45,0.49
,,,,,T,,0.51,0.48,0.45,0.39,0.29,0.12,0.42,,,,,,,,,,
,,,,,R  f,,0.09,0.09,0.11,0.16,0.26,0.48,0.14,,,,,,,,,,
,,,,,R b,,0.12,0.13,0.15,0.21,0.34,0.59,0.19,,,,,,,,,,
,,,,,,,0.34,0.37,0.39,0.4,0.41,0.37,0.37,,,,,,,,,,
,,,,,,,0.05,0.06,0.06,0.05,0.05,0.03,0.05,,,,,,,,,,
5h,,0.25,GRY CLR,0.41,SHGC,,0.47,0.44,0.42,0.37,0.29,0.16,0.39,,0.43,0.43,0.38,0.42,,0.36,0.37,0.33,0.36
,,,,,T,,0.36,0.32,0.29,0.25,0.18,0.07,0.28,,,,,,,,,,
,,,,,R  f,,0.07,0.07,0.08,0.12,0.21,0.43,0.12,,,,,,,,,,
,,,,,R b,,0.1,0.11,0.13,0.18,0.31,0.55,0.17,,,,,,,,,,
,,,,,,,0.51,0.54,0.56,0.57,0.56,0.47,0.53,,,,,,,,,,
,,,,,,,0.07,0.07,0.07,0.06,0.05,0.03,0.06,,,,,,,,,,
5i,,0.25,BLUGRN CLR,0.67,SHGC,,0.5,0.47,0.45,0.4,0.32,0.17,0.43,,0.46,0.46,0.41,0.44,,0.6,0.6,0.54,0.59
,,,,,T,,0.4,0.37,0.34,0.3,0.22,0.1,0.32,,,,,,,,,,
,,,,,R  f,,0.08,0.08,0.1,0.14,0.24,0.46,0.13,,,,,,,,,,
,,,,,R b,,0.11,0.11,0.14,0.19,0.31,0.55,0.17,,,,,,,,,,
,,,,,,,0.47,0.49,0.5,0.51,0.5,0.42,0.48,,,,,,,,,,
,,,,,,,0.06,0.06,0.06,0.05,0.04,0.03,0.05,,,,,,,,,,
5j,,0.25,HI-P GRN CLR,0.59,SHGC,,0.39,0.37,0.35,0.31,0.25,0.14,0.33,,0.36,0.36,0.32,0.35,,0.53,0.53,0.47,0.52
,,,,,T,,0.28,0.26,0.24,0.2,0.15,0.06,0.22,,,,,,,,,,
,,,,,R  f,,0.06,0.07,0.08,0.12,0.21,0.43,0.11,,,,,,,,,,
,,,,,R b,,0.1,0.11,0.13,0.19,0.31,0.55,0.17,,,,,,,,,,
,,,,,,,0.62,0.65,0.65,0.65,0.62,0.5,0.63,,,,,,,,,,
,,,,,,,0.03,0.03,0.03,0.03,0.02,0.01,0.03,,,,,,,,,,
5k,,0.25,"SS on CLR 8%, CLR",0.07,SHGC,,0.13,0.12,0.12,0.11,0.1,0.06,0.11,,0.13,0.13,0.11,0.12,,0.06,0.06,0.06,0.06
,,,,,T,,0.05,0.05,0.04,0.04,0.03,0.01,0.04,,,,,,,,,,
,,,,,R  f,,0.33,0.34,0.35,0.37,0.44,0.61,0.37,,,,,,,,,,
,,,,,R b,,0.38,0.37,0.38,0.4,0.46,0.61,0.4,,,,,,,,,,
,,,,,,,0.61,0.61,0.6,0.58,0.53,0.37,0.56,,,,,,,,,,
,,,,,,,0.01,0.01,0.01,0.01,0.01,0.01,0.01,,,,,,,,,,
5l,,0.25,"SS on CLR 14%, CLR",0.13,SHGC,,0.17,0.17,0.16,0.15,0.13,0.08,0.16,,0.17,0.16,0.14,0.15,,0.12,0.12,0.1,0.11
,,,,,T,,0.08,0.08,0.08,0.07,0.05,0.02,0.07,,,,,,,,,,
,,,,,R  f,,0.26,0.27,0.28,0.31,0.38,0.57,0.3,,,,,,,,,,
,,,,,R b,,0.34,0.33,0.34,0.37,0.44,0.6,0.36,,,,,,,,,,
,,,,,,,0.63,0.64,0.64,0.63,0.61,0.56,0.6,,,,,,,,,,
,,,,,,,0.02,0.02,0.02,0.02,0.02,0.02,0.02,,,,,,,,,,
5m,,0.25,"SS on CLR 20%, CLR",0.18,SHGC,,0.22,0.21,0.21,0.19,0.16,0.09,0.2,,0.21,0.21,0.18,0.2,,0.16,0.16,0.14,0.16
,,,,,T,,0.12,0.11,0.11,0.09,0.07,0.03,0.1,,,,,,,,,,
,,,,,R  f,,0.21,0.22,0.23,0.26,0.34,0.54,0.25,,,,,,,,,,
,,,,,R b,,0.3,0.3,0.31,0.34,0.41,0.59,0.33,,,,,,,,,,
,,,,,,,0.64,0.64,0.63,0.62,0.57,0.41,0.61,,,,,,,,,,
,,,,,,,0.03,0.03,0.03,0.03,0.02,0.02,0.03,,,,,,,,,,
5n,,0.25,"SS on GRN 14%, CLR",0.11,SHGC,,0.16,0.16,0.15,0.14,0.12,0.08,0.14,,0.16,0.16,0.14,0.14,,0.1,0.1,0.09,0.1
,,,,,T,,0.05,0.05,0.05,0.04,0.03,0.01,0.04,,,,,,,,,,
,,,,,R  f,,0.14,0.14,0.16,0.19,0.27,0.49,0.18,,,,,,,,,,
,,,,,R b,,0.34,0.33,0.34,0.37,0.44,0.6,0.36,,,,,,,,,,
,,,,,,,0.8,0.8,0.79,0.76,0.69,0.49,0.76,,,,,,,,,,
,,,,,,,0.01,0.01,0.01,0.01,0.01,0.01,0.01,,,,,,,,,,
5o,,0.25,"TI on CLR 20%, CLR",0.18,SHGC,,0.21,0.2,0.19,0.18,0.15,0.09,0.18,,0.2,0.2,0.18,0.19,,0.16,0.16,0.14,0.16
,,,,,T,,0.11,0.1,0.1,0.08,0.06,0.03,0.09,,,,,,,,,,
,,,,,R  f,,0.22,0.22,0.24,0.27,0.34,0.54,0.26,,,,,,,,,,
,,,,,R b,,0.32,0.31,0.32,0.35,0.42,0.59,0.35,,,,,,,,,,
,,,,,,,0.65,0.66,0.65,0.63,0.58,0.41,0.62,,,,,,,,,,
,,,,,,,0.02,0.02,0.02,0.02,0.02,0.01,0.02,,,,,,,,,,
5p,,0.25,"TI on CLR 30%, CLR",0.27,SHGC,,0.29,0.28,0.27,0.25,0.2,0.12,0.25,,0.27,0.27,0.24,0.26,,0.24,0.24,0.22,0.24
,,,,,T,,0.18,0.17,0.16,0.14,0.1,0.05,0.15,,,,,,,,,,
,,,,,R  f,,0.15,0.15,0.17,0.2,0.29,0.51,0.19,,,,,,,,,,
,,,,,R b,,0.27,0.27,0.28,0.31,0.4,0.58,0.31,,,,,,,,,,
,,,,,,,0.64,0.64,0.63,0.62,0.58,0.43,0.61,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.04,0.03,0.02,0.04,,,,,,,,,,
"Low-e Double Glazing, e = 0.2 on surface 2",,,,,,,,,,,,,,,,,,,,,,,
17a,,0.13,LE CLR,0.76,SHGC,,0.65,0.64,0.61,0.56,0.43,0.23,0.57,,0.59,0.6,0.53,0.58,,0.68,0.68,0.61,0.67
,,,,,T,,0.59,0.56,0.54,0.48,0.36,0.18,0.5,,,,,,,,,,
,,,,,R  f,,0.15,0.16,0.18,0.24,0.37,0.61,0.22,,,,,,,,,,
,,,,,R b,,0.17,0.18,0.2,0.26,0.38,0.61,0.24,,,,,,,,,,
,,,,,,,0.2,0.21,0.21,0.21,0.2,0.16,0.2,,,,,,,,,,
,,,,,,,0.07,0.07,0.08,0.08,0.07,0.05,0.07,,,,,,,,,,
17b,,0.25,LE CLR,0.73,SHGC,,0.6,0.59,0.57,0.51,0.4,0.21,0.53,,0.55,0.55,0.49,0.53,,0.65,0.66,0.58,0.64
,,,,,T,,0.51,0.48,0.46,0.41,0.3,0.14,0.43,,,,,,,,,,
,,,,,R  f,,0.14,0.15,0.17,0.22,0.35,0.59,0.21,,,,,,,,,,
,,,,,R b,,0.15,0.16,0.18,0.23,0.35,0.57,0.22,,,,,,,,,,
,,,,,,,0.26,0.26,0.26,0.26,0.25,0.19,0.25,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.11,0.1,0.07,0.1,,,,,,,,,,
"Low-e Double Glazing, e = 0.2 on surface 3",,,,,,,,,,,,,,,,,,,,,,,
17c,,0.13,CLR LE,0.76,SHGC,,0.7,0.68,0.65,0.59,0.46,0.24,0.61,,0.64,0.64,0.57,0.62,,0.68,0.68,0.1,0.67
,,,,,T,,0.59,0.56,0.54,0.48,0.36,0.18,0.5,,,,,,,,,,
,,,,,R  f,,0.17,0.18,0.2,0.26,0.38,0.61,0.24,,,,,,,,,,
,,,,,R b,,0.15,0.16,0.18,0.24,0.37,0.61,0.22,,,,,,,,,,
,,,,,,,0.11,0.12,0.13,0.13,0.14,0.15,0.12,,,,,,,,,,
,,,,,,,0.14,0.14,0.14,0.13,0.11,0.07,0.13,,,,,,,,,,
17d,,0.25,CLR LE,0.73,SHGC,,0.65,0.63,0.6,0.54,0.42,0.21,0.56,,0.59,0.6,0.53,0.58,,0.65,0.66,0.58,0.64
,,,,,T,,0.51,0.48,0.46,0.41,0.3,0.14,0.43,,,,,,,,,,
,,,,,R  f,,0.15,0.16,0.18,0.23,0.35,0.57,0.22,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.17,0.22,0.35,0.59,0.21,,,,,,,,,,
,,,,,,,0.17,0.19,0.2,0.21,0.22,0.22,0.19,,,,,,,,,,
,,,,,,,0.17,0.17,0.17,0.15,0.13,0.07,0.16,,,,,,,,,,
17e,,0.13,BRZ LE,0.58,SHGC,,0.57,0.54,0.51,0.46,0.35,0.18,0.48,,0.52,0.52,0.46,0.51,,0.52,0.52,0.46,0.51
,,,,,T,,0.46,0.43,0.41,0.36,0.26,0.12,0.38,,,,,,,,,,
,,,,,R  f,,0.12,0.12,0.14,0.18,0.28,0.5,0.17,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.17,0.23,0.35,0.6,0.21,,,,,,,,,,
,,,,,,,0.31,0.34,0.35,0.37,0.38,0.35,0.34,,,,,,,,,,
,,,,,,,0.11,0.11,0.1,0.1,0.08,0.04,0.1,,,,,,,,,,
17f,,0.25,BRZ LE,0.45,SHGC,,0.45,0.42,0.4,0.35,0.27,0.14,0.38,,0.42,0.42,0.37,0.4,,0.4,0.41,0.36,0.4
,,,,,T,,0.33,0.3,0.28,0.24,0.17,0.07,0.26,,,,,,,,,,
,,,,,R  f,,0.09,0.09,0.1,0.14,0.23,0.44,0.13,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.21,0.34,0.58,0.2,,,,,,,,,,
,,,,,,,0.48,0.51,0.52,0.54,0.53,0.45,0.5,,,,,,,,,,
,,,,,,,0.11,0.11,0.1,0.09,0.07,0.04,0.09,,,,,,,,,,
17g,,0.13,GRN LE,0.7,SHGC,,0.55,0.52,0.5,0.44,0.34,0.17,0.46,,0.5,0.51,0.45,0.49,,0.62,0.63,0.56,0.62
,,,,,T,,0.44,0.41,0.38,0.33,0.24,0.11,0.36,,,,,,,,,,
,,,,,R  f,,0.11,0.11,0.13,0.17,0.27,0.48,0.16,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.17,0.23,0.35,0.6,0.21,,,,,,,,,,
,,,,,,,0.35,0.38,0.39,0.41,0.42,0.37,0.38,,,,,,,,,,
,,,,,,,0.11,0.1,0.1,0.09,0.07,0.04,0.09,,,,,,,,,,
17h,,0.25,GRN LE,0.61,SHGC,,0.41,0.39,0.36,0.32,0.25,0.13,0.34,,0.38,0.38,0.34,0.36,,0.54,0.55,0.49,0.54
,,,,,T,,0.29,0.26,0.24,0.21,0.15,0.06,0.23,,,,,,,,,,
,,,,,R  f,,0.08,0.08,0.09,0.13,0.22,0.43,0.13,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.21,0.34,0.58,0.2,,,,,,,,,,
,,,,,,,0.53,0.57,0.58,0.59,0.58,0.48,0.56,,,,,,,,,,
,,,,,,,0.1,0.09,0.09,0.08,0.06,0.03,0.08,,,,,,,,,,
17i,,0.13,GRY LE,0.53,SHGC,,0.54,0.51,0.49,0.44,0.33,0.17,0.46,,0.5,0.5,0.44,0.48,,0.47,0.48,0.42,0.47
,,,,,T,,0.43,0.4,0.38,0.33,0.24,0.11,0.35,,,,,,,,,,
,,,,,R  f,,0.11,0.11,0.13,0.17,0.27,0.48,0.16,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.17,0.22,0.35,0.6,0.21,,,,,,,,,,
,,,,,,,0.36,0.39,0.4,0.42,0.42,0.38,0.39,,,,,,,,,,
,,,,,,,0.1,0.1,0.1,0.09,0.07,0.04,0.09,,,,,,,,,,
17j,,0.25,GRY LE,0.37,SHGC,,0.39,0.37,0.35,0.31,0.24,0.13,0.33,,0.36,0.36,0.32,0.35,,0.33,0.33,0.3,0.33
,,,,,T,,0.27,0.25,0.23,0.2,0.14,0.06,0.21,,,,,,,,,,
,,,,,R  f,,0.09,0.09,0.11,0.14,0.23,0.44,0.14,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.22,0.34,0.58,0.2,,,,,,,,,,
,,,,,,,0.55,0.58,0.59,0.59,0.58,0.48,0.56,,,,,,,,,,
,,,,,,,0.09,0.09,0.08,0.07,0.06,0.03,0.08,,,,,,,,,,
17k,,0.25,BLUGRN LE,0.62,SHGC,,0.45,0.42,0.4,0.35,0.27,0.14,0.37,,0.42,0.42,0.37,0.4,,0.55,0.56,0.5,0.55
,,,,,T,,0.32,0.29,0.27,0.23,0.17,0.07,0.26,,,,,,,,,,
,,,,,R  f,,0.09,0.09,0.1,0.14,0.23,0.44,0.13,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.21,0.34,0.58,0.2,,,,,,,,,,
,,,,,,,0.48,0.51,0.53,0.54,0.54,0.45,0.51,,,,,,,,,,
,,,,,,,0.11,0.1,0.1,0.09,0.07,0.03,0.09,,,,,,,,,,
17l,,0.25,HI-P GRN LE,0.55,0.241,,0.34,0.31,0.3,0.26,0.2,0.11,0.28,,0.32,0.32,0.28,0.3,,0.49,0.5,0.44,0.48
,,,,,T,,0.22,0.19,0.18,0.15,0.1,0.04,0.17,,,,,,,,,,
,,,,,R  f,,0.07,0.07,0.08,0.11,0.2,0.41,0.11,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.21,0.33,0.58,0.2,,,,,,,,,,
,,,,,,,0.64,0.67,0.68,0.68,0.66,0.53,0.65,,,,,,,,,,
,,,,,,,0.08,0.07,0.06,0.06,0.04,0.02,0.06,,,,,,,,,,
"Low-e Double Glazing, e = 0.1 on surface 2",,,,,,,,,,,,,,,,,,,,,,,
21a,,0.13,LE CLR,0.76,SHGC,,0.65,0.64,0.62,0.56,0.43,0.23,0.57,,0.59,0.6,0.53,0.58,,0.68,0.68,0.61,0.67
,,,,,T,,0.59,0.56,0.54,0.48,0.36,0.18,0.5,,,,,,,,,,
,,,,,R  f,,0.15,0.16,0.18,0.24,0.37,0.61,0.22,,,,,,,,,,
,,,,,R b,,0.17,0.18,0.2,0.26,0.38,0.61,0.24,,,,,,,,,,
,,,,,,,0.2,0.21,0.21,0.21,0.2,0.16,0.2,,,,,,,,,,
,,,,,,,0.07,0.07,0.08,0.08,0.07,0.05,0.07,,,,,,,,,,
21b,,0.25,LE CLR,0.72,SHGC,,0.6,0.59,0.57,0.51,0.4,0.21,0.53,,0.55,0.55,0.49,0.53,,0.64,0.65,0.58,0.63
,,,,,T,,0.51,0.48,0.46,0.41,0.3,0.14,0.43,,,,,,,,,,
,,,,,R  f,,0.14,0.15,0.17,0.22,0.35,0.59,0.21,,,,,,,,,,
,,,,,R b,,0.15,0.16,0.18,0.23,0.35,0.57,0.22,,,,,,,,,,
,,,,,,,0.26,0.26,0.26,0.26,0.25,0.19,0.25,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.11,0.1,0.07,0.1,,,,,,,,,,
"Low-e Double Glazing, e = 0.1 on surface 3",,,,,,,,,,,,,,,,,,,,,,,
21c,,0.13,CLR LE,0.75,SHGC,,0.6,0.58,0.56,0.51,0.4,0.22,0.52,,0.55,0.55,0.49,0.53,,0.67,0.68,0.6,0.66
,,,,,T,,0.48,0.45,0.43,0.37,0.27,0.13,0.4,,,,,,,,,,
,,,,,R  f,,0.26,0.27,0.28,0.32,0.42,0.62,0.31,,,,,,,,,,
,,,,,R b,,0.24,0.24,0.26,0.29,0.38,0.58,0.28,,,,,,,,,,
,,,,,,,0.12,0.13,0.14,0.14,0.15,0.15,0.13,,,,,,,,,,
,,,,,,,0.14,0.15,0.15,0.16,0.16,0.1,0.15,,,,,,,,,,
21d,,0.25,CLR LE,0.72,SHGC,,0.56,0.55,0.52,0.48,0.38,0.2,0.49,,0.51,0.52,0.46,0.5,,0.64,0.65,0.58,0.63
,,,,,T,,0.42,0.4,0.37,0.32,0.24,0.11,0.35,,,,,,,,,,
,,,,,R  f,,0.24,0.24,0.25,0.29,0.38,0.58,0.28,,,,,,,,,,
,,,,,R b,,0.2,0.2,0.22,0.26,0.34,0.55,0.25,,,,,,,,,,
,,,,,,,0.19,0.2,0.21,0.22,0.23,0.22,0.21,,,,,,,,,,
,,,,,,,0.16,0.17,0.17,0.17,0.16,0.1,0.16,,,,,,,,,,
21e,,0.13,BRZ LE,0.57,SHGC,,0.48,0.46,0.44,0.4,0.31,0.17,0.42,,0.44,0.44,0.39,0.43,,0.51,0.51,0.46,0.5
,,,,,T,,0.37,0.34,0.32,0.27,0.2,0.08,0.3,,,,,,,,,,
,,,,,R  f,,0.18,0.17,0.19,0.22,0.3,0.5,0.21,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.25,0.29,0.37,0.57,0.28,,,,,,,,,,
,,,,,,,0.34,0.37,0.38,0.39,0.39,0.35,0.37,,,,,,,,,,
,,,,,,,0.11,0.12,0.12,0.12,0.11,0.07,0.11,,,,,,,,,,
21f,,0.25,BRZ LE,0.45,SHGC,,0.39,0.37,0.35,0.31,0.24,0.13,0.33,,0.36,0.36,0.32,0.35,,0.4,0.41,0.36,0.4
,,,,,T,,0.27,0.24,0.22,0.19,0.13,0.05,0.21,,,,,,,,,,
,,,,,R  f,,0.12,0.12,0.13,0.16,0.24,0.44,0.16,,,,,,,,,,
,,,,,R b,,0.19,0.2,0.22,0.25,0.34,0.55,0.24,,,,,,,,,,
,,,,,,,0.51,0.54,0.55,0.56,0.55,0.46,0.53,,,,,,,,,,
,,,,,,,0.1,0.1,0.1,0.1,0.09,0.05,0.1,,,,,,,,,,
21g,,0.13,GRN LE,0.68,SHGC,,0.46,0.44,0.42,0.38,0.3,0.16,0.4,,0.42,0.43,0.38,0.41,,0.61,0.61,0.54,0.6
,,,,,T,,0.36,0.32,0.3,0.26,0.18,0.08,0.28,,,,,,,,,,
,,,,,R  f,,0.17,0.16,0.17,0.2,0.29,0.48,0.2,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.25,0.29,0.37,0.57,0.27,,,,,,,,,,
,,,,,,,0.38,0.41,0.42,0.43,0.43,0.38,0.4,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.11,0.1,0.06,0.1,,,,,,,,,,
21h,,0.25,GRN LE,0.61,SHGC,,0.36,0.33,0.31,0.28,0.22,0.12,0.3,,0.34,0.34,0.3,0.32,,0.54,0.55,0.49,0.54
,,,,,T,,0.24,0.21,0.19,0.16,0.11,0.05,0.18,,,,,,,,,,
,,,,,R  f,,0.11,0.1,0.11,0.14,0.22,0.43,0.14,,,,,,,,,,
,,,,,R b,,0.19,0.2,0.22,0.25,0.34,0.55,0.24,,,,,,,,,,
,,,,,,,0.56,0.59,0.61,0.61,0.59,0.48,0.58,,,,,,,,,,
,,,,,,,0.09,0.09,0.09,0.08,0.08,0.04,0.08,,,,,,,,,,
21i,,0.13,GRY LE,0.52,SHGC,,0.46,0.44,0.42,0.38,0.3,0.16,0.39,,0.42,0.43,0.38,0.41,,0.46,0.47,0.42,0.46
,,,,,T,,0.35,0.32,0.3,0.25,0.18,0.08,0.28,,,,,,,,,,
,,,,,R  f,,0.16,0.16,0.17,0.2,0.28,0.48,0.2,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.25,0.29,0.37,0.57,0.27,,,,,,,,,,
,,,,,,,0.39,0.42,0.43,0.44,0.44,0.38,0.41,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.11,0.1,0.06,0.1,,,,,,,,,,
21j,,0.25,GRY LE,0.37,SHGC,,0.34,0.32,0.3,0.27,0.21,0.12,0.28,,0.32,0.32,0.28,0.3,,0.33,0.33,0.3,0.33
,,,,,T,,0.23,0.2,0.18,0.15,0.11,0.04,0.17,,,,,,,,,,
,,,,,R  f,,0.11,0.11,0.12,0.15,0.23,0.44,0.15,,,,,,,,,,
,,,,,R b,,0.2,0.2,0.22,0.25,0.34,0.55,0.24,,,,,,,,,,
,,,,,,,0.58,0.6,0.61,0.61,0.59,0.48,0.59,,,,,,,,,,
,,,,,,,0.08,0.08,0.08,0.08,0.07,0.04,0.08,,,,,,,,,,
21k,,0.25,BLUGRN LE,0.62,SHGC,,0.39,0.37,0.34,0.31,0.24,0.13,0.33,,0.36,0.36,0.32,0.35,,0.55,0.56,0.5,0.55
,,,,,T,,0.28,0.25,0.23,0.2,0.14,0.06,0.22,,,,,,,,,,
,,,,,R  f,,0.12,0.12,0.13,0.16,0.24,0.44,0.16,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.25,0.28,0.37,0.57,0.27,,,,,,,,,,
,,,,,,,0.51,0.54,0.56,0.56,0.55,0.46,0.53,,,,,,,,,,
,,,,,,,0.08,0.09,0.08,0.08,0.08,0.05,0.08,,,,,,,,,,
21l,,0.25,HI-P GRN W/LE CLR,0.57,SHGC,,0.31,0.3,0.29,0.26,0.21,0.12,0.27,,0.29,0.29,0.26,0.28,,0.51,0.51,0.46,0.5
,,,,,T,,0.22,0.21,0.19,0.17,0.12,0.06,0.18,,,,,,,,,,
,,,,,R  f,,0.07,0.07,0.09,0.13,0.22,0.46,0.12,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.24,0.28,0.37,0.57,0.27,,,,,,,,,,
,,,,,,,0.67,0.68,0.67,0.66,0.62,0.46,0.65,,,,,,,,,,
,,,,,,,0.04,0.05,0.05,0.05,0.04,0.03,0.04,,,,,,,,,,
"Low-e Double Glazing, e = 0.05 on surface 2",,,,,,,,,,,,,,,,,,,,,,,
25a,,0.13,LE CLR,0.72,SHGC,,0.41,0.4,0.38,0.34,0.27,0.14,0.36,,0.38,0.38,0.34,0.36,,0.64,0.65,0.58,0.63
,,,,,T,,0.37,0.35,0.33,0.29,0.22,0.11,0.31,,,,,,,,,,
,,,,,R  f,,0.35,0.36,0.37,0.4,0.47,0.64,0.39,,,,,,,,,,
,,,,,R b,,0.39,0.39,0.4,0.43,0.5,0.66,0.42,,,,,,,,,,
,,,,,,,0.24,0.26,0.26,0.27,0.28,0.23,0.26,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.04,0.03,0.03,0.04,,,,,,,,,,
25b,,0.25,LE CLR,0.7,SHGC,,0.37,0.36,0.34,0.31,0.24,0.13,0.32,,0.34,0.34,0.3,0.33,,0.62,0.63,0.56,0.62
,,,,,T,,0.3,0.28,0.27,0.23,0.17,0.08,0.25,,,,,,,,,,
,,,,,R  f,,0.3,0.3,0.32,0.35,0.42,0.6,0.34,,,,,,,,,,
,,,,,R b,,0.35,0.35,0.35,0.38,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.34,0.35,0.35,0.36,0.35,0.28,0.34,,,,,,,,,,
,,,,,,,0.06,0.07,0.07,0.06,0.06,0.04,0.06,,,,,,,,,,
25c,,0.25,BRZ W/LE CLR,0.42,SHGC,,0.26,0.25,0.24,0.22,0.18,0.1,0.23,,0.25,0.25,0.22,0.23,,0.37,0.38,0.34,0.37
,,,,,T,,0.18,0.17,0.16,0.14,0.1,0.05,0.15,,,,,,,,,,
,,,,,R  f,,0.15,0.16,0.17,0.21,0.29,0.51,0.2,,,,,,,,,,
,,,,,R b,,0.34,0.34,0.35,0.37,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.63,0.63,0.63,0.61,0.57,0.42,0.6,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.04,0.03,0.03,0.04,,,,,,,,,,
25d,,0.25,GRN W/LE CLR,0.6,SHGC,,0.31,0.3,0.28,0.26,0.21,0.12,0.27,,0.29,0.29,0.26,0.28,,0.53,0.54,0.48,0.53
,,,,,T,,0.22,0.21,0.2,0.17,0.13,0.06,0.18,,,,,,,,,,
,,,,,R  f,,0.1,0.1,0.12,0.16,0.25,0.48,0.15,,,,,,,,,,
,,,,,R b,,0.35,0.34,0.35,0.37,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.64,0.64,0.64,0.63,0.59,0.43,0.62,,,,,,,,,,
,,,,,,,0.05,0.05,0.05,0.05,0.04,0.03,0.05,,,,,,,,,,
25e,,0.25,GRY W/LE CLR,0.35,SHGC,,0.24,0.23,0.22,0.2,0.16,0.09,0.21,,0.23,0.23,0.2,0.21,,0.31,0.32,0.28,0.31
,,,,,T,,0.16,0.15,0.14,0.12,0.09,0.04,0.13,,,,,,,,,,
,,,,,R  f,,0.12,0.13,0.15,0.18,0.26,0.49,0.17,,,,,,,,,,
,,,,,R b,,0.34,0.34,0.35,0.37,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.69,0.69,0.68,0.67,0.62,0.45,0.66,,,,,,,,,,
,,,,,,,0.03,0.03,0.03,0.03,0.03,0.02,0.03,,,,,,,,,,
25f,,0.25,BLUE W/LE CLR,0.45,SHGC,,0.27,0.26,0.25,0.23,0.18,0.11,0.24,,0.26,0.25,0.22,0.24,,0.4,0.41,0.36,0.4
,,,,,T,,0.19,0.18,0.17,0.15,0.11,0.05,0.16,,,,,,,,,,
,,,,,R  f,,0.12,0.12,0.14,0.17,0.26,0.49,0.16,,,,,,,,,,
,,,,,R b,,0.34,0.34,0.35,0.37,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.66,0.66,0.65,0.64,0.6,0.44,0.63,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.04,0.04,0.03,0.04,,,,,,,,,,
25g,,0.25,HI-P GRN W/LE CLR,0.53,SHGC,,0.27,0.26,0.25,0.23,0.18,0.11,0.23,,0.26,0.25,0.22,0.24,,0.47,0.48,0.42,0.47
,,,,,T,,0.18,0.17,0.16,0.14,0.1,0.05,0.15,,,,,,,,,,
,,,,,R  f,,0.07,0.07,0.09,0.13,0.22,0.46,0.12,,,,,,,,,,
,,,,,R b,,0.35,0.34,0.35,0.38,0.44,0.6,0.37,,,,,,,,,,
,,,,,,,0.71,0.72,0.71,0.69,0.64,0.47,0.68,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.04,0.03,0.02,0.04,,,,,,,,,,
Triple Glazing,,,,,,,,,,,,,,,,,,,,,,,
29a,,0.13,CLR CLR CLR,0.74,SHGC,,0.68,0.65,0.62,0.54,0.39,0.18,0.57,,0.62,0.62,0.55,0.6,,0.66,0.67,0.59,0.65
,,,,,T,,0.6,0.57,0.53,0.45,0.31,0.12,0.49,,,,,,,,,,
,,,,,R  f,,0.17,0.18,0.21,0.28,0.42,0.65,0.25,,,,,,,,,,
,,,,,R b,,0.17,0.18,0.21,0.28,0.42,0.65,0.25,,,,,,,,,,
,,,,,,,0.1,0.11,0.12,0.13,0.14,0.14,0.12,,,,,,,,,,
,,,,,,,0.08,0.08,0.09,0.09,0.08,0.07,0.08,,,,,,,,,,
,,,,,,,0.06,0.06,0.06,0.06,0.05,0.03,0.06,,,,,,,,,,
29b,,0.25,CLR CLR CLR,0.7,SHGC,,0.61,0.58,0.55,0.48,0.35,0.16,0.51,,0.56,0.56,0.5,0.54,,0.62,0.63,0.56,0.62
,,,,,T,,0.49,0.45,0.42,0.35,0.24,0.09,0.39,,,,,,,,,,
,,,,,R  f,,0.14,0.15,0.18,0.24,0.37,0.59,0.22,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.18,0.24,0.37,0.59,0.22,,,,,,,,,,
,,,,,,,0.17,0.19,0.2,0.21,0.22,0.21,0.19,,,,,,,,,,
,,,,,,,0.12,0.13,0.13,0.13,0.12,0.08,0.12,,,,,,,,,,
,,,,,,,0.08,0.08,0.08,0.08,0.06,0.03,0.08,,,,,,,,,,
29c,,0.25,HI-P GRN CLR CLR,0.53,SHGC,,0.32,0.29,0.27,0.24,0.18,0.1,0.26,,0.3,0.3,0.26,0.29,,0.47,0.48,0.42,0.47
,,,,,T,,0.2,0.17,0.15,0.12,0.07,0.02,0.15,,,,,,,,,,
,,,,,R  f,,0.06,0.07,0.08,0.11,0.2,0.41,0.11,,,,,,,,,,
,,,,,R b,,0.13,0.14,0.16,0.22,0.35,0.57,0.2,,,,,,,,,,
,,,,,,,0.64,0.67,0.68,0.68,0.66,0.53,0.65,,,,,,,,,,
,,,,,,,0.06,0.06,0.05,0.05,0.05,0.03,0.05,,,,,,,,,,
,,,,,,,0.04,0.04,0.04,0.03,0.02,0.01,0.04,,,,,,,,,,
"Triple Glazing, e = 0.2 on surface 2",,,,,,,,,,,,,,,,,,,,,,,
32a,,0.13,LE CLR CLR,0.68,SHGC,,0.6,0.58,0.55,0.48,0.35,0.17,0.51,,0.55,0.55,0.49,0.53,,0.61,0.61,0.54,0.6
,,,,,T,,0.5,0.47,0.44,0.38,0.26,0.1,0.41,,,,,,,,,,
,,,,,R  f,,0.17,0.19,0.21,0.27,0.41,0.64,0.25,,,,,,,,,,
,,,,,R b,,0.19,0.2,0.22,0.29,0.42,0.63,0.26,,,,,,,,,,
,,,,,,,0.2,0.2,0.2,0.21,0.21,0.17,0.2,,,,,,,,,,
,,,,,,,0.08,0.08,0.08,0.09,0.08,0.07,0.08,,,,,,,,,,
,,,,,,,0.06,0.06,0.06,0.06,0.05,0.03,0.06,,,,,,,,,,
32b,,0.25,LE CLR CLR,0.64,SHGC,,0.53,0.5,0.47,0.41,0.29,0.14,0.44,,0.49,0.49,0.43,0.47,,0.57,0.58,0.51,0.56
,,,,,T,,0.39,0.36,0.33,0.27,0.17,0.06,0.3,,,,,,,,,,
,,,,,R  f,,0.14,0.15,0.17,0.21,0.31,0.53,0.2,,,,,,,,,,
,,,,,R b,,0.16,0.16,0.19,0.24,0.36,0.57,0.22,,,,,,,,,,
,,,,,,,0.28,0.31,0.31,0.34,0.37,0.31,0.31,,,,,,,,,,
,,,,,,,0.11,0.11,0.11,0.11,0.1,0.08,0.11,,,,,,,,,,
,,,,,,,0.08,0.08,0.08,0.07,0.05,0.03,0.07,,,,,,,,,,
"Triple Glazing, e = 0.2 on surface 5",,,,,,,,,,,,,,,,,,,,,,,
32c,,0.13,CLR CLR LE,0.68,SHGC,,0.62,0.6,0.57,0.49,0.36,0.16,0.52,,0.57,0.57,0.5,0.55,,0.61,0.61,0.54,0.6
,,,,,T,,0.5,0.47,0.44,0.38,0.26,0.1,0.41,,,,,,,,,,
,,,,,R  f,,0.19,0.2,0.22,0.29,0.42,0.63,0.26,,,,,,,,,,
,,,,,R b,,0.18,0.19,0.21,0.27,0.41,0.64,0.25,,,,,,,,,,
,,,,,,,0.11,0.12,0.13,0.14,0.15,0.15,0.13,,,,,,,,,,
,,,,,,,0.09,0.1,0.1,0.1,0.1,0.08,0.1,,,,,,,,,,
,,,,,,,0.11,0.11,0.11,0.1,0.08,0.04,0.1,,,,,,,,,,
32d,,0.25,CLR CLR LE,0.64,SHGC,,0.56,0.53,0.5,0.44,0.32,0.15,0.47,,0.51,0.52,0.46,0.5,,0.57,0.58,0.1,0.56
,,,,,T,,0.39,0.36,0.33,0.27,0.17,0.06,0.3,,,,,,,,,,
,,,,,R  f,,0.16,0.16,0.19,0.24,0.36,0.57,0.22,,,,,,,,,,
,,,,,R b,,0.14,0.15,0.17,0.21,0.31,0.53,0.2,,,,,,,,,,
,,,,,,,0.17,0.19,0.2,0.21,0.22,0.22,0.19,,,,,,,,,,
,,,,,,,0.13,0.14,0.14,0.14,0.13,0.1,0.13,,,,,,,,,,
,,,,,,,0.15,0.16,0.15,0.14,0.12,0.05,0.14,,,,,,,,,,
"Triple Glazing, e = 0.1 on surface 2 and 5",,,,,,,,,,,,,,,,,,,,,,,
40a,,0.13,LE CLR LE,0.62,SHGC,,0.41,0.39,0.37,0.32,0.24,0.12,0.34,,0.38,0.38,0.34,0.36,,0.55,0.56,0.5,0.55
,,,,,T,,0.29,0.26,0.24,0.2,0.13,0.05,0.23,,,,,,,,,,
,,,,,R  f,,0.3,0.3,0.31,0.34,0.41,0.59,0.33,,,,,,,,,,
,,,,,R b,,0.3,0.3,0.31,0.34,0.41,0.59,0.33,,,,,,,,,,
,,,,,,,0.25,0.27,0.28,0.3,0.32,0.27,0.28,,,,,,,,,,
,,,,,,,0.07,0.08,0.08,0.08,0.07,0.06,0.07,,,,,,,,,,
,,,,,,,0.08,0.09,0.09,0.09,0.07,0.04,0.08,,,,,,,,,,
40b,,0.25,LE CLR LE,0.59,SHGC,,0.36,0.34,0.32,0.28,0.21,0.1,0.3,,0.34,0.34,0.3,0.32,,0.53,0.53,0.47,0.52
,,,,,T,,0.24,0.21,0.19,0.16,0.1,0.03,0.18,,,,,,,,,,
,,,,,R  f,,0.34,0.34,0.35,0.38,0.44,0.61,0.37,,,,,,,,,,
,,,,,R b,,0.23,0.23,0.25,0.28,0.36,0.56,0.27,,,,,,,,,,
,,,,,,,0.24,0.25,0.26,0.28,0.3,0.25,0.26,,,,,,,,,,
,,,,,,,0.1,0.11,0.11,0.11,0.1,0.07,0.1,,,,,,,,,,
,,,,,,,0.09,0.09,0.09,0.08,0.07,0.03,0.08,,,,,,,,,,
"Triple Glazing, e = 0.05 on surface 2 and 4",,,,,,,,,,,,,,,,,,,,,,,
40c,,0.13,LE LE CLR,0.58,SHGC,,0.27,0.25,0.24,0.21,0.16,0.08,0.23,,0.26,0.25,0.22,0.25,,0.52,0.52,0.46,0.51
,,,,,T,,0.18,0.17,0.16,0.13,0.08,0.03,0.14,,,,,,,,,,
,,,,,R  f,,0.41,0.41,0.42,0.44,0.5,0.65,0.44,,,,,,,,,,
,,,,,R b,,0.46,0.45,0.46,0.48,0.53,0.68,0.47,,,,,,,,,,
,,,,,,,0.27,0.28,0.28,0.29,0.3,0.24,0.28,,,,,,,,,,
,,,,,,,0.12,0.12,0.12,0.12,0.11,0.07,0.12,,,,,,,,,,
,,,,,,,0.02,0.02,0.02,0.02,0.01,0.01,0.02,,,,,,,,,,
40d,,0.25,LE LE CLR,0.55,SHGC,,0.26,0.25,0.23,0.21,0.16,0.08,0.22,,0.25,0.25,0.21,0.24,,0.49,0,0.44,0.48
,,,,,T,,0.15,0.14,0.12,0.1,0.07,0.02,0.12,,,,,,,,,,
,,,,,R  f,,0.33,0.33,0.34,0.37,0.43,0.6,0.36,,,,,,,,,,
,,,,,R b,,0.39,0.38,0.38,0.4,0.46,0.61,0.4,,,,,,,,,,
,,,,,,,0.34,0.36,0.36,0.37,0.36,0.28,0.35,,,,,,,,,,
,,,,,,,0.15,0.15,0.15,0.14,0.12,0.08,0.14,,,,,,,,,,
,,,,,,,0.03,0.03,0.03,0.03,0.02,0.01,0.03,,,,,,,,,,
`
