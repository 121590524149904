<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from './State.js'
import { SystemResultsState } from './System.js'
import { PleaseContactStr } from './Base.js'
import CalcContextView from './CalcContextView.vue'
import DebugOptions from './DebugOptions.vue'
import HelpSection from './HelpSection.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'

/*
let props = defineProps({
  selectedSystem: Object,
});
*/

let selectedSystem = computed(() => {
  return gApp.proj().getResultsPageSelectedSystem();
});

function canRerunCalculation() {
  // Admin can always rerun
  if (gApp.isAdminUser()) {
    return true;
  }
  return false;
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SystemResults">
      <h4 class="mb-s">System Results</h4>
      <p class="mb-m">
        Use this page to build different  types for use in the Build House tab.
      </p>
      <p>
        The <b>R-value</b> of the  will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.
        <br><br>
          Consult your local building code for minimum requirements of <b>R-value</b>. For <b>absorptance</b>, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results.
        <br><br>

        When using the <b>Wall Builder</b>, certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall.
        <br><br>

        The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.
        <br><br>

         built in this section can be used as  types  in the Build House tab.

      </p>
    </HelpSection>
    <div v-if="selectedSystem">
      <div class="mb-l">
        <button @click="gApp.router.go(-1)" class="LinkBtn BackBtn mb-m">Back</button>
        <div class="">
          <h1 class="PageH1 SystemName"><i class="bi-bar-chart-line mr-xs"></i><span class="">{{ selectedSystem.name.value || 'Untitled' }}</span> Results</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text">
          Calculate and view the load results for this system. To edit the system, go the 'Systems' tab.
        </p>
      </div>
      <div class="mb-m BorderedSection">
        <div class="mb-m">
          <h4>Run Calculations</h4>
          <p class="help-text mb-xs">Run the load calculations here.</p>
        </div>
        <div class="mb-m">
          <div class="mb-s">
            <p class="mute-header">Design temps</p>
            <FieldInput v-model="selectedSystem.designTempInputs.heatingDesignTemp" />
            <FieldInput v-model="selectedSystem.designTempInputs.coolingDesignTemp" />
          </div>
          <div v-if="gApp.isAdminUser()" class="mb-s">
            <p class="mute-header">[Admin Only] Debug Options</p>
            <DebugOptions v-model="selectedSystem.debugCalcOptions" />
          </div>
        </div>
        <button class="CreateBtn RunCalcBtn mb-s" @click="selectedSystem.calculateLoadsAsync()" :disabled="!canRerunCalculation()">Run Calculations</button>
        <div v-if="selectedSystem.getResultsState() == SystemResultsState.Calculating">
          <p class="">Please wait for the current calculations to finish, before recalculating.</p>
        </div>
        <div v-else>
          <div v-if="gApp.proj().getAllErrors()" class="mb-l">
            <p class="mute-header"><i class="bi-exclamation-triangle-fill mr-xs"></i>Found Project Errors:</p>
            <p class="MarginBotS big-help-text">You must resolve the following errors before calculating loads:</p>
            <div v-for="(error, i) in gApp.proj().getAllErrors()" class="ErrorDiv mb-xxs">
              <div class="Flex RowNoWrap GapS AlignStart">
                <p><i class="bi-exclamation-circle ErrorIcon"></i></p>
                <div>
                  <p class="mute-header">{{error.path}}</p>
                  <p class="help-text">{{error.msg}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Calculations will take several minutes.</p>
          </div>
        </div>
        <!--
        <SimpleCollapse title="Run new calculation..." class="Section Emphasis">
          <div class="mb-s">
            <p class="mute-header">Design temps</p>
            <FieldInput v-model="selectedSystem.designTempInputs.heatingDesignTemp" />
            <FieldInput v-model="selectedSystem.designTempInputs.coolingDesignTemp" />
          </div>
          <div v-if="gApp.isAdminUser()" class="mb-s">
            <p class="mute-header">[Admin Only] Debug Options</p>
            <DebugOptions v-model="selectedSystem.debugCalcOptions" />
          </div>
          <SystemResults :system="selectedSystem" />
        </SimpleCollapse>
        -->
      </div>
      <div class="BorderedSection">
        <div class="mb-s">
          <h4>Results</h4>
          <p class="help-text mb-xs">These are the results of the most recently run calculation.</p>
        </div>
        <div v-if="selectedSystem.getResultsState() == SystemResultsState.None">
          <p class="Italic">No results yet. Run a calculation above.</p>
        </div>
        <div v-else-if="selectedSystem.getResultsState() == SystemResultsState.Calculating">
          <p class="mb-s"><i class="bi-arrow-clockwise mr-xs rotate-icon"></i>Calculating, please wait... </p>
          <div>
            <p class="mute-header mb-xs">Progress:</p>
            <p class="mb-xs"><!--<b>{{selectedSystem.getResultsProgressPercent()}}%</b>-->Total Time: {{ selectedSystem.getResultsTimeCalculating() }}</p>
            <p class="CalcDetails LogText mb-s" v-html="selectedSystem.getResultsDetailedProgressStr()"></p>
            <p class="CalcProgress LogText muted-text Italic">{{selectedSystem.getResultsProgressStr()}}</p>
          </div>
        </div>
        <div v-else-if="selectedSystem.getResultsState() == SystemResultsState.Ready">
          <div class="mb-s InputInfoBox">
            <div class="Flex">
              <p>Heating design temp.:</p>
              <p class="InputInfo">{{selectedSystem.loadResults.getHeatingDesignTempStr()}}</p>
            </div>
            <div class="Flex">
              <p>Cooling design temp.:</p>
              <p class="InputInfo">{{selectedSystem.loadResults.getCoolingDesignTempStr()}}</p>
            </div>
            <div class="Flex">
              <p>Calculated on:</p>
              <p class="InputInfo">{{selectedSystem.loadResults.getStartDateStr()}}</p>
            </div>
            <div class="Flex">
              <p>Calculation time:</p>
              <p class="InputInfo">{{selectedSystem.loadResults.getDurationStr()}}</p>
            </div>
          </div>
          <div v-if="!selectedSystem.loadResults.error" class="mb-m">
            <SimpleCollapse title="Output Summary" class="Section Emphasis">
            </SimpleCollapse>
            <SimpleCollapse title="Detailed Output Breakdown" class="Section Emphasis">
            </SimpleCollapse>
            <SimpleCollapse title="System Input Summary" class="Section Emphasis">
            </SimpleCollapse>
            <SimpleCollapse title="Space Input Breakdown" class="Section Emphasis">
            </SimpleCollapse>
          </div>
          <div v-else class="mb-m">
            <p class="Bold">Unexpected Error</p>
            <p class="mb-xs">
              There was an unexpected error during the calculation. {{PleaseContactStr}}
            </p>
            <p class="delete-red">{{ selectedSystem.loadResults.error }}</p>
          </div>
          <SimpleCollapse v-if="gApp.isAdminUser()" title="[Admin Only] Calculation Log" class="Section Emphasis">
            <CalcContextView :ctx="selectedSystem.loadResults.calcContext"/>
          </SimpleCollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}

.Section {
  margin-bottom: var(--space-xs);
}

.BackBtn {
  font-size: var(--f-m);
}

.SystemName {
}

.ErrorIcon {
  color: var(--delete-red);
}

.ErrorDiv {
  /* border: 1px solid var(--b-20); */
  width: 800px;
  border: 1px solid var(--grey-60);
  /* background-color: var(--grey-90); */
  /* background-color: var(--delete-red); */
  border-radius: var(--br-s);
  padding: var(--xs);
}

.RunCalcBtn {
  padding: var(--s) var(--m);
}

.InputInfoBox {
  width: 450px;
}

.InputInfo {
  color: blue;
  margin-left: auto;
}

.CalcProgress {
}

.CalcDetails:deep(.IsDone) {
  color: blue;
}

.CalcDetails:deep(.InProg) {
  color: blue;
}

.IsDone {
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  display: inline-block;
  animation: rotate 2.0s linear infinite;
}

</style>
