import {
  setupClass, lookupData, Matches,
  interpolateInMap, doubleInterpolateInMap,
  IdsMap, ObjectUtils, PleaseContactStr,
  IntervalTimer,
} from './Base.js'
import { CsvHelper } from './MaterialData/CsvHelper.js'

import { Units } from './Units.js'

export class DataTable {
  init(cols, rowData) {
    this.cols = cols
    this.rowData = rowData
  }

  static createFromCSV(csvStr) {
    let csvHelper = new CsvHelper(csvStr)
    let cols = []
    for (let i = 0; i < csvHelper.numCols(); ++i) {
      let colName = csvHelper.csv.data[0][i]
      cols.push({
        name: colName,
        field: i,
      })
    }
    let rowData = {}
    for (let i = 1; i < csvHelper.numRows(); ++i) {
      let row = {}
      //console.log(`Row ${i}: ${csvHelper.csv.data[i]}`)
      for (let j = 0; j < csvHelper.numCols(); ++j) {
        row[j] = csvHelper.csv.data[i][j]
      }
      //console.log(`Row ${i}: ${JSON.stringify(row)}`)
      rowData[i] = row
    }
    return DataTable.create(cols, rowData);
  }
}
setupClass(DataTable)