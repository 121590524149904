<script setup>
import { ref, computed, onMounted, nextTick, } from 'vue'
import { gApp, Field, FieldType, YesNo, } from './State.js'
import { removeElem, prettyJson, formatNum,
  formatNumBrief, } from './SharedUtils.js'
import { PleaseContactStr } from './Base.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import LocationData from './LocationData.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import CalcLogItem from './CalcLogItem.vue'
import InputsSummary from './InputsSummary.vue'
import LoadBreakdownTable from './LoadBreakdownTable.vue'
import { jsPDF } from "jspdf";

console.log("Setting HouseResults helper vars");

let pdfPageOne = ref(null);
let pdfPageTwo = ref(null);
let datePdfGenerated = ref(null);

let proj = computed(() => {
  return gApp.proj();
})

let results = computed(() => {
  let proj = gApp.proj();
  if (proj.getAllErrors()) {
    return;
  }
  return proj.calcResults();
})
console.log("Results: ", results);

function logLocData() {
  // console.log("LocObj: ", gApp.proj().toplevelData.locationData.locObj);
}

async function exportPdf() {
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF();
  let pageHeight = doc.internal.pageSize.height;

  let marginX = 15;
  let marginY = 15;
  let nextY = marginY;

  await doc.html(pdfPageOne.value, {
      callback: function(doc) {
          return doc;
      },
      x: marginX,
      y: nextY,
      width: 170,
      windowWidth: 650,
  });

  doc.addPage();
  nextY = pageHeight + marginY;
  await doc.html(pdfPageTwo.value, {
      callback: function(doc) {
          return doc;
      },
      x: marginX,
      y: nextY,
      width: 170,
      windowWidth: 650,
  });

  let projName = gApp.proj().getUiName().replace(' ', '_');
  doc.save(`${projName}.pdf`);
}

async function downloadAsPdf() {
  //gApp.toast('Feature not ready yet. Coming soon.', {type: 'success'});
  //let dateStr = new Date().toISOString().slice(0, 10)
  datePdfGenerated.value = new Date().toLocaleDateString();
  nextTick(() => {
    exportPdf();
  })
}

</script>

<template>
  <div class="Section">
    <div>
      <div class="Flex">
        <h3 class="mb-xxs">Load Results</h3>
        <button v-if="!proj.getAllErrors()" class="ml-auto CreateBtn" @click="downloadAsPdf">Download as PDF</button>
      </div>
      <div v-if="proj.getAllErrors()" class="">
        <p class="MarginBotS big-help-text">Please resolve the following items:</p>
        <div v-for="(error, i) in proj.getAllErrors()" class="ErrorDiv mb-xxs">
          <div class="Flex RowNoWrap GapS AlignStart">
            <p><i class="bi-exclamation-circle ErrorIcon"></i></p>
            <div>
              <p class="mute-header">{{error.path}}</p>
              <p class="help-text">{{error.msg}}</p>
            </div>
          </div>
          <!-- <p>{{error.msg}}</p> -->
        </div>
      </div>
      <div v-else>
        <div v-if="proj.debugOn && results.outputCtx">
          <SimpleCollapse v-if="proj.debugOn" title="Debug Log" class="MarginBotXXS">
            <p class="DebugLog">{{results.outputCtx.getLogStr()}}</p>
          </SimpleCollapse>
          <SimpleCollapse v-if="proj.debugOn" title="Debug Log (Dict)" class="MarginBotM">
            <CalcLogItem :item="results.outputCtx.getLogDict()" />
          </SimpleCollapse>
        </div>
        <div v-if="results.error">
          <h4>Unexpected Error:</h4>
          <p>An unexpected error occurred while calculating the load results. {{PleaseContactStr}}</p>
          <p class="DebugLog ResultsErrorLog">{{results.error}}</p>
        </div>
        <div v-else>
          <!-- Hidden element used for pdf generation -->
          <div v-show="false" class="PdfOutput ToplevelPdf">
            <div ref="pdfPageOne">
              <h1>{{ proj.getUiName() }}</h1>
              <div class="MarginBotS PdfOutput">
                <p>Location: {{gApp.proj().toplevelData.locationData.locPath.fullName}}</p>
                <p>Heating Design Temp: {{proj.designTempInputs.heatingDesignTemp.getSelectLabelStr()}}</p>
                <p>Cooling Design Temp: {{proj.designTempInputs.coolingDesignTemp.getSelectLabelStr()}}</p>
                <p>Generated on {{datePdfGenerated}}</p>
              </div>
              <LoadBreakdownTable ref="loadsTable" :results="results"/>
            </div>
            <div ref="pdfPageTwo">
              <!-- <h2>Inputs Summary</h2> -->
              <InputsSummary ref="inputsSummary" :results="results" class="PdfOutput"/>
            </div>
          </div>
          <div class="MarginBotM" title="Load Breakdown" :styles="{Emphasis:true}">
            <!-- <h3>Final Load Outputs</h3> -->
            <div class="mb-s">
              <FieldInput v-model="proj.designTempInputs.heatingDesignTemp" />
              <FieldInput v-model="proj.designTempInputs.coolingDesignTemp" />
            </div>
            <!-- <button v-if="!proj.getAllErrors()" class="ml-auto CreateBtn mb-xxs">Download as PDF</button> -->
            <LoadBreakdownTable :results="results"/>
          </div>
          <SimpleCollapse title="Inputs Summary">
            <InputsSummary :results="results"/>
          </SimpleCollapse>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-s);
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.OutputSection {
  margin-bottom: var(--space-xs);
}

.DebugLog {
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 16px;
  line-height: 1.25;
}

.ResultsErrorLog {
  padding: 8px;
  background-color: lightgrey;
}

.ErrorIcon {
  color: var(--delete-red);
}

.ErrorDiv {
  /* border: 1px solid var(--b-20); */
  width: 800px;
  border: 1px solid var(--grey-60);
  /* background-color: var(--grey-90); */
  /* background-color: var(--delete-red); */
  border-radius: var(--br-s);
  padding: var(--xs);
}

.ToplevelPdf table {
  border-right: none;
  border-bottom: none;
}

.PdfOutput {
  font-family: sans-serif;
  font-size: 16px;
}

</style>
