<script setup>
import { ref, reactive, } from 'vue'
import { gApp, DebugOn, WindowType, WindowStyle, InstallationSealing, Units } from './State.js'
import { formatNum, removeElem, protectedFunc, downloadTextFile, generateItemName, isDupName, } from './SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'

import { handleErrorCaptured } from './ErrorUtils.js'
import { useCaptureError } from './Composables.js'
import ErrorNotice from './ErrorNotice.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'

let proj = gApp.proj();
let windows = proj.windowTypes;
let selectedWindow = ref(null);
let deleteModal = ref(null);

function selectWindow(win) {
  selectedWindow.value = win;
}

function deleteWindow(win) {
  deleteModal.value.showModal("Delete", `Delete window type ${win.name.value}?`, () => {
    removeElem(windows, win)
  })
}

function addWindowType() {
  windows.push(WindowType.create(generateItemName('Window', windows), true))
  selectWindow(windows[windows.length - 1]);
}

function exportTestCase() {
  selectedWindow.value.exportAsTestCase();
}

</script>

<template>
  <div>
    <!-- <ErrorNotice :errorObj="errorObj" /> -->
    <HelpSection id="WindowsView">
      <h4 class="mb-s">Window Types</h4>
      <p class="mb-s">
        Use this page to build different window types for use in the Build House tab.
      </p>
      <p>
        If <b>Manual Entry</b> is used, the <b>U-value</b> and <b>SHGC</b> and must be entered for the entire fenestration product (i.e. including the frame). SHGC entry must be the direct beam SHGC value, as reported by the National Fenestration Rating Council (NFRC). Since the NFRC is a North American standard, users outside North America will need to convert from their regional standard to NFRC ratings for SHGC to get accurate results. 
        <br><br>
        With the SHGC input for direct beam, SHGC values for other angles will be estimated based on the window type.
        <br><br>
        If <b>Build Window</b> is used, it is recommended to check the U-value and SHGC outputs generated. Values are estimated based on available empirical data, and caution should be taken when considering the impacts of the outputs.
        <br><br>
        For selecting the <b>panes</b> that the low-e coatings are <b>applied to</b>, refer to the diagram below:
        <br>
        <img src="./assets/win-panes.png" class="HelpImg">
        <br>
        For three panes, the pattern continues, where surface 6 would be the surface immediately adjacent to the indoors.
        <br><br>
        <b>Frame Width</b> is the thickness of the frame as seen from the frontal view on the outside of the building; it is not related to the thickness of the wall that the window is installed in.
        <br>
        <img src="./assets/win-frame.png" class="HelpImg">
      </p>
    </HelpSection>
    <div v-if="selectedWindow">
      <div class="mb-m">
        <button @click="selectedWindow=null" class="LinkBtn mb-m"><i class="bi-arrow-left-circle mr-xxs"></i>Done Editing</button>
        <h1>{{ selectedWindow.name.value || 'Untitled' }}</h1>
      </div>
      <FieldInput v-model="selectedWindow.name" />
      <WarnText v-if="isDupName(selectedWindow.name.value, windows)">There is already a window with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <FieldInput v-model="selectedWindow.unusualShape" />
        <FieldInput v-model="selectedWindow.height" />
        <FieldInput v-model="selectedWindow.width" />
        <FieldInput v-model="selectedWindow.unusualShapeArea" />
        <FieldInput v-model="selectedWindow.unusualShapePerimeter" />
        <FieldInput v-model="selectedWindow.style" />
        <FieldInput v-model="selectedWindow.sealing" />
        <FieldInput v-model="selectedWindow.bugScreen" class="MarginBotS" />
      </InputGroup>
      <InputGroup title="U-Value & SHGC">
        <FieldInput v-model="selectedWindow.inputType" />
        <div v-if="selectedWindow.inputType.value == 'Manual'">
          <FieldInput v-model="selectedWindow.manualUValue" />
          <FieldInput v-model="selectedWindow.manualShgc" />
        </div>
        <div v-else>
          <div class="mt-m mb-xs">
            <p class="mute-header">Window Builder</p>
            <p class="help-text">Build your window here. The U-Value and SHGC will be calculated from these inputs.</p>
          </div>
          <FieldList :obj="selectedWindow.windowBuilder" class="MarginBotXS" />
        </div>
      </InputGroup>
      <InputGroup title="Results">
        <FieldInput v-model="selectedWindow.outputUValue" />
        <FieldInput v-model="selectedWindow.outputShgc" />
        <button v-if="DebugOn()" @click="exportTestCase" class="LinkBtn mt-s">Export as test case</button>
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Window Types</h1>
      <div class="mb-l Container">
        <p class="mute-header">Info</p>
        <p class="help-text">
          Add a <b>Window Type</b> for each different type of window used in your project. Then, go to the <b>Walls</b> section of <b>Build House</b> to add windows
          to the walls of your house.
        </p>
      </div>
      <button @click="addWindowType" class="MarginBotXS CreateBtn">Add window type</button>
      <table class="SimpleTable" v-if="windows.length">
        <tr>
          <th>Name</th>
          <th>Style</th>
          <th>Height</th>
          <th>Width</th>
          <th>U-Value</th>
          <th>SHGC (0 Deg)</th>
          <th>SHGC (Diff.)</th>
          <th>Installation</th>
          <th>Bug Screen</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="(window, index) in windows" class="WindowSection MarginBotL">
          <td>{{ window.name.value }}</td>
          <td>{{ WindowStyle._labels[window.style.value] }}</td>
          <td>{{ window.height.getValueStr(Units.ft) }}</td>
          <td>{{ window.width.getValueStr(Units.ft) }}</td>
          <td>{{ window.outputUValue.getValueStr() }}</td>
          <td>{{ protectedFunc(() => formatNum(window.computeShgc().Deg0), 'Error') }}</td>
          <td>{{ protectedFunc(() => formatNum(window.computeShgc().Diffuse), 'Error') }}</td>
          <td>{{ InstallationSealing._labels[window.sealing.value] }}</td>
          <td>{{ window.hasBugScreen() ? "Yes" : "No" }}</td>
          <td><button @click="selectWindow(window)">Edit</button></td>
          <td><button @click="deleteWindow(window)">Delete</button></td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Outputs {
  margin-top: var(--space-s);
}
</style>
