import { kGroundSurfaceTempAmplitudesData } from './GroundSurfaceTempAmplitudesData.js'
import { CsvHelper } from '../MaterialData/CsvHelper.js'
import Papa from 'papaparse';

function findNearestValue(csv, row, col) {
  // Do a bootleg BFS around the start row/col
  for (let radius = 1; radius < 10; radius++) {
    let offsets = [];
    for (let i = 0; i < 2*radius; ++i) {
      // 4 square edges, clockwise
      offsets.push([radius, radius - i]);
      offsets.push([radius - i, -radius]);
      offsets.push([-radius, -radius + i]);
      offsets.push([-radius + i, radius]);
    }
    for (const offset of offsets) {
      let val = csv.lookupValue(row + offset[1], col + offset[0], Number);
      if (val !== 0) {
        console.log(`Found better temp: ${val}`);
        return val;
      }
    }
  }
  console.log(`Could not find better temp`);
  return 0;
}

export class GroundSurfaceTempAmplitudes {
  static lookupValue(latitude, longitude) {
    console.log(`Looking up ground surf. temp amplitude for Lat=${latitude}, Long=${longitude}`)
    let csv = new CsvHelper(kGroundSurfaceTempAmplitudesData);

    // Find row (latitude)
    let targetRow = null;
    for (let i = 1; i + 1 < csv.numRows(); ++i) {
      // Numbers are from 90->0
      let valA = csv.lookupValue(i, 0, Number);
      let valB = csv.lookupValue(i + 1, 0, Number);
      // console.log(`Checking lat b/w ${valA} and ${valB}`)
      if (valA >= latitude && latitude >= valB) {
        // console.log("FOUND");
        targetRow = i + 1;
        break;
      }
    }
    if (targetRow === null) {
      console.log("Could not find proper row");
      return 0;
    }

    // Find col (longitude)
    let targetCol = null;
    for (let i = 1; i + 1 < csv.numCols(); ++i) {
      // Numbers are from 180->0. Convert to -180->0 (aka 180W to 0)
      let valA = -1 * csv.lookupValue(0, i, Number);
      let valB = -1 * csv.lookupValue(0, i + 1, Number);
      // console.log(`Checking long b/w ${valA} and ${valB}`)
      if (valB >= longitude && longitude >= valA) {
        // console.log("FOUND");
        targetCol = i + 1;
        break;
      }
    }
    if (targetCol === null) {
      console.log(`Could not find a proper col`);
      return 0;
    }

    let res = csv.lookupValue(targetRow, targetCol, Number);
    console.log(`Found temp: ${res} (${targetRow}, ${targetCol})`);
    
    // If we get a 0 value, means we are in water. See if there is an adjacent non-zero value
    // that we can use instead.
    if (res == 0) {
      res = findNearestValue(csv, targetRow, targetCol);
    }
    return res;
  }
};

