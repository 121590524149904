import { makeEnum, makeEnumWithData, } from './Base.js'
import { DataTable } from './DataTable.js'
import { Units, getLabel } from './Units.js'

export let DummySpaceData = { "cfm/person": 0, "L/s / person": 0, "cfm/ft^2": 0, "L/s/m^2": 0, "occupant density": 0, "air class": 0 }

export let SpaceTypes = {
  "Unknown": {
  },
  "Animal Facilities": {
    "Animal Exam Room": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 20, "air class": 2 },
    "Animal Imaging (MRI/CT/PET)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Animal Operating Rooms": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Animal Postoperative Recovery Room": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Animal Preparation Room": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Animal Surgery Scrub": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Large Animal Holding Room": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Necropsy": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Small Animal Cage Room (static cages)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 },
    "Small Animal Cage Room (ventilated cages)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 3 }
  },
  "Correctional Facilities": {
    "Booking/waiting": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 50, "air class": 2 },
    "Cell": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 25, "air class": 2 },
    "Dayroom": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 30, "air class": 1 },
    "Guard Station": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 15, "air class": 1 }
  },
  "Educational Facilities": {
    "Art Classroom": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 2 },
    "Classroom (age 5 to 8)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 25, "air class": 1 },
    "Classroom (age 9+)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 35, "air class": 1 },
    "Computer Lab": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 25, "air class": 1 },
    "Daycare sickroom": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 25, "air class": 3 },
    "Daycare (through age 4)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 25, "air class": 2 },
    "Lecture classroom": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 65, "air class": 1 },
    "Lecture Hall (fixed seats)": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 150, "air class": 1 },
    "Libraries": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 10, "air class": 1 },
    "Media Center": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 25, "air class": 1 },
    "Multiuse Assembly": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 100, "air class": 1 },
    "Music/theater/dance": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 35, "air class": 1 },
    "Science Laboratories": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 25, "air class": 2 },
    "University/college laboratories": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 25, "air class": 2 },
    "Wood/metal shop": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 20, "air class": 2 }
  },
  "Food and Beverage Service": {
    "Bar, cocktail lounge": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 100, "air class": 2 },
    "Cafeteria/fast-food dining": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 100, "air class": 2 },
    "Kitchen (cooking)": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 20, "air class": 2 },
    "Restaurant": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 70, "air class": 2 }
  },
  "General": {
    "Breakrooms": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 25, "air class": 1 },
    "Coffee Stations": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 20, "air class": 1 },
    "Conference/meeting": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 50, "air class": 1 },
    "Corridors": { "cfm/person": 0, "L/s / person": 0, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 0, "air class": 1 },
    "Occupiable storage for liquids or gels": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 2, "air class": 2 }
  },
  "Hotels, Motels, Resorts, Dormitories": {
    "Barracks sleeping areas": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 20, "air class": 1 },
    "Bedroom/living room": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 10, "air class": 1 },
    "Laundry Rooms, central": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 10, "air class": 2 },
    "Laundry Room within dwelling units": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 10, "air class": 1 },
    "Lobbies/prefunction": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 30, "air class": 1 },
    "Multipurpose assembly": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 120, "air class": 1 }
  },
  "Miscellaneous Spaces": {
    "Banks of Bank lobbies": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 15, "air class": 1 },
    "Bank vaults/safe deposit": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 5, "air class": 2 },
    "Computer (not printing)": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 4, "air class": 1 },
    "Freezer and refrigerated spaces (<50F, 10C)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0, "L/s/m^2": 0, "occupant density": 0, "air class": 2 },
    "Manufacturing where hazardous materials are not used": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 7, "air class": 2 },
    "Manufacturing where hazardous materials are used (excluding heavy industrial and chemical processes)": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 7, "air class": 3 },
    "Pharmacy (prep. Area)": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.18, "L/s/m^2": 0.9, "occupant density": 10, "air class": 2 },
    "Photo studios": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 10, "air class": 1 },
    "Shipping/receiving": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 2, "air class": 2 },
    "Sorting, packing, light assembly": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 7, "air class": 2 },
    "Telephone closets": { "cfm/person": 0, "L/s / person": 0, "cfm/ft^2": 0, "L/s/m^2": 0, "occupant density": 0, "air class": 1 },
    "Transportation waiting": { "cfm/person": 7.5, "L/s / person": 3.8, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 100, "air class": 1 },
    "Warehouses": { "cfm/person": 10, "L/s / person": 5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 0, "air class": 2 }
  },
  "Office Buildings": {
    "Breakrooms": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.12, "L/s/m^2": 0.6, "occupant density": 50, "air class": 1 },
    "Main entry lobbies": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 10, "air class": 1 },
    "Occupiable storage rooms for dry materials": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 2, "air class": 1 },
    "Office space": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 5, "air class": 1 },
    "Reception areas": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 30, "air class": 1 },
    "Telephone/data entry": { "cfm/person": 5, "L/s / person": 2.5, "cfm/ft^2": 0.06, "L/s/m^2": 0.3, "occupant density": 60, "air class": 1 },
  },
  "Public Assembly Spaces": {
    "Auditorium seating areas": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 150, "air_class": 1 },
    "Courtrooms": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 70, "air_class": 1 },
    "Legislative chambers": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 50, "air_class": 1 },
    "Libraries": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 10, "air_class": 1 },
    "Lobbies": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 150, "air_class": 1 },
    "Museums (children's)": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 40, "air_class": 1 },
    "Museums/galleries": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 40, "air_class": 1 },
    "Places of Religious Worship": { "cfm/person": 5, "L_s_person": 2.5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 120, "air_class": 1 }
  },
  "Residential": {
    "Common Corridors": { "cfm/person": 0, "L_s_person": 0, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 0, "air_class": 1 }
  },
  "Retail": {
    "Sales": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 15, "air_class": 2 },
    "Barbershop": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 25, "air_class": 2 },
    "Beauty and nail salons": { "cfm/person": 20, "L_s_person": 10, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 25, "air_class": 2 },
    "Coin-operated laundry": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 20, "air_class": 2 },
    "Mall common areas": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 40, "air_class": 1 },
    "Pet shops": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.18, "L_s_m2": 0.9, "occupant_density": 10, "air_class": 2 },
    "Supermarket": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 8, "air_class": 1 }
  },
  "Sports and Entertainment": {
    "Bowling Alley": { "cfm/person": 10, "L_s_person": 5, "cfm/ft^2": 0.12, "L_s_m2": 0.6, "occupant_density": 40, "air_class": 1 },
    "Disco/dance floors": { "cfm/person": 20, "L_s_person": 10, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 100, "air_class": 2 },
    "Gambling Casinos": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.18, "L_s_m2": 0.9, "occupant_density": 120, "air_class": 1 },
    "Game Arcades": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.18, "L_s_m2": 0.9, "occupant_density": 20, "air_class": 1 },
    "Gym, sports arena (play area)": { "cfm/person": 20, "L_s_person": 10, "cfm/ft^2": 0.18, "L_s_m2": 0.9, "occupant_density": 7, "air_class": 2 },
    "Health club/aerobics room": { "cfm/person": 20, "L_s_person": 10, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 40, "air_class": 2 },
    "Health club/weight rooms": { "cfm/person": 20, "L_s_person": 10, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 10, "air_class": 2 },
    "Spectator areas": { "cfm/person": 7.5, "L_s_person": 3.8, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 150, "air_class": 1 },
    "stages, studios": { "cfm/person": 10, "L_s_person": 5, "cfm/ft^2": 0.06, "L_s_m2": 0.3, "occupant_density": 70, "air_class": 1 },
    "Swimming (pool and deck)": { "cfm/person": 0, "L_s_person": 0, "cfm/ft^2": 0.48, "L_s_m2": 2.4, "occupant_density": 0, "air_class": 2 }
  },
}


export let SpaceTypeCategory = makeEnum({
  Unknown: `Unknown`,
  AnimalFacilities: `Animal Facilities`,
  CorrectionalFacilities: `Correctional Facilities`,
  EducationalFacilities: `Educational Facilities`,
  FoodAndBeverageService: `Food and Beverage Service`,
  General: `General`,
  HotelsMotelsResortsDormitories: `Hotels, Motels, Resorts, Dormitories`,
  MiscellaneousSpaces: `Miscellaneous Spaces`,
  OfficeBuildings: `Office Buildings`,
  PublicAssemblySpaces: `Public Assembly Spaces`,
  Residential: `Residential`,
  Retail: `Retail`,
  SportsAndEntertainment: `Sports and Entertainment`,
})

export function GetSpaceTypesForCategory(category) {
  let catCsvName = SpaceTypeCategory._labels[category];
  return Object.keys(SpaceTypes[catCsvName]);
}

export function GetSpaceTypesMultiTieredOptions() {
  // TODO - implement this
  /*
  let optionsMap = {
    type: SpaceTypeCategory,
    children: []
  }
  for (const categoryKey in SpaceTypeCategory) {
    let categoryTypesDict = {}
    for (const typeKey in SpaceTypes[categoryKey]) {
      categoryTypesDict[typeKey] = typeKey;
    }
    optionsMap.children.push({
      type: categoryKey,
      children: GetSpaceTypesForCategory(categoryKey)
    })
  }
  */
}

export function GetOccupancyTableData() {
  let data = {};
  for (const categoryName in SpaceTypes) {
    if (categoryName === 'Unknown') {
      continue;
    }
    for (const typeName in SpaceTypes[categoryName]) {
      let label = `${categoryName} / ${typeName}`;
      let typeData = SpaceTypes[categoryName][typeName];
      let occupantDensity = null;
      if ('occupant density' in typeData) {
        occupantDensity = typeData['occupant density'];
      } else if ('occupant_density' in typeData) {
        occupantDensity = typeData['occupant_density'];
      } else {
        throw new Error(`Missing occupant density for ${label}`);
      }
      data[label] = {
        label: label,
        value: occupantDensity,
      }
    }
  }
  return DataTable.create([
    {name: 'Space Type', field: 'label'},
    {name: `Occupant Density (${getLabel(Units.PeoplePer1000ft2)})`, field: 'value'},
  ], data);
}