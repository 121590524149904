import { makeEnum } from './Base.js'

/*
Recessed fluorescent luminaire without lens
Recessed fluorescent luminaire with lens
Downlight compact fluorescent luminaire
Downlight incandescent luminaire
Non-in-ceiling fluorescent luminaire
Recessed LED troffer partial aperture diffuser
Recessed LED troffer uniform diffuser
Recessed high-efficacy LED troffer
Recessed LED downlight
Recessed LED retrofit kit 2×4
Recessed LED color tuning fixture
High-bay LED fixture
Linear pendant LED fixture
Unknown
*/

export let LuminaireType = makeEnum({
  RecessedFluorescentLuminaireWithoutLens: 'Recessed fluorescent luminaire without lens',
  RecessedFluorescentLuminaireWithLens: 'Recessed fluorescent luminaire with lens',
  DownlightCompactFluorescentLuminaire: 'Downlight compact fluorescent luminaire',
  DownlightIncandescentLuminaire: 'Downlight incandescent luminaire',
  NonInCeilingFluorescentLuminaire: 'Non-in-ceiling fluorescent luminaire',
  RecessedLEDTrofferPartialApertureDiffuser: 'Recessed LED troffer partial aperture diffuser',
  RecessedLEDTrofferUniformDiffuser: 'Recessed LED troffer uniform diffuser',
  RecessedHighEfficacyLEDTroffer: 'Recessed high-efficacy LED troffer',
  RecessedLEDDownlight: 'Recessed LED downlight',
  RecessedLEDRetrofitKit2x4: 'Recessed LED retrofit kit 2×4',
  RecessedLEDColorTuningFixture: 'Recessed LED color tuning fixture',
  HighBayLEDFixture: 'High-bay LED fixture',
  LinearPendantLEDFixture: 'Linear pendant LED fixture',
  Unknown: 'Unknown',
})