import { 
  setupClass, 
  makeEnum,
} from './Base.js'

import {
  valOr
} from './SharedUtils.js'

import {
  SystemType,
  ZoneAirflowCalcMethod,
} from './System.js'

import * as Space from './Space.js'

import { Season, YesNo, RecoveryType, } from './Components.js'

import * as math from 'mathjs'
import { MatrixUtils, scalarSum, makeVector, makeMonthVector, makeHourVector, } from './Math.js'

import * as psy from './Psychrometrics.js'
import { PsyCalcMethod } from './Psychrometrics.js'

function maxInArray(arr) {
  let max = arr[0]
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      max = arr[i]
    }
  }
  return max
}

function applySpaceQuantities(matrixArr, spaceQuantities) {
  let res = []
  for (let i = 0; i < matrixArr.length; ++i) {
    res.push(math.multiply(matrixArr[i], spaceQuantities[i]))
  }
  return res
}

let HeatingOrCooling = makeEnum({
  Heating: 'Heating',
  Cooling: 'Cooling',
})

class QSupplyValues {
  constructor(q_supply_values) {
    this.q_supply_values = q_supply_values
  }

  getSystemSupplyCooling(mo, hr) {
    return this.q_supply_values.Q_supply_cooling.get([mo, hr])
  }

  getZoneSupplyCooling(zoneIndex, mo, hr) {
    return this.q_supply_values.Q_zone_supply_cooling_values[zoneIndex].get([mo, hr])
  }

  getSpaceSupplyCooling(zoneIndex, spaceIndex, mo, hr) {
    return this.q_supply_values.Q_space_supply_cooling_values[zoneIndex][spaceIndex].get([mo, hr])
  }

  getSystemSupplyCoolingPeak() {
    let peakValue = null
    let peakMo = null
    let peakHr = null
    for (let mo = 0; mo < 12; ++mo) {
      for (let hr = 0; hr < 24; ++hr) {
        let value = this.getSystemSupplyCooling(mo, hr)
        if (peakValue == null || value > peakValue) {
          peakValue = value
          peakMo = mo
          peakHr = hr
        }
      }
    }
    return {peakValue, peakMo, peakHr}
  }

  getSystemSupplyHeating() {
    return this.q_supply_values.Q_supply_heating
  }

  getZoneSupplyHeating(zoneIndex) {
    return this.q_supply_values.Q_zone_supply_heating_values[zoneIndex]
  }

  getSpaceSupplyHeating(zoneIndex, spaceIndex) {
    return this.q_supply_values.Q_space_supply_heating_values[zoneIndex][spaceIndex]
  }
}

class SpaceVentilationValues {
  constructor() {
    this.spaceIdToValues = {}
  }

  setValuesForSpaceType(spaceType, values) {
    this.spaceIdToValues[spaceType.id] = values
  }

  getValuesForSpaceType(spaceType) {
    return this.spaceIdToValues[spaceType.id]
  }

  hasValuesForSpaceType(spaceType) {
    return this.spaceIdToValues.hasOwnProperty(spaceType.id)
  }
}

class OutdoorAirIntakeValues {
  constructor(values) {
    this.values = values
  }

  get_V_ot_cooling_for_hr(hr) {
    return this.values.V_ot_cooling_by_hr[hr]
  }

  get_V_ot_heating() {
    return this.values.V_ot_heating
  }
}

class ZoneResult {
  constructor() {
    this.spaceResults = []
  }
}

export class SystemLoadCalculator {
  constructor(system, proj, ctx, debugOpts) {
    this.system = system
    this.proj = proj;
    this.ctx = ctx
    this.debugOpts = valOr(debugOpts, {})

    this.zoneResults = []
  }

  calc_T_db_out(hr, mo) {
    return this.ctx.designTemps.getCoolingDryBulbOut(this.ctx, mo, hr)
  }

  calc_T_wb_out(hr, mo) {
    return this.ctx.designTemps.getCoolingWetBulbOut(this.ctx, mo, hr)
  }

  /*
  The outdoor air percentage in supply air.
  System must be a single-zone CAV.
  */
  calc_Percent_OA(cavInputs, heatingOrCooling) {
    let ctx = this.ctx
    ctx.startSection("Percent OA")

    ctx.assert(this.system.zones.length == 1, "System must have exactly one zone")
    ctx.assert(this.system.getSystemType() == SystemType.CAV, "System must be CAV")

    ctx.M_OA = []
    this.system.forEachSpaceInZone(0, (space) => {
      ctx.startSection(`Space ${space.spaceIndex}`)
      ctx.ventilationValues = ctx.spaceVentilations.getValuesForSpaceType(space.spaceType)
      ctx.V_oz_space = heatingOrCooling == HeatingOrCooling.Heating ?
        ctx.ventilationValues.V_oz_heating : ctx.ventilationValues.V_oz_cooling;
      ctx.Q_space_supply = heatingOrCooling == HeatingOrCooling.Heating ?
        ctx.Q_supply_values.getSpaceSupplyHeating(0, space.spaceIndex) :
        ctx.Q_supply_values.getSpaceSupplyCooling(0, space.spaceIndex, 0, 0);
      if (cavInputs.zoneAirflowCalcMethod.value == ZoneAirflowCalcMethod.TotalPeak) {
        ctx.log(`Adjusting Q_space_supply because zoneAirflowCalcMethod is TotalPeak`)
        ctx.Q_zone_supply = heatingOrCooling == HeatingOrCooling.Heating ?
          ctx.Q_supply_values.getZoneSupplyHeating(0) :
          ctx.Q_supply_values.getZoneSupplyCooling(0, 0, 0);
        ctx.Q_system_supply = heatingOrCooling == HeatingOrCooling.Heating ?
          ctx.Q_supply_values.getSystemSupplyHeating() :
          ctx.Q_supply_values.getSystemSupplyCooling(0, 0);
        ctx.Q_space_supply = ctx.eval('Q_space_supply / Q_zone_supply * Q_system_supply', {
        }, 'Q_space_supply')
      }
      ctx.M_OA_space = ctx.eval('V_oz_space / Q_space_supply', {
      }, 'M_OA')
      ctx.M_OA.push(ctx.M_OA_space)
      ctx.endSection()
    });
    ctx.Percent_OA = ctx.eval('maxInArray(M_OA)', {
      maxInArray,
    }, 'Percent_OA')
    let res = ctx.Percent_OA
    ctx.endSection()
    return res
  }

  calc_V_ot_single_zone_CAV(heatingOrCooling) {
    let ctx = this.ctx
    ctx.startSection("V_ot single zone CAV")
    let cavInputs = this.system.cavSystemInputs
    // Note: for CAV systems, the Q_supply will be the same for all (mo, hr)
    if (heatingOrCooling == HeatingOrCooling.Heating) {
      ctx.Q_zone_supply = ctx.Q_supply_values.getZoneSupplyHeating(0)
    } else {
      ctx.Q_zone_supply = ctx.Q_supply_values.getZoneSupplyCooling(0, 0, 0)
    }
    ctx.Percent_OA = this.calc_Percent_OA(cavInputs, heatingOrCooling)
    ctx.V_ot = ctx.eval("Percent_OA * Q_zone_supply", {
    }, 'V_ot')
    ctx.log("(Incorrect?) For single-zone CAV, V_ot_min = V_ot")
    // TODO - fix this?
    ctx.V_ot_min = ctx.V_ot
    let res = {
      V_ot: ctx.V_ot,
      V_ot_min: ctx.V_ot_min,
    }
    ctx.endSection()
    return res
  }

  calc_V_ot_multi_zone_CAV(heatingOrCooling) {
    let ctx = this.ctx
    ctx.startSection("V_ot multi zone CAV")

    ctx.P_system = this.system.estimatedTotalOccupancy.value
    ctx.P_sum_spaces = 0
    this.system.forEachSpace((space) => {
      ctx.P_sum_spaces += space.quantity * space.spaceType.getNumOccupants();
    })
    ctx.D = ctx.eval('P_system/max(1, P_sum_spaces)', {
    }, 'D')

    // TODO - left off here
    // Wait to here Magnus's comment on how to account for quantity of each space
    ctx.innerSumA = 0
    ctx.innerSumB = 0
    ctx.V_ou = ctx.eval('D*innerSumA + innerSumB', {
    }, 'V_ou')
    ctx.E_v = ctx.eval('max(0.75, 0.6*D + 0.22)', {}, 'E_v')
    ctx.V_ot = ctx.eval('V_ou / E_v', {
    }, 'V_ot')
    // TODO - is this correct?
    ctx.V_ou_min = ctx.eval('V_ou', {}, 'V_ou_min')
    ctx.V_ot_min = ctx.eval('V_ou_min / E_v', {
    }, 'V_ot_min')
    let res = {
      V_ot: ctx.V_ot,
      V_ot_min: ctx.V_ot_min,
    }
    ctx.endSection()
    return res
  }

  calc_V_ot(heatingOrCooling) {
    let ctx = this.ctx
    ctx.startSection(`Calc V_ot (Outdoor air intake) - ${heatingOrCooling}`)
    if (this.system.getSystemType() == SystemType.CAV) {
      if (this.system.zones.length == 1) {
        ctx.V_ot_results = this.calc_V_ot_single_zone_CAV(heatingOrCooling)
      } else if (this.system.zones.length > 1) {
        ctx.V_ot_results = this.calc_V_ot_multi_zone_CAV(heatingOrCooling)
      } else {
        throw new Error("The system has no zones")
      }
    } else if (this.system.getSystemType() == SystemType.VAV) {
      // VAV is treated the same as multi-zone CAV
      ctx.V_ot_results = this.calc_V_ot_multi_zone_CAV(heatingOrCooling)
    } else {
      throw new Error("Unsupported system type: " + this.system.getSystemType())
    }
    let res = ctx.V_ot_results
    ctx.endSection()
    return res
  }

  calc_V_ot_values() {
    let ctx = this.ctx
    ctx.startSection("Calc V_ot values")

    // Calculate V_ot and V_ot_min for heating and cooling
    let V_ot_heating_values = this.calc_V_ot(HeatingOrCooling.Heating)
    ctx.V_ot_heating = V_ot_heating_values.V_ot
    ctx.V_ot_heating_min = V_ot_heating_values.V_ot_min

    let V_ot_cooling_values = this.calc_V_ot(HeatingOrCooling.Cooling)
    ctx.V_ot_cooling = V_ot_cooling_values.V_ot
    ctx.V_ot_cooling_min = V_ot_cooling_values.V_ot_min

    // Apply "Change ventilation for modes" setting.
    if (this.system.changeVentilationForModes.value == YesNo.No) {
      ctx.log("Change ventilation for modes is No, so take the maxes")
      ctx.V_ot_max = ctx.eval('max(V_ot_heating, V_ot_cooling)', {
      }, 'V_ot_max')
      ctx.V_ot_min_max = ctx.eval('max(V_ot_heating_min, V_ot_cooling_min)', {
      }, 'V_ot_min_max')
      ctx.V_ot_heating = ctx.eval('V_ot_max', {}, 'V_ot_heating')
      ctx.V_ot_cooling = ctx.eval('V_ot_max', {}, 'V_ot_cooling')
      ctx.V_ot_cooling_min = ctx.eval('V_ot_min_max', {}, 'V_ot_cooling_min')
    } else {
      ctx.log("Change ventilation for modes is Yes, so keep the values separate")
    }

    // If Demand-controlled ventilation is on, apply it here.
    if (this.system.usingDemandControlledVentilation()) {
      ctx.log("Using demand-controlled ventilation. V_ot values depend on time.")
      ctx.startSection("Demand-controlled ventilation")
      ctx.V_ot_cooling_by_hr = makeVector(24)
      ctx.schedule = this.system.getDemandControlledVentilationSchedule(ctx.systemOccupancySchedule)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        let V_ot_hr = ctx.eval('V_ot_cooling + S*(V_ot_cooling - V_ot_cooling_min)', {
          S: ctx.schedule[hr],
        }, 'V_ot_hr')
        ctx.V_ot_cooling_by_hr[hr] = V_ot_hr
        ctx.endSection()
      }
      ctx.logValue('V_ot_cooling_by_hr', ctx.V_ot_cooling_by_hr)
      ctx.endSection()
    } else {
      ctx.log("Not using demand-controlled ventilation. V_ot is not time-dependent.")
      ctx.V_ot_cooling_by_hr = makeVector(24).fill(ctx.V_ot_cooling)
    }

    let res = new OutdoorAirIntakeValues({
      V_ot_heating: ctx.V_ot_heating,
      V_ot_cooling_by_hr: ctx.V_ot_cooling_by_hr,
    })
    ctx.endSection()
    return res
  }

  getSpaceResults(zoneIndex, spaceIndex) {
    return this.zoneResults[zoneIndex].spaceResults[spaceIndex]
  }

  getSpaceType(zoneIndex, spaceIndex) {
    return this.system.zones[zoneIndex].spaces[spaceIndex].getSpaceType()
  }

  calcSpaceVentilations() {
    let ctx = this.ctx
    ctx.startSection("Calc all space ventilations")
    let spaceVentilations = new SpaceVentilationValues()
    this.system.forEachSpace((space) => {
      ctx.startSection(`Zone ${space.zoneIndex}, Space ${space.spaceIndex}`)
      if (spaceVentilations.hasValuesForSpaceType(space.spaceType)) {
        ctx.log("Already have values for this space type")
        return;
      }
      ctx.ventilations = space.spaceType.ventilation.calc_Ventilation_values(ctx)
      spaceVentilations.setValuesForSpaceType(space.spaceType, ctx.ventilations)
      ctx.endSection()
    })
    let res = spaceVentilations
    ctx.endSection()
    return res
  }

  calc_Q_supply_cool_space(zoneIndex, spaceIndex) {
    let ctx = this.ctx
    ctx.startSection(`Q_supply_cool_space - Zone ${zoneIndex} Space ${spaceIndex}`)
    
    let space = this.getSpaceType(zoneIndex, spaceIndex)
    let spaceResults = this.getSpaceResults(zoneIndex, spaceIndex)
    let q_cool_space_matrix = spaceResults.cooling.q_sensible
    ctx.v_supply_cool = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.90
      }).v
    ctx.V_pz_min_space = ctx.spaceVentilations.getValuesForSpaceType(space).V_pz_min_cooling

    ctx.Q_supply_cool_space = math.zeros(12, 24)
    for (let mo = 0; mo < 12; ++mo) {
      ctx.startSection(`Month ${mo}`)
      for (let hr = 0; hr < 24; ++hr) {
        ctx.startSection(`Hour ${hr}`)
        ctx.T_cool_zone = this.calc_T_zone_cooling(zoneIndex, hr)
        ctx.Q_supply = ctx.eval('max(q_cool_space*v_supply_cool/(60*0.24*(T_cool_zone - T_supply_cool)), ' +
          'V_pz_min_space)', {
            q_cool_space: q_cool_space_matrix.get([mo, hr]),
          }, 'Q_supply')
        ctx.Q_supply_cool_space.set([mo, hr], ctx.Q_supply)
        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('Q_supply_cool_space', ctx.Q_supply_cool_space)
    let res = ctx.Q_supply_cool_space
    ctx.endSection()
    return res
  }

  calc_Q_supply_heat_space(zoneIndex, spaceIndex) {
    let ctx = this.ctx
    ctx.startSection(`Q_supply_heat_space - Zone ${zoneIndex} Space ${spaceIndex}`)

    let space = this.getSpaceType(zoneIndex, spaceIndex)
    let spaceResults = this.getSpaceResults(zoneIndex, spaceIndex)
    ctx.q_heat_space = spaceResults.heating.q_sensible;
    ctx.v_supply_heat = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.20,
      }).v
    ctx.T_heat_zone_occupied = this.calc_T_zone_heating(zoneIndex)
    ctx.T_heat_zone_unoccupied = this.calc_T_zone_heating_unoccupied(zoneIndex)
    ctx.log("Set T_heat_zone to the max of occupied and unoccupied temperatures")
    ctx.T_heat_zone = ctx.eval('max(T_heat_zone_occupied, T_heat_zone_unoccupied)', {
    }, 'T_heat_zone')
    ctx.V_pz_min_space = ctx.spaceVentilations.getValuesForSpaceType(space).V_pz_min_heating
    ctx.Q_supply_heat_space = ctx.eval('max(q_heat_space*v_supply_heat/(60*0.24*(T_supply_heat - T_heat_zone)), ' +
      'V_pz_min_space)', {
      }, 'Q_supply_heat_space')
    
    let res = ctx.Q_supply_heat_space
    ctx.endSection()
    return res
  }

  get_Q_supply_cool_spaces(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection("Q_supply_cool_spaces")
    let data = []
    this.system.forEachSpaceInZone(zoneIndex, (space) => {
      let Q_supply_cool_space = this.calc_Q_supply_cool_space(space.zoneIndex, space.spaceIndex)
      data.push(Q_supply_cool_space)
    })
    ctx.endSection()
    return data
  }

  get_Q_supply_heat_spaces(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection("Q_supply_heat_spaces")
    let data = []
    this.system.forEachSpaceInZone(zoneIndex, (space) => {
      let Q_supply_heat_space = this.calc_Q_supply_heat_space(space.zoneIndex, space.spaceIndex)
      data.push(Q_supply_heat_space)
    })
    ctx.endSection()
    return data
  }

  calc_Q_supply_CAV() {
    /*
    Note: For CAV, Q_supply_cooling is a matrix (of all the same value) and Q_supply_heating is a scalear
    */
    let ctx = this.ctx;
    ctx.startSection("Calc Q_supply CAV")
    ctx.separateAirflows = this.system.separateAirflow.value;
    ctx.zoneAirflowCalcMethod = this.system.cavSystemInputs.zoneAirflowCalcMethod.value;

    ctx.Q_zone_supply_cooling_values = []
    ctx.Q_zone_supply_heating_values = []

    ctx.Q_space_supply_cooling_values = []
    ctx.Q_space_supply_heating_values = []

    this.system.forEachZone((zone) => {
      ctx.startSection("Zone " + zone.zoneIndex)

      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces(zone.zoneIndex)
      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces(zone.zoneIndex)
      ctx.spaceQuantities = zone.zone.getSpaceQuantities()

      ctx.Q_space_supply_cooling_values.push(Q_supply_cooling_arr)
      ctx.Q_space_supply_heating_values.push(Q_supply_heating_arr)
      
      if (ctx.zoneAirflowCalcMethod == ZoneAirflowCalcMethod.SumOfPeaks) {
        let Q_zone_supply_cooling = ctx.eval('sumOfMatrixMaxs(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities))', {
          sumOfMatrixMaxs: MatrixUtils.sumOfMatrixMaxs,
          applySpaceQuantities,
          Q_supply_cooling_arr,
        }, 'Q_zone_supply_cooling')

        let Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
          sum: scalarSum,
          applySpaceQuantities,
          Q_supply_heating_arr,
        }, 'Q_zone_supply_heating')

        if (ctx.separateAirflows == YesNo.Yes) {
          ctx.log("Separate airflows")
          ctx.Q_zone_supply_cooling = Q_zone_supply_cooling
          ctx.Q_zone_supply_heating = Q_zone_supply_heating
        } else {
          ctx.log("Combined airflows, taking max")
          let max_Q_zone_supply = Math.max(Q_zone_supply_cooling, Q_zone_supply_heating)
          ctx.Q_zone_supply_cooling = max_Q_zone_supply
          ctx.Q_zone_supply_heating = max_Q_zone_supply
        }
      } else if (ctx.zoneAirflowCalcMethod == ZoneAirflowCalcMethod.TotalPeak) {
        let Q_zone_supply_cooling = ctx.eval('maxInMatrix(matrixSum(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities)))', {
          maxInMatrix: MatrixUtils.maxInMatrix,
          matrixSum: MatrixUtils.sumOfMatrices,
          applySpaceQuantities,
          Q_supply_cooling_arr,
        }, 'Q_zone_supply_cooling')

        let Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
          sum: scalarSum,
          Q_supply_heating_arr,
          applySpaceQuantities,
        }, 'Q_zone_supply_heating')

        if (ctx.separateAirflows == YesNo.Yes) {
          ctx.log("Separate airflows")
          ctx.Q_zone_supply_cooling = Q_zone_supply_cooling
          ctx.Q_zone_supply_heating = Q_zone_supply_heating
        } else {
          ctx.log("Combined airflows, taking max")
          let max_Q_zone_supply = Math.max(Q_zone_supply_cooling, Q_zone_supply_heating)
          ctx.Q_zone_supply_cooling = max_Q_zone_supply
          ctx.Q_zone_supply_heating = max_Q_zone_supply
        }
      } else {
        throw new Error("Unsupported zoneAirflowCalcMethod: " + ctx.zoneAirflowCalcMethod)
      }

      ctx.log("Making Q_supply_cooling matrix from value")
      ctx.Q_zone_supply_cooling = ctx.eval('makeMatrixOfValue(12, 24, Q_zone_supply_cooling)', {
        makeMatrixOfValue: MatrixUtils.makeMatrixOfValue,
      }, 'Q_supply_cooling')

      ctx.Q_zone_supply_cooling_values.push(ctx.Q_zone_supply_cooling)
      ctx.Q_zone_supply_heating_values.push(ctx.Q_zone_supply_heating)
      ctx.endSection()
    })

    ctx.Q_supply_cooling = ctx.eval('matrixSum(Q_zone_supply_cooling_values)', {
      matrixSum: MatrixUtils.sumOfMatrices,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
    }, 'Q_supply_cooling')
    ctx.Q_supply_heating = ctx.eval('sum(Q_zone_supply_heating_values)', {
      sum: scalarSum,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
    }, 'Q_supply_heating')

    ctx.logLoadMatrix('Q_supply_cooling', ctx.Q_supply_cooling)
    ctx.logValue('Q_supply_heating', ctx.Q_supply_heating)

    let res = {
      Q_space_supply_cooling_values: ctx.Q_space_supply_cooling_values,
      Q_space_supply_heating_values: ctx.Q_space_supply_heating_values,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply_VAV() {
    /*
    Note: For VAV, Q_supply_cooling is a matrix, and Q_supply_heating is a scalar
    */
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply VAV")

    ctx.Q_zone_supply_cooling_values = []
    ctx.Q_zone_supply_heating_values = []

    ctx.Q_space_supply_cooling_values = []
    ctx.Q_space_supply_heating_values = []

    this.system.forEachZone((zone) => {
      ctx.startSection("Zone " + zone.zoneIndex)

      let Q_supply_cooling_arr = this.get_Q_supply_cool_spaces(zone.zoneIndex)
      let Q_supply_heating_arr = this.get_Q_supply_heat_spaces(zone.zoneIndex)
      ctx.spaceQuantities = zone.zone.getSpaceQuantities()

      ctx.Q_space_supply_cooling_values.push(Q_supply_cooling_arr)
      ctx.Q_space_supply_heating_values.push(Q_supply_heating_arr)

      ctx.Q_zone_supply_cooling = ctx.eval('matrixSum(applySpaceQuantities(Q_supply_cooling_arr, spaceQuantities))', {
        matrixSum: MatrixUtils.sumOfMatrices,
        applySpaceQuantities,
        Q_supply_cooling_arr,
      }, 'Q_zone_supply_cooling')

      ctx.Q_zone_supply_heating = ctx.eval('sum(applySpaceQuantities(Q_supply_heating_arr, spaceQuantities))', {
        sum: scalarSum,
        applySpaceQuantities,
        Q_supply_heating_arr,
      }, 'Q_zone_supply_heating')

      ctx.Q_zone_supply_cooling_values.push(ctx.Q_zone_supply_cooling)
      ctx.Q_zone_supply_heating_values.push(ctx.Q_zone_supply_heating)
      ctx.endSection()
    })

    ctx.Q_supply_cooling = ctx.eval('matrixSum(Q_zone_supply_cooling_values)', {
      matrixSum: MatrixUtils.sumOfMatrices,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
    }, 'Q_supply_cooling')
    ctx.Q_supply_heating = ctx.eval('sum(Q_zone_supply_heating_values)', {
      sum: scalarSum,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
    }, 'Q_supply_heating')

    ctx.logLoadMatrix('Q_supply_cooling', ctx.Q_supply_cooling)
    ctx.logValue('Q_supply_heating', ctx.Q_supply_heating)

    let res = {
      Q_space_supply_cooling_values: ctx.Q_space_supply_cooling_values,
      Q_space_supply_heating_values: ctx.Q_space_supply_heating_values,
      Q_zone_supply_cooling_values: ctx.Q_zone_supply_cooling_values,
      Q_zone_supply_heating_values: ctx.Q_zone_supply_heating_values,
      Q_supply_cooling: ctx.Q_supply_cooling,
      Q_supply_heating: ctx.Q_supply_heating,
    }
    ctx.endSection()
    return res
  }

  calc_Q_supply() {
    let ctx = this.ctx
    ctx.startSection("Calc Q_supply")

    ctx.systemType = this.system.getSystemType()
    if (ctx.systemType == SystemType.CAV) {
      ctx.res = this.calc_Q_supply_CAV()
    } else if (ctx.systemType == SystemType.VAV) {
      ctx.res = this.calc_Q_supply_VAV()
    } else {
      throw new Error("Unsupported system type: " + ctx.systemType)
    }

    let res = new QSupplyValues(ctx.res)
    ctx.endSection()
    return res
  }

  isSystemOccupied(hr) {
    return this.ctx.systemOccupancySchedule[hr] > 0;
  }

  // System occupancy
  calc_O_system(hr) {
    let ctx = this.ctx
    ctx.startSection("Calc O_system")
    ctx.O_system = 0
    this.system.forEachSpace((space) => {
      ctx.O_space = space.spaceType.getNumOccupants()
      ctx.S_space = space.spaceType.getOccupancySchedule().getData()[hr]
      ctx.O_space = ctx.eval('N*O_space*S_space', {
        N: space.quantity,
      }, 'O_space')
      ctx.O_system += ctx.O_space
    })
    let res = ctx.O_system
    ctx.endSection()
    return res
  }

  calc_T_zone_cooling(zoneIndex, hr) {
    let ctx = this.ctx
    ctx.startSection(`Calc T_zone cooling - Zone ${zoneIndex}, Hr ${hr}`)
    let zone = this.system.zones[zoneIndex]
    ctx.systemOccupied = this.isSystemOccupied(hr)
    ctx.T_zone = ctx.systemOccupied ? zone.summerTempOccupied.value : zone.summerTempUnoccupied.value
    let res = ctx.T_zone
    ctx.endSection()
    return res
  }

  calc_T_zone_heating(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection(`Calc T_zone heating`)
    let zone = this.system.zones[zoneIndex]
    // Note: for heating calcs, we always use the occupied temperature
    ctx.log("Assuming system is occupied")
    ctx.T_zone = zone.winterTempOccupied.value;
    let res = ctx.T_zone;
    ctx.endSection()
    return res
  }

  calc_T_zone_heating_unoccupied(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection(`Calc T_zone heating unoccupied`)
    let zone = this.system.zones[zoneIndex]
    ctx.T_zone = zone.winterTempUnoccupied.value
    let res = ctx.T_zone
    ctx.endSection()
    return res
  }

  calc_v_zone_cooling(zoneIndex, hr) {
    let ctx = this.ctx
    ctx.startSection(`Calc v_zone cooling - Zone ${zoneIndex}, Hr ${hr}`)
    let zone = this.system.zones[zoneIndex]
    ctx.T_zone = this.calc_T_zone_cooling(zoneIndex, hr)
    ctx.Humidity_setpoint_zone = zone.humiditySetpoint.value / 100.0
    ctx.v_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: ctx.Humidity_setpoint_zone,
      }).v
    let res = ctx.v_zone
    ctx.endSection()
    return res
  }

  calc_v_zone_heating(zoneIndex) {
    let ctx = this.ctx
    ctx.startSection(`Calc v_zone heating - Zone ${zoneIndex}`)
    ctx.T_zone = this.calc_T_zone_heating(zoneIndex)
    ctx.v_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.30,
      }).v
    let res = ctx.v_zone
    ctx.endSection()
    return res
  }

  calc_W_return_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc W_return")
    ctx.require('m_return_zones')
    ctx.require('m_return')

    ctx.W_return = 0
    this.system.forEachZone((zone) => {
      ctx.startSection("Zone " + zone.zoneIndex)
      ctx.T_zone = this.calc_T_zone_cooling(zone.zoneIndex, hr)
      ctx.Humidity_setpoint_zone = zone.zone.humiditySetpoint.value / 100.0
      ctx.W_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: ctx.Humidity_setpoint_zone,
        }).W
      ctx.m_return_zone = ctx.m_return_zones[zone.zoneIndex]
      ctx.W_return_item = ctx.eval('W_zone*m_return_zone', {
      }, 'W_return_item')
      ctx.W_return += ctx.W_return_item
      ctx.endSection()
    })
    ctx.W_return /= ctx.m_return
    let res = ctx.W_return
    ctx.endSection()
    return res
  }

  calc_W_return_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc W_return")
    ctx.require('m_return_zones')
    ctx.require('m_return')

    ctx.W_return = 0
    this.system.forEachZone((zone) => {
      ctx.startSection("Zone " + zone.zoneIndex)
      ctx.log("Note: We assume winter humidity is 0.30 in all zones")
      ctx.T_zone = this.calc_T_zone_heating(zone.zoneIndex)
      ctx.W_zone = ctx.call(psy.CalcPsychrometrics, ctx.T_zone, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: 0.30,
        }).W
      ctx.m_return_zone = ctx.m_return_zones[zone.zoneIndex]
      ctx.W_return_item = ctx.eval('W_zone*m_return_zone', {
      }, 'W_return_item')
      ctx.W_return += ctx.W_return_item
      ctx.endSection()
    })
    ctx.W_return /= ctx.m_return
    let res = ctx.W_return
    ctx.endSection()
    return res
  }

  calc_q_plenum_matrix() {
    let ctx = this.ctx
    ctx.startSection("Calc q_plenum matrix")
    ctx.q_plenum = math.zeros(12, 24)
    this.system.forEachSpace((space) => {
      ctx.startSection(`Zone ${space.zoneIndex}, Space ${space.spaceIndex}`)
      let results = this.getSpaceResults(space.zoneIndex, space.spaceIndex)
      ctx.item = results.plenum_loads
      ctx.item = math.multiply(ctx.item, space.quantity)
      ctx.q_plenum = math.add(ctx.q_plenum, ctx.item)
      ctx.endSection()
    })
    let res = ctx.q_plenum
    ctx.logLoadMatrix('q_plenum', ctx.q_plenum)
    ctx.endSection()
    return res
  }

  calc_q_plenum(mo, hr) {
    return this.ctx.q_plenum_matrix.get([mo, hr])
  }

  calc_q_fan_supply() {
    let ctx = this.ctx
    ctx.startSection("Calc q_fan_supply")
    let systemFans = this.system.systemFans
    if (systemFans.useSupplyFan.value == YesNo.No) {
      return 0;
    }
    let supplyFan = systemFans.supplyFan
    if (supplyFan.motorIsInAirStream.value == YesNo.Yes) {
      ctx.log("Motor is in air stream")
      ctx.q_fan_supply = ctx.eval('2545*P_M_supply', {
        P_M_supply: supplyFan.power.value,
      }, 'q_fan_supply')
    } else {
      ctx.log("Motor is not in air stream")
      ctx.require('m_recirc')
      ctx.require('m_vent')
      ctx.P_A_F_supply = ctx.eval('0.00209*(m_recirc+m_vent)*p_supply', {
        p_supply: supplyFan.externalStaticPressure.value,
      }, 'A_F_supply')
      ctx.P_F_supply = ctx.eval('P_A_supply/n_F_supply', {
        n_F_supply: supplyFan.fanEfficiency.value / 100.0,
      }, 'P_F_supply')
      ctx.q_fan_supply = ctx.eval('2545*P_F_supply', {}, 'q_fan_supply')
    }
    let res = ctx.q_fan_supply
    ctx.endSection()
    return res
  }

  calc_q_fan_return(m_return) {
    let ctx = this.ctx
    ctx.startSection("Calc q_fan_return")
    let systemFans = this.system.systemFans
    if (systemFans.useReturnFan.value == YesNo.No) {
      ctx.log("No return fan")
      return 0;
    }
    let returnFan = systemFans.returnFan
    if (returnFan.motorIsInAirStream.value == YesNo.Yes) {
      ctx.log("Motor is in air stream")
      ctx.q_fan_return = ctx.eval('2545*P_M_return', {
        P_M_return: returnFan.power.value,
      }, 'q_fan_return')
    } else {
      ctx.log("Motor is not in air stream")
      ctx.m_return = m_return
      ctx.P_A_return = ctx.eval('0.00209*m_return*p_return', {
        p_return: returnFan.externalStaticPressure.value,
      }, 'P_A_return')
      ctx.P_F_return = ctx.eval('P_A_return/n_F_return', {
        n_F_return: returnFan.fanEfficiency.value / 100.0,
      }, 'P_F_return')
      ctx.q_fan_return = ctx.eval('2545*P_F_return', {
      }, 'q_fan_return')
    }

    let res = ctx.q_fan_return
    ctx.endSection()
    return res
  }

  calc_W_supply_cooling() {
    let ctx = this.ctx
    ctx.startSection("Calc W_supply")

    ctx.require('T_supply_cool')
    ctx.W_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.95,
    }).W

    // For version 2:
    /*
    ctx.require('v_supply')
    ctx.require('peak_Q_supply')
    ctx.require('peak_Q_supply_mo')
    ctx.require('peak_Q_supply_hr')
    let peakMo = ctx.peak_Q_supply_mo
    let peakHr = ctx.peak_Q_supply_hr
    ctx.mixValuesAtPeak = this.calc_mix_values_cooling(peakMo, peakHr)
    ctx.T_mix_peak = ctx.mixValuesAtPeak.T_mix
    ctx.W_return_peak = ctx.mixValuesAtPeak.W_return
    // TODO - how to get this?
    ctx.q_lat_peak = 0
    ctx.T_db_avg_peak = ctx.eval('(T_supply_cool+T_mix_peak)/2.0', {
    }, 'T_db_avg_peak')
    ctx.W_supply = ctx.eval('W_return_peak - q_lat_peak/(60*peak_Q_supply*(1.0/v_supply))*' +
      '(1061+0.444*T_db_avg_peak)', {
      }, 'W_supply')
    */

    let res = ctx.W_supply
    ctx.endSection()
    return res
  }

  calc_m_return_zones_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc m_return cooling")

    let m_return_zones = []
    this.system.forEachZone((zone) => {
      ctx.startSection(`Zone ${zone.zoneIndex}`)
      ctx.Q_supply_zone = ctx.Q_supply_values.getZoneSupplyCooling(zone.zoneIndex, mo, hr)
      ctx.v_zone = this.calc_v_zone_cooling(zone.zoneIndex, hr)
      ctx.m_return_zone = ctx.eval('Q_supply_zone/v_zone', {
      }, 'm_return_zone')
      m_return_zones.push(ctx.m_return_zone)
      ctx.endSection()
    })

    let res = m_return_zones
    ctx.endSection()
    return res
  }

  calc_m_return_zones_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc m_return heating")

    let m_return_zones = []
    this.system.forEachZone((zone) => {
      ctx.startSection(`Zone ${zone.zoneIndex}`)
      ctx.Q_supply_zone = ctx.Q_supply_values.getZoneSupplyHeating(zone.zoneIndex)
      ctx.v_zone = this.calc_v_zone_heating(zone.zoneIndex)
      ctx.m_return_zone = ctx.eval('Q_supply_zone/v_zone', {
      }, 'm_return_zone')
      m_return_zones.push(ctx.m_return_zone)
      ctx.endSection()
    })

    let res = m_return_zones
    ctx.endSection()
    return res
  }

  calc_return_values_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc return values cooling")

    ctx.m_return_zones = this.calc_m_return_zones_cooling(mo, hr)
    ctx.m_return = ctx.eval('sum(m_return_zones)', {
      sum: scalarSum,
    }, 'm_return')

    ctx.Q_supply = ctx.Q_supply_cooling.get([mo, hr])

    let innerSumElems = []
    this.system.forEachZone((zone) => {
      let T_zone = this.calc_T_zone_cooling(zone.zoneIndex, hr)
      let Q_supply_zone = ctx.Q_supply_values.getZoneSupplyCooling(zone.zoneIndex, mo, hr)
      innerSumElems.push({
        T_zone: T_zone,
        Q_supply_zone: Q_supply_zone,
      })
    })
    ctx.innerSum = ctx.evalSum('T_zone*Q_zone_supply', innerSumElems, 'innerSum')  
    ctx.q_plenum = this.calc_q_plenum(mo, hr)
    ctx.q_fan_return = this.calc_q_fan_return(ctx.m_return)
    ctx.T_return = ctx.eval('(1.0/Q_supply)*innerSum + (q_plenum+q_fan_return)/'+
      '(60*0.24*m_return)', {
    }, 'T_return')
    ctx.W_return = this.calc_W_return_cooling(mo, hr)
    ctx.h_return = ctx.eval('0.24*T_return+W_return*(1061+0.444*T_return)', {
    }, 'h_return')
    ctx.v_return = ctx.call(psy.CalcPsychrometrics, ctx.T_return, ctx.altitude,
      PsyCalcMethod.CalcWithHumidityRatio, {
        W: ctx.W_return,
      }).v

    let res = {
      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,
    }
    ctx.endSection()
    return res
  }

  calc_return_values_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc return values heating")

    ctx.m_return_zones = this.calc_m_return_zones_heating()
    ctx.m_return = ctx.eval('sum(m_return_zones)', {
      sum: scalarSum,
    }, 'm_return')
    ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyHeating()

    let innerSumElems = []
    this.system.forEachZone((zone) => {
      let T_zone = this.calc_T_zone_heating(zone.zoneIndex)
      let Q_supply_zone = ctx.Q_supply_values.getZoneSupplyHeating(zone.zoneIndex)
      innerSumElems.push({
        T_zone: T_zone,
        Q_supply_zone: Q_supply_zone,
      })
    })
    ctx.innerSum = ctx.evalSum('T_zone*Q_zone_supply', innerSumElems, 'innerSum')  
    ctx.q_fan_return = this.calc_q_fan_return(ctx.m_return)
    ctx.T_return = ctx.eval('(1.0/Q_supply)*innerSum + q_fan_return/'+
      '(60*0.24*m_return)', {
    }, 'T_return')
    ctx.W_return = this.calc_W_return_heating()
    ctx.h_return = ctx.eval('0.24*T_return+W_return*(1061+0.444*T_return)', {
    }, 'h_return')
    ctx.v_return = ctx.call(psy.CalcPsychrometrics, ctx.T_return, ctx.altitude,
      PsyCalcMethod.CalcWithHumidityRatio, {
        W: ctx.W_return,
      }).v

    let res = {
      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,
    }

    ctx.endSection()
    return res
  }

  calc_mix_values_cooling(mo, hr) {
    let ctx = this.ctx
    ctx.startSection("Calc mix values cooling")

    let heatRecovery = this.system.heatRecovery;
    ctx.recoveryType = heatRecovery.recoveryType.value

    let t_db_out = this.calc_T_db_out(hr, mo)
    let t_wb_out = this.calc_T_wb_out(hr, mo)
    let psychrometrics = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithWetBulbTemp, {
        t_wb_F: t_wb_out
      })
    ctx.h_oa = psychrometrics.h
    ctx.W_oa = psychrometrics.W
    ctx.T_oa = t_db_out

    ctx.W_sat_vent = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
      RH: 1.0,
    }).W

    let returnValues = this.calc_return_values_cooling(mo, hr)
    ctx.T_return = returnValues.T_return
    ctx.W_return = returnValues.W_return
    ctx.h_return = returnValues.h_return
    ctx.v_return = returnValues.v_return

    ctx.V_ot_hr = ctx.V_ot_values.get_V_ot_cooling_for_hr(hr)
    ctx.m_vent = ctx.eval('V_ot_hr*air_density', {
    }, 'm_vent')
    ctx.Q_return = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)
    ctx.m_recirc = ctx.eval('(Q_return-V_ot_hr)/v_return', {
    }, 'm_recirc')
    if (ctx.recoveryType != RecoveryType.None) {
      // These vars are only needed if RecoveryType is not None
      ctx.Q_exhaust = heatRecovery.calc_Q_exhaust(ctx, ctx.V_ot_hr)
      ctx.m_exhaust = ctx.eval('Q_exhaust/v_return', {
      }, 'm_exhaust')
      ctx.m_min = ctx.eval('min(m_exhaust, m_vent)', {
      }, 'm_min')
    }

    if (ctx.recoveryType == RecoveryType.None) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type None`)
      ctx.h_vent = ctx.h_oa
      ctx.T_vent = ctx.T_oa
      ctx.W_vent = ctx.W_oa
    } else if (ctx.recoveryType == RecoveryType.HRV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type HRV`)
      let hrvInputs = heatRecovery.hrvGroup
      ctx.W_vent = ctx.eval('min(W_oa, W_sat_vent)', {
      }, 'W_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/m_vent * (T_oa-T_return))', {
        epsilon_s: hrvInputs.get('summerEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.h_vent = ctx.eval('0.24*T_vent+W_vent*(1061+0.444*T_vent)', {
      }, 'h_vent')
    } else if (ctx.recoveryType == RecoveryType.ERV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type ERV`)
      let ervInputs = heatRecovery.ervGroup
      ctx.h_vent = ctx.eval('h_oa-(epsilon_T*m_min/m_vent*(h_oa-h_return))', {
        epsilon_T: ervInputs.get('summerTotalEfficiency').value / 100.0,
      }, 'h_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/m_vent*(T_oa-T_return))', {
        epsilon_s: ervInputs.get('summerSensibleEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_vent = ctx.eval('min((h_vent-0.24*T_vent)/(1061+0.444*T_vent), W_sat_vent)', {
      }, 'W_vent')
    } else {
      throw new Error("Unsupported recovery type: " + recoveryType)
    }

    ctx.usingEconomizer = this.system.isUsingEconomizer();
    let economizerOn = ctx.usingEconomizer ? ctx.h_vent <= ctx.h_return : false;
    if (economizerOn) {
      ctx.log("Economizer on (h_vent <= h_return)")
      ctx.F_max = this.system.getEconomizerMaxOutdoorAirFraction()
      ctx.h_mix = ctx.eval('F_max*h_oa+(1-F_max)/h_oa', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('F_max*W_oa+(1-F_max)*W_oa', {
      }, 'W_mix')
    } else {
      ctx.log("Economizer off")
      ctx.h_mix = ctx.eval('(h_vent*m_vent + h_return*m_recirc)/(m_vent+m_recirc)', {
      }, 'h_mix')
      ctx.W_mix = ctx.eval('(W_vent*m_vent+W_return*m_recirc)/(m_vent+m_recirc)', {
      }, 'W_mix')
    }
    
    ctx.T_mix = ctx.eval('(h_mix - 1061*W_mix)/(0.24+0.444*W_mix)', {
    }, 'T_mix')

    let res = {
      h_mix: ctx.h_mix,
      W_mix: ctx.W_mix,
      T_mix: ctx.T_mix,

      T_return: ctx.T_return,
      W_return: ctx.W_return,
      h_return: ctx.h_return,
      v_return: ctx.v_return,
    }
    ctx.endSection()
    return res
  }

  async calc_q_cooling_system() {
    let ctx = this.ctx
    ctx.startSection("Calc q_cooling_system")

    ctx.q_cooling_sens = math.zeros(12, 24)
    ctx.q_cooling_lat = math.zeros(12, 24)

    let peakValues = ctx.Q_supply_values.getSystemSupplyCoolingPeak()
    ctx.peak_Q_supply= peakValues.peakValue
    ctx.peak_Q_supply_mo = peakValues.peakMo
    ctx.peak_Q_supply_hr = peakValues.peakHr

    ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_cool, ctx.altitude,
      PsyCalcMethod.CalcWithRelativeHumidity, {
        RH: 0.90
    }).v
    ctx.W_supply = this.calc_W_supply_cooling()

    for (let mo = 0; mo < 12; mo++) {
      if (valOr(this.debugOpts.calcFirstHourOnly, false) && mo != 0) {
        break;
      }
      ctx.startSection("Month " + mo)
      for (let hr = 0; hr < 24; hr++) {
        if (valOr(this.debugOpts.calcFirstHourOnly, false) && hr != 0) {
          break;
        }
        ctx.startSection("Hour " + hr)
        ctx.setProgressText(`Calculating system cooling for month ${mo+1}, hour ${hr+1}`)
        await ctx.briefWait()

        ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyCooling(mo, hr)

        ctx.mix_values = this.calc_mix_values_cooling(mo, hr)
        ctx.h_mix = ctx.mix_values.h_mix
        ctx.W_mix = ctx.mix_values.W_mix
        ctx.T_mix = ctx.mix_values.T_mix

        ctx.q_fan_supply = this.calc_q_fan_supply()
        let q_sens = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(T_mix-T_supply_cool) + ' +
          'q_fan_supply/(60*0.24*Q_supply*(1.0/v_supply))', {
        }, 'q_sens');
        ctx.q_cooling_sens.set([mo, hr], q_sens)

        ctx.T_db_avg = ctx.eval('(T_mix + T_supply_cool)/2.0', {
        }, 'T_db_avg')
        let q_lat = ctx.eval('60*0.24*Q_supply*(1.0/v_supply)*(W_mix-W_supply)*(1061+0.444*T_db_avg)', {
        }, 'q_lat');
        ctx.q_cooling_lat.set([mo, hr], q_lat)

        ctx.endSection()
      }
      ctx.endSection()
    }

    ctx.logLoadMatrix('q_cooling_sens', ctx.q_cooling_sens)
    ctx.logLoadMatrix('q_cooling_lat', ctx.q_cooling_lat)

    let res = {
      q_cooling_sens: ctx.q_cooling_sens,
      q_cooling_lat: ctx.q_cooling_lat,
    }

    ctx.endSection()
    return res;
  }

  calc_W_supply_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc W_supply")

    // Yes the "Add Winter Humidification" section of System Load Calculations doc
    // Note - for V1, we are not supporting winter humidification
    let addWinterHumidification = false;
    if (!addWinterHumidification) {
      ctx.log("No winter humidification (not yet supported). W_supply = W_mix")
      ctx.require('W_mix')
      ctx.W_supply = ctx.eval('W_mix', {}, 'W_supply')
    } else {
      ctx.assert(false, "Winter humidification not supported")
      /*
      ctx.m_supply_heat = ctx.eval('m_recirc+m_vent', {
      }, 'm_supply_heat')
      // TODO
      ctx.T_room = 0
      ctx.T_supply_heat = 0
      ctx.T_db_avg = ctx.eval('(T_room+T_supply_heat)/2.0', {
      }, 'T_db_avg')

      // TODO - fill in vars
      ctx.peak_mo = 0
      ctx.peak_hr = 0
      ctx.W_return_peak = 0
      ctx.q_lat_peak = 0
      ctx.Q_supply = 0
      ctx.v_supply = 0
      ctx.T_db_avg_peak = 0
      ctx.W_supply = ctx.eval('W_return_peak - q_lat_peak/(60*Q_supply*air_density*' +
        '(1061+0.444*T_db_avg_peak)', {
        }, 'W_supply')
      */
    }

    let res = ctx.W_supply
    ctx.endSection()
    return res
  }

  calc_mix_values_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc mix values heating")

    let heatRecovery = this.system.heatRecovery;
    ctx.recoveryType = heatRecovery.recoveryType.value

    // TODO - what hr and mo to use?
    let hr = 0
    let mo = 0
    let t_db_out = this.calc_T_db_out(hr, mo)
    let t_wb_out = this.calc_T_wb_out(hr, mo)
    let psychrometrics = ctx.call(psy.CalcPsychrometrics, t_db_out, ctx.altitude,
      PsyCalcMethod.CalcWithWetBulbTemp, {
        t_wb_F: t_wb_out
      })
    ctx.h_oa = psychrometrics.h
    ctx.W_oa = psychrometrics.W
    ctx.T_oa = t_db_out

    let returnValues = this.calc_return_values_heating()
    ctx.T_return = returnValues.T_return
    ctx.W_return = returnValues.W_return
    ctx.h_return = returnValues.h_return
    ctx.v_return = returnValues.v_return
    
    ctx.V_ot = ctx.V_ot_values.get_V_ot_heating()
    ctx.m_vent = ctx.eval('V_ot*air_density', {
    }, 'm_vent')
    ctx.Q_return = ctx.Q_supply_values.getSystemSupplyHeating()
    ctx.m_recirc = ctx.eval('(Q_return-V_ot)/v_return', {
    }, 'm_recirc')
    if (ctx.recoveryType != RecoveryType.None) {
      // These vars are only needed if RecoveryType is not None
      ctx.Q_exhaust = heatRecovery.calc_Q_exhaust(ctx, ctx.V_ot)
      ctx.m_exhaust = ctx.eval('Q_exhaust/v_return', {
      }, 'm_exhaust')
      ctx.m_min = ctx.eval('min(m_exhaust, m_vent)', {
      }, 'm_min')
    }

    if (ctx.recoveryType == RecoveryType.None) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type None`)
      ctx.h_vent = ctx.h_oa
      ctx.T_vent = ctx.T_oa
      ctx.W_vent = ctx.W_oa
    } else if (ctx.recoveryType == RecoveryType.HRV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type HRV`)
      let hrvInputs = heatRecovery.hrvGroup
      ctx.W_vent = ctx.W_oa
      ctx.T_vent = ctx.eval('T_oa - (epsilon_s*m_min/m_vent)*(T_oa-T_return)', {
        epsilon_s: hrvInputs.get('winterEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.h_vent = ctx.eval('0.24*T_vent+W_vent*(1061+0.444*T_vent)', {
      }, 'h_vent')
    } else if (ctx.recoveryType == RecoveryType.ERV) {
      ctx.log(`Calc h_vent, T_vent, W_vent for recovery type ERV`)
      let ervInputs = heatRecovery.ervGroup
      ctx.h_vent = ctx.eval('h_oa-(epsilon_T*m_min/m_vent*(h_oa-h_return))', {
        epsilon_T: ervInputs.get('winterTotalEfficiency').value / 100.0,
      }, 'h_vent')
      ctx.T_vent = ctx.eval('T_oa-(epsilon_s*m_min/m_vent)*(T_oa-T_return)', {
        epsilon_s: ervInputs.get('winterSensibleEfficiency').value / 100.0,
      }, 'T_vent')
      ctx.W_vent = ctx.eval('(h_vent-0.24*T_vent)/(1061+0.444*T_vent)', {
      }, 'W_vent')
    } else {
      throw new Error("Unsupported recovery type: " + ctx.recoveryType)
    }

    ctx.h_mix = ctx.eval('(m_vent*h_vent + m_recirc*h_return)/(m_vent+m_recirc)', {
    }, 'h_mix')
    ctx.W_mix = ctx.eval('(W_vent*m_vent + W_return*m_recirc)/(m_vent+m_recirc)', {
    }, 'W_mix')
    ctx.T_mix = ctx.eval('(h_mix - 1061*W_mix)/(0.24*0.444*W_mix)', {
    }, 'T_mix')

    let res = {
      h_mix: ctx.h_mix,
      W_mix: ctx.W_mix,
      T_mix: ctx.T_mix,
    }

    ctx.endSection()
    return res
  }

  calc_v_supply_heating() {
    let ctx = this.ctx
    ctx.startSection("Calc v_supply heating")

    let addWinterHumidification = false;
    if (!addWinterHumidification) {
      ctx.log("Add winter humidification is off (not yet supported)")
      ctx.W_supply = this.calc_W_supply_heating()
      ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
        PsyCalcMethod.CalcWithHumidityRatio, {
          W: ctx.W_supply
      }).v
    } else {
      ctx.log("Add winter humidification is on")
      ctx.assert(false, "Winter humidification not supported")
      ctx.v_supply = ctx.call(psy.CalcPsychrometrics, ctx.T_supply_heat, ctx.altitude,
        PsyCalcMethod.CalcWithRelativeHumidity, {
          RH: 0.80
      }).v
    }

    let res = ctx.v_supply
    ctx.endSection()
    return res
  }

  async calc_q_heating_system() {
    let ctx = this.ctx
    ctx.startSection("Calc q_heating_system")

    // Note: For heating, q_heat does not depend on (mo, hr).
    // We return q_heating_sens, which is a single scalar value

    ctx.require('T_supply_heat')
    ctx.Q_supply = ctx.Q_supply_values.getSystemSupplyHeating()

    ctx.mixValues = this.calc_mix_values_heating()
    ctx.T_mix = ctx.mixValues.T_mix
    ctx.W_mix = ctx.mixValues.W_mix

    ctx.v_supply = this.calc_v_supply_heating()
    ctx.q_fan_supply = this.calc_q_fan_supply()
    ctx.q_heat = ctx.eval('60*0.24*Q_supply/v_supply*(T_supply_heat-T_mix)-q_fan_supply/(60*0.24*Q_supply/v_supply)', {
    }, 'q_heat')

    let res = {
      q_heating_sens: ctx.q_heat,
    }

    ctx.endSection()
    return res;
  }

  async calcSpaceLoadResults() {
    // Calc the results for each space, and store them for later use
    let ctx = this.ctx
    ctx.startSection("Space load results")
    ctx.startProgressSection('Zones', this.system.zones.map((zone, i) => {
      return {name: `Zone ${i}`};
    }));

    for (let zoneIndex = 0; zoneIndex < this.system.zones.length; zoneIndex++) {
      let zone = this.system.zones[zoneIndex]
      ctx.startSection(`Zone ${zoneIndex}`)
      ctx.setProgress(`Zone ${zoneIndex}`)
      ctx.startProgressSection('Spaces', zone.spaces.map((space, i) => {
        return {name: `Space ${i}`};
      }));
      let zoneResult = new ZoneResult()
      this.zoneResults.push(zoneResult)
      for (let spaceIndex = 0; spaceIndex < zone.spaces.length; spaceIndex++) {
        let space = zone.spaces[spaceIndex]
        let spaceType = space.getSpaceType()
        ctx.startSection("Space " + spaceType.name.value)
        ctx.setProgress(`Space ${spaceIndex}`)
        let spaceResults = null
        if (valOr(this.debugOpts.useDummySpaceLoads, false)) {
          spaceResults = spaceType.getDummyLoadResults(ctx, {})
        } else {
          let zoneInfo = Space.ZoneInfo.createFromZone(zone);
          spaceResults = await spaceType.calculateLoadsAsync(ctx, zoneInfo, {})
        }
        ctx.logValue('heating, q_sensible', spaceResults.heating.q_sensible)
        ctx.logValue('heating, q_latent', spaceResults.heating.q_latent)
        ctx.logLoadMatrix('cooling, q_sensible', spaceResults.cooling.q_sensible)
        ctx.logLoadMatrix('cooling, q_latent', spaceResults.cooling.q_latent)
        zoneResult.spaceResults.push(spaceResults)
        ctx.endSection()
      }
      ctx.endProgressSection();
      ctx.endSection()
    }

    ctx.endProgressSection();

    await ctx.longWait(2);

    ctx.endSection()
  }

  calcSystemOccupancySchedule() {
    let ctx = this.ctx
    ctx.startSection("System occupancy schedule")
    let systemOccupancySchedule = makeVector(24).fill(0.0)
    for (let hr = 0; hr < 24; hr++) {
      systemOccupancySchedule[hr] = this.system.isOccupied(hr) ? 1.0 : 0.0;
    }
    ctx.logValue('systemOccupancySchedule', systemOccupancySchedule)
    ctx.endSection()
    return systemOccupancySchedule;
  }

  async calcAllOutputs() {
    let ctx = this.ctx
    ctx.startSection("Calc outputs")
    ctx.startProgressSection('System loads', {
      'Space loads': {},
      'Space ventilations': {},
      'System cooling': {},
      'System heating': {},
    })

    ctx.setProgress("Space loads")
    await this.calcSpaceLoadResults()

    ctx.systemOccupancySchedule = this.calcSystemOccupancySchedule()

    ctx.setProgress("Space ventilations")
    ctx.spaceVentilations = this.calcSpaceVentilations()

    ctx.T_supply_cool = this.system.getCoolingSupplyTemp()
    ctx.T_supply_heat = this.system.getHeatingSupplyTemp()
    ctx.Q_supply_values = this.calc_Q_supply()
    ctx.q_plenum_matrix = this.calc_q_plenum_matrix()
    ctx.V_ot_values = this.calc_V_ot_values()

    ctx.setProgress("System cooling")
    await this.calc_q_cooling_system()
    ctx.setProgress("System heating")
    await this.calc_q_heating_system()

    ctx.endProgressSection()
    ctx.endSection()
  }

  setBasicValues() {
    let ctx = this.ctx
    ctx.startSection("Basic")

    // Building and env:
    let locationData = this.proj.buildingAndEnv.getLocationData();
    console.log("Location data: ", locationData);
    // Put the location data in 'toplevelData' for compatibility with residential calculations
    ctx.toplevelData = {
      locationData: locationData.getOutputs(),
      dayOfMonth: 21,
    }
    ctx.buildingAndEnv = this.proj.buildingAndEnv.getOutputs();

    // TODO - is air_density_0 correct?
    ctx.air_density_0 = 0.074
    ctx.altitude  = ctx.toplevelData.locationData.elevation;
    ctx.P_loc = ctx.call(psy.calcP_loc, ctx.altitude)
    ctx.P_atm = psy.P_std
    ctx.air_density = ctx.eval('air_density_0 * P_loc / P_atm', {}, 'air_density')

    // Design temps:
    ctx.designTemps = this.system.designTempInputs.getDesignTemps(ctx);

    // TODO - are these accurate?
    ctx.summerIndoorRH = 0.50;
    ctx.winterIndoorRH = 0.30;

    ctx.endSection()
  }

  async calcLoads() {
    let ctx = this.ctx
    this.setBasicValues()
    await this.calcAllOutputs()
  }
}