export let ActivityLevels_Data = `Degree of Activity,Location,"Total Heat, Adult Male, Bth/h","Total Heat, Adjusted, M/F, Bth/h","Sensible Heat, Btu/h","Latent Heat, Btu/h","% Sensible Heat that is Radiant, Low Velocity air","% Sensible Heat that is Radiant, High Velocity air",
Seated,Theater,390,350,245,105,0.6,0.27,0.435
"Seated, very light work","Offices, hotels, apartments",450,400,245,155,0.6,0.27,0.435
Moderately active office work,"Offices, hotels, apartments",475,450,250,200,0.6,0.27,0.435
"Standing, light work; walking",Department store; retail store,550,450,250,200,0.58,0.38,0.48
"Walking, standing","Drug store, bank",550,500,250,250,0.58,0.38,0.48
Sedentary work,Restaurantc,490,550,275,275,0.58,0.38,0.48
Light bench work,Factory,800,750,275,475,0.58,0.38,0.48
Moderate dancing,Dance hall,900,850,305,545,0.49,0.35,0.42
Walking 3 mph; light machine work,Factory,1000,1000,375,625,0.49,0.35,0.42
Bowling,Bowling alley,1500,1450,580,870,0.49,0.35,0.42
Heavy work,Factory,1500,1450,580,870,0.54,0.19,0.365
Heavy machine work; lifting,Factory,1600,1600,635,965,0.54,0.19,0.365
Athletics,Gymnasium,2000,1800,710,1090,0.54,0.19,0.365`