<script setup>
import { ref, onMounted, reactive, computed } from 'vue'
import FieldInput from './FieldInput.vue'

const props = defineProps({
  obj: Object,
  fieldNames: Object,
  labelWidth: {
    type: Number,
    default: 250,
  },
})

let theFields = computed(() => {
  if (props.fieldNames) {
    return props.fieldNames;
  }
  return props.obj.fields;
})

</script>

<template>
  <div class="">
    <div v-for="(fieldName, i) in theFields">
      <FieldInput :labelWidth="labelWidth" v-model="obj[fieldName]" />
    </div>
  </div>
</template>

<style scoped>
</style>
