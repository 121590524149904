export let BuildingBoardAndSiding = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
Asbestos/cement board,120,4,0.125,0.24,1
Cement board,71,1.7,0.294117647,0.2,1
"Fiber/cement board, high density",75,1.5,0.333333333,0.2,1
"Fiber/cement board, low density",36,0.75,0.666666667,0.2,1
Gypsum or plaster board,40,1.1,0.454545455,0.21,1
Oriented strand board (OSB),41,0.769230769,0.65,0.45,1
Plywood (douglas fir),29,0.632911392,0.79,0.45,1
Plywood/wood panels,28,0.694444444,1.08,0.45,1
"Vegetable fiber board, sheathing, regular density",18,0.378787879,1.32,0.31,1
"Vegetable fiber board, sheathing, intermediate density",22,0.458715596,1.09,0.31,1
"Vegetable fiber board, nail-based sheathing",25,0.47,1.06,0.31,1
"Vegetable fiber board, shingle backer",18,0.39893617,0.94,0.3,1
"Vegetable fiber board, sound-deadening board",15,0.37037037,1.35,0.3,1
"Vegetable fiber board, tile and lay-in panels, plain or acoustic",18,0.4,1.25,0.14,1
"Vegetable fiber board, laminated paperboard",30,0.5,1,0.33,1
Hardboard,52,0.82,0.609756098,0.31,1
"Particleboard, low density",44,0.83,0.602409639,0.31,1
"Particleboard, high density",56,1.06,0.471698113,0.31,1
Waferboard,37,0.63,0.21,0.45,1
"Shingles, Asbestos/cement",120,,0.21,0.31,0
"Shingles, Wood",120,,1.15,0.31,0
"Siding, Asbestos/cement",120,,0.21,0.24,0
"Siding, Asphalt",120,,0.18,0.29,0
Hardboard siding,120,2.916666667,0.15,0.35,1
"Siding, Wood",120,,0.81,0.28,0
"Siding, Aluminum, steel, or vinyl, over sheathing, hollow-backed",120,,0.62,0.29,0
"Siding, Aluminum, steel, or vinyl, over sheathing, insulating-board-backed",120,,1.82,0.32,0
"Siding, Aluminum, steel, or vinyl, over sheathing, foil-backed",120,,2.96,0.32,0
"Siding, Aluminum, steel, or vinyl, over sheathing, insulated vinyl siding",120,,2.35,0.32,0`
