export let kGroundSurfaceTempAmplitudesData = `,180,178,176,174,172,170,168,166,164,162,160,158,156,154,152,150,148,146,144,142,140,138,136,134,132,130,128,126,124,122,120,118,116,114,112,110,108,106,104,102,100,98,96,94,92,90,88,86,84,82,80,78,76,74,72,70,68,66,64,62,60,58,56,54,52,50,48,46,44,42
76,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,30,30,30,0,0,0,0,0,0,0,0,0,0,30,0,0,0,0,0,0,0,0,0,0,0,0,0,30,30,30,30,30,30,30,30,30,30,30,30,30,30
74.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,30,30,30,30,30,30,30,30,30,0,30,30,30,30,30,0,30,30,30,30,30,30,0,0,0,0,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30,30
72.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,29.5,29.5,29.5,29.5,29.5,0,0,0,0,0,0,0,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5,0,29.5,0,0,0,0,0,29.5,0,0,0,0,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5
71.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,29,29,29,29,29,29,29,29,29,29,29,29,29,29.5,29.5,29.5,29.5,0,29.5,29.5,29.5,29.5,29.5,29.5,29.5,29.5,0,0,0,0,0,0,0,0,0,29,29,29,29,29,29,29,29
69.6,0,0,0,0,0,0,0,0,0,18,22,25,27,27,27.5,28,28,28.5,0,0,0,0,0,0,0,28.5,28.5,0,28.5,0,0,28.5,0,0,0,0,0,29,29,29,29,29,29,29.5,0,0,29.5,29.5,29.5,29.5,29.5,29,29,29,29,0,0,0,0,0,0,0,28.5,28.5,28.5,28.5,28.5,28.5,28.5,28.5
68,13,0,0,0,0,0,0,14,14,17,20,22,25,27,27,27,27,27.5,27.5,27.5,27.5,27.5,27.5,27.5,27.5,27.5,28.5,28.5,28.5,28.5,28.5,28.5,28.5,28.5,28.5,29,29,29,29,0,0,29,29,29.5,29.5,29.5,0,29.5,29.5,29.5,29.5,29.5,29,29,29,29,0,0,0,0,0,0,0,28.5,28.5,28.5,28.5,28.5,28.5,28.5
66.4,13,13,13,13,13,0,0,13.5,14,14,18,20,22,25,26.5,27,27,27,27,27,27,27,27,27,27,27,27.5,27.5,27.5,27.5,27.5,27.5,27.5,28.5,28.5,28.5,28.5,29,29,29,29,29,29,29.5,29.5,29.5,29.5,29.5,29.5,0,0,29,29,29,29,29,28.5,27.5,27.5,27.5,0,0,0,27.5,27.5,27.5,27.5,27.5,27.5,27.5
64.8,13,13,13,13,13,0,13.5,13.5,14,14,17,18,21,22,24,25,24,25,24,25,25,26,26,26,26,26.5,27,27,27,27,27,27,27.5,27.5,27.5,27.5,28.5,28.5,28.5,28.5,29,29,29,29,29.5,29.5,29.5,29.5,29,29,0,29,29,28.5,28.5,28.5,27.5,27.5,27.5,0,0,0,0,27.5,27.5,27.5,27.5,27.5,27.5,27.5
63.2,0,0,0,0,0,0,0,13.5,14,14,17,18,18,20,20,20.5,20.5,20.5,20.5,21,22,22,22,22.5,23,23.5,23.5,24,24,26,26,26.5,27,27,27,27.5,27.5,27.5,27.5,28.5,28.5,28.5,28.5,28.5,29,29,29,29,28.5,28.5,0,28.5,0,27.5,27.5,27.5,27.5,0,0,0,0,0,0,27.5,27.5,27.5,27.5,27.5,27.5,27.5
61.6,0,0,0,0,0,0,0,13.5,13.5,14,16,16,17,17,17,17,17,18,18,18,18,18,20,20,20,22,22,22.5,22.5,23,24,25,26,26,26.5,27,27,27,27.5,27.5,28.5,28.5,28.5,0,0,0,0,0,28.5,28.5,28.5,28.5,28.5,27.5,27.5,0,0,0,0,0,0,0,0,0,0,27,27,27,27,27
60,0,0,0,0,0,0,0,13.5,13.5,13.5,14,14,14,14,14,0,0,0,17,17,17,17.5,17.5,18,18,20,20,20,22,22,22.5,22.5,23,24,25,26,26,27,27,27.5,27.5,27.5,28.5,28.5,0,0,0,0,0,0,28.5,28.5,27.5,27.5,27,26.5,26,26,0,0,0,0,0,0,0,0,0,0,0,0
58.4,0,0,0,0,0,0,0,0,0,0,13.5,13.5,13.5,13.5,0,0,0,0,0,0,0,17,17,17.5,17.5,18,18.5,19,20,21,21.5,21.5,22,22.5,22.5,24,25,26,26.5,27,27,27.5,27.5,28.5,28.5,0,0,0,0,0,28.5,27.5,27,27,26.5,26,25.5,25,25,0,0,0,0,0,0,0,0,0,0,0
56.8,0,0,0,0,0,0,0,13.5,13.5,13.5,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17,17.5,18,18.5,19,20,20,21,21,21.5,22,23,24,25,26,26.5,27,27,27.5,27.5,27.5,27.5,27.5,27.5,27.5,0,27,27,27,26,25.5,25,24,24,24.5,23,23,0,0,0,0,0,0,0,0,0
55.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17,17,17.5,18,18,19,20,20,20.5,21,21.5,22,22.5,24,25,26,26,26.5,27,27,27,27,27,27,27,0,26.5,26.5,26,25,24,24,24.5,23,23,22.5,22.5,22.5,0,0,0,0,0,0,0,0
53.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17,17.5,18,18.5,19,19.5,20,20.5,21,21.5,22.5,24,25,25,25.5,26,26,26,26,26,26,26,26,25.5,25.5,25.5,24,24,24.5,23,22.5,22.5,22,22,21,20.5,0,0,0,0,0,0,0,0
52,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17,17.5,18,19,19.5,20,20.5,21,21.5,22.5,24,25,25.5,25.5,25.5,25.5,25.5,25.5,25.5,25.5,25,25,25,25,24,24.5,23,22.5,22,21,21,20,20,20,19.5,19,0,0,0,0,0,0,0
50.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17.5,18,18.5,19.5,20,20.5,21,21.5,22.5,24.5,24.5,25.5,25,25,25,25,25,25.5,25.5,25.5,24.5,24.5,24.5,23,22.5,22,22,20,19.5,19,19,18.5,18,18,18,18,0,0,0,0,0,0
48.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17,17.5,18.5,18.5,20,20,20.5,21,22,22.5,24.5,24.5,25,25,25,25,25,25,24.5,24.5,23.5,23.5,23,22.5,22,22,20,19.5,18,17.5,17.5,17,17,0,0,0,0,0,0,0,0,0,0
47.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17.5,18,18.5,19,20,20,20.5,21,22,22.5,24,24.5,24.5,24.5,24.5,24.5,24.5,24.5,24.5,23,22.5,22,22,20.5,20,18,18,17,17,0,16,0,0,0,0,0,0,0,0,0,0,0,0
45.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,17.5,18,18.5,19,20,20,20.5,21,21.5,22,23,24,24,24,24,24,24,23,22.5,22,21.5,21,20,19,17.5,17,16,16,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
44,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,18,18.5,19,19.5,20,21,21,21.5,22,22,23,23,23,23,23,22,22,21.5,21,20,20,19,18,17,16,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
42.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,18,18.5,19,19,19.5,20,20,21,21,22,22,22.5,22.5,22,22,21.5,21,21,20.5,20,20,18.5,17,16,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
40.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,18,18,18.5,19,19,19.5,20,21,21,21.5,22,22,21.5,21,20.5,20.5,19.5,20,20,19.5,18.5,18,16,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
39.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,18,18,18,18.5,18.5,19,20,20,20.5,21,21,20,18.5,18,18,18,19,19,19,18.5,18.5,17,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
37.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,16.5,17.5,17.5,18,18,18.5,19,19,19,19,18.5,18,17,17,18,18.5,18.5,19,19,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
36,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,16.5,16.5,17,17,18,18,18,18.5,18,18,17,16,16,17,18,18,19,19,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
34.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,15,15,16.5,16,16,16.5,17,16.5,17,16,0,0,0,0,0,0,19,19,19,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
32.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,15,15,15.5,14.5,14.5,16,16,16,14.5,0,0,0,0,0,0,0,19,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
31.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,15,0,12,12,12,12,12,12,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
29.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,9,9,9,9,9,9,0,0,0,9,9,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
28,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,7,7,7,7,7,7,7,7,7,7,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
26.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,6,6,6,6,6,6,6,6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
24.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,4.5,4.5,4.5,4.5,4.5,4.5,4.5,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
23.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,4,4,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
21.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
20,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
18.4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
16.8,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
15.2,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
13.6,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
12,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0`
