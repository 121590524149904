<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'

const props = defineProps({
  type: {
    default: "error",
  }
});

function getClasses() {
  if (props.type == 'error') {
    return {'input-error-text': true}
  } else if (props.type == 'warn') {
    // return {'mute-text': true}
    return {}
  }
}

</script>

<template>
  <p class="" :class="getClasses()"><i class="bi-exclamation-circle mr-xxs"></i><slot></slot></p>
</template>

<style scoped>
</style>
