import { DataTable } from './DataTable';

export let ConstructionWeightsReference = `Construction weight,Exterior wall,Roof/ceiling,Partitions,Floor,Furnishings
Light,"Steel siding, 2” insulation, air space, ¾” gypsum","4” LW concrete, ceiling air space, acoustic tile","¾” gypsum, air space, ¾” gypsum","Acoustic tile, ceiling air space, 4” LW concrete",1” wood at 50% of floor area
Medium,"4” face brick, 2” insulation, air space, ¾” gypsum","4” HW concrete, ceiling air space, acoustic tile","¾” gypsum, air space, ¾” gypsum","Acoustic tile, ceiling air space, 4” HW concrete",1” wood at 50% of floor area
Heavy,"4” face brick, 8” HW concrete, air space, 2” simulation, ¾” gypsum","8” HW concrete, ceiling air space, acoustic tile","¾” gypsum, 8” HW concrete block, ¾” gypsum","Acoustic tile, ceiling air space, 8” HW concrete",1” wood at 50% floor area`


export function GetConstructionWeightsReference() {
  return DataTable.createFromCSV(ConstructionWeightsReference)
}
