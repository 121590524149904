<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'

const props = defineProps({
  title: {
    default: "",
  },
  styles: {
    default: {},
  },
});

const emit = defineEmits(['onOpen'])

let isOpen = ref(false);

function getIconClasses() {
  // return !isOpen.value ? {'bi-plus-circle': true} : {'bi-dash-circle': true};
  return !isOpen.value ? {'bi-plus': true} : {'bi-dash': true};
}

function toggleOpen() {
  if (isOpen.value) {
    isOpen.value = false;
  } else {
    isOpen.value = true;
    emit('onOpen');
  }
}

</script>

<template>
  <div class="CollapseDiv" :class="styles">
    <button class="CollapseBtn" @click="toggleOpen" :class="{IsOpen: isOpen}">{{ title }}<i class="icon" :class="getIconClasses()"></i></button>
    <div v-show="isOpen" class="Content">
      <slot>Default body</slot>
    </div>
  </div>
</template>

<style scoped>
.CollapseDiv {
  max-width: 1000px;
}

.CollapseBtn {
  /* background-color: #777; */
  /* color: white; */
  /* padding: var(--space-xs); */

  background-color: var(--grey-90);
  width: 100%;

  border: 1px solid var(--grey-60);

  /* border: none; */
  /* outline: none; */

  text-align: left;
  /* font-size: var(--f-m2); */
  font-size: var(--f-m);
}

.CollapseBtn:hover {
  /* background-color: #555; */
  background-color: var(--grey-70);
}

.CollapseDiv.Emphasis .CollapseBtn {
  /* background-color: skyblue; */
  /* background-color: var(--pc-light); */
  /* background-color: var(--pc-med); */
  /* color: white; */
}

.CollapseDiv.Emphasis .CollapseBtn:hover {
  /* background-color: var(--pc-med2); */
}

.CollapseBtn.IsOpen {
  /* background-color: var(--grey-70); */
  /* font-weight: bold; */
}

.Content {
  padding: var(--space-s);
  /* background-color: #f1f1f1; */
  border: 1px solid var(--grey-60);
  border-top: none;
}

.icon {
  float: right;
  color: var(--grey-40);
}
</style>
