<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from './State.js'
import { Schedule } from './Schedule.js'
import { Space } from './Space.js'
import { formatNum, removeElem, generateItemName, isDupName, } from './SharedUtils.js'
import { Units, getLabel } from './Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSchedule = ref(null);
let deleteModal = ref(null);

let schedules = computed(() => {
  return proj.schedules;
})

function selectSchedule(schedule) {
  selectedSchedule.value = schedule;
}

function deleteSchedule(schedule) {
  deleteModal.value.showModal("Delete", `Delete ${schedule.name.value}?`, () => {
    removeElem(schedules.value, schedule);
  });
}

function addSchedule() {
  schedules.value.push(Schedule.create(generateItemName('Schedule', proj.schedules, 'TYPE_NAME-CTR'), true))
  selectSchedule(schedules.value[schedules.value.length - 1]);
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SchedulesView">
      <h4 class="mb-s">Schedules</h4>
      <p class="mb-m">
        Use this page to build different  types for use in the Build House tab.
      </p>
      <p>
        The <b>R-value</b> of the  will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.
        <br><br>
          Consult your local building code for minimum requirements of <b>R-value</b>. For <b>absorptance</b>, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results.
        <br><br>

        When using the <b>Wall Builder</b>, certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall.
        <br><br>

        The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.
        <br><br>

         built in this section can be used as  types  in the Build House tab.

      </p>
    </HelpSection>
    <div v-if="selectedSchedule">
      <div class="mb-m">
        <button @click="selectedSchedule=null" class="LinkBtn mb-m"><i class="bi-arrow-left-circle mr-xxs"></i>Done Editing</button>
        <div class="Flex">
          <h1 class="">{{ selectedSchedule.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <FieldInput v-model="selectedSchedule.name" class="MarginBotXS" />
      <WarnText v-if="isDupName(selectedSchedule.name.value, schedules)">There is already a system with that name. Please choose another.</WarnText>
      <InputGroup title="General">
        <FieldGroup v-model="selectedSchedule.hourFields" />
      </InputGroup>
    </div>
    <div v-else>
      <h1 class="PageH1">Schedules</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text">
          Add a <b></b> for each different type of  used in your project. Then, go to the <b>Build House</b> page to add  
          to your house (which will reference these types).
        </p>
      </div>
      <button @click="addSchedule()" class="MarginBotXS CreateBtn">Add Schedule</button>
      <table class="SimpleTable" v-if="schedules.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="schedule in schedules" class="WallSection MarginBotL">
          <td>{{ schedule.name.value }}</td>
          <td>
            <button @click="selectSchedule(schedule)">Edit</button>
          </td>
          <td>
            <button @click="deleteSchedule(schedule)">Delete</button>
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}
</style>
