<script setup>
import { ref } from 'vue'
import { gApp, Wall, WallWindow, WallDoor,
  Roof, RoofSkylight, Partition, FloorType, YesNo,
  HouseFloor,
  ManualOrAutomatic, ManualOrSelectFromList, RecoveryType,
} from './State.js'
import { removeElem } from './SharedUtils.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import LocationData from './LocationData.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'

import WallsSection from './WallsSection.vue'
import RoofsSection from './RoofsSection.vue'
import PartitionsSection from './PartitionsSection.vue'
import FloorsSection from './FloorsSection.vue'

let proj = gApp.proj();
let ventData = proj.ventilationInfiltration;
let internals = proj.internals;
let misc = proj.miscDetails;

</script>

<template>
  <div class="FlexHelp">
    <h1 class="PageH1">Build House</h1>
    <HelpButton id="build" :hasSlot="false"></HelpButton>
  </div>
  <HelpSection id="build">
    <h4>Build House</h4>
    <p class="mb-s">Use this page to describe your house's interior and exterior.</p>
    <p class="mb-s">Fill out each section. Click the <i class="bi-question-circle pc"></i> icons for more help.</p>
    <p>When you're done, go back to the <b>Overview</b> tab to see your house's load breakdown.</p>
  </HelpSection>
  <div class="mb-m">
    <p class="mute-header">Info</p>
    <p class="help-txt">Fill out all sections here to describe your house. When you're done, go to the <b>Overview</b> page for your load results.</p>
  </div>
  <SimpleCollapse class="Section Emphasis" title="General Data" @onOpen="gApp.proj().setHelpId('GeneralData')">
    <HelpButton class="float-r" id="GeneralData">
      <h4 class="mb-s">General Data</h4>
      <p class="mb-s">
        This info is used to help calculate infiltration, ventilation, and internal gains. Also, the temperature and humidity values
        will affect all heating and cooling calculations.
      </p>
      <p class="mb-s">
        <b>Number of Bedrooms:</b> The number of bedrooms is used for the ventilation calculation and occupancy estimate. The occupancy estimate will also have an effect on the estimated internal sensible and latent loads. This value is not important if internal gains and ventilation are entered manually.
      </p>
      <p class="mb-s">
        <b>Number of Storeys:</b> The number of storeys is only used for infiltration estimates. Basements should not be counted. However, if the house is a split-level, where the lower level is at least 50% above ground, then the lower level should be counted as a storey.
      </p>
      <p class="mb-s">
        <b> Total Floor Area:</b> Floor area should be measured from outside walls. This is the total square footage (or meterage) of the house, not including the basement, crawl space, attic, attached garage, or any other space outside of the exterior walls. 
      </p>
      <p class="mb-s">
        <b>Average Ceiling Height:</b> Average ceiling height does not include the basement. If an accurate value cannot be determined, a rough estimate is still valuable. For most houses, this is around 8.5 to 9 feet (2.6 to 2.7 meters). A general approach is to take the height from the main floor to the ceiling of the top floor, and divide by the number of storeys. 
Where areas exist in the home of a ceiling that extends up multiple storeys (such as an indoor balcony), this should NOT be counted to increase the average ceiling height. The intention of this input is to determine the total internal height from the main floor up to the ceiling of the top floor. Skewing this number can lead to inaccurate infiltration calculations.
      </p>
      <p class="mb-s">
        <b>Total Building Volume:</b> Due to some houses having highly variable shapes and ceiling heights, the building volume must be entered manually. It should be calculated based on outside wall dimensions, and not include basements, attics, crawl spaces, or garages.
      </p>
      <p class="mb-s">
        <b>Indoor Temperatures/Humidities:</b>
        <br>
        These will have a major impact on total heat loss/gain during peak load times. Typical values are 70 F (21 C) for the winter and 75 F (24 C) in the summer. Typical values for humidity are 30% for winter and 50% for summer. 
        <br>
        <br>
        The summer value will be dependent on the dewpoint temperature of the cooling coil. If this is unknown, a common value is 55 F (13 C). 55 F (13 C) dewpoint temperature at an indoor temperature of 75 F (24 C) results in 50% humidity. 
        <br>
        <br>
        For heating calculations, the specified humidity will only have an impact on latent loads for infiltration and ventilation. If humidification is not important, these values can be excluded from the total load calculations. 
        <br>
        <br>
        For cold, dry winter climates, providing a winter relative humidity of lower than 30% may be more accurate.
        <br>
      </p>
    </HelpButton>
    <div class="mb-s">
      <p class="mute-header">Info</p>
      <p class="help-text">
        Provide some general info about your house.
      </p>
    </div>
    <p class="extra-mute-header">Basic</p>
    <FieldList :labelWidth="300" :obj="proj.toplevelData" :fieldNames="proj.toplevelData.basicFields" class="mb-s" />
    <p class="extra-mute-header">Temp. and Humidity</p>
    <FieldList :labelWidth="300" :obj="proj.toplevelData" :fieldNames="proj.toplevelData.tempFields" />
  </SimpleCollapse>
  <SimpleCollapse class="Section Emphasis" title="Ventilation" @onOpen="gApp.proj().setHelpId('Ventilation')">
    <HelpButton class="float-r" id="Ventilation">
      <h4 class="mb-s">Ventilation</h4>
      <p class="mb-s">
        Where an exact amount of total ventilation is known, it should be entered manually. Otherwise, using the automatic calculation method follows ASHRAE Standard 62.2 to calculate required ventilation. 
        <br>
        <br>
        The other inputs, when combined with the Infiltration and General Data inputs will be used to calculate total heating and cooling loads from outdoor air entering the building. The ventilation and exhaust differences will impact infiltration based on potential pressurization of the building.
        <br>
        <br>
        The <b>Continuous Exhaust</b> input is intended for exhaust that is not related to combustion heating equipment, such as continuous bathroom exhaust. If exhaust from sources such as the bathroom or kitchen are intermittent, they do not count toward the continuous exhaust input. 
        <br>
        <br>
        The <b>Other Balanced Airflows</b> input is for any non-heat recovery units that take in and exhaust air simultaneously at equal rates. This is only intended for units that will allow airflow into the house. If the airflow is completely separate and sealed off from entering the house, this input may be left as 0 as it will not impact heating or cooling loads.
      </p>
    </HelpButton>
    <div class="mb-m">
      <p class="mute-header">Info</p>
      <p class="help-text">
        Describe your house's ventilation.
      </p>
    </div>
    <InputGroup title="Ventilation" class="MarginBotM">
      <div class="">
        <FieldInput v-model="ventData.entryMethod" />
        <FieldInput v-model="ventData.totalVentilation" />
      </div>
      <FieldInput v-model="ventData.continuousExhaust"/>
    </InputGroup>
    <InputGroup title="Recovery" class="mb-m">
      <div class="">
        <FieldInput v-model="ventData.recoveryType" />
      </div>
      <FieldGroup v-model="ventData.hrvGroup" />
      <FieldGroup v-model="ventData.ervGroup" />
    </InputGroup>
    <FieldInput v-model="ventData.otherBalancedAirflows" />
  </SimpleCollapse>
  <SimpleCollapse class="Section Emphasis" title="Infiltration" @onOpen="gApp.proj().setHelpId('Infiltration')">
    <HelpButton class="float-r" id="Infiltration">
      <h4 class="mb-s">Infiltration</h4>
      <p class="mb-s">
        It is recommended that the automatic calculation method is used for infiltration. If you or your office/company have a standard method of estimating infiltration, it may be of value to try entering a manual value and then try the standard built-in method for comparison.
        <br><br>
          The <b>Total Exposed Building Area</b> should be estimated from the exterior walls and roofs. Basement walls that are below the ground surface and basement floors do not count towards this value. If a floor is suspended above the ground and exposed to the atmosphere, then that area should be included. Otherwise, do not include the floor area.
        <br><br>
          For the <b>Building Construction Quality</b> input, use the following table as a guide for estimating the correct value:
        <br><br>
          <table class="SimpleTable" border="1">
            <tr>
              <td>Tight</td>
              <td>New building where construction will be or was supervised by an air sealing specialist.</td>
            </tr>
            <tr>
              <td>Good</td>
              <td>High quality construction of a new or semi-new building, carefully sealed by an experienced builder.</td>
            </tr>
            <tr>
              <td>Average</td>
              <td>Typical house.</td>
            </tr>
            <tr>
              <td>Leaky</td>
              <td>Typical pre-1970s house.</td>
            </tr>
            <tr>
              <td>Very Leaky</td>
              <td>Old house in original condition with no improvements to sealing.</td>
            </tr>
          </table>
        <br><br>
          The <b>Flue Pipe Effective Leakage Area</b> is the opening area of a flue pipe from a fuel-fired appliance that penetrates the exterior wall. A chimney is a common example of this. Dryer vents should not be included in this value. 
        <br><br>
        Example: a gas-fired furnace with a 3 in. diameter flue pipe. The area of the pipe is A=&pi;*r&sup2;=&pi;*(1.5)&sup2;=7.07in&sup2;. This would then be the value of the Flue Pipe Effective Leakage Area. If the house had another separate flue pipe, then the areas would be added together.
        <br><br>
        In the case of a chimney, be sure to use only the diameter of the outlet, regardless of how wide the main pipe of the chimney is.
      </p>
    </HelpButton>
    <div class="mb-m">
      <p class="mute-header">Info</p>
      <p class="help-text">
        Describe your house's infiltration.
      </p>
    </div>
    <div title="Infiltration">
      <FieldInput v-model="ventData.infiltrationEntryMethod" :labelWidth="300" />
      <FieldGroup v-model="ventData.infiltrationManual" :labelWidth="300" />
      <FieldGroup v-model="ventData.infiltrationAutomatic" :labelWidth="300" />
    </div>
  </SimpleCollapse>
  <SimpleCollapse class="Section Emphasis" title="Internal Gains" @onOpen="gApp.proj().setHelpId('InternalGains')">
    <HelpButton class="float-r" id="InternalGains">
      <h4 class="mb-s">Internal Gains</h4>
      <p class="mb-s">
        The internal gains may be entered manually, or you may opt to use <b>standard calculation methods</b>. The standards calculations are based on estimated occupancy and the total floor area.
        <br>
        <br>
        Internal gain estimates using the standard calculation methods are based on correlations taken from a large survey of homes and account for both sensible and latent loads from appliances, lighting, people, computers, etc., under normal conditions. 
        <br>
        <br>
        If you would like to size your system based on unusually high loads, such as a large number of occupants or uncommon appliances that give off high sensible or latent heat, then it is recommended to enter the information manually.
        <br>
        <br>
        Values calculated from this section will not contribute to the heating load.
      </p>
    </HelpButton>
    <div class="mb-m">
      <p class="mute-header">Info</p>
      <p class="help-text">
        Describe your house's internal gains.
      </p>
    </div>
    <FieldInput v-model="internals.useStdMethod" />
    <FieldInput v-model="internals.additionalOccupants" />
    <FieldInput v-model="internals.additionalSensibleLoads" />
    <FieldInput v-model="internals.additionalLatentLoads" />
  </SimpleCollapse>
  <WallsSection :space="gApp.proj()" />
  <RoofsSection :space="gApp.proj()" />
  <PartitionsSection :space="gApp.proj()" />
  <FloorsSection :space="gApp.proj()" />
  <SimpleCollapse class="Section MarginBotXL LastSection Emphasis" title="System Details" @onOpen="gApp.proj().setHelpId('SystemDetails')">
      <HelpButton class="float-r" id="SystemDetails">
        <h4 class="mb-s">System Details</h4>
        <p class="mb-s">
          Location of duct runs, leakage rate, insulation, and system type will have an impact on the system load without having any impact on space load. 
          <br><br>
            <b>Leakage rate</b> is difficult to predict prior to construction and testing of the system. If this value is unknown, it may be valuable to try different values and see the effect that leakage has on the system.
          <br><br>
          Typical leakage rates range from 5% to 11%. Data outside this range is not available, and it is not recommended to give a leakage rate outside of this range, unless you are confident that the leakage will be less than 5%.
          <br><br>
          Since leakage impacts beyond 11% are not available, any entry above 11% will be assumed to have the same effect as 11% leakage so as not to extrapolate data.
          <br><br>
          Leakage rates can be estimated with the following legend:
          <br><br>
          <table border="" class="SimpleTable">
            <tr>
              <td>New construction, well-sealed and insulated ducts of high quality construction</td>
              <td>≤ 5%</td>
            </tr>
            <tr>
              <td>New construction, medium level construction with no duct insulation</td>
              <td>5% to 7%</td>
            </tr>
            <tr>
              <td>Existing building (> 10 years old), little to no visible deterioration of sealing or duct insulation</td>
              <td>7% to 9%</td>
            </tr>
            <tr>
              <td>Old building with old ducts, sealant and/or duct insulation in poor condition</td>
              <td>9% to 11%</td>
            </tr>
            <tr>
              <td>Sealant non-existent or in very poor condition; noticeable leakage</td>
              <td>11%</td>
            </tr>
          </table>
          <br><br>
          System details only impact the distribution losses, which are reported as their own line item in the detailed load breakdown. In some cases, distribution losses may be a significant portion of the total system load, and caution should be taken when looking at space load versus system load.
        </p>
      </HelpButton>
      <div class="mb-m">
        <p class="mute-header">Info</p>
        <p class="help-text">
          Describe your house's system details here.
        </p>
      </div>
    <FieldInput v-model="misc.ductRunLocation"/>
    <InputGroup title="Leakage Rate">
      <FieldInput v-model="misc.typicalLeakageRateEntry" />
      <FieldInput v-model="misc.typicalLeakageRate" />
    </InputGroup>
    <FieldInput v-model="misc.ductInsulation" />
    <FieldInput v-model="misc.systemType" />
  </SimpleCollapse>
</template>

<style scoped>
.Section {
  margin-bottom: var(--space-xs);
}

.LastSection {
  margin-bottom: var(--space-xl);
}

.WallsTable td {
  vertical-align: top;
}

.RowDetails {
  border-left: 2px solid var(--pc-dark);
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.RowDetailsRow:hover {
  background-color: inherit;
}

.AddBtn {
  margin-top: var(--xs);
}

.CloseDetailsBtn {
  float: right;
}
</style>
