<script setup>
import { ref, onMounted, reactive, computed, watch } from 'vue'
import { gApp, Field, FieldType, YesNo, } from './State.js'
import { removeElem, prettyJson, formatNum,
  formatNumBrief, } from './SharedUtils.js'
import { Units, getLabel } from './Units.js'
import FieldInput from './FieldInput.vue'

const props = defineProps({
  results: {
    type: Object
  }
});

function formatLoadValue(num) {
  return Math.round(num);
}

function valOrNA(val) {
  if (val !== null && val !== undefined) {
    return formatLoadValue(val);
  } else {
    return 'N/A';
  }
}

</script>

<template>
  <table class="SimpleTable LoadsTable">
    <tr>
      <th colspan="5">Load Breakdown Summary</th>
    </tr>
    <tr>
      <th></th>
      <th colspan="2">Cooling [{{getLabel(Units.Load)}}]</th>
      <th colspan="2">Heating [{{getLabel(Units.Load)}}]</th>
    </tr>
    <tr>
      <th></th>
      <th>Sensible</th>
      <th>Latent</th>
      <th>Sensible</th>
      <th>Latent</th>
    </tr>
    <template v-for="(outputGroup, groupKey) in results.outputs">
      <tr>
        <td colspan="5"><b>{{ groupKey }}</b></td>
      </tr>
      <tr v-for="(subGroup, subGroupKey) in outputGroup">
        <td>{{ subGroupKey }}</td>
        <td>{{ valOrNA(subGroup.cooling.sensible) }}</td>
        <td>{{ valOrNA(subGroup.cooling.latent) }}</td>
        <td>{{ valOrNA(subGroup.heating.sensible) }}</td>
        <td>{{ valOrNA(subGroup.heating.latent) }}</td>
      </tr>
    </template>
    <tr>
    </tr>
  </table>
</template>

<style scoped>
.LoadsTable {
  width: 100%;
  max-width: 1000px;
}
</style>
