export let PlasteringMaterials = `
Material,Density,Conductivity,Resistance,Specific Heat Capacity,Thickness Required
"Cement plaster, sand aggregate",116,5,,0.2,1
Sand aggregate,,4.8,,0.2,1
Gypsum plaster,70,2.9,,,1
Perlite aggregate,45,1.5,,0.32,1
Sand aggregate (2),105,5.3,,0.2,1
"Vermiculite aggregate, low density",30,1.35,,,1
"Vermiculite aggregate, high density",45,1.9,,,1
"Perlite plaster, low density",25,0.55,,,1
"Perlite plaster, high density",38,1.32,,,1
Pulpboard or paper plaster,38,0.48,,,1
Sand/cement plaster,98,4.4,,,1
Sand/cement/lime plaster,90,3.33,,,1
Sand/gypsum (3:1) plaster,97,4.5,,,1`
