<script setup>
import { ref, onMounted, reactive, computed } from 'vue'

const props = defineProps({
  item: Object,
})

let isOpen = ref(false);

</script>

<template>
  <div class="LogItem">
    <div class="Flex text-btn CollapseBtn" @click="isOpen = !isOpen">
      <span>{{ isOpen ? 'vvv ' : '>>> ' }}</span>
      <p class="LogText" v-html="item.name"></p>
    </div>
    <div v-if="isOpen" class="SubItems">
      <div v-for="(subItem, i) in item.log" class="SubItem">
        <p v-if="typeof subItem === 'string'" class="LogText" v-html="subItem"></p>
        <CalcLogItemV2 v-else :item="subItem" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.SubItems {
  padding: 8px 8px 8px 16px;
  border-left: 1px solid lightgrey;
}

.SubItem {
  margin-bottom: 8px;
}

.SubItem:last-child {
  margin-bottom: 0px;
}

.CollapseButton {
}

.LogText:deep(.FuncName) {
  font-weight: bold;
  color: green;
}

.LogText:deep(.CallRes) {
  color: blue;
}

.LogText:deep(.InputsHdr) {
  font-weight: bold;
  color: lightseagreen;
}

.LogText:deep(.InputsArgs) {
  color: lightseagreen;
}

.LogText:deep(.RetValHdr) {
  font-weight: bold;
  color: blue;
}

.LogText:deep(.RetVal) {
  color: blue;
}

/*
.LogText >>> .InputsMsg {
}
*/

.LogText:deep(.ResultMsg) {
  margin-top: 24px;
}

.LogText:deep(.RootHdr) {
  font-weight: bold;
  color: black;
}

.LogText:deep(.FatalError) {
  color: red;
}

.LogText:deep(.LoadMatrixTable) {
}

</style>
