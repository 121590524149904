import { makeEnum, } from './Base.js'

export let SpaceType = makeEnum({
  Interior: 'Interior',
  Exterior: 'Exterior',
})

export let BuildingMass = makeEnum({
  Light: 'Light',
  Medium: 'Medium',
  Heavy: 'Heavy',
})

export let SpacePercentGlass = makeEnum({
  p10: '10%',
  p50: '50%',
  p90: '90%',
})

export let SpaceHasCarpet = makeEnum({
  Yes: 'Yes',
  No: 'No',
})