import { makeEnum, makeEnumWithData, makeOptions,
  makeEnumWithDataAndLabels,
  setupClass, lookupData, Matches,
} from './Base.js'

import { Field, FieldType, FieldGroup, } from './Field.js'

import { gApp, DebugOn } from './Globals.js'

export class Schedule {
  init(name, makeId) {
    this.name = Field.makeName(`Schedule Name`, name)
    this.id = makeId ? gApp.proj().makeId('Schedule') : 0;

    let fields = {}
    for (let i = 0; i < 24; i++) {
      fields[`${i}`] = new Field({
        name: `Hour ${i}`,
        type: FieldType.Percent,
      });
    }
    this.hourFields = FieldGroup.fromDict(fields)

    this.serFields = [
      'name',
      'id',
      'hourFields',
    ]
    this.childObjs = '$auto'
  }

  getHour(i) {
    return this.hourFields.get(`${i}`).value;
  }

  getData() {
    let data = []
    for (let i = 0; i < 24; i++) {
      data.push(this.getHour(i) / 100.0);
    }
    return data;
  }
}
setupClass(Schedule)
