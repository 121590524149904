<script setup>
import { ref, computed, watch, } from 'vue'
import { gApp, } from './State.js'
import { System, SystemType, } from './System.js'
import { Zone, ZoneSpace, } from './Zone.js'
import { formatNum, removeElem, generateItemName, isDupName, } from './SharedUtils.js'
import { Units, getLabel } from './Units.js'
import FieldInput from './FieldInput.vue'
import FieldGroup from './FieldGroup.vue'
import FieldList from './FieldList.vue'
import ConfirmModal from './ConfirmModal.vue'
import InputGroup from './InputGroup.vue'
import WarnText from './WarnText.vue'
import HelpSection from './HelpSection.vue'
import HelpButton from './HelpButton.vue'
import SimpleCollapse from './SimpleCollapse.vue'
import InputObject from './InputObject.vue'

const props = defineProps({
})

let proj = gApp.proj();
let selectedSystem = ref(null);
let deleteModal = ref(null);

let systems = computed(() => {
  return proj.systems;
})

function selectSystem(system) {
  selectedSystem.value = system;
}

function deleteSystem(system) {
  deleteModal.value.showModal("Delete", `Delete ${system.name.value}?`, () => {
    removeElem(systems.value, system);
  });
}

function addSystem() {
  systems.value.push(System.create(generateItemName('System', proj.systems, 'TYPE_NAME-CTR'), true))
  selectSystem(systems.value[systems.value.length - 1]);
}

function addZone(system) {
  let zone = Zone.create(generateItemName('Zone', system.zones, 'TYPE_NAME-CTR'))
  system.zones.push(zone);
}

</script>

<template>
  <div class="Container">
    <HelpSection id="SystemsView">
      <h4 class="mb-s">Systems</h4>
      <p class="mb-m">
        Use this page to build different  types for use in the Build House tab.
      </p>
      <p>
        The <b>R-value</b> of the  will impact both the heating and cooling loads. The absorptance value will only affect the cooling load.
        <br><br>
          Consult your local building code for minimum requirements of <b>R-value</b>. For <b>absorptance</b>, if the material and/or colour are unknown, a conservative estimate would be 0.9. Values above 0.9 are unusual and would likely cause unrealistic results.
        <br><br>

        When using the <b>Wall Builder</b>, certain materials have a standard thickness, and a thickness input is not required for those materials. Thermal resistance of convection on both interior and exterior surfaces has already been built into the program and should not be taken into account when building a wall.
        <br><br>

        The order that materials are placed in will not affect heating or cooling loads, only the total R-value and solar absorptance will affect the loads. The total R-value is the sum of R-values of each layer.
        <br><br>

         built in this section can be used as  types  in the Build House tab.

      </p>
    </HelpSection>
    <div v-if="selectedSystem">
      <div class="mb-l">
        <button @click="selectedSystem=null" class="LinkBtn mb-m"><i class="bi-arrow-left-circle mr-xxs"></i>Done Editing</button>
        <div class="Flex">
          <h1 class="">{{ selectedSystem.name.value || 'Untitled' }}</h1>
          <!-- <button class="LinkBtn">Rename</button> -->
        </div>
      </div>
      <SimpleCollapse title="Zones" class="Section Emphasis">
        <p class="help-text mb-m">Describe the System's zones.</p>
        <div class="mb-m">
          <SimpleCollapse v-for="(zone, i) in selectedSystem.zones" :title="zone.name.value"
            class="MarginBotXS">
            <div class="MarginBotS">
              <div class="mb-s">
                <FieldInput v-model="zone.name" />
              </div>
              <div class="mb-m">
                <p class="mute-header">Spaces</p>
                <table class="SimpleTable MinorTable w-90 mb-xs" v-if="zone.spaces.length">
                  <tr>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th></th>
                  </tr>
                  <tr v-for="space in zone.spaces">
                    <td><FieldInput v-model="space.spaceType" :compact="true"/></td>
                    <td><FieldInput v-model="space.quantity" :compact="true"/></td>
                    <td><button @click="removeElem(zone.spaces, space)" class="small-upper">Delete</button></td>
                  </tr>
                </table>
                <button @click="zone.spaces.push(ZoneSpace.create())" class="small-upper AddBtn">Add Space</button>
              </div>
              <p class="mute-header">Temps</p>
              <FieldList :labelWidth="300" :obj="zone" :fieldNames="zone.tempFields" class="" />
            </div>
            <button @click="removeElem(selectedSystem.zones, zone)" class="LinkBtn">Delete Zone</button>
          </SimpleCollapse>
        </div>
        <button @click="addZone(selectedSystem)" class="MarginBotXS CreateBtn">Add Zone</button>
      </SimpleCollapse>
      <SimpleCollapse title="General" class="Section Emphasis">
        <p class="help-text mb-m">Provide general info for the System.</p>
        <div class="mb-m">
          <FieldInput v-model="selectedSystem.name" class="MarginBotXS" />
          <WarnText v-if="isDupName(selectedSystem.name.value, systems)">There is already a system with that name. Please choose another.</WarnText>
        </div>
        <div class="mb-m">
          <!--<p class="mute-header">Basic</p>-->
          <FieldInput v-model="selectedSystem.estimatedTotalOccupancy" />
          <FieldInput v-model="selectedSystem.changeVentilationForModes" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Demand Controlled Ventilation</p>
          <FieldGroup v-model="selectedSystem.demandControlledVentilation" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Heat Recovery</p>
          <div class="mb-m">
            <FieldInput v-model="selectedSystem.heatRecovery.recoveryType" />
          </div>
          <FieldGroup class="mb-m" v-model="selectedSystem.heatRecovery.supplyAir" />
          <FieldGroup class="mb-m" v-model="selectedSystem.heatRecovery.exhaustAir" />
          <FieldGroup v-model="selectedSystem.heatRecovery.hrvGroup" />
          <FieldGroup v-model="selectedSystem.heatRecovery.ervGroup" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Economizer</p>
          <FieldGroup v-model="selectedSystem.economizer" />
        </div>
        <div class="mb-m">
          <p class="mute-header">Winter Humidity</p>
          <FieldList :obj="selectedSystem" :fieldNames="selectedSystem.winterHumidityFields" />
        </div>
      </SimpleCollapse>
      <SimpleCollapse title="Air Handler Fans" class="Section Emphasis">
        <p class="help-text mb-m">Describe the System's fans.</p>
        <div>
          <div class="mb-m">
            <p class="mute-header">Supply Fan</p>
            <FieldInput :labelWidth="300" v-model="selectedSystem.systemFans.useSupplyFan" />
            <FieldList v-if="selectedSystem.systemFans.usingSupplyFan()" :labelWidth="300" :obj="selectedSystem.systemFans.supplyFan" :fieldNames="selectedSystem.systemFans.supplyFan.fields" />
          </div>
          <div class="mb-m">
            <p class="mute-header">Return Fan</p>
            <FieldInput :labelWidth="300" v-model="selectedSystem.systemFans.useReturnFan" />
            <FieldList v-if="selectedSystem.systemFans.usingReturnFan()" :labelWidth="300" :obj="selectedSystem.systemFans.returnFan" :fieldNames="selectedSystem.systemFans.returnFan.fields" />
          </div>
        </div>
      </SimpleCollapse>
      <SimpleCollapse title="Details" class="Section Emphasis">
          <p class="help-text mb-m">Provide more system details.</p>
          <FieldList :labelWidth="300" :obj="selectedSystem" :fieldNames="selectedSystem.detailFields" />
      </SimpleCollapse>
      <SimpleCollapse :title="selectedSystem.getSystemName()" v-if="selectedSystem.systemType.value !== SystemType.Unknown" class="Section Emphasis">
          <p class="help-text mb-m">Provide details specific to the {{selectedSystem.getSystemName()}} system.</p>
          <div v-if="selectedSystem.systemType.value == SystemType.CAV">
            <InputObject :labelWidth="300" v-model="selectedSystem.cavSystemInputs" />
          </div>
          <div v-if="selectedSystem.systemType.value == SystemType.VAV">
            <InputObject :labelWidth="300" v-model="selectedSystem.vavSystemInputs" />
          </div>
          <div v-if="selectedSystem.systemType.value == SystemType.RadiantInductionOrChilledBeam">
            <InputObject :labelWidth="300" v-model="selectedSystem.radiantInductionSystemInputs" />
          </div>
      </SimpleCollapse>
    </div>
    <div v-else>
      <h1 class="PageH1">Systems</h1>
      <div class="mb-l">
        <p class="mute-header Container">Info</p>
        <p class="help-text">
          Add your building's Systems here. Each System has a list of Zones, which contain Spaces. Heating and cooling loads will be calculated for each System.
        </p>
      </div>
      <button @click="addSystem()" class="MarginBotXS CreateBtn">Add System</button>
      <table class="SimpleTable SystemsTable" v-if="systems.length">
        <colgroup>
          <col style="width: 300px;">
          <col>
          <!-- other columns -->
        </colgroup>
        <tr>
          <th>Name</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="system in systems" class="WallSection MarginBotL">
          <td>{{ system.name.value }}</td>
          <td>
            <button @click="selectSystem(system)">Edit</button>
          </td>
          <td>
            <button @click="deleteSystem(system)">Delete</button>
          </td>
        </tr>
      </table>
    </div>
    <ConfirmModal ref="deleteModal"></ConfirmModal>
  </div>
</template>

<style scoped>
.Result {
  color: blue;
}

.Section {
  margin-bottom: var(--space-xs);
}

</style>
